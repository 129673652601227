import React from 'react';
import { Grid, Modal } from 'semantic-ui-react';

const ViewMembershipFaqModal = (props) => (

    <Modal open='true' className="large infoBox editorStyleFix" data-testid='viewMembershipFaq'>
        <Modal.Header>
            <span className={(props.selectedItem.Status == "true" || props.selectedItem.Status) ? 'statusActive' : 'statusInactive'}>{(props.selectedItem.Status == "true" || props.selectedItem.Status) ? 'Active' : 'Inactive'}</span>
            <i onClick={() => { props.closeModal(2) }} class="close">&#x2716;</i>
        </Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Grid>
                    <Grid.Row className="mt10">
                        <Grid.Column>
                            <div class="questionLabel" dangerouslySetInnerHTML={{ __html: props.selectedItem.Question }}></div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <div class="answerLabel" dangerouslySetInnerHTML={{ __html: props.selectedItem.Answer }}></div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="actions">
                            {props.userPermission.Update && <a className="editBtn" onClick={() => { props.openEditModal(props.selectedItem.FAQId) }}>
                                <i aria-hidden="true" class="pencil icon"></i>
                            </a>}

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Description>
        </Modal.Content >
    </Modal >
)

export default ViewMembershipFaqModal;
