import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { truncateText } from 'helpers/utilCommon';
import { Icon } from 'semantic-ui-react';


class Dragdrop extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: props.items,
      padding: 8
    };

    this.onDragEnd = this.onDragEnd.bind(this);
  }

  // A little function to help us with reordering the result
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // Some basic styles to make the items look a bit nicer
    userSelect: 'none',
    display: isDragging ? 'table' : '',
    // Change background colour if dragging
    background: isDragging ? '#f1f1f1' : '#000',

    // Styles we need to apply on draggables
    ...draggableStyle
  });

  getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : '#f1f1f1',
    padding: this.state.padding,
    border: this.state.border
  });


  onDragEnd(result) {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.props.resequenceItems(items);

    this.setState({
      items
    });
  }

  componentDidMount() {

    const targetElements = document.getElementsByClassName('truncatedText');

    for (let i = 0; i < targetElements.length; i++) {
      truncateText(targetElements[i], 2, 40);
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { items } = this.state;
    const { openViewModal, allowResequenceing, deleteFAQ, allowDelete } = this.props;

    return (
      allowResequenceing ?
        <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <tbody
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.FAQId} draggableId={String(item.FAQId)} index={index}>
                    {(provided, snapshot) => (

                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <td className="question" onClick={e => openViewModal(item)}>
                          <i aria-hidden="true" class="equal icon"></i>
                          <div className="truncatedText" dangerouslySetInnerHTML={{ __html: item.Question }}></div>
                        </td>
                        <td className="answer">
                          <div className="truncatedText" dangerouslySetInnerHTML={{ __html: item.Answer }}>
                          </div>
                        </td>
                        <td className="status">
                          {(item.Status == "true" || item.Status) ? 'Active' : 'Inactive'}
                        </td>
                        {allowDelete && <td className="action">
                          <button data-testid="deleteFaq" onClick={e => deleteFAQ(item.FAQId)}> <Icon name='trash' className="squareIcon" /></button>
                        </td>}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext> :

        <tbody>
          {items.map((item, index) => (
            <tr >
              <td className="question" onClick={e => openViewModal(item)}>
                <i aria-hidden="true" class="equal icon"></i>
                <div className="truncatedText" dangerouslySetInnerHTML={{ __html: item.Question }}></div>
              </td>
              <td className="answer">
                <div class="truncatedText" dangerouslySetInnerHTML={{ __html: item.Answer }}>
                </div>
              </td>
              <td>
                {(item.Status == "true" || item.Status) ? 'Active' : 'Inactive'}
              </td>
            </tr>
          ))}

        </tbody>
    );
  }
}

export default Dragdrop
