// @flow
import * as type from 'actionTypes';

const init = {
	initialValues: {
		ReportTitle: '',
		ReportID: "",
		ReportType: "",
		TableauURL: ""
	}
}

const reducerReports = (state = init, action) => {
	switch (action.type) {

		default:
			return state;
	}
};

export default reducerReports;