import * as commonActions from 'actions/commonActions';
import * as type from 'actionTypes';
import axios from 'axios';
import getApiUrl from 'helpers/apiUrls';
import * as lodash from 'lodash';
import * as utilCommon from '../helpers/utilCommon';
// Login
export const login = (dispatch, inputDTO, callback) => {
	const url = getApiUrl('login', 'login');
	dispatch(commonActions.setLoader(true))
	axios.post(url, inputDTO)
		.then((response) => {
			dispatch(commonActions.setLoader(false))
			if (response.data.success === true && response.data.accessToken) {
				utilCommon.setLoginDataToCacheManager(response, 'LOGIN'); // Add Details in LS
				dispatch({ type: type.LOGIN, loginCredential: response.data });
				callback();
			} else {
				utilCommon.setLoginDataToCacheManager(response, 'DELETE'); // Delete Details from LS.
				if (response.data.message !== '') {
					dispatch(commonActions.setMessage(true, response.data.message));
				} else {
					dispatch(commonActions.setMessage(true, '1011'));
				}
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '1011'));
		});
};

export const authenticateByAzureADAction = (dispatch, callback) => {
	const url = getApiUrl('login', 'authenticateByAzureAD');
	dispatch(commonActions.setLoader(true))
	axios.get(url).then((response) => {
		dispatch(commonActions.setLoader(false))
		if (lodash.get(response.data, 'success')) {
			utilCommon.setLoginDataToCacheManager(response, 'LOGIN'); // Add details in LS.
			dispatch({ type: type.AZURE_LOGIN, azureCredentials: response });
			// Return true if get accessToken from OSL
			callback(true, lodash.get(response.data, 'message'));
		} else {
			utilCommon.setLoginDataToCacheManager(response, 'DELETE');
			callback(false, lodash.get(response.data, 'message'));
		}
	})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '1012'));
		});
};

// Auth by Cookies token
export const authByCookiesTokenAction = (dispatch, inputDTO, callback) => {
	const url = getApiUrl('login', 'authCookiesToken');
	dispatch(commonActions.setLoader(true))
	axios.post(url, { source: inputDTO.appId }).then((response) => {
		dispatch(commonActions.setLoader(false))
		if (lodash.get(response.data, 'success')) {
			utilCommon.setLoginDataToCacheManager(response, 'LOGIN'); // Add details in LS.
			dispatch({ type: type.AZURE_LOGIN, azureCredentials: response });
			// Return true if get accessToken from OSL
			callback(true, lodash.get(response.data, 'message'));
		} else {
			utilCommon.setLoginDataToCacheManager(response, 'DELETE');
			callback(false, lodash.get(response.data, 'message'));
		}
	})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '1012'));
		});
};




export default {
	login,
	authenticateByAzureADAction,
	authByCookiesTokenAction
};
