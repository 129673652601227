import React from 'react';
import { Tab } from 'semantic-ui-react'
import DetailsForm from 'components/forms/Membership/MembershipType/detailsForm';
import SettingForm from 'components/forms/Membership/MembershipType/settingForm';

const panes = [
    {
        menuItem: 'Details', render: (props) =>
            <Tab.Pane disabled={props.tab.details} >
                <DetailsForm {...props} />
            </Tab.Pane>
    },
    {
        menuItem: 'Settings', render: (props) =>
            <Tab.Pane disabled={props.tab.setting} className="active" >
                <SettingForm {...props} />
            </Tab.Pane>
    }

]

const AddMembershipForm = (props) => (<Tab panes={panes} {...props} activeIndex={props.activeTab} onTabChange={props.handleTabChange} />)
export default AddMembershipForm;
