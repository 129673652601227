import AccordianMenu from "components/atoms/AccordianMenu";
import StandardBreadcrumb from 'components/atoms/Breadcrumb';
import HeaderMenu from 'components/atoms/Header';
import PageNotFound from 'components/atoms/PageNotFound';
import UnAuthorizeAccess from 'components/atoms/UnAuthorize';
import { addRedirectUrlToLogin, allowPermissionForAuth, cacheManager, filterPermissionUrl, noCookiesRedirectToLogin, validateNavigationFromUrl } from 'helpers/utilCommon';
import _ from 'lodash';
import React from "react";
import { HIDE_NOT_FOUND_PAGE, SHOW_NOT_FOUND_PAGE } from '../../../actionTypes';

class Layout extends React.PureComponent {

	constructor(props) {
		super(props);
		const self = this;
		self.state = {
			loading: false,
			selectedComponent: 'application-list',
			isRedirect: false,
			previousPathName: '',
			parent: '',
			currentPage: '',
			parentPath: '',
			isAuthorizedPage: false // Used to check permission on page,
		};
		this.validateCustomLoginRedirectUrl();
	}

	validateCustomLoginRedirectUrl = () => {
		const { isUserAuthorized, isLoginByAzureAD } = this.props;
		let redirectUrl = '/';
		// CONDITION WORKS ONLY WITHOUT LOGIN CONDITION
		if (!isUserAuthorized && !isLoginByAzureAD) { // VALIDATE THE TOEKN EXIST OR NOT
			if (this.props.location.pathname && this.props.location.pathname.length > 0) {
				redirectUrl = addRedirectUrlToLogin(this.props.location.pathname, isLoginByAzureAD);
			}
			this.props.history.push(redirectUrl);
		}
	}

	validateUrlHistory = () => {
		this.validateBreadcrumbsUrls();
		this.unlisten = this.props.history.listen((location, action) => {
			if (location.pathname !== this.state.previousPathName) {
				// Update Breadcrumb on Change Url
				this.validateBreadcrumbsUrls();
				// Hanlde on Navigation to Redirect to login if Other application has been logout.
				noCookiesRedirectToLogin()
			}
		}, this);
	}

	componentDidMount() {
		this.validateUrlHistory();
	}

	componentWillUnmount() {
		if (this.unlisten) {
			this.unlisten();
		}
	}

	// Validate only GET Permission from Requested Urls
	checkGetPermissionRequestFromUrl = (requestUrl) => {
		let isAuth = false;
		// Condition for Allow Default Route
		if (requestUrl && requestUrl.routeUrl === 'home') {
			isAuth = true
		} else {
			// Rest Validate condition for Route allow or not
			let isPageAuthorized = filterPermissionUrl(requestUrl, requestUrl.oslMethod);
			isAuth = allowPermissionForAuth(isPageAuthorized);
		}
		return isAuth;
	}
	// Validate BreadCrumb from Requested Urls
	validateBreadcrumbsUrls = () => {
		let parent = '';
		let currentPage = '';
		let parentPath = '';
		let isAuth = false;
		const currentPath = this.props.history.location.pathname;
		this.props.dispatchEvent(HIDE_NOT_FOUND_PAGE);
		// Get URL Details
		let urlDetails = validateNavigationFromUrl(currentPath);
		if (!urlDetails) {	// IF Routes Not Matched
			this.props.dispatchEvent(SHOW_NOT_FOUND_PAGE);
			return false;
		}
		// IF Routes Matched
		if (_.get(urlDetails, 'selectedSingleResult', null) && _.get(urlDetails, 'selectedSingleResult').length > 0) {
			let navUrlResult = _.get(urlDetails, 'selectedSingleResult')[0];
			parent = navUrlResult.breadcrumbsParentTitle;
			currentPage = navUrlResult.breadcrumbsTitle;
			parentPath = navUrlResult.breadcrumbsParentPath;
			isAuth = this.checkGetPermissionRequestFromUrl(navUrlResult);
			this.props.getPermissionOnPage(navUrlResult, _.get(urlDetails, 'selectedFullResult', null))
		}
		this.setState({
			parent,
			currentPage,
			previousPathName: currentPath,
			parentPath,
			isAuthorizedPage: isAuth
		})
	}

	render() {
		const userDetail = cacheManager.getItem('userDetail') && JSON.parse(cacheManager.getItem('userDetail'))
		const { parent, currentPage, isAuthorizedPage, parentPath } = this.state;
		const { showPageNotFound, logout, isUserAuthorized } = this.props;
		return (

			isUserAuthorized ? (
				<div data-testid="layoutCmp" className="homePage">
					<HeaderMenu userDetail={userDetail} logout={logout}></HeaderMenu>
					<div className="ui container padTopBot50">
						<StandardBreadcrumb parent={parent} currentPage={currentPage} parentPath={parentPath} />
						<section className="sideMenuLeft">
							<AccordianMenu ></AccordianMenu>
						</section>
						<section className="mainWrapperRight">
							{showPageNotFound ? <PageNotFound /> : isAuthorizedPage ? this.props.children : <UnAuthorizeAccess />}
						</section>
					</div>
				</div>
			) : ""
		);
	}
}

export default Layout;
