
import CheckboxElement from 'components/atoms/CheckBox';
import RenderSelect from 'components/atoms/Select';
import AddCommitteeTypeForm from 'components/forms/Committee/addCommitteeTypeForm';
import React, { Component } from "react";
import { reduxForm } from 'redux-form';
import { Accordion, Form, Grid, Icon, Popup } from 'semantic-ui-react';


class ViewCommitteeTypeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            committeeTypeDetails: {},
            operatorList: [],
            classificationListLeft: [],
            classificationListRight: [],
            activePopUp: -1
        }

    }

    componentWillMount() {
        const committeeTypeId = this.props.location.pathname.split('/')[3] || 0;
        if (committeeTypeId === '0' || committeeTypeId === 0) {
            this.props.openAddform(() => this.setState({ isAddButtonClicked: true, isViewMode: false, isEdit: false }));
        } else {
            this.props.getCommitteeTypeDetails(committeeTypeId, this.setCommitteeTypeDetails);
            this.setState({
                isAddButtonClicked: false, isViewMode: true
            });
            let scrollElement = document.getElementById('root');
            scrollElement.scrollTop = 0;
        }

    }

    setCommitteeTypeDetails = (val) => {
        this.setState({ committeeTypeDetails: val });
        if (val && val.IsBalanceRequired) {
            this.props.getOperatorList(this.setOperatorList);
            this.props.getClassificationList(this.setClassificationListData);
        }
    }

    setOperatorList = (response) => {

        this.setState({
            operatorList: response,
            loading: false
        });
    }

    setClassificationListData = (response) => {
        if (response) {
            const committeeTypeDetails = this.state.committeeTypeDetails;
            let classificationListLeft = JSON.parse(JSON.stringify(response));
            let classificationListRight = JSON.parse(JSON.stringify(response));
            for (let j = 0; j < committeeTypeDetails.CommitteeBalanceRule.length; j++) {
                for (let i = 0; i < response.length; i++) {
                    if (committeeTypeDetails.CommitteeBalanceRule[j].ClassificationTypeId === response[i].ClassificationTypeId
                        && committeeTypeDetails.CommitteeBalanceRule[j].IsLeft === true) {
                        classificationListLeft[i].checked = true;
                    } else if (committeeTypeDetails.CommitteeBalanceRule[j].ClassificationTypeId === response[i].ClassificationTypeId
                        && committeeTypeDetails.CommitteeBalanceRule[j].IsLeft === false) {
                        classificationListRight[i].checked = true;
                    }

                }
            }
            classificationListLeft = classificationListLeft.filter((item) => item.checked === true);
            classificationListRight = classificationListRight.filter((item) => item.checked === true);
            this.setState({
                classificationListLeft: classificationListLeft,
                classificationListRight: classificationListRight,
                loading: false
            });
        }

    }

    setData = (list) => {
        let response = []
        if (list && list.length > 0) {
            list.map((res) => {
                let result = {};
                result['value'] = res['OperatorId'];
                result['text'] = res['OperatorName'];
                response.push(result);
            });
        } return response;
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    editButtonClicked = (id) => {
        let data = { id: id }
        this.props.getCommitteeTypeDetailForEdit(data, () => this.setState({
            isViewMode: false,
            isAddButtonClicked: true,
            isEdit: true
        }))

    }

    backButtonClickedEvent() { // WHILE CLICK ON BACK/CANCEL BUTTON
        this.props.resetModel();
        this.setState({
            isAddButtonClicked: false, isViewMode: false, isEdit: false
        });
        this.props.history.push('/committee-list');
    }
    handleOpenPopUp = (event, index) => {
        this.setState({ activePopUp: index })
    }
    handleCanclePopUp = () => {
        this.setState({ activePopUp: -1 })
    }

    render() {

        const { activeIndex, committeeTypeDetails, operatorList, classificationListLeft,
            classificationListRight, isAddButtonClicked, isViewMode, isEdit, activePopUp } = this.state;
        const { userPermission } = this.props;
        return (
            <div data-testid='manageCommitteeTypeCmp'>
                {!isAddButtonClicked &&
                    <div class="headingTitle clearfix mb20">
                        <h2>Manage Committee Type</h2>
                        {userPermission.Update && <a className="editBtn" onClick={() => this.editButtonClicked(committeeTypeDetails.CommitteeTypeId)}><i aria-hidden="true" class="pencil icon"></i></a>}
                    </div>}
                {!isAddButtonClicked &&
                    <div className="addCommitteeWrap">
                        <Form size='large' noValidate>
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column >
                                        <label className="titleLabel">Committee Type Name</label>
                                        <div className="titleInfo">
                                            {committeeTypeDetails.CommitteeTypeName}
                                        </div>

                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <h5>Manage Committee Hierarchy</h5>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <label className="titleLabel mt10">Levels
                                    </label>
                                        <div>
                                            {committeeTypeDetails.CommitteeLevel && committeeTypeDetails.CommitteeLevel.length > 0 &&
                                                (
                                                    committeeTypeDetails.CommitteeLevel.map((_item, index) => {
                                                        return <span className="levelPills">{_item.CommitteeLevelName}

                                                            <Popup trigger={<Icon name="ellipsis vertical" onClick={(event) => this.handleOpenPopUp(event, index)} />}
                                                                flowing
                                                                hoverable
                                                                hideOnScroll
                                                                className="manageFields"
                                                                position="right center"
                                                                open={activePopUp == index}
                                                                onClose={this.handleCanclePopUp}
                                                            >
                                                                <div className="privGrroupname">Eligible Fields</div>

                                                                <Grid className="privActionList" vertical columns='4' style={{ 'border-bottom': 'none' }}>
                                                                    {_item.Eligibilities && _item.Eligibilities.length > 0 &&
                                                                        _item.Eligibilities.map((eligibleItem) => {
                                                                            return <Grid.Column>
                                                                                <CheckboxElement
                                                                                    IsChecked={eligibleItem.Status}
                                                                                    IsEditable={false}
                                                                                    setLabeltitleProp={eligibleItem.Title}
                                                                                />
                                                                            </Grid.Column>
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </Popup>
                                                        </span>
                                                    }))}
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <label className="titleLabel">Max. Number of Members Permitted</label>
                                        <div className="titleInfo">
                                            {committeeTypeDetails.IsLimitedMembersPermitted ? 'Limited' : 'Unlimited'}
                                        </div>

                                    </Grid.Column>

                                    {committeeTypeDetails.IsLimitedMembersPermitted && <Grid.Column >
                                        <label className="titleLabel">Number of Members</label>
                                        <div className="titleInfo">
                                            {committeeTypeDetails.NoOfMembersPermitted}
                                        </div>

                                    </Grid.Column>}
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <h5>Membership Type eligible to join</h5>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <label className="titleLabel">Selected Membership Types</label>
                                        <div className="titleInfo">
                                            {committeeTypeDetails.MembershipType && committeeTypeDetails.MembershipType.length > 0 &&
                                                (
                                                    committeeTypeDetails.MembershipType.map((_item) => {
                                                        return <span className="levelPills">{_item.MembershipTypeName}</span>
                                                    }))}

                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column >
                                        <div className="borderDashed"></div>

                                        <label className="titleLabel">Balance Required</label>
                                        <div className="titleInfo">
                                            {committeeTypeDetails.IsBalanceRequired ? 'Yes' : 'No'}
                                        </div>
                                    </Grid.Column>

                                </Grid.Row>
                                {committeeTypeDetails.IsBalanceRequired &&
                                    <Grid.Row>
                                        <Grid.Column>
                                            <label className="titleLabel">Set Balance Rule</label>
                                            <section className="greyBox type2 clearfix">
                                                <div className="memClassType ">
                                                    <span className="memClassTypeTitle">Member Classification Type</span>
                                                    <ul className="memClassTypeList">
                                                        {classificationListLeft.length > 0 &&
                                                            (classificationListLeft.map((_item) => {
                                                                return <li>
                                                                    <CheckboxElement IsEditable={false} setLabeltitleProp={_item.Name} IsChecked={_item.checked} />

                                                                </li>
                                                            }))}

                                                    </ul>
                                                </div>
                                                <div className="operator ">
                                                    <span className="memClassTypeTitle">Operator</span>
                                                    {operatorList.length > 0 &&
                                                        <RenderSelect placeholder="" value={committeeTypeDetails.OperatorId}
                                                            options={this.setData(operatorList)} disabled={true} />}
                                                </div>
                                                <div className="memClassType ">
                                                    <span className="memClassTypeTitle">Member Classification Type</span>
                                                    <ul className="memClassTypeList">
                                                        {classificationListRight.length > 0 &&
                                                            (classificationListRight.map((_item, index) => {
                                                                return <li>
                                                                    <CheckboxElement IsEditable={false} setLabeltitleProp={_item.Name} IsChecked={_item.checked} />
                                                                </li>
                                                            }))}
                                                    </ul>
                                                </div>

                                            </section>
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                                <Grid.Row>
                                    <Grid.Column className="comitteeAccord">
                                        <div className="borderDashed"></div>
                                        <div className="mb20">
                                            <CheckboxElement IsChecked={committeeTypeDetails.IsEnableCommitteeOnWeb} IsEditable={false} className="chbx" setLabeltitleProp={'Suppress committee from public web (committee home page, search, product pages and jurisdiction list)'} />
                                        </div>
                                        <div className="mb20">
                                            <CheckboxElement IsChecked={committeeTypeDetails.IsSuppressOnWI} IsEditable={false} className="chbx" setLabeltitleProp={'Suppress committee from registering/editing a work item or submitting a ballot item'} />
                                        </div>
                                        {committeeTypeDetails.IsEnableCommitteeOnWeb && (
                                            <Accordion fluid >
                                                {committeeTypeDetails.ExternalApplication.map((item, index) => {
                                                    return <React.Fragment>
                                                        <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
                                                            <Icon name='dropdown' />
                                                            {item.ApplicationName}
                                                        </Accordion.Title>
                                                        <Accordion.Content active={activeIndex === index}>

                                                            <div className="checkBoxWrapper">
                                                                <Grid columns='4'>
                                                                    <Grid.Row>
                                                                        {item.Privilege.map((privilegItem) => {
                                                                            return <Grid.Column>
                                                                                <CheckboxElement IsChecked={privilegItem.IsChecked} IsEditable={false} className="chbx" setLabeltitleProp={privilegItem.ApplicationPrivilegeName} />

                                                                            </Grid.Column>
                                                                        })}
                                                                    </Grid.Row>

                                                                </Grid>
                                                            </div>
                                                        </Accordion.Content>
                                                    </React.Fragment>

                                                })}
                                            </Accordion>
                                        )}

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Form>
                    </div>}
                {isAddButtonClicked && < AddCommitteeTypeForm {...this.props}
                    viewmode={isViewMode} backButtonClicked={this.backButtonClickedEvent.bind(this)} isEdit={isEdit} />}
            </div>
        );
    }
}
export default (reduxForm({
    form: 'viewCommitteeTypeForm'
})(ViewCommitteeTypeForm));
