// Store and services
import membershipDocumentActions from "actions/Membership/membershipDocumentActions";
import * as type from 'actionTypes';
import MembershipDocumentList from 'components/lists/Membership/documentList';
import { connect } from "react-redux";
import commonActions from 'actions/commonActions';
import { uploadFileInS3Action } from "../../actions/commonActions";


const mapStateToProps = state => {
    return {
        initialValues: state.documentReducer.documentData,
        noRecordMessage: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['6003'].text,
        documentNameRequired: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2201'].text,
        isDuplicateDocName: state.documentReducer.isDuplicateDocName,
        duplicateDocNameError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2210'].text,
        userPermission: state.commonReducer.userPermission
    }
};

const mapDispatchToProps = (dispatch) => ({
    addMembershipDocument: (application, callback) => {
        membershipDocumentActions.addMembershipDocument(dispatch, application, callback);
    },
    getMemberShipDocumentsList: (callback) => {
        membershipDocumentActions.getMemberShipDocumentsList(dispatch, callback);
    },
    editMembershipDocument: (application, callback) => {
        membershipDocumentActions.editMembershipDocument(dispatch, application, callback);
    },
    setEditFormData: function (value, callback) {
        dispatch({ type: type.VIEW_DOCUMENT, documentData: value });
        callback();
    },
    resetState: () => {
        dispatch({ type: type.RESET_DOCUMENT_STATE })
    },
    deleteDocument: (payload, callback) => {
        membershipDocumentActions.deleteDocument(dispatch, payload, callback);
    },
    displayNoChangesMadeMessage: () => dispatch(commonActions.setMessage(true, '2040')),
    getAwsSigned: (fileName, callback) => {
        membershipDocumentActions.getAwsSignedAction(dispatch, fileName, callback);
    },
    uploadFileInS3: (url, postData, callback) => {
        uploadFileInS3Action(dispatch, url, postData, callback);
    },
    downloadFile: (payload, callback) => {
        membershipDocumentActions.downloadFileAction(dispatch, payload, callback);
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipDocumentList);