// Store and services
import officerTitleActions from "actions/Committee/officerTitleActions";
import * as type from 'actionTypes';
import OfficerTitleList from 'components/lists/Committee/officerTitlesList';
import commonActions from 'actions/commonActions'
import { connect } from "react-redux";


const mapStateToProps = state => {
    return {
        initialValues: state.officerTitleReducer.officerTitleData,
        noRecordMessage: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['6004'].text,
        requiredTitleNameError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2220'].text,
        delinkConfirmMessag: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2030'].text,
        isDuplicateOfficerTitle: state.officerTitleReducer.isDuplicateOfficerTitle,
        duplicateOfficerTitleError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2226'].text,
        userPermission: state.commonReducer.userPermission
    }
};

const mapDispatchToProps = (dispatch) => ({
    getOfficerTitleList: (data, callback) => officerTitleActions.getOfficerTitleList(data, dispatch, callback),
    getOfficerTitleDetail: (data, callback) => officerTitleActions.getOfficerTitleDetail(dispatch, data, callback),
    addOfficerTitle: (data, callback) => officerTitleActions.addOfficerTitle(dispatch, data, callback),
    resetState: () => dispatch({ type: type.RESET_OFFICER_TITLE_STATE }),
    editOfficerTitle: (data, callback) => officerTitleActions.editOfficerTitle(dispatch, data, callback),
    editResponsibility: (data, callback) => officerTitleActions.editResponsibility(dispatch, data, callback),
    getResponsibility: (data, callback) => officerTitleActions.getResponsibility(dispatch, data, callback),
    postResequence: (data, callback) => officerTitleActions.postResequence(dispatch, data, callback),
    getOfficerResponsebilityCommitteeTypeLevel: (data, callback) => officerTitleActions.getOfficerResponsebilityCommitteeTypeLevel(data, dispatch, callback),
    postOfficerTitleCommitteeImpact: (data, callback) => officerTitleActions.postOfficerTitleCommitteeImpact(data, dispatch, callback),
    getOfficerTitleAlreadyExists: (data, callback) => officerTitleActions.getOfficerTitleAlreadyExists(data, dispatch, callback),
    displayNoChangesMadeMessage: () => dispatch(commonActions.setMessage(true, '2040'))
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficerTitleList);
