import React from 'react';
import { Button, Modal, Form, Grid, Icon } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import RenderInputField from 'components/atoms/Input';
import { MembershipDocumentModel } from 'models/Membership/membershipDocumentModel';
import RenderRadioField from 'components/atoms/RadioBox';
import { isEqual, get } from 'lodash';
import { getFileType } from '../../../helpers/utilCommon';
import UploadPopup from '../../atoms/UploadPopup';

class UpdateMembershipDocument extends React.Component {

    nameRequired = value => value && value.trim() ? undefined : this.props.documentNameRequired;
    state = {
        fileObjects: {
            signedParamUrl: '',
            file: '',
            filePath: '',
            isProcess: false,
            status: false
        }
    }
    closeModal = () => {
        this.props.resetState();
        this.props.closeModal(1);
    }

    handleForm = (values) => {
        const { initialValues } = this.props
        const document = MembershipDocumentModel;

        try {
            document.Id = values.Id;
            if (values.status !== undefined) {
                document.Status = values.status === 'active';
            } else {
                document.Status = this.props.initialValues.Status;
            }
            document.Name = values.Name.trim();
            document.BlobContainerName = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
            if (this.state.fileObjects.file) {
                document.BlobFileName = this.state.fileObjects.filePath
                document.Type = getFileType(this.state.fileObjects.file.name);
            }
            else {
                document.BlobFileName = values.BlobFileName;
                document.Type = values.Type;
            }

            const currentDocumentObj = {
                Name: document.Name,
                Status: document.Status,
                BlobFileName: document.BlobFileName,
                BlobContainerName: document.BlobContainerName,
                DocumentType: document.Type
            }
            const prevDocumentObj = {
                Name: initialValues.Name,
                Status: initialValues.Status,
                BlobFileName: initialValues.BlobFileName,
                BlobContainerName: initialValues.BlobContainerName,
                DocumentType: document.Type
            }
            if (isEqual(currentDocumentObj, prevDocumentObj)) {
                this.props.displayNoChangesMadeMessage();
                this.props.closeModal(1);
            } else if (this.state.fileObjects.file && this.state.fileObjects.status) {
                this.props.uploadFileInS3(this.state.fileObjects.signedParamUrl, this.state.fileObjects.file, isUploaded => {
                    if (isUploaded) {
                        this.props.editMembershipDocument(document, () => {
                            this.props.closeModal(1);
                        });
                    }
                });
            } else {
                this.props.editMembershipDocument(document, () => {
                    this.props.closeModal(1);
                });
            }
        }
        catch (err) {
            console.log('Error occured while updating document.', err);
        }
    };

    // TODO : request for signed Url in OSL.
    signedAwsUrlHandler = (file, cb) => {
        this.props.getAwsSigned(file, result => {
            cb(result)
        });
    }
    // TODO : Callback to get with valid file extention and signed Url
    resultSetHandler = (result) => {
        if (result.status) {
            let params = {};
            params.signedParamUrl = result.signedParams.url
            params.file = result.file
            params.filePath = result.signedParams.params.Key
            params.isProcess = result.isProcess
            params.status = result.status;
            this.setState({ fileObjects: params });
        } else {
            this.setState({ fileObjects: result });
        }
    }

    render() {
        const { handleSubmit, initialValues, change, isDuplicateDocName, duplicateDocNameError, submitting } = this.props;

        return (

            <Modal open='true' className="tiny" data-testid = 'editDocumentForm'>
                <Form size='large' onSubmit={handleSubmit(this.handleForm)} novalidate>
                    <Modal.Header>Edit Document <i onClick={this.closeModal} class="close">&#x2716;</i></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>

                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="Name" type="text"
                                            component={RenderInputField} label="Document Name"
                                            validate={[this.nameRequired]}
                                            autoFocus='true' maxLength="100" required='true'
                                            isDuplicate={isDuplicateDocName}
                                            duplicateError={duplicateDocNameError}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label className="label"> <i aria-hidden="true" class="asterisk  icon"></i> Status</label>
                                        <Field name="status"
                                            component={RenderRadioField}
                                            className="mr10"
                                            type="radio" change={change}
                                            options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]}
                                            selectedValue={initialValues.Status ? 'active' : 'inactive'} />

                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <label className="label">
                                            <i aria-hidden="true" class="asterisk  icon"></i> Upload Document
                                        </label>
                                        <label className="label">
                                            <Icon name={initialValues.Type === 'pdf' ? 'file pdf outline' : 'file word outline'} /> {initialValues.BlobFileName}
                                        </label>
                                        <UploadPopup title="UPLOAD ATTACHMENT"
                                            extension="pdf, doc, docx"
                                            formatLabel="pdf, doc, docx"
                                            dirName={''}
                                            signedAwsUrlHandler={this.signedAwsUrlHandler}
                                            resultSetHandler={this.resultSetHandler}
                                            action={false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column className="actions">
                                        <Button primary type="submit" disabled={get(this.state.fileObjects, 'isProcess') || submitting}>
                                            Save
                                        </Button>
                                        <Button secondary onClick={this.closeModal} className="cancel">
                                            Cancel
                                        </Button>

                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>

                        </Modal.Description>
                    </Modal.Content>
                </Form>
            </Modal>
        );
    }
}

export default (reduxForm({
    form: 'updateMembershipDocument'
})(UpdateMembershipDocument));
