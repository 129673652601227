import * as type from 'actionTypes';

const initialState = {
	documentData: null,
	isDuplicateDocName: false
};

const reducerDocument = (state = initialState, action) => {
	switch (action.type) {

		case type.VIEW_DOCUMENT:
			const data = {
				Id: action.documentData.Id,
				Key: action.documentData.Key,
				Name: action.documentData.Name,
				Type: action.documentData.Type,
				Status: action.documentData.Status,
				BlobFileName: action.documentData.BlobFileName,
				BlobContainerName: action.documentData.BlobContainerName
			};

			return {
				...state,
				documentData: data
			};
		case type.ADD_DOCUMENT:
		case type.EDIT_DOCUMENT:
			return {
				...state,
				isDuplicateDocName: action.isDuplicateDocName
			};
		case type.RESET_DOCUMENT_STATE:
			return {
				...state,
				isDuplicateDocName: false
			}
		default:
			return state;
	}
};

export default reducerDocument;