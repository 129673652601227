import axios from 'axios';
import getApiUrl from 'helpers/apiUrls';
import * as commonActions from 'actions/commonActions';
import * as type from 'actionTypes';

export const getFaqList = (dispatch, callback, faqTypeId = 1) => {

	let url = getApiUrl('faq', 'getFaqs');
	url = url + "/" + faqTypeId;
	dispatch(commonActions.setLoader(true));
	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
				callback();
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7007'));
			callback();
		});
};

export const addFaq = (dispatch, faqObj, callback) => {
	const url = getApiUrl('faq', 'addFaq');
	dispatch(commonActions.setLoader(true));
	axios.post(url, faqObj)
		.then((response) => {
			dispatch(commonActions.setLoader(false));

			if (response.data.status) {
				callback(response.data);
				dispatch(commonActions.setMessage(true, '5003'));
			}
			// Handle error case
			else if (response.data.message === "2020") {
				dispatch({ type: type.ADD_FAQ, isDuplicateQuestion: true })
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7008'));
		});
};

export const editFaq = (dispatch, faqObj, callback) => {
	const url = getApiUrl('faq', 'editFaq');
	dispatch(commonActions.setLoader(true));

	axios.put(url, faqObj)
		.then((response) => {
			dispatch(commonActions.setLoader(false));

			if (response.data.status) {
				callback();
				dispatch(commonActions.setMessage(true, '5004'));
			}
			// Handle error case
			else if (response.data.message === "2020") {
				dispatch({ type: type.EDIT_FAQ, isDuplicateQuestion: true })
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7012'));
		});
};

export const getFaqDetails = (faqId, dispatch, callback, faqTypeId = 1) => {
	const url = getApiUrl('faq', 'getFaqDetails') + '/' + faqTypeId + '/' + faqId;

	dispatch(commonActions.setLoader(true));

	axios.get(url)
		.then((response) => {

			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				dispatch({ type: type.VIEW_FAQ, faqData: response.data.content })
				callback();
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7013'));
		});
};

export const resequenceFaqs = (dispatch, faqsList) => {
	const url = getApiUrl('faq', 'resequenceFaqs');
	dispatch(commonActions.setLoader(true));

	axios.put(url, faqsList)
		.then((response) => {
			dispatch(commonActions.setLoader(false));

			if (response.data.status) {
				dispatch(commonActions.setMessage(true, '5005'));
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7014'));
		});
};

export const deleteFAQ = (dispatch, faqId, callback) => {
	const url = getApiUrl('faq', 'deleteFaq') + '/' + faqId;
	dispatch(commonActions.setLoader(true));

	axios.delete(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));

			if (response.data.status) {
				callback();
				dispatch(commonActions.setMessage(true, '5018'));
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7012'));
		});
};

export default {
	getFaqList,
	addFaq,
	editFaq,
	getFaqDetails,
	resequenceFaqs,
	deleteFAQ
}
