// @flow
import * as type from 'actionTypes';

const init = {
	isEdit: false,
	initialValues: {
		IsUnlimitedMembers: 'Unlimited',
		IsUnlimitedCommittees: 'Unlimited',
		RenewalPeriodId: 1,
		membershipTypesApplication: []
	},
	validateSupress: false,
	activeTab: 0

}

// Method to store the initial values of suppress flags
const formatInitialValues = (data) => {
	data.IsSuppressFeeRenewalEmailsOld = data.IsSuppressFeeRenewalEmails;
	data.IsSuppressFeeRenewalPrintOld = data.IsSuppressFeeRenewalPrint;
	data.AutoRenewalApplicableOld = data.AutoRenewalApplicable;

	return data;
}

const reducerMembershipType = (state = init, action) => {
	switch (action.type) {

		case type.MEMBERSHIPTYPE_LIST:
			return {
				...state,
				type: action.type,
				_list: action.payload,
				initialValues: undefined,
				isEdit: false
			};
		case type.MEMBERSHIPTYPE_DETAILS_SUBMIT: // Submit details
			return {
				...state,
				initialValues: Object.assign({}, state.initialValues, action.payload.details),
				_details: action.payload.details,
				type: action.type,
				_app: action.payload.app,
				_doc: action.payload.doc,
				_period: action.payload.period
			};
		case type.MEMBERSHIPTYPE_SETTING_BACK_EVENT: // Back from setting to details
			return {
				...state,
				_activeTab: action.payload,
				type: action.type,
				activeTab: 0,
				initialValues: state._details ? state._details : undefined
			};

		case type.MEMBERSHIPTYPE_ENABLE_WEB_APP_SELECTED_EVENT:
			return {
				...state,
				_enableWebAppSelection: action.payload,
				type: action.type
			};
		case type.MEMBERSHIPTYPE_ENABLE_WEB_APP_DATA_EVENT:
			return {
				...state,
				_enableWebData: action.payload,
				type: action.type
			};
		case type.MEMBERSHIPTYPE_SETTING_SUBMIT:
			return {
				...state,
				type: action.type
			};
		case type.MEMBERSHIPTYPE_EDIT:
			return {
				...state,
				type: action.type,
				_editData: action.payload.memberShipTypeData,
				isEdit: true,
				initialValues: formatInitialValues(action.payload.memberShipTypeData),
				validateSupress: action.payload.suppressData
			};
		case type.MEMBERSHIPTYPE_EDIT_ON_DEATILS:
			return {
				...state,
				type: action.type,
				isEdit: true
			};
		case type.MEMBERSHIPTYPE_EDIT_ON_SETTING:
			return {
				...state,
				type: action.type,
				isEdit: false

			};
		case type.MEMBERSHIPTYPE_ENABLE_WEB_APP_DOC_SELECTED_EVENT: // Select doc and application checked

			return {
				...state,
				_enableWebData: action.payload,
				type: action.type
			};
		case type.MEMBERSHIPTYPE_DOC_DELETE:
			return { ...state }
		case type.MEMBERSHIPTYPE_SETTING_HOLD_VALUE:
			let _initialValues = state._details;
			if (action.payload && _initialValues) {
				action.payload.map((object) => { _initialValues[object.key] = object.value })
			}

			return {
				...state,
				initialValues: _initialValues ? _initialValues : undefined
			}
		case type.MEMBERSHIPTYPE_CLEAR_ACTION:
			return {
				...state,
				type: ''
			}
		default:
			return state;
	}
};

export default reducerMembershipType;