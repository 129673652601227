import CheckboxElement from "components/atoms/CheckBox/checkbox";
import RenderInputField from 'components/atoms/Input';
import RenderRadioField from "components/atoms/RadioBox";
import SelectSimple from "components/atoms/SelectDropdown";
import AccordionFluidMembershiptType from 'components/forms/Membership/MembershipType/accordianForm';
import _ from 'lodash';
import React, { Component } from 'react';
import { change, Field, reduxForm, untouch } from 'redux-form';
import { Confirm, Form, Grid } from 'semantic-ui-react';
import { membershipTypeDisableNewMembership } from "../../../../models/Membership/membershipTypeModel";
let prevDataMemberShipTypeDetails = {};
class SettingForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isViewMode: false,
            isPermittedMembers: false,
            isPermittedCommittees: false,
            isEnableOnWeb: false,
            isPeriodChange: false,
            IsUnlimitedMembersValue: 'Unlimited',
            IsUnlimitedCommitteesValues: 'Unlimited',
            _enableWebData: [],
            _selectedDocument: [],
            selectedPeriodId: 1,
            IsSuppressFeeRenewalEmails: false,
            IsSuppressFeeRenewalPrint: false,
            alertMessage: 'Update will be applicable to only those records which are not associated with this Membership Type yet',
            isConfirm: false
        }
        this.membershipTypesApplication = null;
    }

    componentDidMount() {
        this.setState({ isViewMode: true });
        if (!this.props.isEdit) {
            this.props.change('RenewalPeriodId', this.props.periodList[0].RenewalPeriodId)
            this.setState({ RenewalPeriodId: this.props.periodList[0].RenewalPeriodId })
        }
        prevDataMemberShipTypeDetails = _.cloneDeep(this.props.membershipTypeDetails)
    }

    componentWillMount() {
        if (_.get(this.props, 'isEdit', false)) {
            this.setSettingValueOnEdit(this.props.membershipTypeDetails);
            this.props.membershipTypeDetailsSubmit(this.props.membershipTypeDetails);
        } else if (this.props.membershipTypeDetails) {
            this.setSettingValueOnEdit(this.props.membershipTypeDetails);
        }
    }
    oncloseAlert = () => {
        this.setState({ isConfirm: false })
    }
    // Set data on edit while load first
    setSettingValueOnEdit = (data) => {
        // VALIDATE THE UNLIMITED MEMBER KEY
        if (data.IsUnlimitedMembers === true || data.IsUnlimitedMembers === undefined) {
            this.setState({
                isPermittedMembers: false,
                IsUnlimitedMembersValue: 'Unlimited'
            });
        } else {
            this.setState({
                isPermittedMembers: true,
                IsUnlimitedMembersValue: 'Limited'
            });
        }

        // VALIDATE THE COMMITTEE KEY
        if (data.IsUnlimitedCommittees === true || data.IsUnlimitedCommittees === undefined) {
            this.setState({
                isPermittedCommittees: false,
                IsUnlimitedCommitteesValues: 'Unlimited'
            })
        } else {
            this.setState({
                isPermittedCommittees: true,
                IsUnlimitedCommitteesValues: 'Limited'
            })
        }

        // VALIDATE THE PERIOD KEY
        this.setState({ isPeriodChange: _.get(data, 'RenewalPeriodId', 0) === 4 })

        // DISPLAY ENABLED DATA
        this.setState({ isEnableOnWeb: _.get(data, 'EnableOnWeb', false) })

        // Supress Fee Renewal Emails
        this.setState({ IsSuppressFeeRenewalEmails: _.get(data, 'IsSuppressFeeRenewalEmails', false) });

        // SupressFeeRenewal Print
        this.setState({ IsSuppressFeeRenewalPrint: _.get(data, 'IsSuppressFeeRenewalPrint', false) });


    }

    permittedMemberOnChangeHandler = (e) => {
        const { textContent } = e.currentTarget;
        let obj = []
        if (textContent === 'Limited') {
            this.resetFields('settingForm', {
                MaxNumberofMember: ''
            });
            obj.push({ key: 'MaxNumberofMember', value: '' })
            this.setState({
                isPermittedMembers: true
            });
            obj.push({ key: 'IsUnlimitedMembers', value: false })
            obj.push({ key: 'isPermittedMembers', value: true })
        } else {
            this.setState({
                isPermittedMembers: false
            })
            obj.push({ key: 'IsUnlimitedMembers', value: true });
            obj.push({ key: 'isPermittedMembers', value: false })
        }
        this.props.membersettingHoldValue(obj);
    }

    permittedCommitteesOnChangeHandler = (e) => {
        const { textContent } = e.currentTarget;
        let obj = []
        if (textContent === 'Limited') {
            this.resetFields('settingForm', {
                MaxNumberofCommittee: ''
            });
            obj.push({ key: 'MaxNumberofCommittee', value: '' })

            this.setState({
                isPermittedCommittees: true
            });
            obj.push({ key: 'IsUnlimitedCommittees', value: false })
            obj.push({ key: 'isPermittedCommittees', value: true })
        } else {
            this.setState({
                isPermittedCommittees: false
            })
            obj.push({ key: 'IsUnlimitedCommittees', value: true });
            obj.push({ key: 'isPermittedCommittees', value: false })
        }
        this.props.membersettingHoldValue(obj);
    }

    enableOnWebHandler = (e) => {
        this.setState({
            isEnableOnWeb: !this.state.isEnableOnWeb
        }, (() => {
            this.props.membersettingHoldValue([{ key: 'EnableOnWeb', value: this.state.isEnableOnWeb }]);
        }))
    }

    setData = (list) => {
        let response = []
        if ((list || []).length > 0) {
            list.map((res) => {
                let result = {};
                result.key = res.RenewalPeriodId;
                result.value = res.RenewalPeriodId;
                result.text = res.RenewalPeriod;
                switch (result.text) {
                    case 'Yearly':
                        result.disabled = true
                        break;
                    case 'Not Applicable':
                        result.disabled = true
                        break;
                    case 'Other':
                        result.disabled = true
                        break;
                    default:
                }
                response.push(result);
            });
        }
        return response;
    }

    componentDidUpdate(prevProps) {
        if (_.get(prevProps, 'isEdit', false)) {
            if (prevProps.app !== this.props.app) {
                this.setSettingValueOnEdit(this.props.membershipTypeDetails);
            }
        }
        // Update the details when changing from view to edit mode
        if (prevProps.viewMode && !this.props.viewMode) {
            prevDataMemberShipTypeDetails = _.cloneDeep(this.props.membershipTypeDetails)
        }
    }


    onChangePeriodHandler = (e, value) => {
        if (value === 4) {
            this.resetFields('settingForm', { SpecifyMonth: '' });
            this.setState({ isPeriodChange: true })
        } else {
            this.setState({ isPeriodChange: false })
        }
    }

    resetFields = (formName, fieldsObj) => {
        Object.keys(fieldsObj).forEach(fieldKey => {
            // Rset the field's value
            this.props.dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));
            // Reset the field's error
            this.props.dispatch(untouch(formName, fieldKey));
        });
    }

    onchangeCheckBox = (e, value, name) => {
        const self = this;
        switch (name) {
            case 'IsSuppressFeeRenewalEmails':
                if (self.props.isEdit) {
                    self.setState({
                        IsSuppressFeeRenewalEmails: value,
                        isConfirm: true,
                        alertMessage: 'Update will be applicable to only those records which are not associated with this Membership Type yet.'
                    }, () => {
                        self.props.membersettingHoldValue([{ key: name, value: value }])
                    });

                } else {
                    self.props.membersettingHoldValue([{ key: name, value: value }]);
                }
                break;
            case 'IsSuppressFeeRenewalPrint':
                if (self.props.isEdit) {
                    self.setState({
                        IsSuppressFeeRenewalPrint: value,
                        isConfirm: true,
                        alertMessage: 'Update will be applicable to only those records which are not associated with this Membership Type yet.'
                    }, () => self.props.membersettingHoldValue([{ key: name, value: value }]));

                } else {
                    self.props.membersettingHoldValue([{ key: name, value: value }]);
                }
                break;
            default:
                self.props.membersettingHoldValue([{ key: name, value: value }]);
        }
    }

    getRenewalPeriodName = (selectedPeriodId) => {
        const { periodList } = this.props;

        if (periodList && periodList.length > 0) {
            let period = periodList.find(item => item.RenewalPeriodId === selectedPeriodId);

            if (period) {
                return period.RenewalPeriod;
            }
        }

        return '';
    }

    render() {
        const { isPermittedMembers, isPermittedCommittees, isEnableOnWeb, IsUnlimitedCommitteesValues,
            IsUnlimitedMembersValue, selectedPeriodId, alertMessage } = this.state;
        const { viewMode, initialValues, periodList } = this.props;

        if (!viewMode) {
            return (
                <Form size='large' onSubmit={this.props.handleSubmit} data-testid='setting-form' >

                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <label className="label">Max. Number of Members Permitted</label>
                                <div className="radioWrap">
                                    <Field name="IsUnlimitedMembers"
                                        formName="addMembershipForm"
                                        component={RenderRadioField}
                                        className="mr10"
                                        type="radio"
                                        selectedValue={IsUnlimitedMembersValue}
                                        onChange={(e) => this.permittedMemberOnChangeHandler(e)}
                                        options={[{ label: 'Unlimited', value: 'Unlimited' }, { label: 'Limited', value: 'Limited' }]} />

                                </div>
                            </Grid.Column>
                            {isPermittedMembers &&
                                <Grid.Column className="twoColInput">
                                    <label className="label"><i aria-hidden="true" class="asterisk  icon"></i>Number of Members</label>
                                    <Field
                                        type="text"
                                        name="MaxNumberofMember"
                                        component={RenderInputField}
                                        placeholder="Max Number of Member"
                                        maxLength="5"
                                        {...this.props.rest}
                                    />
                                </Grid.Column>
                            }

                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <label className="label">Max. Number of Committees Permitted to Join</label>
                                <div className="radioWrap">
                                    <Field name="IsUnlimitedCommittees"
                                        formName="addMembershipForm"
                                        component={RenderRadioField}
                                        className="mr10"
                                        type="radio"
                                        selectedValue={IsUnlimitedCommitteesValues}
                                        onChange={(e) => this.permittedCommitteesOnChangeHandler(e)}
                                        options={[{ label: 'Unlimited', value: 'Unlimited' }, { label: 'Limited', value: 'Limited' }]} />
                                </div>
                            </Grid.Column>
                            {isPermittedCommittees &&
                                <Grid.Column className="twoColInput">
                                    <label className="label"><i aria-hidden="true" class="asterisk  icon"></i>Number of Committees</label>
                                    <Field
                                        type="text"
                                        name="MaxNumberofCommittee"
                                        component={RenderInputField}
                                        placeholder="Max Number of Committee"
                                        className="dollarPreFix"
                                        maxLength="5"
                                    />
                                </Grid.Column>
                            }
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <label className="label">
                                    <i aria-hidden="true" class="asterisk  icon"></i>
                                    Renewal Period
                                </label>
                                <Field
                                    name="RenewalPeriodId"
                                    value={selectedPeriodId}
                                    onChange={this.onChangePeriodHandler}
                                    options={this.setData(periodList)}
                                    component={SelectSimple}
                                />

                            </Grid.Column>
                            {this.state.isPeriodChange &&
                                <Grid.Column className="twoColInput">
                                    <label className="label"><i aria-hidden="true" class="asterisk  icon"></i>Specify Months</label>
                                    <Field
                                        type="text"
                                        name="SpecifyMonth"
                                        component={RenderInputField}
                                        className="dollarPreFix"
                                        maxLength="5"
                                    />
                                </Grid.Column>
                            }
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <Field name="IsSuppressFeeRenewalEmails"
                                    label="Suppress Fee Renewal Emails"
                                    component={CheckboxElement} value={true} onChange={(e, value) => this.onchangeCheckBox(e, value, 'IsSuppressFeeRenewalEmails')}
                                    type="checkbox" />

                            </Grid.Column>
                            <Grid.Column className="twoColInput">
                                <Field name="IsSuppressFeeRenewalPrint"
                                    label="Suppress Fee Renewal Print"
                                    component={CheckboxElement} onChange={(e, value) => this.onchangeCheckBox(e, value, 'IsSuppressFeeRenewalPrint')}
                                    type="checkbox" />
                            </Grid.Column>

                            <Grid.Column className="twoColInput">
                                <Field name="AutoRenewalApplicable"
                                    label="Auto-Renewal Applicable"
                                    component={CheckboxElement} type="checkbox" onChange={(e, value) => this.onchangeCheckBox(e, value, 'AutoRenewalApplicable')} />

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <Field name="EnableOnWeb"
                                    label="Enable Membership Type on web"
                                    component={CheckboxElement} type="checkbox"
                                    onChange={(e) => this.enableOnWebHandler(e)}
                                />

                            </Grid.Column>
                            {membershipTypeDisableNewMembership.includes(initialValues.MembershipTypeId) ? 
                            <Grid.Column className="twoColInput" style={{ pointerEvents: 'none' }}>
                                <Field name="IsNewMembership"
                                    label="Enable New Membership through Manual Order"
                                    component={CheckboxElement} disabled type="checkbox"
                                />
                            </Grid.Column>: <Grid.Column className="twoColInput">
                                <Field name="IsNewMembership"
                                    label="Enable New Membership through Manual Order"
                                    component={CheckboxElement} type="checkbox" onChange={(e, value) => this.onchangeCheckBox(e, value, 'IsNewMembership')} />
                            </Grid.Column>}                      
                            <Grid.Column className="twoColInput">
                                <Field name="IsRenewal"
                                    label="Enable Renewal through Manual Order"
                                    component={CheckboxElement} type="checkbox" onChange={(e, value) => this.onchangeCheckBox(e, value, 'IsRenewal')} />

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <Field name="IsReinstatement"
                                    label="Enable Reinstatement through Manual Order"
                                    component={CheckboxElement} type="checkbox" onChange={(e, value) => this.onchangeCheckBox(e, value, 'IsReinstatement')} />

                            </Grid.Column>
                        </Grid.Row>
                        {isEnableOnWeb &&
                            <Grid.Row>
                                <Grid.Column>
                                    <div class="mt10">
                                        <AccordionFluidMembershiptType {...this.props}></AccordionFluidMembershiptType>
                                    </div>
                                </Grid.Column>

                            </Grid.Row>
                        }
                    </Grid>
                    <Confirm className="confirmBox noCancelBtn" confirmButton="Ok" content={alertMessage}
                        onCancel={this.oncloseAlert} onConfirm={this.oncloseAlert} open={false} />
                </Form>
            );
        }
        return (

            <Form size='large' onSubmit={this.props.handleSubmit} >

                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column className="twoColInput">
                            <label className="titleLabel">Max. Number of Members Permitted</label>
                            <span className="titleInfo">{IsUnlimitedMembersValue}</span>
                        </Grid.Column>
                        {isPermittedMembers &&
                            <Grid.Column className="twoColInput">
                                <label className="titleLabel">Number of Members</label>
                                <span className="titleInfo">{initialValues.MaxNumberofMember}</span>
                            </Grid.Column>
                        }
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="twoColInput">
                            <label className="titleLabel">Max. Number of Committees Permitted to Join</label>
                            <span className="titleInfo">{IsUnlimitedCommitteesValues}</span>
                        </Grid.Column>
                        {isPermittedCommittees &&
                            <Grid.Column className="twoColInput">
                                <label className="titleLabel">Number of Committees</label>
                                <span className="titleInfo">{initialValues.MaxNumberofCommittee}</span>
                            </Grid.Column>
                        }
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="twoColInput">
                            <label className="titleLabel">Renewal Period</label>
                            <span className="titleInfo">{this.getRenewalPeriodName(initialValues.RenewalPeriodId)}</span>
                        </Grid.Column>
                        {this.state.isPeriodChange &&
                            <Grid.Column className="twoColInput">
                                <label className="titleLabel">Specify Months</label>
                                <span className="titleInfo">{initialValues.SpecifyMonth}</span>
                            </Grid.Column>
                        }
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="twoColInput" style={{ pointerEvents: 'none' }}>
                            <Field name="IsSuppressFeeRenewalEmails" component={CheckboxElement}
                                label="Suppress Fee Renewal Emails"
                                type="checkbox" />

                        </Grid.Column>

                        <Grid.Column className="twoColInput" style={{ pointerEvents: 'none' }}>
                            <Field name="IsSuppressFeeRenewalPrint"
                                label="Suppress Fee Renewal Print"
                                component={CheckboxElement}
                                type="checkbox" />

                        </Grid.Column>

                        <Grid.Column className="twoColInput" style={{ pointerEvents: 'none' }}>
                            <Field name="AutoRenewalApplicable"
                                label="Auto-Renewal Applicable"
                                component={CheckboxElement} type="checkbox" />

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="twoColInput" style={{ pointerEvents: 'none' }}>
                            <Field name="EnableOnWeb"
                                label="Enable Membership Type on web"
                                component={CheckboxElement} type="checkbox"
                                onChange={(e) => this.enableOnWebHandler(e)}
                            />

                        </Grid.Column>
                        <Grid.Column className="twoColInput" style={{ pointerEvents: 'none' }}>
                            <Field name="IsNewMembership"
                                label="Enable New Membership through Manual Order"
                                component={CheckboxElement} type="checkbox"
                            />

                        </Grid.Column>
                        <Grid.Column className="twoColInput" style={{ pointerEvents: 'none' }}>
                            <Field name="IsRenewal"
                                label="Enable Renewal through Manual Order"
                                component={CheckboxElement} type="checkbox"
                            />

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="twoColInput" style={{ pointerEvents: 'none' }}>
                            <Field name="IsReinstatement"
                                label="Enable Reinstatement through Manual Order"
                                component={CheckboxElement} type="checkbox"
                            />

                        </Grid.Column>
                    </Grid.Row>
                    {isEnableOnWeb &&
                        <Grid.Row>
                            <Grid.Column>
                                <div class="mt10">
                                    <AccordionFluidMembershiptType {...this.props}></AccordionFluidMembershiptType>
                                </div>
                            </Grid.Column>

                        </Grid.Row>
                    }
                </Grid>

            </Form>
        )
    }
}

const submitHandler = (data, dispatch, props) => { // REMOTE BUTTON HANDLE EVENT
    const { details, appWithDocSelection, membershipTypeDetails } = props;
    let appList = _.uniq(appWithDocSelection, 'ApplicationId');
    let toDelete = [];
    let newAppList = [];
    if (appList) {
        for (let i = 0; i < appList.length; i++) {
            if (!appList[i].ExposeAnnualFee &&
                !appList[i].ExposeBenefits &&
                !appList[i].ExposeFAQ &&
                !appList[i].ExposeMembershipName &&
                !appList[i].ExposeSummary &&
                !appList[i].ExsposeDescription &&
                appList[i].MembershipTypesAppDocument.length === 0) {
                toDelete.push(appList[i].ApplicationId);
            }
        }
        const setToDelete = new Set(toDelete);
        newAppList = setToDelete.size > 0 ? appList.filter(obj => !setToDelete.has(obj.ApplicationId)) : appList;
    }

    let ResponseData = {
        "IsSuppressFeeRenewalEmailsOld": membershipTypeDetails.IsSuppressFeeRenewalEmailsOld !== undefined ? membershipTypeDetails.IsSuppressFeeRenewalEmailsOld : false,
        "IsSuppressFeeRenewalPrintOld": membershipTypeDetails.IsSuppressFeeRenewalPrintOld !== undefined ? membershipTypeDetails.IsSuppressFeeRenewalPrintOld : false,
        "AutoRenewalApplicableOld": membershipTypeDetails.AutoRenewalApplicableOld !== undefined ? membershipTypeDetails.AutoRenewalApplicableOld : false,
        "IsUnlimitedMembers": data.IsUnlimitedMembers === 'Unlimited' || data.IsUnlimitedMembers === true || data.IsUnlimitedMembers === undefined,
        "IsUnlimitedCommittees": data.IsUnlimitedCommittees === 'Unlimited' || data.IsUnlimitedCommittees === true || data.IsUnlimitedCommittees === undefined,
        "EnableOnWeb": data.EnableOnWeb ? data.EnableOnWeb : false,
        "MaxNumberofMember": data.MaxNumberofMember ? parseInt(data.MaxNumberofMember) : null,
        "MaxNumberofCommittee": data.MaxNumberofCommittee ? parseInt(data.MaxNumberofCommittee) : null,
        "RenewalPeriodId": data.RenewalPeriodId ? parseInt(data.RenewalPeriodId) : "", // Now default value change in feature
        "CanParticipateinTechCommittee": data.CanParticipateinTechCommittee ? data.CanParticipateinTechCommittee : false,
        "ReceiveFreeMemberBenefits": data.ReceiveFreeMemberBenefits ? data.ReceiveFreeMemberBenefits : false,
        "AutoRenewalApplicable": data.AutoRenewalApplicable ? data.AutoRenewalApplicable : false,
        "SendRenewalNotice": data.SendRenewalNotice ? data.SendRenewalNotice : false,
        "SpecifyMonth": data.SpecifyMonth ? parseInt(data.SpecifyMonth) : null,
        "membershipTypesApplication": newAppList ? newAppList : null,
        "IsSuppressFeeRenewalEmails": data.IsSuppressFeeRenewalEmails ? data.IsSuppressFeeRenewalEmails : false,
        "IsSuppressFeeRenewalPrint": data.IsSuppressFeeRenewalPrint ? data.IsSuppressFeeRenewalPrint : false,
        "IsReinstatement": data.IsReinstatement ? data.IsReinstatement : false,
        "IsRenewal": data.IsRenewal ? data.IsRenewal : false,
        "IsNewMembership": data.IsNewMembership ? data.IsNewMembership : false
    }

    const dataToCompare = comparePrevData(ResponseData);

    if (_.isEqual(dataToCompare[0], dataToCompare[1]) && _.isEqual(dataToCompare[2], dataToCompare[3])) {
        props.displayNoChangesMadeMessage();
        props.backButtonClicked();

    } else if (_.get(props, 'isEdit', false)) {
        ResponseData.MembershipTypeId = _.get(props.membershipTypeDetails, 'MembershipTypeId');
        props.membershipTypeSettingSubmitOnEdit(ResponseData, () => {
            props.membershipTypeList();
            props.history.push('/membership-type');
        });
    } else {
        ResponseData.MembershipTypeName = details.MembershipTypeName || '';
        ResponseData.FeeAmount = details.FeeAmount ? parseFloat(details.FeeAmount) : 0.0;
        ResponseData.IncomeType = details.IncomeType;
        ResponseData.Summary = details.Summary || '';
        ResponseData.Benefits = details.Benefits || '';
        ResponseData.Description = details.Description || '';
        props.memberTypeSubmit(ResponseData, () => {
            callafterSubmit(props);
        });
    }
}

const comparePrevData = (ResponseData) => {
    const currentMemberShipTypesApplicationArray = [];
    const prevMemberShipTypesApplicationArray = [];
    const currentData = {
        IsUnlimitedMembers: ResponseData.IsUnlimitedMembers,
        IsUnlimitedCommittees: ResponseData.IsUnlimitedCommittees,
        MaxNumberofMember: ResponseData.MaxNumberofMember,
        MaxNumberofCommittee: ResponseData.MaxNumberofCommittee,
        RenewalPeriodId: ResponseData.RenewalPeriodId,
        IsSuppressFeeRenewalEmails: ResponseData.IsSuppressFeeRenewalEmails,
        IsSuppressFeeRenewalPrint: ResponseData.IsSuppressFeeRenewalPrint,
        AutoRenewalApplicable: ResponseData.AutoRenewalApplicable,
        EnableOnWeb: ResponseData.EnableOnWeb,
        SpecifyMonth: ResponseData.SpecifyMonth,
        IncomeType: ResponseData.IncomeType,
        IsReinstatement: ResponseData.IsReinstatement,
        IsRenewal: ResponseData.IsRenewal,
        IsNewMembership: ResponseData.IsNewMembership
    };
    const prevData = {
        IsUnlimitedMembers: prevDataMemberShipTypeDetails.IsUnlimitedMembers,
        IsUnlimitedCommittees: prevDataMemberShipTypeDetails.IsUnlimitedCommittees,
        MaxNumberofMember: prevDataMemberShipTypeDetails.MaxNumberofMember,
        MaxNumberofCommittee: prevDataMemberShipTypeDetails.MaxNumberofCommittee,
        RenewalPeriodId: prevDataMemberShipTypeDetails.RenewalPeriodId,
        IsSuppressFeeRenewalEmails: prevDataMemberShipTypeDetails.IsSuppressFeeRenewalEmails,
        IsSuppressFeeRenewalPrint: prevDataMemberShipTypeDetails.IsSuppressFeeRenewalPrint,
        AutoRenewalApplicable: prevDataMemberShipTypeDetails.AutoRenewalApplicable,
        EnableOnWeb: prevDataMemberShipTypeDetails.EnableOnWeb,
        SpecifyMonth: prevDataMemberShipTypeDetails.SpecifyMonth,
        IncomeType: prevDataMemberShipTypeDetails.IncomeType,
        IsReinstatement: prevDataMemberShipTypeDetails.IsReinstatement,
        IsRenewal: prevDataMemberShipTypeDetails.IsRenewal,
        IsNewMembership: prevDataMemberShipTypeDetails.IsNewMembership
    };

    (ResponseData.membershipTypesApplication && ResponseData.membershipTypesApplication.length > 0) && ResponseData.membershipTypesApplication.sort((a, b) => {
        return a.ApplicationId - b.ApplicationId;
    }).map((membershipTypesApplicationItem) => {
        currentMemberShipTypesApplicationArray.push(
            membershipTypesApplicationItem.ApplicationId,
            membershipTypesApplicationItem.ExposeMembershipName,
            membershipTypesApplicationItem.ExposeSummary,
            membershipTypesApplicationItem.ExsposeDescription,
            membershipTypesApplicationItem.ExposeBenefits,
            membershipTypesApplicationItem.ExposeAnnualFee,
            membershipTypesApplicationItem.ExposeFAQ
        )
        membershipTypesApplicationItem.MembershipTypesAppDocument.length > 0 && membershipTypesApplicationItem.MembershipTypesAppDocument.map((membershipTypesAppDocumentItem) => {
            currentMemberShipTypesApplicationArray.push(membershipTypesAppDocumentItem.DocumentId);
        })
    });

    (prevDataMemberShipTypeDetails.MembershipTypesApplication && prevDataMemberShipTypeDetails.MembershipTypesApplication.length > 0) && prevDataMemberShipTypeDetails.MembershipTypesApplication.sort((a, b) => {
        return a.ApplicationId - b.ApplicationId;
    }).map((membershipTypesApplicationItem) => {
        prevMemberShipTypesApplicationArray.push(
            membershipTypesApplicationItem.ApplicationId,
            membershipTypesApplicationItem.ExposeMembershipName,
            membershipTypesApplicationItem.ExposeSummary,
            membershipTypesApplicationItem.ExsposeDescription,
            membershipTypesApplicationItem.ExposeBenefits,
            membershipTypesApplicationItem.ExposeAnnualFee,
            membershipTypesApplicationItem.ExposeFAQ
        )
        membershipTypesApplicationItem.MembershipTypesAppDocument.length > 0 && membershipTypesApplicationItem.MembershipTypesAppDocument.map((membershipTypesAppDocumentItem) => {
            prevMemberShipTypesApplicationArray.push(membershipTypesAppDocumentItem.DocumentId);
        })
    });
    return [currentData, prevData, currentMemberShipTypesApplicationArray, prevMemberShipTypesApplicationArray];
}

const callafterSubmit = (props) => {
    props.membershipTypeList();
    props.history.push('/membership-type');
}

const validate = (values, props) => { // VALIDATE ALL VALIDATION
    const errors = {}
    let obj = []
    const regExpNumeric = /^[0-9]+$/;
    if (!values.MaxNumberofMember) {
        errors.MaxNumberofMember = props.code['2306'].text;
    } else if (values.MaxNumberofMember && !regExpNumeric.test((values.MaxNumberofMember))) {
        errors.MaxNumberofMember = props.code['2310'].text;
    } else {
        obj.push({ key: 'MaxNumberofMember', value: values.MaxNumberofMember });
        props.membersettingHoldValue(obj);
    }
    if (!values.MaxNumberofCommittee) {
        errors.MaxNumberofCommittee = props.code['2307'].text;
    } else if (values.MaxNumberofCommittee && !regExpNumeric.test((values.MaxNumberofCommittee))) {
        errors.MaxNumberofCommittee = props.code['2311'].text;
    } else {
        obj.push({ key: 'MaxNumberofCommittee', value: values.MaxNumberofCommittee });
        props.membersettingHoldValue(obj);
    }

    if (!values.RenewalPeriodId) {
        errors.RenewalPeriodId = props.code['2312'].text;
    } else {
        obj.push({ key: 'RenewalPeriodId', value: values.RenewalPeriodId });
        props.membersettingHoldValue(obj);
    }

    if (!values.SpecifyMonth) {
        errors.SpecifyMonth = props.code['2317'].text;
    } else if (values.SpecifyMonth && !regExpNumeric.test((values.SpecifyMonth))) {
        errors.SpecifyMonth = props.code['2313'].text;
    } else if (values.SpecifyMonth < 1) {
        errors.SpecifyMonth = props.code['2313'].text;
    } else {
        obj.push({ key: 'SpecifyMonth', value: values.SpecifyMonth });
        props.membersettingHoldValue(obj);
    }
    return errors
}

export default (reduxForm({
    form: 'settingForm',
    onSubmit: submitHandler,
    validate: validate
})(SettingForm));
