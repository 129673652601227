import AppContainer from './src/containers/App/appContainer';
import React from 'react';
import {createRoot} from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from 'store';
import setupInterceptors from './src/interceptor';
import './index.css';
if (!String.prototype.startsWith) {
    String.prototype.startsWith = (searchString, position) => {
        position = position || 0;
        return this.substr(position, searchString.length) === searchString;
    };
}

(() => {
    let isJiraEnabled = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_ENABLED;
    if (isJiraEnabled === 'true') {
        let src = process.env.REACT_APP_JIRA_ISSUE_COLLECTOR_URL;
        document.write('<script src="' + src + '"><\/script>');
    }
})();

// Configure Store
const store = configureStore({}, {});
// Configure Interceptor
setupInterceptors(store);
// Configure Provider
createRoot(document.getElementById('root')).render(<Provider store={store}>
    <BrowserRouter>
        <AppContainer />
    </BrowserRouter>
</Provider>);
