import * as actionType from "actionTypes";
import DocList from "components/modals/Membership/MembershipType";
import React, { Component } from 'react';
import { Accordion, Checkbox, Confirm, Grid, Icon } from 'semantic-ui-react';

class AccordionFluidMembershiptType extends Component {

        constructor(props) {
                super(props);
                this.state = {
                        activeIndex: -1,
                        isConfirm: false,
                        _publicWeb: [{ key: "ExposeMembershipName", value: "Membership Name" },
                        { key: "ExposeSummary", value: "Summary" },
                        { key: "ExsposeDescription", value: "Description" },
                        { key: "ExposeBenefits", value: "Benefits" },
                        { key: "ExposeAnnualFee", value: "Membership Fee" },
                        { key: "ExposeFAQ", value: "Display Membership FAQ" }],
                        isDocPopupOpen: false,
                        selectedWebId: null,
                        allCheckedApp: [],
                        updatedAppList: [],
                        docIndex: -1,
                        isDeleted: false,
                        docId: null,
                        appId: null,
                        activeApplicationId: -1,
                        appCheckboxstatus: false
                }

                this.openDocPopup = this.openDocPopup.bind(this);
        }

        // Display checked inputs
        viewPublicWeb(appId, obj) {
                return this.state._publicWeb.map((res) => {
                        return (
                                <Grid.Column style={{ pointerEvents: this.props.viewMode ? 'none' : '' }}>
                                        <Checkbox name={res.key} label={res.value} type="checkbox" checked={obj[res.key]}
                                                onChange={(e, data) => this.getAllApplicationCheckedValue(e, data, appId, obj)} />
                                </Grid.Column>
                        )
                });
        }

        // Display data while load page
        // displayAppOnLoad() {

        //         if (this.props.app && this.props.app.length) {
        //                 let tempArr = [];
        //                 this.props.app.map((res) => {
        //                         res['ExposeMembershipName'] = false;
        //                         res['ExposeSummary'] = false;
        //                         res['ExsposeDescription'] = false;
        //                         res['ExposeAnnualFee'] = false;
        //                         res['ExposeFAQ'] = false;
        //                         res['ExposeBenefits'] = false;
        //                         res['isChecked'] = false
        //                         tempArr.push(res);
        //                         tempArr = tempArr.sort((a, b) => {
        //                                 return a.ApplicationName.localeCompare(b.ApplicationName);
        //                         })
        //                         this.setState({ updatedAppList: tempArr })

        //                 });
        //         }
        // }

        // Display value while perform edit
        displayAppOnEdit(data) {

                let tempArr = [];
                if (this.props.app && this.props.app.length) {
                        this.props.app.map((res) => {
                                if (data.hasOwnProperty('MembershipTypesApplication') && data.MembershipTypesApplication.length > 0) { // CHECK APPLICATION DATA ALREADY EXIST
                                        let isArrayReturn = data.MembershipTypesApplication.filter((dataFilter) => {
                                                return dataFilter.ApplicationId === res.ExternalApplicationId
                                        });
                                        if (isArrayReturn != null && isArrayReturn.length > 0) {
                                                res['ExposeMembershipName'] = isArrayReturn[0].ExposeMembershipName;
                                                res['ExposeSummary'] = isArrayReturn[0].ExposeSummary;
                                                res['ExsposeDescription'] = isArrayReturn[0].ExsposeDescription;
                                                res['ExposeAnnualFee'] = isArrayReturn[0].ExposeAnnualFee;
                                                res['ExposeFAQ'] = isArrayReturn[0].ExposeFAQ;
                                                res['ExposeBenefits'] = isArrayReturn[0].ExposeBenefits;
                                                tempArr.push(res);
                                        } else {
                                                res['ExposeMembershipName'] = false;
                                                res['ExposeSummary'] = false;
                                                res['ExsposeDescription'] = false;
                                                res['ExposeAnnualFee'] = false;
                                                res['ExposeFAQ'] = false;
                                                res['ExposeBenefits'] = false;
                                                res['isChecked'] = false;
                                                tempArr.push(res);
                                        }

                                } else { // SET IF EDIT DATA NOT EXIT IN APPLICATION LIST
                                        res['ExposeMembershipName'] = false;
                                        res['ExposeSummary'] = false;
                                        res['ExsposeDescription'] = false;
                                        res['ExposeAnnualFee'] = false;
                                        res['ExposeFAQ'] = false;
                                        res['ExposeBenefits'] = false;
                                        res['isChecked'] = false
                                        tempArr.push(res);
                                }

                        });
                        tempArr = tempArr.sort((a, b) => {
                                return a.ApplicationName.localeCompare(b.ApplicationName);
                        })
                        this.setState({
                                updatedAppList: tempArr
                        })
                }


        }

        componentDidMount() {
                this.displayAppOnEdit(this.props.membershipTypeDetails);
        }

        componentDidUpdate(prevProps, prevState) { // UPDATE MODEL IF APPLICATION LIST NOT UPDATED

                // Display aap app list whether it is edit or add
                if (this.props.hasOwnProperty('isEdit') && this.props.isEdit) {
                        if (prevProps.app != this.props.app) {
                                this.displayAppOnEdit(this.props.membershipTypeDetails);
                                if (this.props.membershipTypeDetails['MembershipTypesApplication'] != null && this.props.membershipTypeDetails['MembershipTypesApplication'].length > 0) {
                                        this.props.membershipTypeDetails['MembershipTypesApplication'].map((res) => {
                                                let docList = [];
                                                if (res['MembershipTypesAppDocument'] != null && res['MembershipTypesAppDocument'].length > 0) { // map object while edit

                                                        res['MembershipTypesAppDocument'].map((res) => {
                                                                let selDocArr = this.props.doc.filter((docRes) => { return docRes.Id == res.DocumentId; });

                                                                if (selDocArr != null && selDocArr.length > 0) { // collect doc list
                                                                        docList.push(selDocArr[0]);
                                                                }
                                                        })
                                                }

                                                res['selectedDoc'] = docList;
                                                this.props.appWithDocSelection.push(res);
                                        })

                                }
                        }
                } else if (prevProps.type == actionType.MEMBERSHIPTYPE_ENABLE_WEB_APP_SELECTED_EVENT && prevProps.app != this.props.app) {
                        /* This.displayAppOnLoad(); */
                        this.displayAppOnEdit(this.props.membershipTypeDetails);
                }
        }


        // Get all checked value in application while click on check box
        getAllApplicationCheckedValue = (e, data, appId, obj) => {
                let self = this;
                let tempObj = {};

                const { name, checked } = data;

                self.state.updatedAppList.map((res) => {
                        if (appId == res.ApplicationId) {
                                tempObj = res;
                                tempObj[name] = checked;
                                tempObj['isChecked'] = true;
                                tempObj['ApplicationId'] = res.ExternalApplicationId;
                                if (self.props.appWithDocSelection.filter(doc => doc.ApplicationId == obj.ApplicationId || doc.ApplicationId == obj.ExternalApplicationId).length > 0) {
                                        tempObj['MembershipTypesAppDocument'] = self.props.appWithDocSelection.filter(doc => doc.ApplicationId == obj.ApplicationId || doc.ApplicationId == obj.ExternalApplicationId)[0].MembershipTypesAppDocument;
                                        tempObj['selectedDoc'] = self.props.appWithDocSelection.filter(doc => doc.ApplicationId == obj.ApplicationId || doc.ApplicationId == obj.ExternalApplicationId)[0].selectedDoc;

                                } else {
                                        tempObj['MembershipTypesAppDocument'] = [];
                                        tempObj['selectedDoc'] = [];
                                }

                        }
                });

                self.props.selectedAppDocEvt(tempObj);
                let temappCheckboxstatus = !self.state.appCheckboxstatus;
                self.setState({ appCheckboxstatus: !temappCheckboxstatus });
        }

        // HandleClick for tabbing state handle
        handleClick = (e, titleProps) => {
                const { index } = titleProps
                const { activeIndex } = this.state;
                const newIndex = activeIndex === index ? -1 : index;
                const newActiveApplicationId = this.state.updatedAppList[index].ExternalApplicationId
                this.setState({ activeIndex: newIndex, activeApplicationId: newActiveApplicationId })
        }

        // Func for open doc list conditionally
        openDocPopup = (event, res) => {
                event.preventDefault();
                // Let's stop this event.
                event.stopPropagation();
                // Convert application to externalId
                res['ApplicationId'] = res['ExternalApplicationId'];
                this.setState({ isDocPopupOpen: true, selectedWebId: res })
        }

        // Delete document while click in delete action while selected
        deleteDoc = (e, docId, appId) => {
                this.setState({ appId, docId, isConfirm: true })
        }

        // Display doc view
        selectedDocView = (details) => {
                const { userPermission } = this.props;

                if (this.props.appWithDocSelection && this.props.appWithDocSelection.length > 0) {
                        let indexVal = this.props.appWithDocSelection.findIndex((res) => {
                                return details.ExternalApplicationId == res.ApplicationId
                        });

                        let filter = this.props.appWithDocSelection.filter((res) => {
                                return res.MembershipTypesAppDocument && res.MembershipTypesAppDocument.length > 0
                        });


                        if (indexVal > -1 && filter.length > 0 && this.props.appWithDocSelection[indexVal].selectedDoc && this.props.appWithDocSelection[indexVal].selectedDoc.length
                                > 0) {
                                return (
                                        <div className="tableWrapper">
                                                <table className="customTable documentTable">
                                                        <thead> <tr>
                                                                {/* <th className="documentID"> Document ID
                                                                </th> */}
                                                                <th className="documentName">
                                                                        Document Name
                                                                </th>
                                                                <th className="type">
                                                                        Type
                                                                </th>
                                                                {userPermission && userPermission.Update && <th className="action">
                                                                        Action
                                                                </th>}
                                                        </tr>
                                                        </thead>
                                                        <tbody>

                                                                {this.props.appWithDocSelection[indexVal].selectedDoc && this.props.appWithDocSelection[indexVal].selectedDoc.length > 0 &&
                                                                        this.props.appWithDocSelection[indexVal].selectedDoc.map((res) => {
                                                                                return (
                                                                                        <tr>
                                                                                                {/* <td> <a onClick={() => { this.setState({ openViewForm: true }) }}>
                                                                                                {res.Key}</a> </td> */}
                                                                                                <td>{res.Name}</td>
                                                                                                <td>{res.Type}</td>

                                                                                                {userPermission && userPermission.Update &&
                                                                                                        (<td className="action txtCenter" onClick={(e) => this.deleteDoc(e, res.Id, this.props.appWithDocSelection[indexVal].ApplicationId)}>
                                                                                                                <button><Icon bordered name='trash' className="squareIcon" /></button>

                                                                                                        </td>)
                                                                                                }

                                                                                        </tr>
                                                                                )
                                                                        })}
                                                        </tbody>
                                                </table>

                                        </div>);
                        }
                }
        }

        // Accordion list view action
        toggleListView(activeIndex) {
                if (this.state.updatedAppList && this.state.updatedAppList.length > 0) {
                        return this.state.updatedAppList.map((res, index) => {
                                return (
                                        <div >
                                                <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
                                                        <Icon name='dropdown down' />
                                                        {res.ApplicationName}
                                                </Accordion.Title>
                                                <Accordion.Content active={activeIndex === index}>
                                                        <p className="commonTitle">Choose fields to be exposed - {res.ApplicationName}</p>
                                                        <div className="checkBoxWrapper">
                                                                <Grid columns='4'>
                                                                        <Grid.Row>
                                                                                {this.viewPublicWeb(res.ApplicationId, res)}

                                                                        </Grid.Row>
                                                                </Grid>
                                                                {(!this.props.viewMode) && (
                                                                        <button className="ui button attachBtn mt20" onClick={(e) => this.openDocPopup(e, res)}><i aria-hidden="true" class="attach icon" ></i>Attach Documents</button>
                                                                )}
                                                        </div>
                                                        {this.selectedDocView(res)}
                                                </Accordion.Content>
                                        </div >
                                )
                        })
                }
                return ("No List Found")
        }

        closePopup = () => {
                this.setState({ isDocPopupOpen: false })
        }

        handleConfirmDelete = () => {
                let self = this;
                const { docId, appId } = this.state;
                this.setState({ isConfirm: false })
                let obj = { "MembershipTypeId": self.props.initialValues.MembershipTypeId, "ApplicationId": appId, "DocumentId": docId }
                this.setState({ isDeleted: true })
                this.props.deleteAttacheDoc(obj, self.handleConfirm)
        }

        handleConfirm = () => {
                const { docId, appId } = this.state;

                if (this.props.appWithDocSelection && this.props.appWithDocSelection.length > 0) {
                        this.setState({ isConfirm: false })
                        this.props.appWithDocSelection.map((res) => {
                                if (res.selectedDoc && res.selectedDoc.length) {
                                        if (res.ApplicationId === appId) {
                                                let indexDoc = res.selectedDoc.findIndex((data) => {
                                                        return data.Id === docId;
                                                });
                                                let indexRealDoc = res.MembershipTypesAppDocument.findIndex((data) => {
                                                        return data.DocumentId === docId;
                                                });
                                                // MembershipTypesAppDocument
                                                if (indexDoc > -1 && indexRealDoc > -1) {
                                                        res.selectedDoc.splice(indexDoc, 1);
                                                        res.MembershipTypesAppDocument.splice(indexRealDoc, 1)
                                                        this.setState({ isDeleted: true, isConfirm: false })
                                                }
                                        }
                                }
                        })
                }
        }

        handleCancel = () => this.setState({ isConfirm: false })

        render() {

                const { activeIndex, isConfirm } = this.state;
                return (
                        <Accordion fluid data-testid='accordion'>
                                {this.toggleListView(activeIndex)}
                                {this.state.isDocPopupOpen &&
                                        <DocList closeModal={this.closePopup} docIndex={this.state.docIndex} {...this.props} selectedDoc={(this.props.appWithDocSelection.length > 0 && this.props.appWithDocSelection.filter((item) => (item.ApplicationId == this.state.activeApplicationId || item.ExternalApplicationId == this.state.activeApplicationId)).length > 0) ? this.props.appWithDocSelection.filter((item) => (item.ApplicationId == this.state.activeApplicationId || item.ExternalApplicationId == this.state.activeApplicationId))[0].selectedDoc : ''} webId={this.state.selectedWebId} />
                                }

                                <Confirm data-testid='confirm' cancelButton='No' confirmButton="Yes" className="confirmBox" content='Are you sure you want to remove this document?'
                                        onCancel={this.handleCancel} onConfirm={this.props.viewMode ? this.handleConfirmDelete : this.handleConfirm} open={isConfirm} />

                        </Accordion>
                )
        }
}
export default AccordionFluidMembershiptType;
