import membershipTypeActions from 'actions/Membership/membershipTypeActions';
import * as actions from 'actionTypes';
import MembershipType from 'components/pages/Membership/membershipType';
import { connect } from "react-redux";

const mapStateToProps = ({ membershipType, i18nReducer, commonReducer }) => ({
  list: membershipType._list,
  code: i18nReducer.dictionaries[i18nReducer.currentLanguage],
  type: membershipType.type,
  period: membershipType._period,
  app: membershipType._app,
  doc: membershipType._doc,
  details: membershipType._details,
  _enableWebData: membershipType._enableWebData,
  userPermission: commonReducer.userPermission
});

const mapDispatchToProps = (dispatch) => ({
  membershipTypeList: () => membershipTypeActions.getMembershipTypeList(dispatch),
  membershipTypeEditEvt: (id, callbackFun) => membershipTypeActions.getMembershipTypeEdit(dispatch, id, callbackFun), // get data by id for display detials
  checkUniqueName: (data, callbackFun) => membershipTypeActions.checkUniqueName(dispatch, data, callbackFun),
  settingBackEvt: (data) => dispatch(membershipTypeActions.membershipTypeSettingBackEvent(data)),
  membershipTypeDetailsSubmit: (data) => membershipTypeActions.getDocumentAndAppList(dispatch, data),
  membersettingHoldValue: (data) => dispatch(membershipTypeActions.holdSettingValueEvent(data)),
  memberTypeSubmit: (data, callbackFun) => membershipTypeActions.memberTypeSubmit(dispatch, data, callbackFun),
  dispatchNotShowEvent: () => { dispatch({ type: actions.SHOW_NOT_FOUND_PAGE }); },
  clearActionType: () => { dispatch({ type: actions.MEMBERSHIPTYPE_CLEAR_ACTION }) }
});
export default connect(mapStateToProps, mapDispatchToProps)(MembershipType);
