import React, { Component } from "react";
import { Grid, Form, Button } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import RenderInputField from "components/atoms/Input";
import RenderTextArea from 'components/atoms/RenderTextarea'
import { getMessage, Number_Regex } from "../../../helpers/utilCommon";
import _ from "lodash";

class AddBOSVolumeForm extends Component {
    constructor(props) {
        super(props);
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.match.params.bosId && (JSON.stringify(prevProps.initialValues) !== JSON.stringify(this.props.initialValues))) {
            this.getBosVolumeDetails(this.props.initialValues)
        }
    }
    getBosVolumeDetails(initialState) {
        if (initialState) {
            this.props.change('SectionNumber', initialState.SectionNumber)
            this.props.change('VolumeNumber', initialState.VolumeNumber)
            this.props.change('Comments', initialState.Comments)
        }
    }

    handleChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        if (name !== "Comments" && Number_Regex.test(value)) {
            this.props.change([name], value)
        } else if (name === "Comments") {
            this.props.change([name], value)
        }
    }

    handleSave = values => {
        const obj = {
            SectionNumber: "",
            VolumeNumber: "",
            Comments: ""
        }
        if (values.SectionNumber && values.VolumeNumber) {
            obj.SectionNumber = values.SectionNumber.length > 1 ? values.SectionNumber : '0' + values.SectionNumber;
            obj.VolumeNumber = values.VolumeNumber.length > 1 ? values.VolumeNumber : '0' + values.VolumeNumber;
            if (values.Comments && values.Comments.length > 0) {
                obj.Comments = values.Comments.trim()
            }
            obj.CreatedBy = 1;
            if (this.props.match.params && this.props.match.params.bosId) {
                obj.BosVolumeId = this.props.match.params.bosId;
                const prevData = this.props.initialValues;
                prevData.CreatedBy = 1;
                prevData.BosVolumeId = this.props.match.params.bosId;
                if (_.isEqual(JSON.stringify(this.props.initialValues), JSON.stringify(obj))) {
                    this.props.displayNoChangesMadeMessage();
                    this.resetForm();
                    return;
                }
                this.props.putBosVolume(obj, () => {
                    this.resetForm();
                });
            } else {
                this.props.postBosVolume(obj, () => {
                    this.resetForm();
                });
            }

        }
    }

    resetForm = () => {
        this.props.reset();
        this.props.backToListingPage();
        this.props.getBosVolumeList(() => {
            this.props.refreshPagination()
        });
    }

    render() {
        const { backToListingPage, handleSubmit, submitting, pageType } = this.props;
        const { handleChange } = this;

        return (
            <div data-testid='addBosVolumeLocation'>
                <div class="headingTitle clearfix mb20">
                    <h2>{pageType === "edit" ? "Update BOS Volume Location" : 'Add BOS Volume Location'}</h2>
                </div>
                <div className="addCommitteeWrap" Id="mandiv">
                    <Form
                        size="large"
                        onSubmit={handleSubmit(this.handleSave)}
                        noValidate
                    >
                        <Grid columns="2">
                            <Grid.Row>
                                <Grid.Column>
                                    <Field
                                        type="text"
                                        name="SectionNumber"
                                        label="Section Number"
                                        required
                                        component={RenderInputField}
                                        placeholder="Add Section Number"
                                        maxLength={2}
                                        onChange={handleChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <div className="dashed-Border"></div>
                                <Grid.Column>
                                    <Field
                                        dataTestid="volumeNumberInput"
                                        type="text"
                                        name="VolumeNumber"
                                        label="Volume Number"
                                        required
                                        component={RenderInputField}
                                        placeholder="Add Volume Number"
                                        maxLength={2}
                                        onChange={handleChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <div className="dashed-Border"></div>
                                <Grid.Column>
                                    <Field type="text"
                                        label="Comments"
                                        name="Comments"
                                        component={RenderTextArea}
                                        placeholder="Add Comments"
                                        maxLength="250" onChange={handleChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid.Row>
                            <Grid.Column className="actions mt30">
                                <Button
                                    primary
                                    type="submit"
                                    disabled={submitting}
                                >
                                    {pageType === "edit" ? 'Update' : 'Save'}
                                </Button>
                                <Button className="cancel ml10" onClick={backToListingPage}>
                                    Cancel
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Form>
                </div>
            </div>
        );
    }
}

const validateHandler = (values, props) => {
    const errors = {};
    if (!values.SectionNumber) {
        errors.SectionNumber = getMessage(props.messageCodes, '7156.text');
    } else if (values.SectionNumber && values.SectionNumber.length === 1) {
        errors.SectionNumber = getMessage(props.messageCodes, '7161.text');
    }
    if (!values.VolumeNumber) {
        errors.VolumeNumber = getMessage(props.messageCodes, '7157.text');
    } else if (values.VolumeNumber && values.VolumeNumber.length === 1) {
        errors.VolumeNumber = getMessage(props.messageCodes, '7161.text');
    }
    return errors;
};

export default reduxForm({
    form: "AddBOSVolumeForm",
    validate: validateHandler
})(AddBOSVolumeForm);
