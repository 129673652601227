import React, { Component } from 'react';
import { Button, Modal, Form, Grid } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import RenderInputField from 'components/atoms/Input';
import { MembershipDocumentModel } from 'models/Membership/membershipDocumentModel';
import { getFileType } from '../../../helpers/utilCommon';
import UploadPopup from '../../atoms/UploadPopup';
import { get } from 'lodash';
class AddMembershipDocument extends Component {
    nameRequired = value => value && value.trim() ? undefined : this.props.documentNameRequired;
    state = {
        fileObjects: {
            signedParamUrl: '',
            file: '',
            filePath: '',
            isProcess: false,
            status: false
        }
    }
    closeModal = () => {
        this.props.resetState();
        this.props.closeModal(3);
    }

    handleForm = (values) => {
        const document = MembershipDocumentModel;

        try {
            // TOOD : Check for request uploading new file.
            if (this.state.fileObjects.file && this.state.fileObjects.status) {
                this.props.uploadFileInS3(this.state.fileObjects.signedParamUrl, this.state.fileObjects.file, isUploaded => {
                    if (isUploaded) {
                        document.Name = values.add_Name.trim();
                        document.BlobContainerName = process.env.REACT_APP_AWS_S3_BUCKET_NAME;
                        document.BlobFileName = this.state.fileObjects.filePath;
                        document.Type = getFileType(this.state.fileObjects.file.name);
                        this.props.addMembershipDocument(document, () => this.closeModal());
                    }
                });
            }

        }
        catch (err) {
            console.log('Error occured while adding document');
        }
    };

    // TODO : request for signed Url in OSL.
    signedAwsUrlHandler = (file, cb) => {
        this.props.getAwsSigned(file, result => {
            cb(result)
        });
    }
    // TODO : Callback to get with valid file extention and signed Url
    resultSetHandler = (result) => {
        if (result.status) {
            let params = {};
            params.signedParamUrl = result.signedParams.url
            params.file = result.file
            params.filePath = result.signedParams.params.Key
            params.isProcess = result.isProcess
            params.status = result.status;
            this.setState({ fileObjects: params });
        } else {
            this.setState({ fileObjects: result });
        }
    }

    render() {
        const { handleSubmit, submitting, isDuplicateDocName, duplicateDocNameError } = this.props;

        return (

            <Modal open='true' className="tiny" data-testid = "addMembershipDocument">
                <Form size='large' onSubmit={handleSubmit(this.handleForm)}>
                    <Modal.Header>Add Document <i onClick={this.closeModal} class="close">&#x2716;</i></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>

                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="add_Name" type="text"
                                            component={RenderInputField} label="Document Name"
                                            validate={[this.nameRequired]}
                                            maxLength="100" required='true'
                                            autoFocus='true'
                                            isDuplicate={isDuplicateDocName}
                                            duplicateError={duplicateDocNameError}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>

                                    <Grid.Column>
                                        <label className="label"> <i aria-hidden="true" class="asterisk  icon"></i> Upload Document</label>
                                        <UploadPopup title="UPLOAD ATTACHMENT"
                                            extension="pdf, doc, docx"
                                            formatLabel="pdf, doc, docx"
                                            dirName={''}
                                            signedAwsUrlHandler={this.signedAwsUrlHandler}
                                            resultSetHandler={this.resultSetHandler}
                                            action={true}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column className="actions">
                                        <Button primary type="submit" disabled={get(this.state.fileObjects, 'isProcess') || submitting}>
                                            Save
                                        </Button>
                                        <Button secondary onClick={this.closeModal} className="cancel">
                                            Cancel
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                        </Modal.Description>
                    </Modal.Content>

                </Form>

            </Modal>
        );
    }
}

export default (reduxForm({
    form: 'AddMembershipDocument'
})(AddMembershipDocument));
