import React, { Component } from 'react';
import { Button, Form, Grid, Accordion, Icon, Modal } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import Checkbox from 'components/atoms/CheckBox/checkbox'
import { OfficerTitlesModel } from 'models/Committee/officerTitleModels';
import RenderInputField from 'components/atoms/Input';
import RenderCheckbox from 'components/atoms/CheckBox';
import DropdownMultipleSelection from 'components/atoms/MultiSelectDropDown';
import RenderRadioField from "components/atoms/RadioBox"
import RenderSelect from 'components/atoms/reduxSelect';//'components/atoms/Select';
import { shallowCopy } from 'helpers/utilCommon'
import _ from 'lodash';

class AddOfficerTitlesForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            activeIndex: 0,
            responsibility: this.props.responsebility,
            IsApplicableToAllCommittees: 'Yes',
            isRecordAdded: false,
            committeeTypeList: this.props.committeeType.committeeTypeList,
            noOfCommitteeDdlList: 0,
            CommitteeTypeLevels: [{ CommitteeTypeId: 0, CommitteeList: this.props.committeeType.committeeTypeList, hierarchyList: [], levelsIds: [], CommitteeLevel: [{ CommitteeLevelId: 0, LevelId: 0 }] }],
            committeeTypeRequired: false,
            enableSubmitButton: true
        };
    }
    titleNameRequired = value => value && value.trim() ? undefined : this.props.requiredTitleNameError;

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }
    changePrivilege = (index, resIndex, subIndex, option) => {
        let tempresponsibility = this.state.responsibility;
        // console.log('tempresponsibility==============================', tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[subIndex].IsChecked)
        switch (option) {
            case 'All':
                const checkAllStatus = tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[subIndex].IsChecked;
                tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[subIndex].IsChecked = !checkAllStatus;
                if (tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[subIndex].IsChecked) {
                    tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList = tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList.map((item) => {
                        item.IsChecked = true;
                        return item;
                    })
                } else {
                    tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList = tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList.map((item) => {
                        item.IsChecked = false;
                        return item;
                    })
                }
                break;

            default:
                const checkStatus = tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[subIndex].IsChecked;
                tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[subIndex].IsChecked = !checkStatus;
                if (option.toLowerCase().includes('update')) {
                    tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList = tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList.map((item) => {
                        let count = tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList.filter((itemdetail) => itemdetail.ResponsibilityPrivilegeName.toLowerCase().includes('update') && item.ResponsibilityPrivilegeName.toLowerCase().includes('update') && itemdetail.ResponsibilityPrivilegeName.toLowerCase() != item.ResponsibilityPrivilegeName.toLowerCase() &&
                            itemdetail.IsChecked).length;

                        if (item.ResponsibilityPrivilegeName.toLowerCase().includes('view') && tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[subIndex].IsChecked &&
                            count == 0) {
                            item.IsChecked = !checkStatus;
                        }

                        return item;
                    })
                } else if (option.toLowerCase().includes('add')) {

                    tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList = tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList.map((item) => {

                        let addcount = tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList.filter((itemdetail) => itemdetail.ResponsibilityPrivilegeName.toLowerCase().includes('add') && item.ResponsibilityPrivilegeName.toLowerCase().includes('add') && itemdetail.ResponsibilityPrivilegeName.toLowerCase() != item.ResponsibilityPrivilegeName.toLowerCase() &&
                            itemdetail.IsChecked).length;

                        if (item.ResponsibilityPrivilegeName.toLowerCase().includes('view') && tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[subIndex].IsChecked &&
                            addcount == 0) {
                            item.IsChecked = !checkStatus;
                        }

                        return item;
                    })
                }
                else if (option.toLowerCase().includes('view')) {

                    tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList = tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList.map((item) => {
                        if (item.ResponsibilityPrivilegeName.toLowerCase().includes('add') || item.ResponsibilityPrivilegeName.toLowerCase().includes('update')) {
                            if (item.IsChecked && checkStatus) {
                                item.IsChecked = false
                            }

                        }

                        return item;
                    })
                } else if (!tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[subIndex].IsChecked) {
                    tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[0].IsChecked = false;
                }
                if (tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList.filter(item => !item.ResponsibilityPrivilegeName.toLowerCase().includes('all') && item.IsChecked).length === tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList.filter(item => item.ResponsibilityPrivilegeMappingId > 0).length) {
                    tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[0].IsChecked = true;
                } else {
                    tempresponsibility[index].ResponsibilityGroupList[resIndex].ResponsibilityPrivilegeList[0].IsChecked = false
                }
                break;
        }

        this.setState({ responsibility: tempresponsibility })
    }
    handleForm = values => {
        this.checkUniqueName((response) => {
            if (!this.state.isRecordAdded && !response) {
                this.setState({ isRecordAdded: true })
                const self = this;
                const appObj = OfficerTitlesModel;
                let committeeTypeRequired = self.committeeTypeRequired
                try {
                    appObj.IsASTMStaff = values.add_IsASTMStaff;
                    appObj.IsApplicableToAllCommittees = self.state.IsApplicableToAllCommittees === 'Yes';
                    appObj.OfficerTitleName = values.add_OfficerTitleName.trim();
                    appObj.Status = true;
                    appObj.ResponsibilityList = this.state.responsibility;
                    appObj.CommitteeTypeLevels = !appObj.IsApplicableToAllCommittees ? self.state.CommitteeTypeLevels.filter(item => item.CommitteeTypeId > 0).map(item => { return { CommitteeTypeId: item.CommitteeTypeId, CommitteeLevel: item.CommitteeLevel } }) : [];
                    if (appObj.CommitteeTypeLevels.length > 0 && appObj.CommitteeTypeLevels.filter(item => item.CommitteeTypeId > 0).length > 0 && !appObj.IsApplicableToAllCommittees) {
                        appObj.CommitteeTypeLevels.map(item => {
                            if (item.CommitteeLevel.filter(item2 => item2.CommitteeLevelId > 0) <= 0) {
                                if (self.props.committeeType.committeeInfo.filter(item3 => item3.CommitteeTypeId === item.CommitteeTypeId && item.CommitteeLevel.length > 0).length > 0) {
                                    item.CommitteeLevel = self.props.committeeType.committeeInfo.filter(item3 => item3.CommitteeTypeId === item.CommitteeTypeId && item.CommitteeLevel.length > 0)[0].CommitteeLevel.map(item3 => { return { CommitteeLevelId: item3.CommitteeLevelId, LevelId: item3.LevelId } })
                                }
                            }
                            return item;
                        })

                    } else if (!appObj.IsApplicableToAllCommittees) {
                        committeeTypeRequired = true;
                        self.setState({ committeeTypeRequired, isRecordAdded: false })
                    }

                    if (!committeeTypeRequired) {
                        self.props.addOfficerTitle(appObj, () => {
                            self.props.onCancel(3);
                            this.setState({ isRecordAdded: false })
                        });
                    }

                }
                catch (err) {
                    this.setState({ isRecordAdded: false })
                }
            }
            this.setState({ enableSubmitButton: true, isRecordAdded: false });
        })
    };
    renderPrivilege = (res, index, resIndex) => {
        if (res.filter(_item => _item.ResponsibilityPrivilegeName.trim() == "All").length == 0) {
            res.push({
                IsChecked: true,
                ResponsibilityPrivilegeId: -1,
                ResponsibilityPrivilegeMappingId: -1,
                ResponsibilityPrivilegeName: " All"
            });
        }


        return res.sort((a, b) => (a.ResponsibilityPrivilegeId > b.ResponsibilityPrivilegeId) ? 1 : -1).map((privilege, privilegeIndex) => {
            if (privilege.ResponsibilityPrivilegeId == -1) {
                let count = 0, tcount = 0;
                res.map((item, index) => {
                    tcount++;
                    if (index > 0 && item.IsChecked) {
                        count++;
                    }
                });
                if (count == tcount - 1) {
                    privilege.IsChecked = true;
                } else {
                    privilege.IsChecked = false;
                }

            }
            return <Grid.Column>

                <RenderCheckbox name='ResponsibilityPrivilegeName' IsEditable={true} IsChecked={privilege.IsChecked} defaultChecked={privilege.IsChecked} onchange={(event) => this.changePrivilege(index, resIndex, privilegeIndex, privilege.ResponsibilityPrivilegeName.trim())} />
                <span className="permsnName">{privilege.ResponsibilityPrivilegeName}</span>
            </Grid.Column>

        })

    }
    onChangeCommitteeTypeHandler = (value, index) => {
        let CommitteeTypeLevels = this.state.CommitteeTypeLevels;
        let committeeInfo = this.props.committeeType.committeeInfo
        CommitteeTypeLevels[index].CommitteeTypeId = value;
        CommitteeTypeLevels[index].levelsIds = [];
        if (value && value > 0) {
            CommitteeTypeLevels[index].hierarchyList = committeeInfo.filter(item => item.CommitteeTypeId.toString() === value.toString())[0].CommitteeLevel.map(item => {
                return { key: item.CommitteeLevelId, value: item.CommitteeLevelId, text: item.CommitteeLevelName }
            })
        } else {
            CommitteeTypeLevels[index].hierarchyList = [];
        }

        CommitteeTypeLevels.map((item, itemIndex) => {
            item.CommitteeList = this.findDifferenceCommitteeType(CommitteeTypeLevels, this.props.committeeType.committeeTypeList, itemIndex)
            return item;
        });
        this.setState({ CommitteeTypeLevels, committeeTypeRequired: false, isRecordAdded: false });
    }
    onChangeCommitteeLevelHandler = (value, index) => {
        let CommitteeTypeLevels = this.state.CommitteeTypeLevels;
        let committeeInfo = this.props.committeeType.committeeInfo
        CommitteeTypeLevels[index].levelsIds = value || [];
        CommitteeTypeLevels[index].CommitteeLevel = [];
        if (CommitteeTypeLevels[index].levelsIds.length > 0) {

            CommitteeTypeLevels[index].levelsIds.map(item => {
                committeeInfo.filter(info => info.CommitteeTypeId.toString() === CommitteeTypeLevels[index].CommitteeTypeId.toString())[0].CommitteeLevel.filter(item2 => item2.CommitteeLevelId.toString() == item.toString()).map(item3 => {
                    CommitteeTypeLevels[index].CommitteeLevel.push({ CommitteeLevelId: item3.CommitteeLevelId, LevelId: item3.LevelId })
                })
            })
        } else {
            CommitteeTypeLevels[index].CommitteeLevel = committeeInfo.filter(item => item.CommitteeTypeId.toString() === CommitteeTypeLevels[index].CommitteeTypeId.toString())[0].CommitteeLevel || [];
        }
        this.setState({ CommitteeTypeLevels });

    }
    setApplicableToAllCommittees = (event) => {
        const { textContent } = event.currentTarget;
        let IsApplicableToAllCommittees = this.state.IsApplicableToAllCommittees
        if (textContent === 'Applicable to all Committee Types') {
            IsApplicableToAllCommittees = 'Yes';
        } else {
            IsApplicableToAllCommittees = 'No';
        }
        this.setState({ IsApplicableToAllCommittees });
    }

    onCancelHandler = () => {
        this.props.onCancel(4);
    }
    removeComitteeTypeItemHandler = (index) => {
        let CommitteeTypeLevels = this.state.CommitteeTypeLevels;
        if (CommitteeTypeLevels.length > 1) {
            CommitteeTypeLevels.splice(index, 1)
            CommitteeTypeLevels = CommitteeTypeLevels.map((item, itemIndex) => {
                item.CommitteeList = this.findDifferenceCommitteeType(CommitteeTypeLevels, this.props.committeeType.committeeTypeList, itemIndex)
                return item;
            })
            this.setState({ CommitteeTypeLevels, committeeTypeRequired: false, isRecordAdded: false }, () =>
                this.state.CommitteeTypeLevels.map((item, itemIndex) => {
                    this.props.change(`committeeType_${itemIndex + 1}`, item.CommitteeTypeId)
                }));
        }

    }
    addComitteeTypeItemHandler = () => {
        let CommitteeTypeLevels = this.state.CommitteeTypeLevels;
        let CommitteeList = this.findDifferenceCommitteeType(CommitteeTypeLevels, this.props.committeeType.committeeTypeList, -1);
        CommitteeTypeLevels.push({ CommitteeTypeId: 0, CommitteeList, CommitteeLevel: [{ CommitteeLevelId: 0, LevelId: 0 }] })

        this.setState({ CommitteeTypeLevels, committeeTypeRequired: false, isRecordAdded: false }, () =>
            this.props.change(`committeeType_${CommitteeTypeLevels.length}`, 0)
        );
    }
    findDifferenceCommitteeType = (CommitteeTypeLevels, committeeTypeList, itemIndex) => {
        let committeetype = [];
        CommitteeTypeLevels.map((item, index) => {
            if (index !== itemIndex && item.CommitteeTypeId > 0) {
                committeetype.push(item.CommitteeTypeId)
            }
            return item;
        })
        return committeeTypeList.filter(item => committeetype.length === 0 || !committeetype.map(cType => cType.toString()).includes(item.value.toString()))
    }
    checkUniqueName = (callback) => {
        this.props.resetState();
        this.setState({ enableSubmitButton: false })
        if (_.get(this.refs.add_OfficerTitleName, 'value', null) !== '') {
            let officerTitleName = this.refs.add_OfficerTitleName.value ? this.refs.add_OfficerTitleName.value.trim() : '';
            let reqParam = { title: officerTitleName, id: 0 };
            this.props.getOfficerTitleAlreadyExists(reqParam, (response) => {
                callback(response);
            })
        } else {
            this.props.resetState();
        }
    }
    render() {
        const { handleSubmit, submitting, isDuplicateOfficerTitle, duplicateOfficerTitleError, resetState } = this.props;
        const { titleNameRequired, onChangeCommitteeTypeHandler, removeComitteeTypeItemHandler, addComitteeTypeItemHandler, onChangeCommitteeLevelHandler } = this;
        const { activeIndex, responsibility, IsApplicableToAllCommittees, committeeTypeRequired, CommitteeTypeLevels, enableSubmitButton } = this.state;
        return (
            <React.Fragment>
                <Form size='large' onSubmit={handleSubmit(this.handleForm)} noValidate data-testid='addOfficerTitleCmp'>
                    <Modal.Content scrolling>
                        <Modal.Description>

                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field ref={'add_OfficerTitleName'} name="add_OfficerTitleName" type="text"
                                            component={RenderInputField} label="Officer Title"
                                            validate={[titleNameRequired]} isDuplicate={isDuplicateOfficerTitle}
                                            duplicateError={duplicateOfficerTitleError} onChange={resetState}
                                            maxLength="100" required='true'
                                        />
                                    </Grid.Column>


                                    <Grid.Column>
                                        <label className="label"> &nbsp;</label>
                                        <div className="radioWrap" style={{ 'margin-top': '7px' }}>
                                            <Field name="add_IsASTMStaff" type="CheckBox" className="mr10"
                                                component={Checkbox} label="ASTM Staff" />

                                        </div>
                                    </Grid.Column>

                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="radioWrap">
                                            <div className="radioWrap">
                                                <Field name="applicable_comm"
                                                    component={RenderRadioField}
                                                    className="mr10"
                                                    type="radio"
                                                    selectedValue={IsApplicableToAllCommittees}
                                                    onChange={(event) => this.setApplicableToAllCommittees(event)}
                                                    options={[{ label: 'Applicable to all Committee Types', value: 'Yes' }, { label: 'Specific to a Committee Type', value: 'No' }]} />
                                            </div>


                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                {IsApplicableToAllCommittees === 'No' && <Grid.Row >
                                    <Grid.Column>
                                        <div className="comApplyRemove">
                                            <div className="tableWrapper">
                                                <table className="customTable">
                                                    <tbody>
                                                        {CommitteeTypeLevels.length > 0 && CommitteeTypeLevels.map((item, index) => {
                                                            return <tr>
                                                                <td width="30%">
                                                                    <div className={committeeTypeRequired ? 'validationError' : ''}>
                                                                        <Field name={`committeeType_${index + 1}`}
                                                                        test={'committee-select'}
                                                                            label="Committee Type"
                                                                            placeholder="Select"
                                                                            component={RenderSelect}
                                                                            value={item.CommitteeTypeId}
                                                                            onChange={(event, value) => onChangeCommitteeTypeHandler(value, index)}
                                                                            options={item.CommitteeList} />
                                                                        {committeeTypeRequired && <span className="errorMessage mt0">{this.props.requiredCommitteeType}</span>}
                                                                    </div>

                                                                </td>
                                                                <td width="60%">
                                                                    <DropdownMultipleSelection name="Hierarchy"
                                                                        placeholder="All"
                                                                        isMultiple={true}
                                                                        label="Committee Hierarchy"
                                                                        value={item.levelsIds}
                                                                        onChange={(value) => onChangeCommitteeLevelHandler(value, index)}
                                                                        options={item.hierarchyList}
                                                                        disabled={item.CommitteeTypeId <= 0}
                                                                    />
                                                                </td>
                                                                <td width="7%" className="action txtCenter">
                                                                    {(CommitteeTypeLevels.length > 1) && <button><i aria-hidden="true" class="trash icon squareIcon" onClick={() => removeComitteeTypeItemHandler(index)}></i></button>}
                                                                </td>
                                                            </tr>

                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="deletAction">
                                                <button title="Add" type="button" class="addBtn noBorder" disabled={!(CommitteeTypeLevels.length > 0 && CommitteeTypeLevels.filter(item2 => item2.CommitteeTypeId === 0).length === 0)} onClick={addComitteeTypeItemHandler}><i aria-hidden="true" class="add icon"></i></button>
                                            </div>
                                        </div>

                                    </Grid.Column>
                                </Grid.Row>}
                                <Grid.Row>
                                    <span className="accordHead">Responsibilities</span>
                                </Grid.Row>

                                <Grid.Row>

                                    <Accordion fluid>

                                        {responsibility.map((item, index) => {
                                            return <React.Fragment>

                                                <Accordion.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
                                                    <Icon name='dropdown' />
                                                    {item.ResponsibilityName}
                                                </Accordion.Title>
                                                <Accordion.Content active={activeIndex === index}>

                                                    <div>
                                                        {item.ResponsibilityGroupList.map((res, resIndex) => {
                                                            return <React.Fragment>
                                                                <p class="commonTitle">{res.ResponsibilityGroupName}</p>
                                                                <div className="checkBoxWrapper">
                                                                    <Grid className="privActionList" columns='4'>
                                                                        {this.renderPrivilege(res.ResponsibilityPrivilegeList, index, resIndex)}
                                                                    </Grid>
                                                                </div>
                                                            </React.Fragment>
                                                        })}
                                                    </div>
                                                </Accordion.Content>

                                            </React.Fragment>
                                        })}
                                    </Accordion>
                                </Grid.Row>

                            </Grid>

                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary type="submit" disabled={!enableSubmitButton}>Save</Button>
                        <Button className="cancel" onClick={this.onCancelHandler}>Cancel</Button>

                    </Modal.Actions>
                </Form>
            </React.Fragment>
        );
    }
}

export default (reduxForm({
    form: 'addOfficerTitlesForm'
})(AddOfficerTitlesForm));
