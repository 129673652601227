import React from "react";
import { Icon } from 'semantic-ui-react';
import CustomTooltip from "components/atoms/Tooltip";
import { setPermissionNavigation } from 'helpers/utilCommon';

let self = {};

export const setClassInstance = (instance) => {
    self = instance;
}
export function listingView(list) {
    const { userPermission } = self.props;
    if (list && list.length > 0) {
        return list.map((result, index) => {
            return (<tr key={result.BosVolumeId}>
                <td>{result.SectionNumber}</td>
                <td>{result.VolumeNumber}</td>
                <td>{result.BosVolumeLocation}</td>
                <td className="description">
                    {result.Comments ?
                        result.Comments.length > 92 ?
                            <CustomTooltip position='bottom left' className="leftAlignTolltip"
                                icon={<div style={{ '-webkit-box-orient': 'vertical' }}>
                                    <span dangerouslySetInnerHTML={{ __html: result.Comments.substring(0, 92) + '...' }}></span></div>}
                                content={result.Comments} />
                            : result.Comments
                        : '----'}
                </td>
                {(userPermission.Update || userPermission.Delete) && <td width="5%" className="action">
                    {userPermission.Update && <button data-testid='editButton' onClick={() => editBosVolume(result)}><Icon className="squareIcon" name='pencil' /> </button>}
                    {userPermission.Delete && <button data-testid='deleteButton' onClick={() => checkValidation(result.BosVolumeId)}><Icon name='trash' className="squareIcon" /></button>}
                </td>}
            </tr>)
        });
    }
    return (<tr>
        <td colSpan="5"> {
            self.props.messageCodes['7151'].text
        }</td>
    </tr>)
}

export function editBosVolume(data) {
    self.props.setEditFormData(data, () => {
        setPermissionNavigation('bosVolume-edit')
        self.props.history.push(`/bos-volume/edit/${data.BosVolumeId}`);
    })
}

export function checkValidation(bosVolumeId) {
    self.setState({ bosVolumeId })
    self.props.checkBosCommitteeAssociation(bosVolumeId, (res) => {
        if (res) {
            self.setState({ openWarningModal: true })
        } else {
            self.setState({ openConfirmationModal: true })
        }
    })
}

export const closeWarning = () => {
    self.setState({ openWarningModal: false, openConfirmationModal: false })
}
export const deleteBosVolume = (confirm) => {
    if (confirm) {
        self.props.deleteBosVolume(self.state.bosVolumeId, (res) => {
            if (res) {
                closeWarning()
                self.props.getBosVolumeList(() => {
                    self.props.refreshPagination()
                });
            }
        })
    } else {
        closeWarning()
    }
}