import CustomTooltip from 'components/atoms/Tooltip';
import React, { Component } from 'react';
import { SortableContainer, SortableElement } from 'react-18-sortable-hoc';
import { Card, Icon } from 'semantic-ui-react';



const isDraggable = (activeFilter, status) => {
    if (activeFilter === false || status === false) {
        return false;
    }
    return true;
}

const SortableItem = SortableElement(({ userPermission, item, setPopup, index, viewOfficerTitle, activeFilter, currentIndex }) => {
    return < Card card-index={item.SequenceNo} disabled={true} data-allowDragable={isDraggable(!activeFilter, item.Status)}  >
        <div data-allowDragable={isDraggable(!activeFilter, item.Status)} className={item.SequenceNo > 0 ? 'roleCard' : 'roleCard inactive'} data-cardSequence={item.SequenceNo}>
            <div className="roleStatus" data-allowDragable={isDraggable(!activeFilter, item.Status)} >
                {item.SequenceNo > 0 && <span className="positionMumber mr10">{item.SequenceNo}</span>}
                <span class="statusIndicator " className={item.Status ? 'statusIndicator active' : 'statusIndicator inactive'}>
                    {item.Status ? 'Active' : 'Inactive'}

                </span>
                <span className="floatRight" >
                    {userPermission && userPermission.Update &&
                        < CustomTooltip content='Edit Officer Title' position='bottom left' icon={<Icon name='pencil' onClick={() => viewOfficerTitle(item.OfficerTitleId)} />} />
                    }
                </span>
            </div>
            {item.OfficerTitleName.length > 15 &&
                <CustomTooltip data-allowDragable={isDraggable(!activeFilter, item.Status)} position='bottom left' icon={<h4 > {item.OfficerTitleName.length > 15 ? item.OfficerTitleName.substring(0, 15) + '...' : item.OfficerTitleName} </h4>} content={item.OfficerTitleName} />

            }
            {item.OfficerTitleName.length <= 15 &&
                <h4 data-allowDragable={isDraggable(!activeFilter, item.Status)}> {item.OfficerTitleName.length > 15 ? item.OfficerTitleName.substring(0, 15) + '...' : item.OfficerTitleName} </h4>
            }
            <div className="officerSubInfo" data-allowDragable={isDraggable(!activeFilter, item.Status)} >
                {item.IsASTMStaff ? <span>ASTM Staff</span> : <span> &nbsp;</span>}
                {item.IsApplicableToAllCommittees ? <span> &nbsp;</span> : !item.IsASTMStaff ? <span>Specific to a Committee</span> : <span>&nbsp;&bull;&nbsp;Specific to a Committee</span>}

            </div>
            <div className="privileges" data-allowDragable={isDraggable(!activeFilter, item.Status)} >
                <span className="privelegeHead">Responsibilities</span>
                <ul className="" data-allowDragable={isDraggable(!activeFilter, item.Status)} >
                    {setPopup(item.ResponsibilityList, currentIndex, isDraggable(!activeFilter, item.Status))}
                </ul>

            </div>
        </div>
    </Card >
}

)

const SortableList = SortableContainer(({ userPermission, items, setPopup, viewOfficerTitle, activeFilter }) => {
    return (
        <Card.Group itemsPerRow={4}>
            {items && items.map((value, index) => {
                return <SortableItem userPermission={userPermission} key={`item-${value.OfficerTitleId}`} hideSortableGhost={false} item={value} setPopup={setPopup} index={index} viewOfficerTitle={viewOfficerTitle} activeFilter={activeFilter} currentIndex={index} />
            })}
        </Card.Group >
    )
})

export default class SortableOfficer extends Component {

    shouldCancelStart = (e) => {
        let elementTarget = e.target.parentNode;
        let isDraggableElement = false;
        if (elementTarget.attributes['data-allowdragable'] !== undefined) {
            if (elementTarget.attributes['data-allowdragable'].value === 'true') {
                isDraggableElement = false;
            } else if (elementTarget.attributes['data-allowdragable'].value === 'false') {
                isDraggableElement = true;
            }
        }

        return isDraggableElement;
    }

    render() {
        return <SortableList userPermission={this.props.userPermission} shouldCancelStart={this.shouldCancelStart} items={this.props.gridList} helperClass="helper" onSortEnd={this.props.onSortEnd} axis="xy" useDragHandle={false} distance={4} setPopup={this.props.setPopup} viewOfficerTitle={this.props.viewOfficerTitle} activeFilter={this.props.activeFilter} />;
    }
}
