import AppRoutes from 'app.routes';
import CustomLoader from 'components/atoms/Loader';
import MessageDismissible from 'components/atoms/ToastMessage';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import PrivilegeNotFound from './components/atoms/PrivilegeNotFound';
import './components/themes/main.css';
import { azureAdFetchCode, clearAzureAdAuthCodeAction, extractValueFromUrl, isReactAppAuthorized, isTokenExistInCookies } from './helpers/utilCommon';
import { FeatureFlagProvider } from './provider/flagFeature';


class App extends Component {

	state = {
		defaultNavigationUrl: null,
		isLoginByAzureAD: process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true",
		isUserHavePrivileges: false
	}


	componentDidMount() {
		this.loadAppWithDefaultUrl();
	}

	loadAppWithDefaultUrl = () => {
		// Get Nav Default Url
		this.setState({ defaultNavigationUrl: this.validateDefaultUrl() },
			() => this.validateAzureCodeOnUrl());
	}

	// Validate Default Url => Login/Dashboard
	validateDefaultUrl = () => {
		const { isLoginByAzureAD } = this.state;
		if (isReactAppAuthorized()) {
			return '/home';
		} else if (isLoginByAzureAD && !isReactAppAuthorized()) {
			return null;
		}
		return '/login';
	}


	// Validate Azure AD request for AD-TOKEN
	validateAzureCodeOnUrl = () => {
		const { isLoginByAzureAD } = this.state;
		let isLoginWithAD = isLoginByAzureAD;
		if (isLoginWithAD && !isReactAppAuthorized()) {
			if (isTokenExistInCookies()) {
				// IA Application type Id, For get Redis Key.
				let applicationTypeId = 2;
				this.props.authByCookiesToken({ appId: applicationTypeId }, (isSuccess, code) => {
					if (isSuccess) {
						this.props.history.push('/home');
					} else if (!isSuccess && code === '1013') {
						// Call here for No Privilege Found
						this.setState({
							isUserHavePrivileges: true
						})
					} else {
						// Else clear session.
						window.location.assign(clearAzureAdAuthCodeAction());
					}
				})
			} else {
				let code = extractValueFromUrl(window.location.href, "code");
				let sessionState = extractValueFromUrl(window.location.href, "session_state");
				let authState = extractValueFromUrl(window.location.href, "state");
				if (code && sessionState && authState) {
					// TODO : Azure Init Call for create browser session.
					this.props.initiateAzureRequest(code, sessionState, authState, isSuccessAzureInitRequest => {
						if (isSuccessAzureInitRequest) {
							this.props.authenticateByAzureAD((isSuccessAzureAuthRequest, resCode) => {
								if (isSuccessAzureAuthRequest) {
									this.props.history.push('/home');
								} else if (!isSuccessAzureAuthRequest && resCode === '1013') {
									// Call here for No Privilege Found
									this.setState({
										isUserHavePrivileges: true
									})
								} else {
									// Else clear session.
									window.location.assign(clearAzureAdAuthCodeAction());
								}
							});
						} else {
							// Call here for No Privilege Found
							this.setState({
								isUserHavePrivileges: true
							})
						}
					})
				}
				// Redirect for get token from Azure AD
				else {
					window.location.assign(azureAdFetchCode());
				}
			}
		}
	}

	render() {
		const { defaultNavigationUrl, isLoginByAzureAD, isUserHavePrivileges } = this.state;
		let isUserAuthorized = isReactAppAuthorized()
		return (
			<div className="appWrapper" data-testid='appCmp'>
				{this.props.loading && <CustomLoader />}
				{isUserHavePrivileges ?
					<PrivilegeNotFound />
					:
					<FeatureFlagProvider>
					<AppRoutes {...this.props} defaultUrl={defaultNavigationUrl} isUserAuthorized={isUserAuthorized} isLoginByAzureAD={isLoginByAzureAD} />
					</FeatureFlagProvider>
				}
				{this.props.showToastMessage && (
					<MessageDismissible content={this.props.content} />
				)}
			</div>
		);
	}
}


export default App;
