export const HEADERINFO =
    [{ 'title': 'Meeting Type', 'hasSorting': true, 'className': "meetingType", "sortKey": 'Name', 'orderBy': 0, 'orderByIcon': 0 },
    { 'title': 'Description', 'hasSorting': false, 'className': "description", 'sortKey': 'Description', 'orderBy': -1, 'orderByIcon': 0 },
    { 'title': 'Status', 'hasSorting': true, 'className': "status", 'sortKey': 'IsActive', 'orderBy': 0, 'orderByIcon': 0 }]


export const RefreshHeader = () => {
    HEADERINFO.map(item => {
        item.orderBy = item.sortKey !== 'Description' ? 0 : item.orderBy;
        item.orderByIcon = 0;
    });
}
