import BosVolumeAction from 'actions/Committee/bosVolumeAction';
import BOSVolumeComponent from 'components/pages/BOSVolume/BOSVolumeComponent';
import { connect } from "react-redux";
import _ from 'lodash';
import * as type from "actionTypes";
import commonActions from 'actions/commonActions'


const mapStateToProps = ({ bosVolumeReducer, i18nReducer, commonReducer }) => {
    return {
        result: bosVolumeReducer.result,
        filteredList: bosVolumeReducer.filteredList,
        initialValues: bosVolumeReducer.initialValues ? bosVolumeReducer.initialValues : undefined,
        type: bosVolumeReducer.type,
        userPermission: commonReducer.userPermission,
        messageCodes: _.get(i18nReducer.dictionaries, i18nReducer.currentLanguage),
    }
};


const mapDispatchToProps = (dispatch) => ({
    getBosVolumeList: (cb) => BosVolumeAction.getBOSVolumeListAction(dispatch, cb),
    postBosVolume: (data, cb) => BosVolumeAction.postBosVolumeAction(dispatch, data, cb),
    putBosVolume: (data, cb) => BosVolumeAction.putBosVolumeAction(dispatch, data, cb),
    setFilteredList: (data) => dispatch({ type: type.BOS_VOLUME_FILTERED_LIST, payload: data }),
    setEditFormData: (data, cb) => {
        dispatch({ type: type.BOS_VOLUME_EDITVIEW, payload: data });
        cb && cb();
    },
    displayNoChangesMadeMessage: () => dispatch(commonActions.setMessage(true, '2040')),
    checkBosCommitteeAssociation: (bosVolumeId, cb) => BosVolumeAction.checkBosCommitteeAssociationAction(dispatch, bosVolumeId, cb),
    deleteBosVolume: (bosVolumeId, cb) => BosVolumeAction.deleteBosVolumeAction(dispatch, bosVolumeId, cb),
});

export default connect(mapStateToProps, mapDispatchToProps)(BOSVolumeComponent);
