import axios from 'axios';
import * as crypto from 'crypto-browserify';
import _ from 'lodash';
import moment from 'moment';
import { Buffer } from 'buffer';
import { createNumberMask, createTextMask } from 'redux-form-input-masks';
import Cookies from 'universal-cookie';
import messages from '../assets/i18n/en';
import { cookieKeysEnum, MODULES, NAVIGATION } from '../models/Common/commonModels';
export const Name_Regex = /^[A-Za-z]*$/
export const NameWithWhiteSpace_Regex = /^[a-zA-Z ]*$/
export const AlphaNumeric_Regex = /^[A-Za-z0-9]*$/
export const Number_Regex = /^[0-9]*$/
export const AlphaNumericWhiteSpace_Regex = /^[a-zA-Z0-9 ]+$/
export const characterWithHyphen = /^[A-Za-z0-9-]*$/
export const Email_Regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
export const NegativeRegex = /^-?\d+(\.\d{1,2})?$/
export const PositiveRegex = /^\d+(\.\d{1,2})?$/
export const Password_Regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[ !"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~])[A-Za-z\d !"#$%&'()*+,-./\\:;<=>?@[\]^_`{|}~]{8,}$/i
export const File_Regex = /([a-zA-Z0-9\s_\\.\-:()])+(.pdf)$/
export const IP_Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
const COOKIES = new Cookies();
// For pagination
export const PAGE_SIZE_FOR_PAGINATION = 25;
export const encrypt = (text) => {
    try {
        if (text) {
            let iv = crypto.randomBytes(Number(process.env.REACT_APP_STORAGE_IV_LENGTH));
            let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(process.env.REACT_APP_STORAGE_VALUE), iv);
            let encrypted = cipher.update(text);
            encrypted = Buffer.concat([encrypted, cipher.final()]);
            return iv.toString('hex') + ':' + encrypted.toString('hex');
        }
        return text;
    } catch (e) {
        console.log("Exception in Encryption", e);
    }
};

export const decrypt = (text) => {
    try {
        if (text) {
            let textParts = text.split(':');
            if (textParts && textParts.length > 0) {
                let iv = Buffer.from(textParts.shift(), 'hex');
                let encryptedText = Buffer.from(textParts.join(':'), 'hex');
                let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(process.env.REACT_APP_STORAGE_VALUE), iv);
                let decrypted = decipher.update(encryptedText);
                decrypted = Buffer.concat([decrypted, decipher.final()]);
                return decrypted.toString();
            }
        }
        return text;
    } catch (e) {
        console.log("Exception in Decryption", e);
    }
};


export const cacheManager = {
    getItem: (key, isDecrypt = true) => {
        if (isDecrypt) {
            return decrypt(window.localStorage.getItem(key));
        } else {
            return window.localStorage.getItem(key)
        }
    },
    setItem: (key, value) => {
        let string = _.isString(value) ? encrypt(value) : encrypt(JSON.stringify(value));
        window.localStorage.setItem(key, string);
    },
    removeItem: (key) => {
        window.localStorage.removeItem(key)
    },
    clearSession: () => {
        window.localStorage.clear()
    }
}

export const cookiesManager = {
    setCookie: (key, value, isParentOrigin = true) => {
        let date = new Date();
        date.setTime(date.getTime() + (60 * 60 * 24 * 1000)); // TODO : Remove Cookies in 24Hrs.
        let options = { path: '/', expires: date, domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname, secure: window.location.hostname !== 'localhost' };
        COOKIES.set(key, encrypt(value), options);
    },
    getCookie: (key, isDecrypt = true, isParentOrigin = true) => {
        let options = { path: '/', domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname, secure: window.location.hostname !== 'localhost' };
        if (isDecrypt) {
            return decrypt(COOKIES.get(key, options))
        }
        return COOKIES.get(key, options);
    },
    removeCookie: (key, isParentOrigin = true) => {
        let options = { path: '/', domain: isParentOrigin ? getDomainFromSubDomain(window.location.hostname) : window.location.hostname, secure: window.location.hostname !== 'localhost' };
        COOKIES.remove(key, options)
    }
}

// export const sort = (list, key) => {
//     let listToreturn = list;
//     listToreturn.sort((a, b) => {
//         if (a[key] < b[key]) return -1;
//         if (a[key] > b[key]) return 1;
//         return 0;
//     })
//     return listToreturn;
// }

// export const deepCompare = (x, y) => {
//     var i, l, leftChain, rightChain;
//     function compare2Objects(x, y) {
//         var p;
//         // remember that NaN === NaN returns false
//         // and isNaN(undefined) returns true
//         if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
//             return true;
//         }

//         // Compare primitives and functions.     
//         // Check if both arguments link to the same object.
//         // Especially useful on the step where we compare prototypes
//         if (x === y) {
//             return true;
//         }

//         // Works in case when functions are created in constructor.
//         // Comparing dates is a common scenario. Another built-ins?
//         // We can even handle functions passed across iframes
//         if ((typeof x === 'function' && typeof y === 'function') ||
//             (x instanceof Date && y instanceof Date) ||
//             (x instanceof RegExp && y instanceof RegExp) ||
//             (x instanceof String && y instanceof String) ||
//             (x instanceof Number && y instanceof Number)) {
//             return x.toString() === y.toString();
//         }

//         // At last checking prototypes as good as we can
//         if (!(x instanceof Object && y instanceof Object)) {
//             return false;
//         }

//         if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
//             return false;
//         }

//         if (x.constructor !== y.constructor) {
//             return false;
//         }

//         if (x.prototype !== y.prototype) {
//             return false;
//         }

//         // Check for infinitive linking loops
//         if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
//             return false;
//         }

//         // Quick checking of one object being a subset of another.
//         // todo: cache the structure of arguments[0] for performance
//         for (p in y) {
//             if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
//                 return false;
//             }
//             else if (typeof y[p] !== typeof x[p]) {
//                 return false;
//             }
//         }

//         for (p in x) {
//             if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
//                 return false;
//             }
//             else if (typeof y[p] !== typeof x[p]) {
//                 return false;
//             }

//             switch (typeof (x[p])) {
//                 case 'object':
//                 case 'function':

//                     leftChain.push(x);
//                     rightChain.push(y);

//                     if (!compare2Objects(x[p], y[p])) {
//                         return false;
//                     }

//                     leftChain.pop();
//                     rightChain.pop();
//                     break;

//                 default:
//                     if (x[p] !== y[p]) {
//                         return false;
//                     }
//                     break;
//             }
//         }

//         return true;
//     }

//     // eslint-disable-next-line no-undef
//     if (arguments.length < 1) {
//         return true; //Die silently? Don't know how to handle such case, please help...
//         // throw "Need two or more arguments to compare";
//     }

//     // eslint-disable-next-line no-undef
//     for (i = 1, l = arguments.length; i < l; i++) {

//         leftChain = []; //Todo: this can be cached
//         rightChain = [];

//         // eslint-disable-next-line no-undef
//         if (!compare2Objects(arguments[0], arguments[i])) {
//             return false;
//         }
//     }

//     return true;
// }


export const setCookie = (key, value) => {
    let d = new Date();
    d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = key + "=" + value + ";" + expires + ";path=/";
};

export const getCookie = (key) => {
    var name = key + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const removeCookie = (key) => {
    let d = new Date();
    d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
    let expires = "expires=" + 'Thu, 01 Jan 1970 00:00:00 UTC';
    document.cookie = key + "=" + ";" + expires + ";path=/";
};



// export function logMessage(key, value) {
//     console.info(key + ' =>', value);
// }

// export function getInputClasses(requiredClass, touched, error, disabled) {
//     if (disabled) {
//         return 'form-inputFieldrdonly'
//     }
//     else {
//         if (touched && error) {
//             if (requiredClass)
//                 return 'form-inputField form-inputFieldError form-inputFieldImp'
//             return 'form-inputField form-inputFieldError'
//         } else {
//             if (requiredClass)
//                 return 'form-inputField form-inputFieldImp'
//             return 'form-inputField'
//         }
//     }

// }

export const phoneMask = createTextMask({
    pattern: '(999)-999-9999'
});

export const phoneMask12 = createTextMask({
    pattern: '999999999999'
});

export const numberMask = createTextMask({
    pattern: '999'
});

export const expiryDateMask = createTextMask({
    pattern: '99/99'
});

export const percentMask = createTextMask({
    pattern: '99%',
});


export const fourDigitMask = createTextMask({
    pattern: '9999'
});

export const FiveDigitMask = createTextMask({
    pattern: '99999'
});

export const SixDigitMask = createTextMask({
    pattern: '999999'
});

export const visaMask = createTextMask({
    pattern: '9999-9999-9999-9999'
});

export const TenDecimalMask = createNumberMask({
    decimalPlaces: 2,
    maximumFractionDigits: 8
});

export const EightDecimalMask = createNumberMask({
    decimalPlaces: 2,
    maximumFractionDigits: 6
});

export const TwoDecimalMask = createNumberMask({
    decimalPlaces: 2,
    maximumFractionDigits: 2
});

const currencyMask = createNumberMask({
    prefix: "$",
    decimalPlaces: 2,
    locale: "en-US",
    maximumFractionDigits: 4
});

const costMask = createNumberMask({
    prefix: "$",
    decimalPlaces: 2,
    locale: "en-US",
    maximumFractionDigits: 8
});

const NineDigitMask = createNumberMask({
    prefix: "$",
    decimalPlaces: 2,
    locale: "en-US",
    maximumFractionDigits: 9
});

const declaredValueMask = createNumberMask({
    prefix: "$",
    decimalPlaces: 2,
    locale: "en-US",
    maximumFractionDigits: 6
})


const currencyMask4 = createNumberMask({
    prefix: "$",
    decimalPlaces: 4,
    locale: "en-US",
    maximumFractionDigits: 4
});

export function truncateText(element, numberOfLines, elementHeight) {
    let nHeight,
        el2 = element.cloneNode(true);
    // Create clone to determine line height
    el2.style.position = 'absolute';
    el2.style.top = '0';
    el2.style.width = '100%';
    el2.style.overflow = 'hidden';
    el2.style.visibility = 'hidden';
    el2.style.whiteSpace = 'nowrap';
    element.parentNode.appendChild(el2);
    nHeight = elementHeight; // Add 2 pixels of slack
    // Clean up
    element.parentNode.removeChild(el2);
    el2 = null;
    // Truncate until desired nLines reached
    if (element.clientHeight - nHeight > 10) {
        let i = 0,
            imax = numberOfLines * 600;
        while (element.clientHeight > nHeight) {
            element.innerHTML = element.textContent.slice(0, -4) + '&hellip;';
            ++i;
            // Prevent infinite loop in "print" media query caused by
            // Bootstrap 3 CSS: a[href]:after { content:" (" attr(href) ")"; }
            if (i === imax) {
                break;
            }

        }
    }
}

export function shallowCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}
export const getMessage = (messageList, message) => {
    return _.get(messageList, message);
}

export function utcDateTimeToLocalDateTime(date) {
    return moment.utc(date).utcOffset(moment().utcOffset())
    .format("L LT");
}


export const encodeText = (text) => {
    return window.btoa(text);
}

export const copyDataToClipboard = (data) => {
    let isCopiedSuccess = false;

    if (window.clipboardData) {
        window.clipboardData.setData("Text", data);
        isCopiedSuccess = true;
    } else {
        const textArea = document.createElement("textarea");
        textArea.value = data;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            isCopiedSuccess = !!successful;
        } catch (err) {
            isCopiedSuccess = false;
        }

        document.body.removeChild(textArea);
    }

    return isCopiedSuccess;
}

export const generatePassword = (length) => {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}



// Replace String at Selected Index
export const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length);
}

// Validate Params Keys while Url Comes from Request
export const validateUrlParams = (routeUrls, currentPath) => {
    currentPath = currentPath && replaceAt(currentPath, 0, " ");
    currentPath = currentPath && currentPath.trim();
    let routeUrlSplitParamsArray = routeUrls && routeUrls.split("/")
    let currentUrlSplitParamsArray = currentPath && currentPath.split("/");
    if (routeUrls.indexOf('/:') > -1 && currentPath.indexOf(routeUrlSplitParamsArray[0]) > -1 && currentPath.indexOf(routeUrlSplitParamsArray[1]) > -1) {
        for (let i = 0; i < routeUrlSplitParamsArray.length; i++) {
            if (currentPath.indexOf(routeUrlSplitParamsArray[i]) === -1) {
                routeUrlSplitParamsArray[i] = currentUrlSplitParamsArray[i];
            }
        }
        return routeUrlSplitParamsArray && routeUrlSplitParamsArray.join("/");
    }
    return routeUrls;
}

// Validate Request Url For Permission
export const validateNavigationFromUrl = (currentPath) => {
    const beautifyCurrentPath = (url) => url && url.replace("/", "");
    let navigationModal = NAVIGATION;
    let result = [];
    if (navigationModal && navigationModal.length > 0) {
        for (let i = 0; i < navigationModal.length; i++) {
            let modulesItems = navigationModal[i][MODULES[i]];
            if (modulesItems && modulesItems.length > 0) {
                result = modulesItems.filter((itemsList) => {
                    return beautifyCurrentPath(currentPath) === validateUrlParams(itemsList.routeUrl, currentPath)
                });
                if (result && result.length > 0) {
                    return { selectedSingleResult: result, selectedFullResult: modulesItems };
                }
            }
        }
    }
}

// Filter Permission Url if Allow
export const filterPermissionUrl = (requestUrl, method) => {
    let permissionsRoleModel = JSON.parse(cacheManager.getItem("privilegeRoleModel"));
    let permissions = permissionsRoleModel && permissionsRoleModel.length > 0 && _.get(permissionsRoleModel[0], 'permissions');
    let isPageAuthorized = permissions && permissions.length > 0 && permissions.filter((items) => {
        if (method) {
            return items.methods.indexOf(method) > -1 && requestUrl.oslUrl === items.resource && items.action === 'allow';
        }
        return requestUrl.oslUrl === items.resource && items.action === 'allow';
    })
    return isPageAuthorized;
}

// Validate Auth or Not
export const allowPermissionForAuth = (isPageAuthorized) => {
    if (isPageAuthorized && isPageAuthorized.length > 0) {
        return true
    }
    return false;
}

// Set Route Urls
export const setPermissionNavigation = (keys, paramsKey = []) => {

    const setParamsOnUrlRoutes = (selectedUrl) => {
        let ParamsIndex = -1; let url = selectedUrl && selectedUrl.length > 0 && selectedUrl[0].routeUrl; url = url && url.split("/");
        if (url && url.length > 0) { for (let i = 0; i < url.length; i++) { if (url[i].indexOf(":") > -1) { ParamsIndex++; url[i] = paramsKey[ParamsIndex]; } } }
        return url && url.join("/");
    }

    let moduleUrls = [];
    NAVIGATION.map((moduleItem, index) => moduleItem[MODULES[index]])
        .map((result) => { result.map((items) => items.uniqueKey === keys && moduleUrls.push(items)) })
    if (moduleUrls && moduleUrls.length > 0) {
        if (paramsKey.length > 0) {
            return `/${setParamsOnUrlRoutes(moduleUrls)}`;
        }
        return `/${moduleUrls[0].routeUrl}`;
    }
    return "/";
}

export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


export const getMessageContent = (code) => {
    return _.get(messages, code).text;
}

export const compareSimpleFormData = (prevData, curData, formField = []) => {
    let changeCount = 0;
    (formField && formField.length > 0) && formField.map(item => {
        if ((prevData[item] && prevData[item].toString() || '').trim().toLowerCase() !== (curData[item] && curData[item].toString() || '').trim().toLowerCase()) {
            changeCount++;
        }
    })
    return changeCount;
}

// TODO : fetch azure ad code
export const azureAdFetchCode = () => {
    // Validate location get from params while called
    let azureTempleteURL = process.env.REACT_APP_AZURE_ROOT_URL + '/<TENENT_ID>/oauth2/authorize?response_type=code&client_id=<CLIENT_ID>&redirect_uri=<REDIRECT_URL>&state=<STATE>&resource=<RESOURCE>';
    azureTempleteURL = azureTempleteURL.replace("<TENENT_ID>", process.env.REACT_APP_TENANT_ID);
    azureTempleteURL = azureTempleteURL.replace("<CLIENT_ID>", process.env.REACT_APP_AUTH_CLIENT_ID);
    azureTempleteURL = azureTempleteURL.replace("<REDIRECT_URL>", process.env.REACT_APP_REDIRECT_URI);
    azureTempleteURL = azureTempleteURL.replace("<STATE>", `${encrypt(Math.random().toString(36) + ':' + getUserAgent().name)}`);
    azureTempleteURL = azureTempleteURL.replace("<RESOURCE>", process.env.REACT_APP_OSL_AD_AUTH_APP_CLIENT_ID);
    return azureTempleteURL;

}

export const clearAzureAdAuthCodeAction = () => {
    // TODO : Remove forcefully Clean Session.
    cacheManager.clearSession()
    cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
    let url = process.env.REACT_APP_AZURE_LOGOUT_URL + '/' + process.env.REACT_APP_TENANT_ID + "/oauth2/logout?post_logout_redirect_uri=" + process.env.REACT_APP_REDIRECT_URI + "/logout"
    return url
}

export const addRedirectUrlToLogin = (pathName) => {
    return '/login?requestUrl=' + pathName;
}

export const getRedirectUrlAfterLogin = (search) => {
    return extractValueFromUrl(search, 'requestUrl');
}

// Extract a query string value from the url
export const extractValueFromUrl = (url, paramName) => {
    let regex = '';
    if (url && url.indexOf(paramName) === 0) {
        regex = new RegExp(paramName + '=([^&#]*)', 'i');
    } else {
        regex = new RegExp('[?&]' + paramName + '=([^&#]*)', 'i');
    }
    let string = regex.exec(url);
    return string ? string[1] : null;
}

export const setLoginDataToCacheManager = (response, loginActionType = null) => {
    // Perform login Action on Type.
    switch (loginActionType) {
        case 'DELETE': // For Delete Token
            cacheManager.removeItem('privilegeRoleModel');
            cacheManager.removeItem('userDetail');
            cacheManager.removeItem('isReactAppAuthorized');
            cacheManager.removeItem(cookieKeysEnum.accessToken);
            cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
            break;
        case 'LOGIN': // For Add login/Azure Ad Login Request
            cacheManager.setItem('privilegeRoleModel', response.data.privilege.privilegeRoleModel);
            cacheManager.setItem('userDetail', response.data.privilege.userDetail);
            cacheManager.setItem('isReactAppAuthorized', true);
            cacheManager.setItem(cookieKeysEnum.accessToken, response.data.accessToken);
            break;
        case 'REFRESH': // For Refresh token Request Update
            if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
                if (cacheManager.getItem(cookieKeysEnum.accessToken)) {
                    cacheManager.removeItem(cookieKeysEnum.accessToken);
                }
                cacheManager.setItem(cookieKeysEnum.accessToken, response.data.accessToken);
            }
            break;
        default:
            break;
    }
}
export const isReactAppAuthorized = () => {
    if (cacheManager.getItem('isReactAppAuthorized')) { // VALIDATE THE TOEKN EXIST OR NOT
        if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true" && !isTokenExistInCookies()) {
            cacheManager.clearSession(); // // Check Access Token Should be Exist In Case of Azure AD
        }
        return true
    }
    return false;
}

export const getCurrentUserEmail = () => {
    let userDetail = cacheManager.getItem('userDetail') && JSON.parse(cacheManager.getItem('userDetail'));
    if (userDetail !== null) { // GET CURRENT LOGGED IN USER EMAIL.
        return _.get(userDetail, 'email');
    }
}


export const isTokenExistInCookies = () => {
    if (cookiesManager.getCookie(cookieKeysEnum['APP_SESSIONID'])) {
        return true
    }
    return false;
}

export const getDomainFromSubDomain = (hostname) => {
    if (window.location.hostname !== "localhost") {
        const dotIndex = hostname.indexOf('.');
        return hostname.substring(dotIndex + 1);
    }
    return hostname

}

export const noCookiesRedirectToLogin = () => {
    if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true" && !isTokenExistInCookies()) {
        window.location.assign('/');
    }
}

export const validateScript = (input) => {
    const { value } = input;
    if (value) {
        let val = value.toString().replace(/</g, '').replace(/>/g, '');
        input.value = val;
    }
    return input
}


export const getFileType = (fileName) => {
    let lastIndex = fileName.lastIndexOf('.');

    if ((fileName.length > lastIndex + 1) && lastIndex > 0) {
        return fileName.substring(lastIndex + 1).toLowerCase();
    }

    return fileName;
}
export const isLocalhost = () => {
    return window.location.origin.includes('localhost');
}

export const callAPI = (url, verb, data, callback) => {
    axios[verb](url, data)
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            callback({});
        })
}

// TODO : Get User Agitent (Identify request coming from browser.)
export const getUserAgent = () => {
    const test = (regexp) => regexp.test(window.navigator.userAgent);
    switch (true) {
        case test(/edg/i): return { name: 'Edge', version: '' };
        case test(/trident/i): return { name: 'IE', version: '' };
        case test(/firefox|fxios/i): return { name: 'Firefox', version: '' };
        case test(/opr\//i): return { name: 'Opera', version: '' };
        case test(/ucbrowser/i): return { name: 'UC', version: '' };
        case test(/samsungbrowser/i): return { name: 'Samsung', version: '' };
        case test(/chrome|chromium|crios/i): return { name: 'Chrome', version: '' };
        case test(/safari/i): return { name: 'Safari', version: '' };
        default: return { name: 'Other', version: '' };
    }
}

export const getCurrentlyShowingItemsInGrid = (pageNumber, pageSize, totalItems) => {
    if (pageNumber > 0 && pageSize > 0 && totalItems > 0) {
        let startIndex = (pageNumber * pageSize) - pageSize + 1;
        let lastIndex = totalItems >= (pageNumber * pageSize) ? (pageNumber * pageSize) : totalItems;
        return `${startIndex} - ${lastIndex} of ${totalItems} items`;
    }
    return '';
}

export const ItemsPerPage = [
    { key: 1, text: "25", value: 25 },
    { key: 2, text: "50", value: 50 },
    { key: 3, text: "75", value: 75 },
    { key: 4, text: "100", value: 100 }
];

export default {
    Password_Regex,
    Email_Regex,
    IP_Regex,
    File_Regex,
    setCookie,
    getCookie,
    removeCookie,
    getMessage,
    phoneMask,
    AlphaNumeric_Regex,
    AlphaNumericWhiteSpace_Regex,
    Name_Regex,
    currencyMask,
    numberMask,
    currencyMask4,
    fourDigitMask,
    SixDigitMask,
    FiveDigitMask,
    TenDecimalMask,
    EightDecimalMask,
    TwoDecimalMask,
    phoneMask12,
    characterWithHyphen,
    visaMask,
    costMask,
    expiryDateMask,
    PositiveRegex,
    NegativeRegex,
    declaredValueMask,
    NineDigitMask,
    percentMask,
    Number_Regex,
    truncateText,
    shallowCopy,
    utcDateTimeToLocalDateTime,
    encodeText,
    copyDataToClipboard,
    generatePassword,
    validateNavigationFromUrl,
    filterPermissionUrl,
    allowPermissionForAuth,
    setPermissionNavigation,
    generateUUID,
    getMessageContent,
    isReactAppAuthorized,
    getCurrentUserEmail,
    setLoginDataToCacheManager,
    cacheManager,
    addRedirectUrlToLogin,
    isTokenExistInCookies,
    noCookiesRedirectToLogin,
    validateScript,
    getFileType,
    callAPI,
    getUserAgent,
    getCurrentlyShowingItemsInGrid
};


