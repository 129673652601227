export const MembershipDocumentModel = {
  "Id": 0,
  "Key": "",
  "Name": "",
  "Type": "",
  "Status": true,
  "BlobFileName": "",
  "BlobContainerName": ""
}
export const HEADERINFO = [{ 'title': 'Document Name', 'hasSorting': false, 'className': "membershipFee", "sortKey": 'documentName', 'orderBy': 0, 'orderByIcon': 0, 'width': '20%' },
{ 'title': 'Type', 'hasSorting': false, 'className': "isEnabled", 'sortKey': 'type', 'orderBy': 0, 'orderByIcon': 0, 'width': '15%' },
{ 'title': 'Status', 'hasSorting': false, 'className': "isEnabled", 'sortKey': 'status', 'orderBy': 0, 'orderByIcon': 0, 'width': '11%' },
{ 'title': 'Updated By', 'hasSorting': false, 'className': "isEnabled", 'sortKey': 'updatedBy', 'orderBy': 0, 'orderByIcon': 0, 'width': '20%' },
{ 'title': 'Updated On', 'hasSorting': false, 'className': "isEnabled", 'sortKey': 'updatedOn', 'orderBy': 0, 'orderByIcon': 0, 'width': '20%' },
{ 'title': 'Actions', 'hasSorting': false, 'className': "isEnabled", 'sortKey': 'action', 'orderBy': 0, 'orderByIcon': 0, 'width': '14%' }
]


