export const HEADERINFO =
  [{ 'title': 'Committee Type', 'hasSorting': true, 'className': "CommitteeName", "sortKey": 'CommitteeTypeName', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'Balance Required', 'hasSorting': true, 'className': "CommitteeFee", 'sortKey': 'IsBalanceRequired', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'Permitted Members', 'hasSorting': true, 'className': "isEnabled", 'sortKey': 'NoOfMembersPermitted', 'orderBy': 0, 'orderByIcon': 0 }
  ]

export const RefreshHeader = () => {
  HEADERINFO.map(item => {
    item.orderBy = 0;
    item.orderByIcon = 0;

    if(item.sortKey === 'CommitteeTypeName') {
      item.orderBy = 1;
      item.orderByIcon = 1;
    }
  });
}
