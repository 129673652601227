// Login
export const LOGIN = 'Login';
export const AZURE_LOGIN = "AZURE_LOGIN";
export const AZURE_REFRESH_TOKEN = "AZURE_REFRESH_TOKEN";
// Application
export const VIEW_APPLICATION = 'ViewApplication';
export const ADD_APPLICATION = 'AddApplication';
export const EDIT_APPLICATION = 'EditApplication';
export const RESET_APPLICATION_STATE = 'ResetState';

// Common
export const SET_MESSAGE = 'SetMessage';
export const SET_LOADER = 'SetLoader';
export const SET_MINI_LOADER = 'SET_MINI_LOADER';
export const SHOW_NOT_FOUND_PAGE = 'SHOW_NOT_FOUND_PAGE';
export const HIDE_NOT_FOUND_PAGE = 'HIDE_NOT_FOUND_PAGE';

// Membership
export const ADD_FAQ = 'AddFaq';
export const VIEW_FAQ = 'ViewFaq';
export const EDIT_FAQ = 'EditFaq';
export const CLICK_ON_ADD_VIEW_IN_MEMBERSHIP_TYPE = "CLICK_ON_ADD_VIEW_IN_MEMBERSHIP_TYPE";

// Classification
export const ADD_CLASSIFICATION = 'AddClassification';
export const VIEW_CLASSIFICATION = 'ViewClassification';
export const EDIT_CLASSIFICATION = 'EditClassification';
export const CLASSIFICATION_DUPLICATE = "CLASSIFICATION_DUPLICATE"
export const APPLICABLE_ALL_COMMITTEES = 'APPLICABLE_ALL_COMMITTEES';
export const PICK_COLOR = 'PICK_COLOR';

export const VIEW_DOCUMENT = 'ViewDocument';
export const ADD_DOCUMENT = 'AddDocument';
export const EDIT_DOCUMENT = 'EditDocument';
export const RESET_DOCUMENT_STATE = 'ResetDocumentState';

// Officer title
export const VIEW_OFFICER_TITLE = 'ViewOfficerTitle';
export const ADD_OFFICER_TITLE = 'AddOfficerTitle';
export const EDIT_OFFICER_TITLE = 'EditOfficerTitle';
export const RESET_OFFICER_TITLE_STATE = 'ResetOfficerTitleState';
export const EDIT_RESPONSIBILITY = 'EDIT_RESPONSIBILITY'


// MEMBERSHIP TYPE ACTION LIST
export const MEMBERSHIPTYPE_LIST = "MEMBERSHIPTYPE_LIST";
export const MEMBERSHIPTYPE_DETAILS_SUBMIT = "MEMBERSHIPTYPE_DETAILS_SUBMIT";
export const MEMBERSHIPTYPE_SETTING_SUBMIT = "MEMBERSHIPTYPE_SETTING_SUBMIT";
export const MEMBERSHIPTYPE_SETTING_BACK_EVENT = "MEMBERSHIPTYPE_SETTING_BACK_EVENT";
export const MEMBERSHIPTYPE_ENABLE_WEB_DOC_SELECTED_EVENT = "MEMBERSHIPTYPE_ENABLE_WEB_DOC_SELECTED_EVENT";
export const MEMBERSHIPTYPE_ENABLE_WEB_APP_SELECTED_EVENT = "MEMBERSHIPTYPE_ENABLE_WEB_APP_SELECTED_EVENT";
export const MEMBERSHIPTYPE_ENABLE_WEB_APP_DATA_EVENT = "MEMBERSHIPTYPE_ENABLE_WEB_APP_DATA_EVENT";
export const MEMBERSHIPTYPE_ENABLE_WEB_APP_DOC_SELECTED_EVENT = "MEMBERSHIPTYPE_ENABLE_WEB_APP_DOC_SELECTED_EVENT";
export const MEMBERSHIPTYPE_EDIT = "MEMBERSHIPTYPE_EDIT";
export const MEMBERSHIPTYPE_EDIT_ON_DEATILS = "MEMBERSHIPTYPE_EDIT_ON_DEATILS";
export const MEMBERSHIPTYPE_EDIT_ON_SETTING = "MEMBERSHIPTYPE_EDIT_ON_SETTING";
export const MEMBERSHIPTYPE_DOC_DELETE = "MEMBERSHIPTYPE_DOC_DELETE";
export const MEMBERSHIPTYPE_SETTING_HOLD_VALUE = "MEMBERSHIPTYPE_SETTING_HOLD_VALUE";
export const MEMBERSHIPTYPE_CLEAR_ACTION = "MEMBERSHIPTYPE_CLEAR_ACTION";

// User Manage Action List
export const USER_ROLE_LIST = "USER_ROLE_LIST";
export const USER_DEFAULT_PRIVILEGE = "USER_DEFAULT_PRIVILEGE"
export const USER_PRIVILEGE_BY_ROLE = "USER_PRIVILEGE_BY_ROLE";
export const USER_PRIVILEGE_BY_USER = "USER_PRIVILEGE_BY_USER";
export const USER_DETAIL_WITH_PRIVILEGE = "USER_DETAIL_WITH_PRIVILEGE";
export const USER_VALIDATE_IN_AD = "USER_VALIDATE_IN_AD";
export const USER_ADD = "USER_ADD";
export const USER_CHANGE_PRIVILEGE = "USER_CHANGE_PRIVILEGE";
export const USER_ISDUPLICATE = "USER_ISDUPLICATE";
export const USER_VIEW = "USER_VIEW";
export const USER_EDIT_SUBMITED = "USER_EDIT_SUBMITED";
export const USER_LIST = "USER_LIST";
export const SET_USER_ID = "SET_USER_ID";

// Committee Type
export const COMMITTEETYPE_LIST = "COMMITTEETYPE_LIST";
export const COMMITTEETYPE_SETTING_HOLD_VALUE = "COMMITTEETYPE_SETTING_HOLD_VALUE";
export const COMMITTEETYPE_DETAILS_SUBMIT = "COMMITTEETYPE_DETAILS_SUBMIT";
export const COMMITTEETYPEADD = "COMMITTEETYPEADD"
export const COMMITTEEPRIVILEGE = "COMMITTEEPRIVILEGE"
export const COMMITTEEDUPLICATE = "COMMITTEEDUPLICATE"
export const COMMITTEETYPEEDITVIEW = "COMMITTEETYPEEDITVIEW"
export const COMMITTEETYPEMODELRESET = "COMMITTEETYPEMODELRESET"

// Meeting Type

export const MEETINGTYPE_LIST = "MEETINGTYPE_LIST"
export const MEETINGTYPE_RESET = "MEETINGTYPE_RESET"
export const MEETINGTYPE_VIEW = "MEETINGTYPE_VIEW"
export const MEETINGTYPE_DUPLICATE = "MEETINGTYPE_DUPLICATE"
export const MEETINGTYPE_EDIT = "MEETINGTYPE_EDIT"
export const MEETINGTYPE_RESET_ALL = "MEETINGTYPE_RESET_ALL"

// BOS Volume Type
export const BOS_VOLUME_LIST = "BOS_VOLUME_LIST";
export const BOS_VOLUME_FILTERED_LIST = "BOS_VOLUME_FILTERED_LIST";
export const BOS_VOLUME_DETAILS_SUBMIT = "BOS_VOLUME_DETAILS_SUBMIT";
export const BOS_VOLUME_PRIVILEGE = "BOS_VOLUME_PRIVILEGE"
export const BOS_VOLUME_DUPLICATE = "BOS_VOLUME_DUPLICATE"
export const BOS_VOLUME_EDITVIEW = "BOS_VOLUME_EDITVIEW"
export const BOS_VOLUME_FORM_RESET = "BOS_VOLUME_FORM_RESET"
export const SHOW_ADD_VOLUME_FORM = "SHOW_ADD_VOLUME_FORM"

// Membrship Template
export const EDIT_TEMPLATE = 'EditTemplate';

// Permission Action
export const ALLOW_PERMISSION_FOR_PAGE = "ALLOW_PERMISSION_FOR_PAGE"