import React from "react";
import ControlledEditor from "./index";
const EditorFieldComponent = props => {

  const { required, label, input: { onChange, value }, id } = props;

  return (
    <ControlledEditor
      id={id}
      required={required}
      onChange={onChange}
      label={label}
      value={value}
    />
  );
};
export default EditorFieldComponent;
