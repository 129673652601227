import axios from 'axios';
import getApiUrl from 'helpers/apiUrls'
import * as commonActions from 'actions/commonActions';
import * as type from 'actionTypes';

const getMeetingTypeListAction = (dispatch, callback) => {

    const url = getApiUrl('meetingType', 'getMeetingTypeList');
    dispatch(commonActions.setLoader(true));

    axios.get(url)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                dispatch({ type: type.MEETINGTYPE_LIST, meetingList: response.data.content ? response.data.content : [] })
                callback();
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7060'));

        });
};

// For add meeting type
export const postMeetingTypeAction = (data, dispatch, callback) => {

    const url = getApiUrl('meetingType', 'postMeetingType');
    dispatch(commonActions.setLoader(true));

    axios.post(url, data)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '5022'));
            } else if (response.data.message === 2601) {
                dispatch({ type: type.MEETINGTYPE_DUPLICATE, isDuplicateMeetingType: true })
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));
            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7061'));

        });
}

// For edit meeting type
export const putMeetingTypeAction = (data, dispatch, callback) => {

    const url = getApiUrl('meetingType', 'putMeetingType');
    dispatch(commonActions.setLoader(true));

    axios.put(url, data)
        .then((response) => {

            dispatch(commonActions.setLoader(false));
            if (response.data.status) {
                callback();
                dispatch(commonActions.setMessage(true, '5023'));
            } else if (response.data.message === 2601) {
                dispatch({ type: type.MEETINGTYPE_DUPLICATE, isDuplicateMeetingType: true })
            } else {
                // Handle error case
                dispatch(commonActions.setMessage(true, response.data.message));

            }
        })
        .catch((err) => {
            dispatch(commonActions.setLoader(false));
            dispatch(commonActions.setMessage(true, '7062'));
            callback();
        });
}
export default {
    getMeetingTypeListAction,
    postMeetingTypeAction,
    putMeetingTypeAction
}