import * as actionType from 'actionTypes';
import TableHeader from 'components/atoms/Table/tableHeader';
import * as header from 'models/Membership/membershipTypeModel';
import React from "react";
import { Link } from 'react-router-dom';
import { setPermissionNavigation } from '../../../helpers/utilCommon';

class MemberShipTypeList extends React.Component {

    state = {
        loading: false,
        isRedirect: false,
        memberTypeList: [],
        selectedId: ''
    }

    componentDidMount() {
        this.props.membershipTypeList();
    }

    componentWillReceiveProps(nextProps) {
        const { type } = nextProps;
        if (type) {

            switch (type) {
                case actionType.MEMBERSHIPTYPE_LIST:
                    this.setState({ memberTypeList: nextProps.list })
                    break;
                default:
                    break;
            }
        }
    }

    componentWillUnmount() {
        this.props.clearActionType();
    }

    listingView(list) {

        if (list && list.length > 0) {
            return list.map((result, index) => {
                return (
                    <tr key={index}>
                        <td >
                            <Link to={setPermissionNavigation('membershipType-detail', [result.MembershipTypeId])}>{result.MembershipTypeName}</Link>
                        </td>
                        <td>
                            {result.FeeAmount === 0 || !result.FeeAmount ? `$${"0.00"}` : `$${(result.FeeAmount).toFixed(2)}`}
                        </td>
                        <td>
                            {result.EnableOnWeb ? 'Yes' : 'No'}
                        </td>
                        <td>
                            {result.hasOwnProperty('MembershipTypeCode') ? result.MembershipTypeCode : ''}
                        </td>
                        <td>
                            {result.hasOwnProperty('StockCode') ? result.StockCode : ''}
                        </td>
                    </tr>
                )
            });
        }
        return (
            <tr><td colSpan="3">{this.props.code['6005'].text}</td></tr>
        )

    }
    onSort = () => {
        // Definitation not require
    }
    render() {
        const { addButtonClicked, userPermission } = this.props;
        return (
            <div data-testid="membershipTypeListCmp">
                <div className="headingTitle clearfix">
                    <h2>Membership Types</h2>
                    {userPermission.Add && <button onClick={addButtonClicked}
                        className="ui secondary button"><i aria-hidden="true" className="plus icon"></i>
                        Add Membership Type</button>}
                </div>
                <div className="contentWrapper clearfix">
                    <div className="tableWrapper">
                        {<table className="customTable memberShipTable" data-testid = 'membership-table'>
                            <thead>
                                <tr>
                                    <TableHeader headerProps={header.HEADERINFO} onClickProps={this.onSort}></TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {this.listingView(this.state.memberTypeList)}
                            </tbody>
                        </table>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default MemberShipTypeList;
