import React from 'react';
import { Checkbox as CheckboxUI } from 'semantic-ui-react';

const Checkbox = ({
    input: { value, onChange, ...input },
    meta: { touched, error },
    ...rest
}) => (
        <div>
            <CheckboxUI
                {...input}
                {...rest}
                defaultChecked={!!value}
                onChange={(e, data) => onChange(data.checked)}
                type="checkbox"
            />
            {touched && error && <span>{error}</span>}
        </div>
    );
export default Checkbox;