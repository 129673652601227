import React, { Component } from 'react';
import { Modal, Grid } from 'semantic-ui-react';



class DocList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isCheckedDoc: [],
            docList: [],
            selectedCheckedData: {},
            isAllChecked: false
        }
    }


    // On load set default checked false in document api list
    onLoad = () => {
        let checkedArray = [];
        let selectedDoc = this.props.selectedDoc;
        if (this.props.doc && this.props.doc.length > 0) {
            let doc = this.props.doc;
            doc.map((res) => {

                res['checked'] = (selectedDoc && selectedDoc.filter(item => { return item.Id == res.Id; }).length > 0);
                checkedArray.push(res);
            });

            this.setState({
                docList: checkedArray
            }, () => this.handleAllCheched())
        }
    }

    componentDidMount() {
        this.onLoad();
    }

    // Get doc check while click for all checked
    getAllChecked = (e) => {

        let checkedArray = [];
        if (this.state.docList && this.state.docList.length > 0) {
            if (e.target.checked) {
                this.state.docList.map((res) => {
                    res['checked'] = true;
                    checkedArray.push(res);

                });
            } else {
                this.state.docList.map((res) => {
                    res['checked'] = false;
                    checkedArray.push(res);

                });
            }
            this.setState({
                docList: checkedArray
            })
        }
        this.handleAllCheched();
    }

    // Document selection while checked on  single click
    getSingleChecked = (e, docId) => {
        let checkedArray = [];
        if (this.state.docList && this.state.docList.length > 0) {

            checkedArray = this.state.docList.map((res) => {
                if (res.Id == docId) {
                    res['checked'] = e.target.checked;
                }

                return res
            });

            this.setState({
                docList: checkedArray
            })
        }

        this.handleAllCheched();
    }

    handleAllCheched = () => {
        if (this.state.docList.length > 0) {
            this.setState({ isAllChecked: (this.state.docList.filter(x => x.checked === true).length === this.state.docList.length) });
        }
    }

    // Display all document list
    viewDocumentList = () => {

        if (this.state.docList && this.state.docList.length > 0) {
            return this.state.docList.map((res) => {
                return (
                    <tr>
                        {/* <td><a href="javascript:void(0);">{res.Key}</a> </td> */}
                        <td>{res.Name}</td>
                        <td>{res.Type} </td>
                        <td className="txtCenter"><input type="checkbox" checked={res.checked} onClick={(e) => this.getSingleChecked(e, res.Id)} /></td>
                    </tr>
                )
            })
        }
        return (
            <tr>
                <td colspan="4"> No document list found</td>

            </tr>
        )

    }

    // All selected doc while checked by user
    getSelectedAllDoc = () => {
        let self = this;
        if (self.state.docList && self.state.docList.length) {
            let filterDoc = self.state.docList;

            let docCollection = [];
            let selectedDoc = [];
            if (filterDoc && filterDoc.length > 0) {

                filterDoc.map((res) => {
                    let obj = {};

                    if (self.props.webId['ExternalApplicationId'] != undefined || self.props.webId['ExternalApplicationId'] != null) {
                        obj['ApplicationId'] = self.props.webId['ExternalApplicationId'];
                    } else {
                        obj['ApplicationId'] = self.props.webId['ApplicationId'];
                    }

                    obj['DocumentId'] = res['Id'];
                    if (res.checked) {
                        docCollection.push(obj);
                        selectedDoc.push(res);
                    }

                });
                self.props.webId['MembershipTypesAppDocument'] = docCollection;
                self.props.webId['selectedDoc'] = selectedDoc;
            }

            self.selectDoc(self.props.webId);
        }
    }

    selectDoc = (data) => {
        this.props.selectedAppDocEvt(data);
        this.props.closeModal();
    }

    render() {
        const { isAllChecked } = this.state;
        return (
            <Modal open='true' className="tiny">
                <Modal.Header>Documents <i onClick={this.props.closeModal} class="close">&#x2716;</i></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <div className="tableWrapper">
                            <table className="customTable documentTable">
                                <thead>
                                    <tr>
                                        {/* <th className="documentID">
                                            Document ID
												</th> */}
                                        <th className="documentName">
                                            Document Name
												</th>
                                        <th className="type">
                                            Type</th>
                                        <th className="action">
                                            <input type="checkbox" checked={isAllChecked} onClick={this.getAllChecked} />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {this.viewDocumentList()}

                                </tbody>
                            </table>

                            <br />
                        </div>
                        <div className="actions">
                            <button className="ui cancel button mr10" size='mini' onClick={this.props.closeModal} >Cancel</button>
                            <button className="ui secondary button" size='mini' onClick={this.getSelectedAllDoc} >Save</button>

                        </div>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        )
    }
}


export default DocList;
