import * as commonActions from 'actions/commonActions';
import userActions from "actions/userActions";
import UserList from 'components/lists/User/userList';
import * as lodash from 'lodash';
import { connect } from "react-redux";

const mapStateToProps = (state) => {
    return {
        roledata: state.userReducer.roledata,
        privilege: state.userReducer.privilegedata,
        defaultRole: 1,
        userInfo: state.userReducer.userInfo,
        isEdit: state.userReducer.isEdit,
        type: state.userReducer.type,
        userNameRequired: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2501'].text,
        userNameExist: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2500'].text,
        userNameSpaceNotAllow: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2502'].text,
        privilegeAssignedRequired: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2503'].text,
        isDuplicate: state.userReducer.isDuplicate,
        viewType: state.userReducer.viewType,
        userPermission: state.commonReducer.userPermission,
        messageCodes: lodash.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage)
    }
};

const mapDispatchToProps = (dispatch) => ({
    roleList: (data, callbackFun) => userActions.getRoleList(dispatch, data, callbackFun),
    defaultPrivilege: (data, callbackFun) => userActions.getdefaultPrivilege(dispatch, data, callbackFun),
    userDetail: (data) => userActions.getUserdetail(dispatch, data),
    usePrivilegeSubmit: (data) => userActions.UserPrivilageSubmit(dispatch, data),
    getUserList: (callback) => userActions.getUserList(dispatch, callback),
    userValidateInAD: (data) => dispatch(userActions.userValidateInAd(data)),
    addUserFromAD: () => dispatch(userActions.userAdd()),
    changePrivilege: (data) => dispatch(userActions.userChangePrivilege(data)),
    userPrivilegeSubmit: (data, callbackFun) => userActions.UserPrivilageSubmit(dispatch, data, callbackFun),
    checkDuplicate: (data, callbackFun) => userActions.getUserdetail(dispatch, data, callbackFun),
    getUserListOnSearch: (searchKey, callback) => userActions.getUserListOnSearch(dispatch, searchKey, callback),
    getvalidateUser: (data, callback) => userActions.getValidateUser(dispatch, data, callback),
    updateUserPassword: (data, callback) => userActions.updateUserPassword(dispatch, data, callback),
    showSuccessCopyMessage: () => dispatch(commonActions.setMessage(true, '2028'))
});

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
