import logoImg from "assets/images/astm-internal.png";
import MenuDropdown from "components/atoms/MenuDropdown";
import _ from 'lodash';
import React from "react";
import { Image } from 'semantic-ui-react';

const HeaderMenu = (props) => (

  <header>
    <div className="ui container">
      <div className="logoWrap">
        <Image src={logoImg} />
      </div>
      <div className="loggedUserWrap">
        <MenuDropdown loggedUserName={props.userDetail ? _.get(props.userDetail, "userName", "").toLocaleUpperCase() : ''}
          options={[
            {
              key: 'user',
              text: (
                <span className={(props.userDetail && _.get(props.userDetail, "userName", "").length > 5) ? 'ellip' : ''}>
                  Signed in as <strong>{props.userDetail ? _.get(props.userDetail, "userName", "").toLocaleUpperCase() : ''}</strong>
                </span>
              ),
              disabled: true
            },
            { key: 'profile', text: 'Your Profile' },
            { key: 'sign-out', text: 'Sign Out' }
          ]}
          logout={props.logout} />
      </div>
    </div>
  </header>
)

export default HeaderMenu;
