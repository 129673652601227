import _ from 'lodash';
import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
class DropdownMultipleSelection extends Component {

    constructor(props) {
        super(props)
        this.state = {
            noResultMessage: '',
            search: '',
            autoFocus: false,
            isSearch: props.isSearch
        }

        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onChangeWithoutReduxHandler = this.onChangeWithoutReduxHandler.bind(this);
        this.onSearchChange = this.onSearchChange.bind(this);
    }

    componentDidMount() {
        this.loadEvent();
    }

    loadEvent = () => {
        if (_.get(this.refs, "dropdownRef", null) && _.get(this.refs.dropdownRef, "ref", null) && _.get(this.refs.dropdownRef.ref, "current", null)) {
            this.refs.dropdownRef.ref.current.addEventListener('keydown', this.menuCloseOnEnterKey, true)
        }
        document.addEventListener('click', this.clickHandlerOutSideDetected, true); // : Handle for outside click
    }

    clickHandlerOutSideDetected = (e) => {
        if (_.get(this.refs,"dropdownRef", null) && _.get(this.refs.dropdownRef, "ref", null) && _.get(this.refs.dropdownRef.ref, "current", null)) {
            if (_.get(this.refs.dropdownRef.ref.current, 'childNodes', []) && _.get(this.refs.dropdownRef.ref.current, 'childNodes', []).length > 3) {
                if (this.refs.dropdownRef.ref.current.childNodes[3].contains(e.target)) {
                    this.setState({
                        autoFocus: false
                    }, () => this.handleCursor());

                } else if (!this.refs.dropdownRef.ref.current.contains(e.target)) {
                    this.setState({
                        autoFocus: false
                    });
                }
            }
        }
    }

    menuCloseOnEnterKey = (e) => {
        if (e.keyCode === 13) {
            this.setState({
                autoFocus: false
            }, () => this.handleCursor());
        }
    }

    inputChangeHandler = (value) => {
        if (value !== null && value.length === 0) {
            this.setState({ autoFocus: false })
        }
    }

    focusClickHandler = () => {
        // :For handleCursor reset cursor while pass type : 2
        this.setState({ autoFocus: true }, () => this.handleCursor(2))
    }

    handleCursor = (type = 1) => {
        if (type === 1 && _.get(this.refs.dropdownRef.ref.current, 'childNodes', []) && _.get(this.refs.dropdownRef.ref.current, 'childNodes', []).length > 0) {
            this.refs.dropdownRef.ref.current.childNodes[0].setAttribute('style', "caret-color:transparent")
        } else if (type === 2 && _.get(this.refs.dropdownRef.ref.current,'childNodes', []) && _.get(this.refs.dropdownRef.ref.current, 'childNodes', []).length > 0) {
            this.refs.dropdownRef.ref.current.childNodes[0].removeAttribute('style');
        }
    }

    // Get value from redix form field
    onChangeHandler(e, data) {

        this.props.input.onChange(data.value);
        if (data.value && this.props.options.length === data.value.length) {
            this.setState({ noResultMessage: '' });
        }
        if (data.value && this.props.maxLength && data.value.length === this.props.maxLength) {
            this.setState({ isSearch: false })
        } else {
            this.setState({ isSearch: true })
        }
    }

    onChangeWithoutReduxHandler(e, data) {
        this.props.onChange(data.value);

        if (data.value && this.props.options.length === data.value.length) {
            this.setState({ noResultMessage: '' });
        }

        // Update the focus state on change always false.
        this.setState({ autoFocus: false })
    }

    onSearchChange(e, data) {
        if (data.searchQuery !== '') {
            this.setState({
                noResultMessage: `"${data.searchQuery.trim()}"`,
                autoFocus: true
            });
        }
        this.handleCursor(2) // :For reset cursor while pass type : 2
    }

    render() {
        // For redux form implementation
        if ('input' in this.props) {
            const { options, renderLabel, isMultiple, placeholder, input: { value, name }, label, required, isDuplicate, disabled, duplicateError, meta: { touched, error }, isSinglItemPrevent } = this.props;
            const { isSearch } = this.state;

            return (
                <div ref='MultiSelectDropDown' className={isSinglItemPrevent && value && value.length === 1 ? 'hideDeleteIcon' : ''}>
                    <label className="label">
                        {required && (<i aria-hidden="true" class="asterisk  icon"></i>)}
                        {label}
                    </label>
                    <Dropdown
                        name={name}
                        ref="dropdownRef"
                        open={this.state.autoFocus}
                        value={value}
                        placeholder={placeholder}
                        onChange={this.onChangeHandler} fluid
                        multiple={isMultiple}
                        search={isSearch}
                        selection
                        onClick={this.focusClickHandler}
                        renderLabel={renderLabel}
                        options={options}
                        disabled={disabled}
                        onSearchChange={this.onSearchChange}
                        noResultsMessage={this.state.noResultMessage.length > 0 ? `Found no matching records for ${this.state.noResultMessage}` : 'No record exists.'}
                        className={touched && error ? "error" : ''} />
                    {touched && (error && <span className="errorMessage  mt0">{error}</span>)}
                    {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
                </div>
            );

        }

        // Without redux form implementation
        const { options, label, required, placeholder, value, isSinglItemPrevent, isSearch, disabled } = this.props;
        return (
            <div ref='MultiSelectDropDown' className={isSinglItemPrevent && value && value.length === 1 ? 'hideDeleteIcon' : ''}>
                {label && (
                    <label className="label">
                        {required && (<i aria-hidden="true" class="asterisk  icon"></i>)}
                        {label}
                    </label>
                )}
                <Dropdown value={value}
                    placeholder={placeholder} fluid multiple selection options={options}
                    search={isSearch}
                    disabled={disabled}
                    onChange={this.onChangeWithoutReduxHandler}
                />
            </div>
        );
    }
}

export default DropdownMultipleSelection;
