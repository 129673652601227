import React from 'react';
import { Button, Form, Grid } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form/immutable';
import EditorFieldComponent from 'components/atoms/TextEditor/EditorFieldComponent';

class AddMembershipFaqForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            question: '',
            answer: '',
            questionError: '',
            answerError: ''
        }
    }

    // closeModal = () => {
    //     this.setState({
    //         question: '',
    //         answer: ''
    //     });

    //     this.props.closeModal();
    // }

    handleForm = (values) => {
        let answerError = undefined;
        let questionError = undefined;

        // Validate question & answer
        if (!values.answer || (values.answer && values.answer.replace(/&nbsp;/gi, '').trim().length <= 7)) {
            answerError = this.props.requiredFaqAnswerError;
        } else if (values.answer && values.answer.length > (8 + 5000)) {
            answerError = this.props.maxlengthAnswerError;
        }

        if (!values.question || (values.question && values.question.replace(/&nbsp;/gi, '').trim().length <= 7)) {
            questionError = this.props.requiredFaqQuestionError;
        } else if (values.question && values.question.length > (8 + 1000)) {
            questionError = this.props.maxlengthQuestionError;
        }

        this.setState({ questionError, answerError });

        if (!questionError && !answerError) {
            this.props.handleSubmit()
        }
    }

    render() {

        const { handleSubmit, submitting, isDuplicateQuestion, duplicateQuestionError } = this.props;
        const { answerError, questionError } = this.state;
        return (
            <Form size='large' onSubmit={handleSubmit(this.handleForm)}>
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <Field name="question" type="text" id="inputquestion"
                                label="Question"
                                required={true}
                                component={EditorFieldComponent} />
                            {questionError && (<span className="errorMessage">{questionError}</span>)}
                            {isDuplicateQuestion && (<span className="errorMessage">{duplicateQuestionError}</span>)}
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Field name="answer" type="text" id="inputanswer"
                                label="Answer"
                                required={true} 
                                component={EditorFieldComponent}/>
                            {answerError && (<span className="errorMessage">{answerError}</span>)}
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="actions">
                            <Button primary type="submit" disabled={submitting} >
                                Save
                    </Button>
                            <Button secondary onClick={this.props.onCancel}>
                                Cancel
                    </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }
}

export default reduxForm({
    form: 'addMembershipFaqForm'
})(AddMembershipFaqForm);
