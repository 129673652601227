import React from 'react';
import { Button, Modal, Form, Grid, Icon } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import UploadPopup from 'components/atoms/UploadPopup';
import RenderTextArea from 'components/atoms/RenderTextarea'
import _ from 'lodash';

export class EditMembershipTemplateModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileObjects: {
                signedParamUrl: '',
                file: '',
                filePath: '',
                isProcess: false
            }
        }
    }

    requiredDescription = value => value && value.trim() ? undefined : this.props.requiredDescription;

    handleForm = (values) => {
        try {
            const { initialValues } = this.props
            const uploadRequest = {
                TemplateId: initialValues.TemplateId,
                Description: values.Description
            }
            // TOOD : Check for request uploading new file.
            if (this.state.fileObjects.file) {
                this.props.uploadFileInS3(this.state.fileObjects.signedParamUrl, this.state.fileObjects.file, isUploaded => {
                    if (isUploaded) {
                        uploadRequest.Path = this.state.fileObjects.filePath;
                        this.props.updateMembershipTemplate(uploadRequest, isSuccess => {
                            this.props.closeModal();
                        })
                    }
                });
            } else if (_.isEqual(initialValues.Description, values.Description)) {
                this.props.displayNoChangesMadeMessage();
                this.props.closeModal();
            } else {
                this.props.updateMembershipTemplate(uploadRequest, isSuccess => {
                    this.props.closeModal();
                })
            }
        }
        catch (err) {
            console.error('Error occured while updating document.');
        }
    };

    // TODO : request for signed Url in OSL.
    signedAwsUrlHandler = (file, cb) => {
        this.props.getAwsSigned(file, result => {
            cb(result)
        });
    }

    // TODO : Callback to get with valid file extention and signed Url
    resultSetHandler = (result) => {
        if (result.status) {
            let params = {};
            params.signedParamUrl = result.signedParams.url
            params.file = result.file
            params.filePath = result.signedParams.params.Key.replace(`${process.env.REACT_APP_AWS_S3_MEM_TEMPLATE_DIR}/`, '')
            params.isProcess = result.isProcess
            this.setState({ fileObjects: params });
        } else {
            this.setState({ fileObjects: result });
        }
    }

    render() {
        const { handleSubmit, initialValues, submitting } = this.props;
        return (
            <Modal open='true' className="tiny" data-testid="editTemplateModal">
                <Form size='large' onSubmit={handleSubmit(this.handleForm)} novalidate>
                    <Modal.Header>Edit Template <i onClick={() => this.props.closeModal()} class="close">&#x2716;</i></Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid columns='equal'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <Field name="Description" type="text"
                                            component={RenderTextArea} label="Description"
                                            validate={[this.requiredDescription]}
                                            maxLength="200" required='true'
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <label className="label">
                                            <i aria-hidden="true" class="asterisk icon"></i>Upload Template
                                        </label>
                                        {initialValues.Path &&
                                            <label className="label">
                                                <Icon name='image outline icon' />
                                                {initialValues.Path}
                                            </label>
                                        }
                                        <UploadPopup title="UPLOAD ATTACHMENT"
                                            extension="jpg, jpeg, png"
                                            formatLabel="jpg, jpeg, png"
                                            dirName={process.env.REACT_APP_AWS_S3_MEM_TEMPLATE_DIR}
                                            signedAwsUrlHandler={this.signedAwsUrlHandler}
                                            resultSetHandler={this.resultSetHandler}
                                            action={!initialValues.Path}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column className="actions">
                                        <Button primary type="submit" disabled={_.get(this.state.fileObjects, 'isProcess') || submitting}>
                                            Save
                                        </Button>
                                        <Button type="button" secondary className="cancel" onClick={() => this.props.closeModal()}>
                                            Cancel
                                        </Button>
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>

                        </Modal.Description>
                    </Modal.Content>
                </Form>
            </Modal >
        );
    }
}

export default (reduxForm({
    form: 'EditMembershipTemplateModal'
})(EditMembershipTemplateModal));