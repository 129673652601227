import TableHeader from 'components/atoms/Table/tableHeader';
import CustomTooltip from "components/atoms/Tooltip";
import common from 'helpers/utilCommon';
import _ from 'lodash';
import * as header from 'models/User/userModel';
import React from "react";
import { Link } from 'react-router-dom';
import { Icon, Modal, Search } from 'semantic-ui-react';
import { setPermissionNavigation } from '../../../helpers/utilCommon';
class UserList extends React.Component {
    constructor(props) {
        super(props);
        const self = this;
        self.state = {
            loading: false,
            isRedirect: false,
            isUserList: true,
            viewType: 0,
            userList: [],
            userView: {},
            sortKey: '',
            InitialUserList: [],
            value: '',
            userName: null,
            password: null,
            isResetPassword: false
        }
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
        this.handleSearchIcon_Click = this.handleSearchIcon_Click.bind(this);

    }

    handleSearchIcon_Click = () => {
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({ isLoading: false, results: null });
        }, 200);
        this.setState({ value: this.state.value });
        let flteredUserList = this.state.InitialUserList;

        const filteredData = flteredUserList.filter(p => String(p.UserName.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
            || String(p.Email.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
            || String(p.FirstName.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
            || String(p.LastName.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
        );
        this.setUserListData(filteredData);
    }

    handleOnKeyDown(e) {
        if (e.which == 13) {
            this.setState({ isLoading: true });
            setTimeout(() => {
                this.setState({ isLoading: false, results: null });
            }, 200);
            this.setState({ value: e.target.value });
            let flteredUserList = this.state.InitialUserList;

            const filteredData = flteredUserList.filter(p => String(p.UserName.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
                || String(p.Email.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
                || String(p.FirstName.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
                || String(p.LastName.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
            );
            this.setUserListData(filteredData);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { type } = nextProps;
        switch (type) {
            case 'USER_ADD':
                this.setState({ isUserList: false, viewType: 0 });
                break;
            case 'USER_VIEW':
                this.setState({ isUserList: false, viewType: 1 });
                break;
            case 'USER_LIST':
                this.setState({ isUserList: true, viewType: 0 });
                break;
            default:
                break;
        }
    }

    componentDidMount() {
        this.props.getUserList(this.setInitailUserListData);
    }
    addClickHandler = () => {
        let self = this;
        self.props.addUserFromAD();
        self.props.history.push(setPermissionNavigation('user-add'));
    }

    setInitailUserListData = (response) => {
        this.setState({
            userList: response,
            InitialUserList: response,
            loading: false
        });
    }

    setUserListData = (response) => {
        this.setState({
            userList: response,
            loading: false
        });

    }



    openEditForm = (_item) => {
        let self = this

        self.props.getUserDetail(_item.UserId, () => {
            self.setState({
                showViewForm: false,
                showEditForm: true
            })
        });
    }

    componentWillMount() {
        this.resetComponent()
    }

    resetComponent = (value) => {
        this.setState({ isLoading: false, results: [] });
        if (value || value === '') {
            this.setState({ value: '' });
            this.props.getUserList(this.setInitailUserListData);
        }

    }

    handleResultSelect = (e, { result }) => {
        this.setState({ value: result.UserName });
        this.props.getUserListOnSearch(result.UserName, this.setUserListData);
    }

    handleSearchChange = (e, { value }) => {

        this.setState({ value });
        let AutoSuggestList = this.state.InitialUserList;
        setTimeout(() => {
            if (this.state.value.length < 1) { return this.resetComponent(value); }
            this.setState({ isLoading: true })
            const filteredData = AutoSuggestList.filter(p => String(p.UserName.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
                || String(p.Email.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
                || String(p.FirstName.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
                || String(p.LastName.toLowerCase().trim()).startsWith(this.state.value.toLowerCase())
            );
            this.setState({
                isLoading: false,
                results: filteredData.length < 10 ? filteredData :
                    filteredData.slice(0, 10)
            })
        }, 300);

    }
    onSort = (event, sortKey) => {
        // Definition not required
    }

    onResetPassword = (item, index) => {
        let password = common.generatePassword(8);
        this.props.updateUserPassword({ "username": item.UserName, "password": common.encodeText(password) }, (response) => {
            if (response) {
                this.setState({ userName: item.UserName, password: password, isResetPassword: false }, () => this.setState({ isResetPassword: true }));
            }
            else {
                this.setState({ isResetPassword: false })
            }
        });
    }

    closeModal = () => this.setState({ isResetPassword: false });

    copyPassword = () => {
        const isCopiedSuccess = common.copyDataToClipboard(this.state.password);
        if (isCopiedSuccess) {
            this.props.showSuccessCopyMessage();
        }
    }

    isChangePasswordVisible = () => {
        if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "false") {
            return true;
        }
        return false;
    }

    render() {
        const { userList, userName, password, isResetPassword } = this.state;
        const { isLoading, value, results } = this.state;
        const { userPermission } = this.props;

        const resultRenderer = ({ UserName, Email, FirstName, LastName }) => (
            <div>
                {ManageContent(UserName, this.state.value)} &bull; {ManageContent(LastName, this.state.value)}, {ManageContent(FirstName, this.state.value)} &bull; {ManageContent(Email, this.state.value)}
            </div>

        );

        let ManageContent = (str, find) => {
            let result = [];
            for (let i = 0; i < str.length; i++) {
                for (let j = 0; j < find.length; j++) {
                    if (i === j) {

                        if (str.toLowerCase().indexOf(find.toLowerCase()) === 0) { result.push(<b>{str[i]}</b>); }
                        else { result.push(str[i]); }

                    }
                }
            }
            result.push(str.slice(find.length, str.length));
            return result;
        }


        return (
            <div data-testid="userList">
                <div className="headingTitle clearfix">
                    <h2>Users <span className="recordCount">{userList ? userList.length : 0} record(s) found</span></h2>
                    {userPermission.Add &&
                        <button data-testid="addButton" onClick={(e) => this.addClickHandler(e)}
                            className="ui secondary button"><i aria-hidden="true" class="plus icon"></i>
                            Add User</button>}
                    <div className="searchBx">
                        <Search
                            data-testid="handleSearch"
                            onKeyDown={this.handleOnKeyDown}
                            loading={isLoading}
                            onResultSelect={this.handleResultSelect}
                            onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                            results={results}
                            value={value}
                            resultRenderer={resultRenderer}
                            showNoResults={false}
                            noResultsMessage={"No user record found in the system."}
                            placeholder="Search User by Username, First Name, Last Name, Email"
                            {...this.props}
                        />
                        <span data-testid="handleSearchIcon" className="hiddenSearchIcon" onClick={this.handleSearchIcon_Click}></span>
                    </div>
                </div>
                <div className="contentWrapper clearfix">
                    <div className="tableWrapper">
                        <table className="customTable userTable">
                            <thead>
                                <tr>
                                    <TableHeader headerProps={header.HEADERINFO} onClickProps={this.onSort}></TableHeader>
                                    {this.isChangePasswordVisible() && (userPermission.Add || userPermission.Update) && <th className="action">Action</th>}
                                </tr>
                            </thead>
                            <tbody>

                                {userList && userList.length > 0 &&
                                    (
                                        userList.map((_item, index) => {
                                            return <tr>

                                                <td className="userName">
                                                    <Link to={setPermissionNavigation('user-detail', [_item.UserId])}>{_item.UserName}</Link></td>
                                                <td className="name">{_item.LastName}, {_item.FirstName}</td>
                                                <td className="email">
                                                    <CustomTooltip position='bottom left' icon={<a href={"mailto:" + _item.Email} className="oneLiner" style={{ '-webkit-box-orient': 'vertical' }}>{_item.Email}</a>} content={_item.Email} />

                                                </td>
                                                <td className="userType">
                                                    {_item.RoleName}
                                                </td>
                                                {this.isChangePasswordVisible() && !_item.editRow && (userPermission.Add || userPermission.Update) && <td className="action"><button onClick={() => this.onResetPassword(_item, index)} title="Reset Password"> <Icon className="squareIcon" name='undo' /></button></td>}
                                            </tr>
                                        })
                                    )}
                            </tbody>
                        </table>
                        {(!userList || (userList && userList.length === 0)) && (
                            <div className="noRecordMessage">No results found.</div>
                        )}
                    </div>
                </div>
                <Modal open={isResetPassword} className="tiny passwordReset">
                    <Modal.Header>Reset Password <i onClick={this.closeModal} class="close">&#x2716;</i></Modal.Header>
                    <Modal.Content scrolling>
                        <Modal.Description>
                            <div className="mb20 mt20">Password has been reset for {userName}</div>
                            <div>New Password</div>
                            <div className="passwordBox">
                                {password}
                            </div>
                            <div className="mb20"><a onClick={this.copyPassword}> copy to clipboard </a></div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        );
    }
}

export default UserList;

