import axios from 'axios';
import * as type from '../../actionTypes';
import getApiUrl from '../../helpers/apiUrls';
import * as commonActions from '../commonActions';

export const getClassificationList = (dispatch, callback, classificationTypeId = 1) => {

	//const params = { headers: authHeader() };
	let url = getApiUrl('classification', 'getClassificationList');
	dispatch(commonActions.setLoader(true));

	axios.get(url)
		.then((response) => {

			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case		
				dispatch(commonActions.setMessage(true, response.data.message));
				callback();
			}

		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7016'));
			callback('');
		});
};

export const addClassification = (dispatch, classificationObj, callback) => {
	const url = getApiUrl('classification', 'addClassification');
	dispatch(commonActions.setLoader(true));
	axios.post(url, classificationObj)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			callback(response.data.status);
			if (response.data.status) {
				
				dispatch(commonActions.setMessage(true, '5013'));
			}
			// Handle error case
			else if (response.data.message == 2401) {
				dispatch({ type: type.CLASSIFICATION_DUPLICATE, isDuplicateClassification: true })
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7017'));
		});
};

export const editClassification = (dispatch, classificationObj, callback) => {
	const url = getApiUrl('classification', 'editClassification');
	dispatch(commonActions.setLoader(true));

	axios.put(url, classificationObj)
		.then((response) => {
			dispatch(commonActions.setLoader(false));

			if (response.data.status) {
				callback();
				dispatch(commonActions.setMessage(true, '5014'));
			} // Handle error case	
			else if (response.data.message == 2401) {
				dispatch({ type: type.CLASSIFICATION_DUPLICATE, isDuplicateClassification: true })
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7021'));
		});
};

export const getClassificationDetails = (classificationId, dispatch, callback, classificationTypeId = 1) => {
	const url = getApiUrl('classification', 'getClassificationDetails') + '/' + classificationId;

	dispatch(commonActions.setLoader(true));

	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				dispatch({ type: type.VIEW_CLASSIFICATION, classificationData: response.data.content })
				callback();
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7022'));
		});
};


export const getCheckClassificationAssign = (dispatch, classificationId, callback) => {
	const url = getApiUrl('classification', 'validateclassification') + '/' + classificationId;

	dispatch(commonActions.setLoader(true));

	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			callback(response.data);
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '2025'));
		});
}
export const getColorPicker = (dispatch, classificationColorId, callback) => {
	const url = getApiUrl('classification', 'colorpicker') + '/' + classificationColorId;
	dispatch(commonActions.setLoader(true));

	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			callback(response.data);
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7057'));
		});
}
export const resequenceClassifications = (classificationsList, callback, dispatch) => {
	const url = getApiUrl('classification', 'resequenceClassifications');
	dispatch(commonActions.setLoader(true));

	axios.put(url, classificationsList)
		.then((response) => {
			dispatch(commonActions.setLoader(false));

			if (response.data.status) {
				callback();
				dispatch(commonActions.setMessage(true, '5025'));
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7023'));
		});
};
export default {
	getClassificationList,
	addClassification,
	editClassification,
	getClassificationDetails,
	getCheckClassificationAssign,
	resequenceClassifications
}
