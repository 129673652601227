
export const EditClassificationModel = {
  "MemberClassificationId": "",
  "Name": "",
  "Description": ""
}

export const NewClassificationModel = {
  "Name": "",
  "Description": "",
  "ClassificationTypeId": 1
}
export const HEADERINFO = [{ 'title': 'Sequence', 'hasSorting': false, 'className': "seqNo", 'sortKey': 'classifDescrpt', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Classification Type', 'hasSorting': false, 'className': "classifType", "sortKey": 'classifType', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Description', 'hasSorting': false, 'className': "description", 'sortKey': 'classifDescrpt', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Applicable to all Committees', 'hasSorting': false, 'className': "isEnabled", 'sortKey': 'classifDescrpt', 'orderBy': 0, 'orderByIcon': 0 }]
