import logoImg from "assets/images/astm-logo-name-blue-rgb.svg";
import LoginForm from 'components/forms/Login/login';
import * as utilCommon from 'helpers/utilCommon';
import React from "react";
import { Image } from 'semantic-ui-react';


class Login extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoginPageShow: true
		}
		this.handleSubmit = this.handleSubmit.bind(this);


	}

	componentDidMount() {
		if (utilCommon.isReactAppAuthorized()) { // VALIDATE THE TOEKN EXIST OR NOT
			this.setState({
				isLoginPageShow: false
			})
			this.props.history.push('/home')
		}
	}


	handleSubmit = values => {

		try {
			if (values && values.username && values.username.length > 0 &&
				values.password && values.password.length > 0) {

				this.props.login(values.username, values.password, () => {

					this.props.history.push("/home");

				});
			}
		}
		catch (err) {
			// Error log
		}
	};

	render() {
		const { isLoginPageShow } = this.state;
		return (

			isLoginPageShow &&
			<div className="loginPage">
				{
					<div className="loginWrap">
						<figure className="logo">
							<Image src={logoImg} />
						</figure>
						<div className="loginCard">
							<p className="titleHead">Log In</p>

							<LoginForm onSubmit={this.handleSubmit} />
						</div>
					</div>
				}
			</div>
		);
	}
}

export default Login;

