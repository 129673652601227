import React from 'react';
import { get } from 'lodash';
import { getFileType } from 'helpers/utilCommon';

const UploadPopup = ({ resultSetHandler, formatLabel, extension, signedAwsUrlHandler, dirName, action }) => {

    const [fileInfo, setFileInfo] = React.useState({ file: {}, errorFileFormat: false, errorFileSize: false, signedParams: null, isProcess: false });

    const onChangehandler = (e, file) => {
        // TODO : check extention fora file.
        if (file && (extension.indexOf(getFileType(file.name)) > -1)) {
            if (file.size / (1024 * 1024) <= 10) {
                // TODO : Set file and error while uploading.
                let s3KeysName = dirName ? `${dirName}/${Date.now()}_${get(file, 'name')}` : `${Date.now()}_${get(file, 'name')}`;
                setFileInfo({ isProcess: true })
                signedAwsUrlHandler(s3KeysName, result => {
                    setFileInfo({
                        file: file,
                        errorFileFormat: false,
                        errorFileSize: false,
                        signedParams: result,
                        isProcess: false
                    });
                    resultSetHandler({ status: true, file: file, signedParams: result, isProcess: false });
                });
            } else { // TODO : Set error in case file size.
                setFileInfo({
                    errorFileSize: true
                })
                resultSetHandler({ status: false, file: file, signedParams: null, isProcess: true });
            }
        }
        else { // TODo : set error in case file format.
            setFileInfo({
                errorFileFormat: true
            });
            resultSetHandler({ status: false, file: file, signedParams: null, isProcess: true });
        }
    }

    return (
        <div>
            {get(fileInfo, 'isProcess') ? <span className="inprogressDv ml20"><i class="hourglass icon"></i> In Progress</span> : null}
            <div className={false ? 'uploadBtn field error' : 'uploadBtn'}>
                <input type='file'
                    id='file'
                    className='input-file'
                    accept={extension}
                    onChange={(e) => onChangehandler(e, e.target.files[0])}
                    required={action}
                />
                {get(fileInfo, 'errorFileFormat') ? <div className="errorMessage">File Format Not Allowed.</div> : null}
                {get(fileInfo, 'errorFileSize') ? <div className="errorMessage">Maximum file size allowed is 10 mb.</div> : null}
            </div >
            <div>
                <span className="legendNotes">Accepted file formats: {formatLabel}</span>
            </div>
        </div>
    )
}

export default UploadPopup;
