import { shallowCopy } from 'helpers/utilCommon';
import React from 'react';
import { Button, Grid, Popup } from 'semantic-ui-react';
import RenderCheckbox from '../CheckBox';
import _ from 'lodash';

class PrivilegeCardTooltip extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            temp_popupList: [],
            isOpen: false,
            initialState: []
        }
    }
    handleOpen = () => {
        this.setState({ isOpen: true, temp_popupList: shallowCopy(this.props.popupList), initialState: shallowCopy(this.props.popupList) });
    }

    handleClose = () => {
        this.setState({ temp_popupList: shallowCopy(this.state.initialState) });
        this.setState({ isOpen: false });
    }

    changePrivilege = (index, subIndex, option) => {

        let temp_popupList = shallowCopy(this.state.temp_popupList);
        switch (option) {
            case 'All':
                const checkAllStatus = temp_popupList[index].ResponsibilityPrivilegeList[subIndex].IsChecked;
                temp_popupList[index].ResponsibilityPrivilegeList[subIndex].IsChecked = !checkAllStatus;
                if (temp_popupList[index].ResponsibilityPrivilegeList[subIndex].IsChecked) {
                    temp_popupList[index].ResponsibilityPrivilegeList = temp_popupList[index].ResponsibilityPrivilegeList.map((item) => {
                        item.IsChecked = true;
                        return item;
                    })
                } else {
                    temp_popupList[index].ResponsibilityPrivilegeList = temp_popupList[index].ResponsibilityPrivilegeList.map((item) => {
                        item.IsChecked = false;
                        return item;
                    })
                }
                break;


            default:
                const checkStatus = temp_popupList[index].ResponsibilityPrivilegeList[subIndex].IsChecked;
                temp_popupList[index].ResponsibilityPrivilegeList[subIndex].IsChecked = !checkStatus;
                if (option.toLowerCase().includes('update')) {
                    temp_popupList[index].ResponsibilityPrivilegeList = temp_popupList[index].ResponsibilityPrivilegeList.map((item) => {

                        let count = temp_popupList[index].ResponsibilityPrivilegeList.filter((itemdetail) => itemdetail.ResponsibilityPrivilegeName.toLowerCase().includes('update') && item.ResponsibilityPrivilegeName.toLowerCase().includes('update') && itemdetail.ResponsibilityPrivilegeName.toLowerCase() != item.ResponsibilityPrivilegeName.toLowerCase() &&
                            itemdetail.IsChecked).length;

                        if (item.ResponsibilityPrivilegeName.toLowerCase().includes('view') && temp_popupList[index].ResponsibilityPrivilegeList[subIndex].IsChecked && count === 0) {
                            item.IsChecked = !checkStatus;
                        }
                        return item;
                    });
                } else if (option.toLowerCase().includes('add')) {

                    temp_popupList[index].ResponsibilityPrivilegeList = temp_popupList[index].ResponsibilityPrivilegeList.map((item) => {
                        let addcount = temp_popupList[index].ResponsibilityPrivilegeList.filter((itemdetail) => itemdetail.ResponsibilityPrivilegeName.toLowerCase().includes('add') && item.ResponsibilityPrivilegeName.toLowerCase().includes('add') && itemdetail.ResponsibilityPrivilegeName.toLowerCase() != item.ResponsibilityPrivilegeName.toLowerCase() &&
                            itemdetail.IsChecked).length;
                        if (item.ResponsibilityPrivilegeName.toLowerCase().includes('view') && temp_popupList[index].ResponsibilityPrivilegeList[subIndex].IsChecked && addcount === 0) {
                            item.IsChecked = !checkStatus;
                        }

                        return item;
                    })
                }
                else if (option.toLowerCase().includes('view')) {

                    temp_popupList[index].ResponsibilityPrivilegeList = temp_popupList[index].ResponsibilityPrivilegeList.map((item) => {
                        if (item.ResponsibilityPrivilegeName.toLowerCase().includes('add') || item.ResponsibilityPrivilegeName.toLowerCase().includes('update')) {
                            if (item.IsChecked && checkStatus) {
                                item.IsChecked = false
                            }
                        }

                        return item;
                    })
                }
                if (!temp_popupList[index].ResponsibilityPrivilegeList[subIndex].IsChecked) {
                    temp_popupList[index].ResponsibilityPrivilegeList[0].IsChecked = false;
                }
                break;
        }
        this.setState({ temp_popupList: temp_popupList });
    }
    submitPrivilege = () => {
        const currentPopupList = []
        const prevPopupList = []

        this.state.temp_popupList.map((popupListItem) => {
            return popupListItem.ResponsibilityPrivilegeList.filter((privilegeListItem) => {
                return privilegeListItem.ResponsibilityPrivilegeName.trim().toLowerCase() !== 'all'
            }).sort((a, b) => { return a.ResponsibilityPrivilegeId - b.ResponsibilityPrivilegeId })
                .map((privilegeListObj) => {
                    currentPopupList.push(privilegeListObj.IsChecked);
                })
        })
        this.props.popupList.map((popupListItem) => {
            return popupListItem.ResponsibilityPrivilegeList.filter((privilegeListItem) => {
                return privilegeListItem.ResponsibilityPrivilegeName.trim().toLowerCase() !== 'all'
            }).sort((a, b) => { return a.ResponsibilityPrivilegeId - b.ResponsibilityPrivilegeId })
                .map((privilegeListObj) => {
                    prevPopupList.push(privilegeListObj.IsChecked);
                })
        })

        if (_.isEqual(currentPopupList, prevPopupList)) {
            this.props.displayNoChangesMadeMessage();
            this.handleClose();
        } else {
            this.props.submitPrivilege(this.props.parentIndex, this.props.resIndex, this.state.temp_popupList, this.handleClose);
        }
    }
    renderPrivilege = (res, index, editAllow) => {
        if (res.filter(_item => _item.ResponsibilityPrivilegeName.trim() == "All").length == 0) {
            res.push({
                IsChecked: true,
                ResponsibilityPrivilegeId: -1,
                ResponsibilityPrivilegeMappingId: -1,
                ResponsibilityPrivilegeName: " All"
            });
        }


        return res.sort((a, b) => (a.ResponsibilityPrivilegeId > b.ResponsibilityPrivilegeId) ? 1 : -1).map((privilege, privilegeIndex) => {
            if (privilege.ResponsibilityPrivilegeId == -1) {
                let count = 0, tcount = 0;
                res.map((item, index) => {
                    tcount++;
                    if (index > 0 && item.IsChecked) {
                        count++;
                    }
                });
                if (count == tcount - 1) {
                    privilege.IsChecked = true;
                } else {
                    privilege.IsChecked = false;
                }

            }
            return <Grid.Column>
                <RenderCheckbox IsEditable={editAllow} IsChecked={privilege.IsChecked} defaultChecked={privilege.IsChecked} onchange={(event) => this.changePrivilege(index, privilegeIndex, privilege.ResponsibilityPrivilegeName.trim())} />
                <span className="permsnName">{privilege.ResponsibilityPrivilegeName}</span>
            </Grid.Column>

        })

    }
    render() {
        const { temp_popupList } = this.state;
        const { _ele, userPermission } = this.props
        return (
            <Popup data-testid='privilageCard' trigger={_ele} flowing hoverable className="privListTooltip" position="right center" on="click" open={this.state.isOpen}
                onClose={() => { this.handleClose(); }}
                onOpen={() => { this.handleOpen(); }}>
                {temp_popupList.map((headerItem, index) => {
                    return <React.Fragment>
                        {headerItem.IsDisplay ? <div className="privGrroupname">{headerItem.ResponsibilityGroupName}</div> : < div className="privGrroupname"></div>}
                        <Grid className="privActionList" columns='3'>
                            {this.renderPrivilege(headerItem.ResponsibilityPrivilegeList, index, userPermission.Update)}

                        </Grid>
                    </React.Fragment>
                })
                }

                <div className="mt30">
                    {userPermission.Update &&
                        <Button primary onClick={() => { this.submitPrivilege(); }}>Update</Button>}
                    <Button className="cancel ml10" onClick={() => { this.handleClose(); }}>Cancel</Button>
                </div>
            </Popup>
        )
    }
}

export default PrivilegeCardTooltip;
