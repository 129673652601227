import React from 'react'
import { Checkbox } from 'semantic-ui-react'

class CheckboxElement extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Checkbox label={this.props.setLabeltitleProp} {...this.props} checked={this.props.IsChecked} defaultChecked={this.props.IsChecked} disabled={!this.props.IsEditable} name={this.props.setLabeltitleProp} onChange={this.props.onchange} />
    )
  }
}

export default CheckboxElement;
