
export const MembershipTemplateModel = {
  "TemplateId": "",
  "Name": "",
  "Code": "",
  "Description": "",
  "Path": "",
  "IsActive": "",
  "CreatedBy": "",
  "CreatedDate": "",
  "ModifiedBy": "",
  "ModifiedDate": ""
}


export const HEADERINFO = [{ 'title': 'Templates Name', 'hasSorting': false, 'className': "templateName", "sortKey": 'MembershipName', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Description', 'hasSorting': false, 'className': "description", 'sortKey': 'answers', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Updated By', 'hasSorting': false, 'className': "updatedBy", 'sortKey': 'status', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Updated On', 'hasSorting': false, 'className': "updatedOn", 'sortKey': 'status', 'orderBy': 0, 'orderByIcon': 0 }]

