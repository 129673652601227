import React from "react";
import { Form } from "semantic-ui-react";

const RenderTextArea = ({ label, input, placeholder, type, maxLength, disabled, id, required, isDuplicate, duplicateError, meta: { touched, error } }) => (
    <div>
        <label className="label">
            {required && (<i aria-hidden="true" class="asterisk  icon"></i>)}
            {label}
        </label>

        <Form.TextArea
            {...input}
            placeholder={placeholder}
            className={(touched && error) || (isDuplicate || isDuplicate === 'true') ? "error" : ''}
            type={type}
            maxLength={maxLength}
            id={id}
            disabled={disabled && disabled ? disabled : false}
        />
        {touched && (error && <span className="errorMessage">{error}</span>)}
        {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
    </div>
);
export default RenderTextArea;
