import axios from 'axios';
import getApiUrl from 'helpers/apiUrls';
import * as commonActions from 'actions/commonActions';

export const getMembershipTemplateListAction = (dispatch, callback) => {
	let url = getApiUrl('membershipTemplate', 'listMembershipTemplate');
	dispatch(commonActions.setLoader(true));
	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
				callback();
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7100'));
			callback();
		});
};

export const updateMembershipTemplateAction = (dispatch, appObj, callback) => {
	const url = getApiUrl('membershipTemplate', 'updateMembershipTemplate');
	dispatch(commonActions.setLoader(true));
	axios.put(url, appObj)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data);
				dispatch(commonActions.setMessage(true, '5026'));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '2023'));
		});
}

export const getAwsSignedAction = (dispatch, fileName, callback) => {
	const url = getApiUrl('membershipTemplate', 'getAwsSigned') + '/' + window.btoa(fileName);
	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, 7103));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, 7103));
		});
};

export const getDownloadSignedUrlAction = (dispatch, fileName, callback) => {
	const url = getApiUrl('membershipTemplate', 'getDownloadSignedUrl') + '/' + window.btoa(fileName);
	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				callback(response.data);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, 7103));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, 7103));
		});
};

export default {
	getMembershipTemplateListAction
}
