import * as membershipTemplateActions from 'actions/Membership/membershipTemplateActions';
import MembershipTemplateList from 'components/lists/Membership/membershipTemplate';
import * as type from 'actionTypes';
import { connect } from "react-redux";
import * as commonActions from 'actions/commonActions';
import { uploadFileInS3Action } from '../../actions/commonActions';

const mapStateToProps = state => {
    return {
        userPermission: state.commonReducer.userPermission,
        initialValues: state.templateReducer.templateData,
        requiredDescription: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2023'].text,
    }
};

const mapDispatchToProps = (dispatch) => ({
    getMembershipTemplateList: (callback) => {
        membershipTemplateActions.getMembershipTemplateListAction(dispatch, callback);
    },
    setEditFormData: function (value, callback) {
        dispatch({ type: type.EDIT_TEMPLATE, templateData: value });
        callback();
    },
    updateMembershipTemplate: (values, callback) => {
        membershipTemplateActions.updateMembershipTemplateAction(dispatch, values, callback);
    },
    getAwsSigned: (fileName, callback) => {
        membershipTemplateActions.getAwsSignedAction(dispatch, fileName, callback);
    },
    uploadFileInS3: (url, postData, callback) => {
        uploadFileInS3Action(dispatch, url, postData, callback);
    },
    getDownloadSignedUrl: (fileName, callback) => {
        membershipTemplateActions.getDownloadSignedUrlAction(dispatch, fileName, callback);
    },
    displayNoChangesMadeMessage: () => dispatch(commonActions.setMessage(true, '2040'))
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipTemplateList);