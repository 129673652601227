import React from "react";
import { Icon } from 'semantic-ui-react';

const PageNotFound = () => (
    <div className="unAuthorizeDv">
        <span className="dontSign"><Icon name="exclamation triangle" /></span>
        <span className="permissionSub">The page you are looking for does not exist.</span>
    </div>
);

export default PageNotFound;
