import React, { Component } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';


// Custom components
import RenderInputField from 'components/atoms/Input';
import RenderRadioField from 'components/atoms/RadioBox';
import RenderTextArea from 'components/atoms/RenderTextarea'
import * as utilCommon from 'helpers/utilCommon';

class AddMeetingTypeForm extends Component {

    state = {
        isDuplicate: this.props.isDuplicateMeetingType
    }
    nameFieldValdiation = (value) => {
        if (!value || (value && !value.trim())) {
            this.props.resetState();
            return this.props.requiredMeetingTypeNameError
        } else if (value && value.trim().length > 100) {
            this.props.resetState();
            return this.props.lengthMeetingTypeNameError
        }
        return undefined
    }
    descriptionFieldValidation = (value) => {
        if (!value || (value && !value.trim())) {
            return this.props.requireMeetingTypeDescription;
        } else if (value && value.trim().length > 500) {
            this.props.resetState();
            return this.props.lengthMeetingTypeDescriptionError
        }
        return undefined
    }

    render() {
        const { handleSubmit, submitting, duplicateMeetingNameError, isDuplicateMeetingType, actionType, initialValues, change } = this.props;
        const { nameFieldValdiation, descriptionFieldValidation } = this;
        return (
            <Form size='large' onSubmit={handleSubmit} noValidate data-testid= 'addMeetingType'>
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <Field name="TitleName" type="text"
                                
                                component={RenderInputField} label="Meeting Type Name "
                                maxLength="100" required='true'
                                validate={nameFieldValdiation}
                                isDuplicate={isDuplicateMeetingType}
                                duplicateError={duplicateMeetingNameError}
                            />
                        </Grid.Column>
                        {actionType == 2 && <Grid.Column>
                            <label className="label"> <i aria-hidden="true" class="asterisk  icon"></i> Status</label>
                            <div className="radioWrap">

                                <Field name="status"
                                    component={RenderRadioField}
                                    className="mr10" change={change}
                                    options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]}
                                    selectedValue={initialValues.status} />

                            </div>
                        </Grid.Column>}
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Field name="Description" type="text" id="description"
                                label="Description" validate={descriptionFieldValidation}
                                component={RenderTextArea} maxLength="500" required='true' />
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="actions">
                            <Button primary type="submit" disabled={submitting}> {actionType == 2 ? 'Update' : 'Save'}</Button>
                            <Button className="cancel" onClick={() => this.props.onCancel(0)}>Cancel</Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form >
        );
    }
}

export default (reduxForm({
    form: 'addMeetingTypeForm'
})(AddMeetingTypeForm));
