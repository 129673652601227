import * as utilCommon from 'helpers/utilCommon';
import { setPermissionNavigation, PAGE_SIZE_FOR_PAGINATION } from 'helpers/utilCommon';

let self = {};

export const setClassInstance = (instance) => {
    self = instance;
    const { pageSize, pageNumber } = self.state
    self.props.getBosVolumeList(async() => {
      await new Promise((c) => setTimeout(c, 50));
            changeListDataOnPagination(pageSize, pageNumber)
        if (self.props.match.params && self.props.match.params.bosId)
            getBosVolumeDetails()
    });
}
export const getBosVolumeDetails = () => {
    if (self.props.result.list && self.props.result.list.length > 0) {
        const res = self.props.result.list.find((item) => {
            return item.BosVolumeId == self.props.match.params.bosId
        })
        self.props.setEditFormData(res)
    }
}
export function changePageType(pageType) {
    if (pageType)
        self.setState({ pageType })
}
export function addButtonClickedEvent() {
    self.props.setEditFormData(null, () => {
        self.props.history.push(setPermissionNavigation('bosVolume-add'));
    });
}

export function backToListingPage() {
    const { pageType } = self.state
    if (pageType === "edit") {
        self.props.history.push('/bos-volume/list')
    } else {
        self.props.history.push('list')
    }
}
export const changeListDataOnPagination = (size, number) => {
    const { result } = self.props;
    const pageSize = size > 0 ? Number(size) : PAGE_SIZE_FOR_PAGINATION;
    const pageNumber = number > 0 ? Number(number) : 1;
    const offset = (pageNumber - 1) * pageSize;
    let bosVolumeList = [], totalItems = 0;
    if (result && result.list && result.list.length > 0) {
        bosVolumeList = result.list.slice(offset, offset + pageSize)
        totalItems = result.count;
    }
    self.setState({ bosVolumeList, totalItems });
    self.props.setFilteredList(bosVolumeList)
    updateCurrentlyShowingItems(totalItems, pageSize, pageNumber);
}

export const onChangeNumberOfItemsPerPage = (e, value) => {
    changeListDataOnPagination(value, 1);
    self.setState({ pageSize: value, pageNumber: 1 });
}

export const onPageChangeHandler = (event, data) => {
    const { pageSize } = self.state;
    changeListDataOnPagination(pageSize, data.activePage);

    self.setState({
        pageNumber: data.activePage
    })
}

export const updateCurrentlyShowingItems = (totalItems, pageSize, pageNumber = 1) => {
    const currentlyShowingItems = utilCommon.getCurrentlyShowingItemsInGrid(pageNumber, pageSize, totalItems);
    self.setState({ currentlyShowingItems });
}

export const onSort = (event, sortKey) => {
    let orderType; // 0 for Ascending order, 1 for descending order

    if (event.target.attributes.getNamedItem('data-order')) {
        orderType = event.target.attributes.getNamedItem('data-order').value;
    } else {
        orderType = event.target.parentElement.attributes.getNamedItem('data-order').value;
    }
    const bosVolumeListData = self.state.bosVolumeList;
    if (bosVolumeListData && bosVolumeListData.length > 0 && sortKey) {
        let tempSortkey = sortKey.split(' ').join('');

        bosVolumeListData.sort((a, b) => {
            if (tempSortkey !== 'Comments') {
                if (orderType == "0") {
                    return a[`${tempSortkey}`] - b[`${tempSortkey}`];
                }
                return b[`${tempSortkey}`] - a[`${tempSortkey}`];
            }
        })
        self.setState({ sortKey: tempSortkey, bosVolumeList: bosVolumeListData });
    }
}

