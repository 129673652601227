import * as commonActions from "actions/commonActions";
import * as type from "actionTypes";
import axios from "axios";
import getApiUrl from "helpers/apiUrls";

const committeeTypeList = data => {
  return {
    type: type.COMMITTEETYPE_LIST,
    payload: data
  };
};
const committeTypeAdd = data => {
  return {
    type: type.COMMITTEETYPEADD,
    payload: data
  };
};

const committeeTypeEditview = data => {
  return {
    type: type.COMMITTEETYPEEDITVIEW,
    payload: data
  };
};

// For getting committee Type list
export const getCommitteeTypeList = dispatch => {
  const url = getApiUrl("committeeType", "getCommitteeTypeList");
  dispatch(commonActions.setLoader(true));

  axios
    .get(url)
    .then(response => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(committeeTypeList(response.data.content));
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, response.data.message));
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "7053"));
    });
};

const committeeTypeSettingBackEvent = data => {
  return {
    payload: data
  };
};
const holdSettingValueEvent = data => {
  return {
    type: type.COMMITTEETYPE_SETTING_HOLD_VALUE,
    payload: data
  };
};

const committeeTypeEnableWebDataEvent = data => {
  return {
    payload: data
  };
};

export const getMembershipTypeList = (dispatch, callback) => {
  const url =
    getApiUrl("committeeType", "getMembershipType") + "/MembershipTypeName/ASC";
  dispatch(commonActions.setLoader(true));
  axios
    .get(url)
    .then(response => {
      if (response.data.status) {
        dispatch(commonActions.setLoader(false));
        dispatch(committeTypeAdd(response.data.content));
        callback();
      } else {
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, "6000"));
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "7025"));
    });
};

// For add new committee
export const postCommitteeType = (dispatch, data, callback) => {
  const url = getApiUrl("committeeType", "postCommitteeType");
  dispatch(commonActions.setLoader(true));
  axios
    .post(url, data)
    .then(response => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(commonActions.setMessage(true, "5020"));
        dispatch({ type: type.COMMITTEETYPEMODELRESET, payload: "" });
        callback();
      } else {
        // Handle error case
        dispatch({ type: type.COMMITTEETYPEMODELRESET, payload: "" });
        dispatch(commonActions.setMessage(true, response.data.message));
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "7055"));
      return false;
    });
};

// For edit committee
export const putCommitteeType = (dispatch, data, callback) => {
  const url = getApiUrl("committeeType", "putCommitteeType");
  dispatch(commonActions.setLoader(true));
  axios
    .put(url, data)
    .then(response => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(commonActions.setMessage(true, "5021"));
        callback();
        dispatch({ type: type.COMMITTEETYPEMODELRESET, payload: "" });
      } else {
        // Handle error case
        dispatch({ type: type.COMMITTEETYPEMODELRESET, payload: "" });
        dispatch(commonActions.setMessage(true, response.data.message));
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "7056"));
      return false;
    });
};

export const checkUniqueName = (dispatch, data, callbackFun) => {
  const url = getApiUrl("committeeType", "checkUniqueName");

  axios
    .get(url + "/" + data)
    .then(response => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        if (response.data.content) {
          callbackFun(response.data.content);
        }
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
    });
};
export const getOperatorList = (dispatch, callback) => {
  const url = getApiUrl("committeeType", "getOperator");
  dispatch(commonActions.setLoader(true));
  axios
    .get(url)
    .then(response => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        if (response.data.content) {
          callback(response.data.content);
        } else {
          callback(response.data.content);
        }
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, "6005"));
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "7025"));
    });
};
export const getApplicationPrivilege = (dispatch, callback) => {
  const url = getApiUrl("committeeType", "getApplicationPrivilege");
  axios
    .get(url)
    .then(response => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        if (response.data.content) {
          callback(response.data.content);
        } else {
          callback(response.data.content);
        }
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, "6005"));
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "7025"));
    });
};

export const getClassificationList = (dispatch, callback, classificationTypeId = 1) => {
  let url = getApiUrl("committeeType", "getClassification");

  dispatch(commonActions.setLoader(true));

  axios
    .get(url)
    .then(response => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        callback(response.data.content);
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, response.data.message));
        callback();
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "7007"));
      callback();
    });
};

export const getCommitteeTypeDetails = (
  dispatch,
  committeeTypeId,
  callback
) => {
  const url =
    getApiUrl("committeeType", "getCommitteeTypeDetails") +
    "/" +
    committeeTypeId;
  dispatch(commonActions.setLoader(true));
  axios
    .get(url)
    .then(response => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status && response.data.content) {
        callback(response.data.content);
      } else {
        // Handle error case
        dispatch({ type: type.SHOW_NOT_FOUND_PAGE });
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
      return false;
    });
};
export const getCommitteeTypeDetailForEdit = (dispatch, data, callback) => {
  const url =
    getApiUrl("committeeType", "getCommitteeTypeDetailForEdit") + "/" + data.id;
  const url1 =
    getApiUrl("committeeType", "getMembershipType") + "/MembershipTypeName/ASC";
  let urlclassification = getApiUrl("committeeType", "getClassification");
  const urlOperator = getApiUrl("committeeType", "getOperator");
  dispatch(commonActions.setLoader(true));
  Promise
    .all([
      axios.get(url),
      axios.get(url1),
      axios.get(urlclassification),
      axios.get(urlOperator)
    ])
    .then(
        ([response, response1, responseClassification, responseOperator]) => {
          dispatch(commonActions.setLoader(false));
          if (response.data.status) {
            dispatch(
              committeeTypeEditview({
                committeeInfo: response.data.content,
                membershipType: response1.data.content,
                classification: responseClassification.data.content,
                operator: responseOperator.data.content
              })
            );
            callback();
          } else {
            // Handle error case
            dispatch(commonActions.setMessage(true, "7051"));
          }
        }
      )
    .catch(err => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, "7051"));
      return false;
    });
};

export const checkLevelAssociatedWithOffice = (dispatch, data, callbackFun) => {
  const url = getApiUrl("committeeType", "checkLevelAssociatedWithOffice");
  dispatch(commonActions.setLoader(true));
  axios
    .get(url + "/" + data.CommitteeTypeId + "/" + data.CommitteeLevelId)
    .then(response => {
      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        if (response.data.content) {
          dispatch(commonActions.setMessage(true, "7064"));
        } else {
          callbackFun(response.data.content);
        }
      }
    })
    .catch(err => {
      dispatch(commonActions.setLoader(false));
    });
};
export const validateMembershipTypeAction = (dispatch, data, callback) => {
  const url = getApiUrl('committeeType', 'validateMembershipType');
  dispatch(commonActions.setLoader(true));
  axios.post(url, data).then(response => {
   dispatch(commonActions.setLoader(false));
    if (response.data.status) {
      callback(response.data.content || []);
    } else {
      dispatch(commonActions.setMessage(true, '7056'));
    }
  })
.catch(err => {
    dispatch(commonActions.setLoader(false));
  })
}

export default {
  getCommitteeTypeList,
  committeeTypeSettingBackEvent,
  committeeTypeEnableWebDataEvent,
  holdSettingValueEvent,
  checkUniqueName,
  getMembershipTypeList,
  getApplicationPrivilege,
  getClassificationList,
  getOperatorList,
  postCommitteeType,
  getCommitteeTypeDetails,
  getCommitteeTypeDetailForEdit,
  putCommitteeType,
  checkLevelAssociatedWithOffice,
  validateMembershipTypeAction
};
