import React from "react";
import TableHeader from 'components/atoms/Table/tableHeader';
import * as header from 'models/Committee/bosVolumeModel';
import * as _func from './function';
import { Confirm } from 'semantic-ui-react';


class BosVolumeList extends React.Component {
    state = {
        openWarningModal: false,
        openConfirmationModal: false,
        bosVolumeId: 0
    }
    componentWillMount() {
        _func.setClassInstance(this);
    }

    render() {
        const { filteredList, onSort, userPermission } = this.props;
        const { openWarningModal, openConfirmationModal } = this.state;
        return (<div data-testid='bosVolumeList'>
            <div className="contentWrapper clearfix">
                <div className="tableWrapper">
                    {
                        <table className="customTable">
                            <thead>
                                <tr>
                                    <TableHeader
                                        headerProps={header.HEADERINFO}
                                        onClickProps={onSort}
                                    ></TableHeader>
                                    {(userPermission.Update || userPermission.Delete) && <th width='10%'>
                                        Action
                                    </th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    _func.listingView(filteredList)
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
            <Confirm className="confirmBox noCancelBtn" confirmButton="Close" content={this.props.messageCodes['7162'].text} onCancel={_func.closeWarning} onConfirm={_func.closeWarning} open={openWarningModal} />
            <Confirm className="confirmBox" cancelButton="NO" confirmButton="YES" content={this.props.messageCodes['7165'].text} data-testid='confirm-box'
                onCancel={() => _func.deleteBosVolume(false)} onConfirm={() => _func.deleteBosVolume(true)} open={openConfirmationModal} />
        </div>
        );
    }
}

export default BosVolumeList;
