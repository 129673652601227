
// Custom components
import TableHeader from 'components/atoms/Table/tableHeader';
import EditMembershipTemplateModal from 'components/modals/Membership/MembershipTemplate/editTemplateModal';
import * as header from 'models/Membership/membershipTemplateModel';
import React, { Component } from "react";
import { Icon } from "semantic-ui-react";
import DateFormat from 'helpers/utilCommon';

class MembershipTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            templateList: [],
            isModalVisible: false
        }
    }

    loadTemplateData = () => {
        this.props.getMembershipTemplateList((response) => {
            this.setState({
                loading: false,
                templateList: response
            })
        });
    }

    componentDidMount() {
        this.loadTemplateData();
    }

    openEditModal = (_item) => {
        this.props.setEditFormData(_item, () => {
            this.setState({
                isModalVisible: true
            });
        });
    }

    closeModal = () => {
        this.setState({ isModalVisible: false }, () =>
            this.loadTemplateData());
    }

    handleDownloadFile = (Path) => {
        if (Path) {
            let fileName = `${process.env.REACT_APP_AWS_S3_MEM_TEMPLATE_DIR}/${Path}`;
            this.props.getDownloadSignedUrl(fileName, (response) => {
                if (response.status) {
                    let { url } = response.content;
                    let link = window.document.createElement('a');
                    link.href = url;
                    link.download = Path;
                    link.click();
                }
            });
        }
    }

    listingView(list) {
        if (list && list.length > 0) {
            return list.map((result, index) => {
                return (
                    <tr key={index}>
                        <td >
                            {result.Name}
                        </td>
                        <td>
                            {result.Description}
                        </td>
                        <td>
                            {result.ModifiedUserName}
                        </td>
                        <td>
                            {result.ModifiedDate ? DateFormat.utcDateTimeToLocalDateTime(result.ModifiedDate) : ''}
                        </td>
                        <td className="action">
                            {this.props.userPermission.Update &&
                                <button data-testid="edit-button" onClick={e => this.openEditModal(result)}><Icon className="squareIcon" name='pencil' /></button>
                            }
                            <button data-testid="download-button" disabled={!result.Path} onClick={e => this.handleDownloadFile(result.Path)}><Icon disabled={!result.Path} className={`squareIcon ${!result.Path && 'noPointer'}`} name='download' /></button>
                        </td>
                    </tr>
                )
            });
        }
        return (
            <tr><td colSpan="3"></td></tr>
        )
    }
    render() {
        const { loading, templateList, isModalVisible } = this.state;
        return (
            <div className="homePage" data-testid="manageTemplatesCmp">
                <div className="ui">
                    <div className="headingTitle clearfix">
                        <h2>Membership Templates</h2>
                    </div>
                    <div className="contentWrapper clearfix">
                        <div className="tableWrapper">
                            <table className="customTable membershipDocTable">
                                <thead>
                                    <tr>
                                        <TableHeader headerProps={header.HEADERINFO}></TableHeader>

                                        <th className="action">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                {(!loading && (templateList && templateList.length > 0)) && (
                                    <tbody>
                                        {this.listingView(this.state.templateList)}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
                {isModalVisible && (
                    <EditMembershipTemplateModal closeModal={this.closeModal} {...this.props} />
                )}
            </div>
        );
    }
}

export default MembershipTemplateList;
