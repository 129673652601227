import Home from 'components/pages/Layout/layoutComponent';
import { connect } from "react-redux";
import { getPermissionOnPageAction, logout } from '../../actions/commonActions';

const mapStateToProps = state => ({
	showPageNotFound: state.commonReducer.showPageNotFound,
	...state
});

const mapDispatchToProps = (dispatch) => ({
	dispatchEvent: (actionType) => { dispatch({ type: actionType }); },
	logout: () => { logout(dispatch) },
	getPermissionOnPage: (requestInput, groupInput) => dispatch(getPermissionOnPageAction(requestInput, groupInput))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

