import React from 'react';
import { Link } from 'react-router-dom';
const StandardBreadcrumb = (props) => (
    <div className="ui breadcrumb">
        <a className="section" href='/home'>Home</a>
        {props.parent.length > 0 && <span><div className="divider">/</div>
            <a className="section">
                <Link to={`/${props.parentPath}`}>{props.parent}</Link>
            </a>
        </span>}
        <span>
            <div className="divider">/</div>
            <div className="active section">{props.currentPage}</div>
        </span>
    </div>
)

export default StandardBreadcrumb
