
export const EditFaqModel = {
  "FAQId": "",
  "Question": "",
  "Answer": "",
  "DisplayOrder": "",
  "Status": "",
  "TypeId": ""
}

export const NewFaqModel = {
  "Question": "",
  "Answer": "",
  "TypeId": 1
}

export const HEADERINFO = [{ 'title': 'Questions', 'hasSorting': false, 'className': "questions", "sortKey": 'MembershipName', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Answers', 'hasSorting': false, 'className': "answers", 'sortKey': 'answers', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Status', 'hasSorting': false, 'className': "status", 'sortKey': 'status', 'orderBy': 0, 'orderByIcon': 0 }]

