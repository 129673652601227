import React from 'react';
import { Modal } from 'semantic-ui-react';

// Forms
import AddOfficerTitlesForm from 'components/forms/Committee/addOfficerTitleForm';

class AddOfficerTitleModal extends React.Component {

    closeModal = type => {
        this.props.closeModal(type);
        this.props.resetState();
    }

    onCancelHandler = () => {
        this.props.closeModal(4);
    }

    render() {

        return (
            <Modal open='true' className="large addOfficerModal">
                <Modal.Header>Add New Officer Title <i onClick={this.onCancelHandler} class="close">&#x2716;</i></Modal.Header>
                <AddOfficerTitlesForm {...this.props} onCancel={this.closeModal} />
            </Modal>
        );
    }
}

export default AddOfficerTitleModal;
