import { connect } from "react-redux";
import Login from 'components/pages/Login/loginComponent';
import authActions from "actions/authActions";
import { encodeText } from '../../helpers/utilCommon';

const mapStateToProps = state => {
	return {
		loginPrivilege: state.authReducer.loginCredential ? state.authReducer.loginCredential.privilege.loginPrivilege : '',
		userPrivilege: state.authReducer.loginCredentia ? state.authReducer.loginCredential.privilege.onUser : '',
		rolePrivilege: state.authReducer.loginCredential ? state.authReducer.loginCredential.privilege.onRole : ''
	}
};

const mapDispatchToProps = (dispatch) => ({
	login: (username, password, callback) => {
		authActions.login(dispatch, { username: username, password: encodeText(password)}, callback);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

