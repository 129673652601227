// @flow
import * as type from 'actionTypes';

const initialState = {
	classificationData: null,
	isDuplicateClassification: false,
	isApplicableToAllCommittees: false,
	colorCode: '',
	isAddedColor: false,
	colorName: ''
};

const reducerClassification = (state = initialState, action) => {
	switch (action.type) {

		case type.VIEW_CLASSIFICATION:

			const data = {
				classificationTypeId: action.classificationData.ClassificationTypeId,
				type: action.classificationData.Name,
				description: action.classificationData.Description,
				isApplicableToAllCommittees: action.classificationData.IsApplicableToAllCommittees,
				ColorCode: action.classificationData.ColorCode,
				ColorName: action.classificationData.ColorName
			};

			return {
				...state,
				classificationData: data
			};

		case type.CLASSIFICATION_DUPLICATE:
			return {
				...state,
				isDuplicateClassification: action.isDuplicateClassification
			};
		case type.ADD_CLASSIFICATION:

		case type.EDIT_CLASSIFICATION:

			return {
				...state,
				isDuplicateClassification: action.isDuplicateClassification
			};
		case type.APPLICABLE_ALL_COMMITTEES:
			let allcommittedd = state.classificationData ? state.classificationData : {};
			allcommittedd['isApplicableToAllCommittees'] = action.isApplicableToAllCommittees;
			return {
				...state,
				classificationData: allcommittedd
			}
		case type.PICK_COLOR:
			let pickedColor = state.classificationData ? state.classificationData : {};
			pickedColor['colorCode'] = action.colorInfo.colorCode;
			pickedColor['isAddedColor'] = action.colorInfo.isAddedColor;
			pickedColor['colorName'] = action.colorInfo.colorName;

			return {
				...state,
				classificationData: pickedColor
			}
		default:
			return {
				...state,
				isDuplicateClassification: false
			};
	}
};

export default reducerClassification;
