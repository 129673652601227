import React, { Component } from 'react';
import { Button, Form, Grid } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import EditorFieldComponent from 'components/atoms/TextEditor/EditorFieldComponent';
import RenderRadioField from 'components/atoms/RadioBox';

class EditMembershipFaqForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            questionError: '',
            answerError: ''
        }
    }

    handleForm = (values) => {
        let answerError = undefined;
        let questionError = undefined;

        // Validate question & answer
        if (!values.answer || (values.answer && values.answer.length < 9)) {
            answerError = this.props.requiredFaqAnswerError;
        } else if (values.answer && values.answer.length > (8 + 5000)) {
            answerError = this.props.maxlengthAnswerError;
        }

        if (!values.question || (values.question && values.question.length < 9)) {
            questionError = this.props.requiredFaqQuestionError;
        } else if (values.question && values.question.length > (8 + 1000)) {
            questionError = this.props.maxlengthQuestionError;
        }

        this.setState({ questionError, answerError })
        if (!questionError && !answerError) {
            this.props.handleSubmit()
        }
    }

    render() {

        const { handleSubmit, submitting, change, initialValues, isDuplicateQuestion, duplicateQuestionError } = this.props;
        const { answerError, questionError } = this.state;

        return (
            <Form size='large' onSubmit={handleSubmit(this.handleForm)} >
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <Field name="question" type="text" id="question"
                                label="Question" required={true}
                                component={EditorFieldComponent} />
                            {questionError && (<span className="errorMessage">{questionError}</span>)}
                            {isDuplicateQuestion && (<span className="errorMessage">{duplicateQuestionError}</span>)}
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Field name="answer" type="text" id="answer"
                                label="Answer" required={true}
                                component={EditorFieldComponent} />
                            {answerError && (<span className="errorMessage">{answerError}</span>)}
                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column>
                            <label className="label"> <i aria-hidden="true" class="asterisk  icon"></i> Status</label>
                            <div className="radioWrap">

                                <Field name="status"
                                    component={RenderRadioField}
                                    className="mr10"
                                    type="radio" change={change}
                                    options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]}
                                    selectedValue={initialValues.status} />
                            </div>
                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column className="actions">
                            <Button primary type="submit" disabled={submitting} >
                                Save
                            </Button>
                            <Button secondary onClick={this.props.onCancel}>
                                Cancel
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        );
    }
}

export default reduxForm({
    form: 'editMembershipFaqForm'
})(EditMembershipFaqForm);
