// Modals and Custom components
import Dragdrop from 'components/atoms/DragndropClassification';
import TableHeader from 'components/atoms/Table/tableHeader';
import AddClassificationModal from 'components/modals/Membership/Classification/addClassificationModal';
import EditClassificationModal from 'components/modals/Membership/Classification/editClassificationModal';
import ViewClassificationModal from 'components/modals/Membership/Classification/viewClassificationModal';
import * as header from 'models/Membership/classificationModels';
import React, { Component } from "react";


class classificationList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            isPopupOpen: false,
            classificationView: {},
            classificationList: [],
            colorPicker: []
        }

        this.closePopupModal = this.closePopupModal.bind(this);
    }

    componentDidMount() {
        this.getClassificationList();
    }

    getClassificationList = () => {
        this.setState({
            loading: true
        })
        this.props.getClassificationList(this.setClassificationListData);
    }

    setClassificationListData = (response) => {

        this.setState({
            classificationList: response,
            loading: false
        });
    }

    openViewModal = (item) => {
        this.setState({
            showViewModal: true,
            selectedClassificationItem: item
        });
    }

    classificationView = (_item) => {
        this.setState({ showViewForm: true, classificationView: _item })
    }

    openEditModal = (classificationId) => {
        this.props.getcolorPicker(0, (response) => this.props.getClassificationDetails(classificationId, () => {
            this.setState({
                showViewModal: false,
                showEditModal: true,
                unbaletoChangeAllCommittee: 0,
                colorPicker: response.content
            })

        }))

    }

    closeModal = _type => {
        let self = this;

        switch (_type) {
            case 1:
                self.setState({ showEditModal: false });
                self.getClassificationList();
                break;
            case 2:
                self.setState({ showViewModal: false });
                break;
            case 3:
                self.setState({ showAddModal: false });
                self.getClassificationList();
                break;
            default:
                self.setState({ showEditModal: false })
        }
    }
    resequenceItems = (items) => {
        // Map the items into required format
        let classificationsList = [];

        for (let i = 0; i < items.length; i++) {
            let obj = {};
            obj.ClassificationTypeId = items[i].ClassificationTypeId;
            obj.DisplayOrder = i + 1;
            classificationsList.push(obj);
        }
        this.props.resequenceClassifications(classificationsList, () => {
            this.getClassificationList();
        });
    }

    closePopupModal() {
        this.setState({
            isPopupOpen: false
        })
    }
    openAddForm = () => {
        this.props.getcolorPicker(0, (response) => {
            let colorPicker = response.content;
            if (colorPicker && colorPicker.length > 0) {
                this.setState({ showAddModal: true, colorPicker: colorPicker }, () => this.props.changeApplicableAll(true))
            }


        });
    }
    onSort = (event, sortKey) => {
        // Definition not required
    }

    render() {
        const { loading, classificationList, showAddModal, showViewModal, showEditModal, colorPicker } = this.state;
        const { userPermission } = this.props;

        return (

            <div data-testid="membershipClassificationCmp">
                <div className="headingTitle clearfix">
                    <h2>Member Classifications</h2>
                    {userPermission.Add && <button onClick={() => this.openAddForm()}
                        className="ui secondary button"><i aria-hidden="true" class="plus icon">
                        </i> Add Classification Type</button>}
                </div>
                <div className="contentWrapper clearfix">
                    <div className="tableWrapper">
                        {<table className="customTable membershipClassifTable">
                            <thead>
                                <tr>
                                    <TableHeader headerProps={header.HEADERINFO} onClickProps={this.onSort}></TableHeader>
                                </tr>
                            </thead>

                            {classificationList && classificationList.length > 0 && (
                                <Dragdrop items={classificationList} openViewModal={(item) => { this.openViewModal(item) }}
                                    resequenceItems={(items) => { this.resequenceItems(items) }}
                                    allowResequenceing={userPermission.Update}
                                />
                            )}

                            {(!loading && (!classificationList || classificationList.length <= 0)) && (
                                <tbody>
                                    <tr>
                                        <td colSpan="4">{this.props.noRecordMessage}</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                        }
                    </div>
                </div>
                {showAddModal && (
                    <AddClassificationModal {...this.props} closeModal={(type) => { this.closeModal(type) }} addClassification={this.props.addClassification} colorPicker={colorPicker} />
                )}
                {showViewModal && (<ViewClassificationModal {...this.props} selectedItem={this.state.selectedClassificationItem}
                    closeModal={(type) => { this.closeModal(type) }}
                    viewDataProp={this.state.classificationView}
                    openEditModal={(classificationId) => { this.openEditModal(classificationId) }}
                    userPermission={userPermission} />)}
                {showEditModal && (
                    <EditClassificationModal {...this.props} closeModal={(type) => { this.closeModal(type) }} unbaletoChangeAllCommittee={this.state.unbaletoChangeAllCommittee} colorPicker={colorPicker} />
                )}
            </div>
        );
    }
}
export default classificationList;
