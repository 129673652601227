export const HEADERINFO =
  [{ 'title': 'Officer Title', 'hasSorting': true, 'className': "officerTitle", "sortKey": 'OfficerTitleName', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'Description', 'hasSorting': true, 'className': "description", 'sortKey': 'OfficerTitleId', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'Status', 'hasSorting': true, 'className': "status", 'sortKey': 'Status', 'orderBy': 0, 'orderByIcon': 0 }]

export const OfficerTitlesModel = {
  "OfficerTitleId": 0,
  "OfficerTitleName": "",
  "TitleDescription": "",
  "Status": true,
  "IsASTMStaff": true,
  "IsApplicableToAllCommittees": true,
  "ResponsibilityList": []
}

export const RefreshHeader = () => {
  HEADERINFO.map(item => {
    item.orderBy = 0;
    item.orderByIcon = 0;
  });
}
