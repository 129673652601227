const en = {
	// Warning messages
	'2040': { type: 'attention', text: 'No changes saved.' },

	// Login messages
	'401': { type: 'error', text: 'Your session has expired, please login again' },
	//Unknown error occured.
	'404': { type: 'error', text: "Unknown error occured." },

	// Access related messages 1001 to 1100
	'1001': { type: 'error', text: 'Your token has been expired.' },
	'1002': { type: 'error', text: 'You are not able to login due to invalid token.' },
	'1003': { type: 'error', text: 'Token could not be verified from Redis client.' },
	'1004': { type: 'error', text: 'Your session has expired. Please logout, then login again.' },
	'1005': { type: 'error', text: 'Token could not be verified from server, please try again' },
	'1006': { type: 'error', text: 'Azure Session could not be verified from server, please try again' },
	'1010': { type: 'error', text: 'User name or password missing.' },
	'1011': { type: 'error', text: 'Error occured during login. Please try again.' },
	'1012': { type: 'error', text: 'Invalid user name or password.' },
	'1013': { type: 'error', text: 'Privilege not assigned on this role.' },
	'1014': { type: 'error', text: 'Access denied.' },

	// Application related messages
	'2001': { type: 'error', text: 'Secure Key already exists.' },
	'2002': { type: 'error', text: 'Application Name is required.' },
	'2003': { type: 'error', text: 'Application Name length is more than permitted length of 100.' },
	'2004': { type: 'error', text: 'Contact Name is required.' },
	'2005': { type: 'error', text: 'Contact Name length is more than permitted length of 150.' },
	'2006': { type: 'error', text: 'Email ID is required.' },
	'2007': { type: 'error', text: 'Contact EmailId length is more than permitted length of 60.' },
	'2008': { type: 'error', text: 'Contact EmailId is not valid.' },
	'2009': { type: 'error', text: 'Application Id is required.' },
	'2010': { type: 'error', text: 'Application Id length is more than permitted length of 10.' },
	'2011': { type: 'error', text: 'Secure Key is required.' },
	'2012': { type: 'error', text: 'Secure Key length is more than permitted length of 100.' },
	'2013': { type: 'error', text: 'Application Name already exists.' },
	'2014': { type: 'error', text: 'Question length is more than permitted length of 1000.' },
	'2016': { type: 'error', text: 'Question is required.' },
	'2017': { type: 'error', text: 'Answer is required.' },
	'2018': { type: 'error', text: 'Answer length is more than permitted length of 5000.' },
	'2020': { type: 'error', text: 'Question already exists.' },

	// Classification  error message
	'2021': { type: 'error', text: 'Classification Type is required.' },
	'2022': { type: 'error', text: 'Classification Type length is more than permitted length of 30.' },
	'2023': { type: 'error', text: 'Description is required.' },
	'2024': { type: 'error', text: 'Description length is more than permitted length of 100.' },
	'2025': { type: 'error', text: 'Invalid classification.' },
	'2026': { type: 'error', text: 'Color is required.' },
	'2401': { type: 'error', text: 'Classification Type already exists.' },
	'2403': { type: 'error', text: 'Color already used.' },
	'2404': { type: 'error', text: 'Color name already exists.' },
	'2405': { type: 'error', text: 'Please provide a color name.' },
	'2406': { type: 'error', text: 'Color already exists.' },

	// Membership document error messages
	'2210': { type: 'error', text: 'Document Name already exists.' },
	'2201': { type: 'error', text: 'Document Name is required.' },
	'2212': { type: 'error', text: 'You cannot delete this document as it is associated with another record.' },

	// Officer title error messages
	'2220': { type: 'error', text: 'Officer Title is required.' },
	'2223': { type: 'error', text: 'OfficerTitle Name is A-Z.' },
	'2224': { type: 'error', text: 'OfficerTitle Name length is more thean of 100 character.' },
	'2225': { type: 'error', text: 'OfficerTitle Description length is more thean of 400 character.' },
	'2226': { type: 'error', text: 'Officer Title already exists.' },
	'2227': { type: 'error', text: 'Responsibility doesn\'t exists.' },
	'2028': { type: 'success', text: 'Data copied successfully.' },
	'2029': { type: 'error', text: 'Please choose at least one Committee Type.' },
	'2030': { type: 'error', text: 'There are Members associated with this officer title. Are you sure you want to delete this record?' },

	// Membership type related code
	'2301': { type: 'error', text: 'Membership Name is required.' },
	'2302': { type: 'error', text: 'Membership Name cannot be greater than 100 character.' },
	'2303': { type: 'error', text: 'Membership Name already exists.' },
	'2304': { type: 'error', text: 'Membership Name should be letters A to Z.' },
	'2305': { type: 'error', text: 'Membership Fee should be numeric.' },
	'2306': { type: 'error', text: 'Number of Members is required.' },
	'2307': { type: 'error', text: 'Number of Committees is required.' },
	'2308': { type: 'error', text: 'Number of Members is required.' },
	'2309': { type: 'error', text: 'Number of Committess is required.' },
	'2310': { type: 'error', text: 'Number of Members should be numeric.' },
	'2311': { type: 'error', text: 'Number of Committess should be numeric.' },
	'2312': { type: 'error', text: 'Renewal Period is required.' },
	'2313': { type: 'error', text: 'Specify Months should be numeric.' },
	'2314': { type: 'error', text: 'Membership ID is required.' },
	'2315': { type: 'error', text: 'Application ID is required.' },
	'2316': { type: 'error', text: 'DocumentId ID is required.' },
	'2317': { type: 'error', text: 'Specify Months should be required.' },
	'2318': { type: 'error', text: 'Space is not allow at starting.' },
	'2319': { type: 'error', text: 'Invalid membership type.' },

	// User Manage related code
	'2500': { type: 'error', text: 'Username already exists.' },
	'2501': { type: 'error', text: 'Please provide the Username.' },
	'2502': { type: 'error', text: 'Spaces are not allowed.' },
	'2503': { type: 'error', text: 'No permissions assigned. Please assign atleast one permission.' },
	'2504': { type: 'error', text: 'Only alphanumeric characters are allowed.' },
	'2509': { type: 'error', text: 'Please provide the Password.' },
	'2510': { type: 'error', text: 'Please provide the confirm Password.' },
	'2511': { type: 'error', text: 'Password requires one lower case letter, one upper case letter, one digit, 8-16 length, and no spaces.' },
	'2512': { type: 'error', text: 'Password and confirm password are not matched.' },
	'2513': { type: 'error', text: 'Password could not be reset, please try again.' },
	'2514': { type: 'error', text: 'Error occurred while fetching data from azure ad.' },
	'2515': { type: 'error', text: 'User doesn\'t exist in Azure AD.' },
	// Committee Type related code
	'2550': { type: 'error', text: 'Committee Type Name is required.' },
	'2551': { type: 'error', text: 'Committee Type Name already exists.' },
	'2552': { type: 'error', text: 'Max Number of Members Permitted is required.' },
	'2553': { type: 'error', text: 'Number of Members Permitted is required.' },
	'2554': { type: 'error', text: 'Please add at least one level for the Committee Type.' },
	'2555': { type: 'error', text: 'Please set Balance Rule.' },
	'2556': { type: 'error', text: 'Please select at least one Classification Type.' },
	'2557': { type: 'error', text: 'Number of Members should be numeric.' },
	'2558': { type: 'error', text: 'Level name already exists.' },

	// Meeting Type related code
	'2600': { type: 'error', text: 'Meeting Type Name is required.' },
	'2601': { type: 'error', text: 'Meeting Type Name already exists.' },
	'2602': { type: 'error', text: 'Meeting Type Name cannot be greater than 100.' },
	'2603': { type: 'error', text: 'Description cannot be greater than 500 character.' },
	'2604': { type: 'error', text: 'Meeting Type Id is required.' },
	'2605': { type: 'error', text: 'Description is required.' },

	// UI validations related errors
	'4001': { type: 'error', text: 'Incorrect input.' },

	// Success messages 5000 to 6000
	'5001': { type: 'success', text: 'Application added successfully.' },
	'5002': { type: 'success', text: 'Application updated successfully.' },
	'5003': { type: 'success', text: 'FAQ added successfully.' },
	'5004': { type: 'success', text: 'FAQ updated successfully.' },
	'5005': { type: 'success', text: 'FAQ sequence updated successfully.' },
	'5006': { type: 'success', text: 'Membership Document updated successfully.' },
	'5007': { type: 'success', text: 'New Membership Document added successfully.' },
	'5008': { type: 'success', text: 'New Officer Title added successfully.' },
	'5009': { type: 'success', text: 'Officer Title updated successfully.' },
	'5010': { type: 'success', text: 'Membership Type added successfully.' },
	'5011': { type: 'success', text: 'Membership Type Details updated successfully.' },
	'5012': { type: 'success', text: 'Membership Type Setting updated successfully.' },
	'5013': { type: 'success', text: 'Member Classification added successfully.' },
	'5014': { type: 'success', text: 'Member Classification updated successfully.' },
	'5015': { type: 'success', text: 'User added successfully.' },
	'5016': { type: 'success', text: 'User Privilege updated successfully.' },
	'5017': { type: 'success', text: 'Officer Title\'s responsibility updated successfully.' },
	'5018': { type: 'success', text: 'FAQ deleted successfully.' },
	'5019': { type: 'success', text: 'Membership Document deleted successfully.' },
	'5020': { type: 'success', text: 'Committee Type added successfully.' },
	'5021': { type: 'success', text: 'Committee Type updated successfully.' },
	'5022': { type: 'success', text: 'Meeting Type added successfully.' },
	'5023': { type: 'success', text: 'Meeting Type updated successfully.' },
	'5024': { type: 'success', text: 'Password changed successfully.' },
	'5025': { type: 'success', text: 'Member Classification sequence updated successfully.' },
	'5026': { type: 'success', text: 'Membership Template updated successfully.' },
	// Info messages	6001 to 7000
	'6000': { type: 'info', text: 'No applications added.' },
	'6001': { type: 'info', text: 'No FAQs added.' },
	'6002': { type: 'info', text: 'No memberships added.' },
	'6003': { type: 'info', text: 'No Membership Documents added.' },
	'6004': { type: 'info', text: 'No Officer Titles added.' },
	'6005': { type: 'info', text: 'No Membership Types added.' },
	'6006': { type: 'info', text: 'No Member Classifications added.' },
	'6007': { type: 'info', text: 'No Role added.' },
	'6008': { type: 'info', text: 'No default Privilege added.' },
	'6009': { type: 'info', text: 'No Privilege for this role added.' },
	'6010': { type: 'info', text: 'No Privilege for this user added.' },
	'6011': { type: 'info', text: 'No User added.' },
	'6012': { type: 'info', text: 'No Committee Types added.' },
	'6013': { type: 'info', text: 'The update will be applicable for those committees that derives default settings from Rules and Exception application. The changes will not be reflected in those committees that have different settings than the default settings in Rules and Exception application. Do you want to continue?' },
	'6014': { type: 'info', text: 'You can not remove this Membership Type as other records are associated with it.' },

	// Apis related errors 7001 to 8000
	// Application related errors
	'7001': { type: 'error', text: 'Add/Update application request content is not valid.' },
	'7002': { type: 'error', text: 'Error occured while getting list application data.' },
	'7003': { type: 'error', text: 'Error occured while getting application data.' },
	'7004': { type: 'error', text: 'Error occured while getting Secure Key.' },
	'7005': { type: 'error', text: 'Error occored while adding application.' },
	'7006': { type: 'error', text: 'Error occored while updating application.' },

	// FAQ related errors
	'7007': { type: 'error', text: 'Error occured while getting list FAQ data.' },
	'7008': { type: 'error', text: 'Error occured while adding FAQ.' },
	'7009': { type: 'error', text: 'FAQ ID is required.' },
	'7010': { type: 'error', text: 'Status is required.' },
	'7011': { type: 'error', text: 'Add/Update membership FAQ request content is not valid.' },
	'7012': { type: 'error', text: 'Error occured while updating FAQ.' },
	'7013': { type: 'error', text: 'Error occured while getting FAQ.' },
	'7014': { type: 'error', text: 'Error occured while resequencing the FAQS.' },
	'7015': { type: 'error', text: 'FAQ Resequence request invalid.' },

	// Classification related errors
	'7016': { type: 'error', text: 'Error occured while getting list member classification data.' },
	'7017': { type: 'error', text: 'Error occured while adding member classification.' },
	'7018': { type: 'error', text: 'Member classification ID is required.' },
	'7019': { type: 'error', text: 'Status is required.' },
	'7020': { type: 'error', text: 'Add/Update member classification request content is not valid.' },
	'7021': { type: 'error', text: 'Error occured while updating member classification.' },
	'7022': { type: 'error', text: 'Error occured while getting member classification.' },
	'7023': { type: 'error', text: 'Error occured while resequencing the member classification.' },
	'7024': { type: 'error', text: 'Member classification Resequence request invalid.' },

	// Membership type related error
	'7025': { type: 'error', text: 'Error occured while fetching membership type data.' },
	'7026': { type: 'error', text: 'Error occured while adding membership type  data.' },
	'7027': { type: 'error', text: 'Valid membership id not found.' },
	'7028': { type: 'error', text: 'Error occured while updating membership type  data.' },

	// User Manage related error
	'7029': { type: 'error', text: 'Error occured while fetching role list data' },
	'7030': { type: 'error', text: 'Error occured while fetching user detail data' },
	'7031': { type: 'error', text: 'Error occured while fetching default privilege data' },
	'7032': { type: 'error', text: 'Error occured while fetching privilege list by role Id data' },
	'7033': { type: 'error', text: 'Error occured while fetching privilege list by user Id data' },
	'7034': { type: 'error', text: 'Error occured while fetching user list data' },
	'7035': { type: 'error', text: 'Error occured while adding user privilege data' },
	'7036': { type: 'error', text: 'Error occured while view user privilege data' },
	'7037': { type: 'error', text: 'Error occured while updating user privilege data' },
	'7038': { type: 'error', text: 'Error occured while validateing user data' },

	// Officer title related errors
	'7039': { type: 'error', text: 'Error occurred while getting Officer Title list data.' },
	'7040': { type: 'error', text: 'Error occurred while getting Officer Title detail data.' },
	'7041': { type: 'error', text: 'Error occurred while adding Officer Title.' },
	'7042': { type: 'error', text: 'Error occurred while updating Officer Title.' },
	'7043': { type: 'error', text: 'Error occurred while updating responsibility Officer Title.' },
	'7044': { type: 'error', text: 'Error occurred while updating responsibility Officer Title.' },

	// Committee Type related error
	'7045': { type: 'error', text: 'Error occurred while fetching operator data.' },
	'7046': { type: 'error', text: 'No operator added.' },
	'7047': { type: 'error', text: 'No committee privilege added.' },
	'7048': { type: 'error', text: 'Error occurred while fetching committee privilege.' },
	'7049': { type: 'error', text: 'Error occurred while duplicate checking committee type name.' },
	'7050': { type: 'error', text: 'No committee type added.' },
	'7051': { type: 'error', text: 'Error occurred while fetching commttee type detail data.' },
	'7052': { type: 'error', text: 'Invalid committee type Id.' },
	'7053': { type: 'error', text: 'Error occurred while fetching committee type list data.' },
	'7054': { type: 'error', text: 'No committee type added.' },
	'7055': { type: 'error', text: 'Error occurred while adding committee type.' },
	'7056': { type: 'error', text: 'Error occurred while updating committee type.' },

	// Classification related error
	'7057': { type: 'error', text: 'Error occurred while getting color picker list.' },

	'7058': { type: 'error', text: 'Error occurred while deleting membership document.' },
	// Officer Title related error
	'7059': { type: 'error', text: 'Error occurred while re-sequencing officer title list.' },

	// Meeting Type related error
	'7060': { type: 'error', text: 'Error occurred while getting Meeting Type list data.' },
	'7061': { type: 'error', text: 'Error occurred while adding Meeting Type.' },
	'7062': { type: 'error', text: 'Error occurred while updating Meeting Type.' },
	'7063': { type: 'error', text: 'Add/Update Meeting Type request content is not valid.' },

	'7064': { type: 'error', text: 'Cannot remove level as it is associated with officer.' },

	// Template Error
	'7100': { type: 'error', text: 'Error occurred while membership template list.' },
	'7101': { type: 'error', text: 'Error occurred while updating membership template.' },
	'7103': { type: 'error', text: 'Error occured while getting signed url from S3.' },
	'7104': { type: 'error', text: 'File upload link has been expired.' },

	// Bos Volume Type related error/info
	'7151': { type: 'info', text: 'No BOS Volume Location added.' },
	'7152': { type: 'error', text: 'Error occurred while fetching BOS Volume list data.' },
	'7153': { type: 'success', text: 'BOS Volume added successfully.' },
	'7154': { type: 'error', text: 'Error occurred while adding BOS Volume' },
	'7156': { type: 'error', text: 'Section Number is required' },
	'7157': { type: 'error', text: 'Volume Number is required' },
	'7158': { type: 'error', text: "The section number cannot be ‘00’ (or) '0'" },
	'7159': { type: 'error', text: "The volume number cannot be ‘00’ (or) '0'" },
	'7160': { type: 'error', text: 'Entered Value already exist in the system. Please try adding a different value.' },
	'7161': { type: 'error', text: 'Two digits are required' },
	'7162': { type: 'info', text: 'Please remove associated committees from BOS Volume Location before performing delete' },
	'7163': { type: 'success', text: 'BOS Volume updated successfully.' },
	'7164': { type: 'success', text: 'BOS Volume deleted successfully.' },
	'7165': { type: 'info', text: 'Are you sure you want to delete this BOS Volume Location?' },

	// Manage Reports Error
	'2802': { type: 'error', text: 'Report Title already exists.' },
	'2806': { type: 'error', text: 'Report ID already exists.' },
	'2808': { type: 'error', text: 'Tableau URL already exists.' },
	'7166': { type: 'error', text: 'Report Title is required.' },
	'7167': { type: 'error', text: 'Report ID is required.' },
	'7168': { type: 'error', text: 'Report Type is required.' },
	'7169': { type: 'error', text: 'Tableau URL is required.' },
	'7170': { type: 'error', text: 'Error occurred while getting membership report list.' },
	'7171': { type: 'success', text: 'Membership Report added successfully.' },
	'7172': { type: 'error', text: 'Error occurred while report type list.' },
	'7173': { type: 'error', text: 'Error occurred while deleting report.' },
	'7174': { type: 'error', text: 'Error occurred while updating report.' },
	'7175': { type: 'error', text: 'Error occurred while adding new report.' },
	'7176': {type: 'info', text: "Are you sure you want to delete this Report?"},
	'7177': { type: 'success', text: 'Membership Report deleted successfully.' },
    '7178': { type: 'success', text: 'Membership Report updated successfully.' }
}
export default en;
