
import React, { Component } from 'react';
import { Form, Radio } from 'semantic-ui-react';

class RenderRadioField extends Component {

    constructor(props) {
        super(props);

        this.state = {
            value: props.selectedValue
        }

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {

        this.props.input.onChange(e);
        if (e && e.currentTarget && e.currentTarget.querySelector('input')) {
            this.setState({
                value: e.currentTarget.querySelector('input').value
            });
        }
    }

    render() {

        const { input, options, disabled } = this.props;
        return (
            <div className="radioWrap">

                {options.map(item => {
                    return (
                        <Form.Field>
                            {!disabled && (
                                <Radio {...input}
                                    label={item.label}
                                    value={item.value}
                                    type='radio'
                                    checked={item.value == this.state.value}
                                    onChange={this.handleChange}
                                />)}
                            {disabled && (
                                <Radio {...input}
                                    label={item.label}
                                    value={item.value}
                                    type='radio'
                                    checked={item.value == this.state.value}
                                    disabled
                                />)}
                        </Form.Field>
                    )
                })}
            </div>
        )
    }
}

export default RenderRadioField;
