import * as type from 'actionTypes';

const initialState = {
    meetingTypeData: null,
    isDuplicateMeetingType: false,
    meetingTypeList: []
};

const reducerMeetingType = (state = initialState, action) => {
    switch (action.type) {
        case type.MEETINGTYPE_VIEW:

            const data = {
                MeetingTypeId: action.meetingTypeData.MeetingTypeId,
                TitleName: action.meetingTypeData.Name,
                Description: action.meetingTypeData.Description,
                status: action.meetingTypeData.IsActive ? 'active' : 'inactive'
            };
            return {
                ...state,
                meetingTypeData: data
            };
        case type.MEETINGTYPE_LIST:
            return {
                ...state,
                meetingTypeList: action.meetingList,
                isDuplicateMeetingType: false

            }
        case type.MEETINGTYPE_DUPLICATE:
            return {
                ...state,
                isDuplicateMeetingType: action.isDuplicateMeetingType
            };
        case type.MEETINGTYPE_VIEW:
            return {
                ...state,
                isDuplicateMeetingType: action.isDuplicateMeetingType,
                meetingTypeData: action.meetingTypeData
            }

        case type.MEETINGTYPE_RESET:
            return {
                ...state,
                isDuplicateMeetingType: false
            }
        case type.MEETINGTYPE_RESET_ALL:
            return {
                ...state,
                meetingTypeData: initialState.meetingTypeData
            }

        default:
            return state;
    }
};

export default reducerMeetingType;