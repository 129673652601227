// @flow
import * as type from 'actionTypes';

const initialState = {
	faqData: null,
	isDuplicateQuestion: false
};

const reducerFaq = (state = initialState, action) => {
	switch (action.type) {

		case type.VIEW_FAQ:

			const data = {
				faqId: action.faqData.FAQId,
				question: action.faqData.Question,
				answer: action.faqData.Answer,
				status: action.faqData.Status ? 'active' : 'inactive',
				displayOrder: action.faqData.DisplayOrder,
				typeId: action.faqData.TypeId
			};

			return {
				...state,
				faqData: data
			};

		case type.ADD_FAQ:
		case type.EDIT_FAQ:
			return {
				...state,
				isDuplicateQuestion: action.isDuplicateQuestion
			};

		default:
			return {
				...state,
				isDuplicateQuestion: false
			};
	}
};

export default reducerFaq;
