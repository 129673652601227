import CheckboxElement from 'components/atoms/CheckBox';
import PrivilegeCardTooltip from 'components/atoms/PrivilegeCard';
import RenderSelect from 'components/atoms/Select';
import SortableOfficer from 'components/atoms/SortableOfficer';
import CustomTooltip from 'components/atoms/Tooltip';
import EditOfficerTitleForm from 'components/forms/Committee/editOfficerTitleForm';
// Modals and custom components
import AddOfficerTitleModal from 'components/modals/Committee/addOfficerTitleModal';
import ViewOfficerTitleModal from 'components/modals/Committee/viewOfficerTitleModal';
import _ from 'lodash';
import React from 'react';
import { arrayMove } from 'react-18-sortable-hoc';
import { Card, Icon, Label, Search } from 'semantic-ui-react';
import { AlphaNumericWhiteSpace_Regex } from '../../../helpers/utilCommon';


class OfficerTitleList extends React.Component {
    constructor(props) {
        super(props);

        const self = this;
        self.state = {
            loading: true,
            officerTitleView: {},
            officerTitleList: [],
            officerTitleListInitData: [],
            AutoSuggestList: [],
            responsebilityList: [],
            selectedStatus: 0,
            statusList: [{ 'value': 2, 'text': 'All' },
            { 'value': 0, 'text': 'Active' },
            { 'value': 1, 'text': 'Inactive' }
            ],
            activeFilter: false,
            committeeTypeList: [],
            committeeInfo: []
        };
        this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
        this.myRef = React.createRef();

    }

    componentDidMount() {
        // Refersh the header while the load the page
        // For calling officer title list api

        let req = { 'officerTitleId': null, 'status': true }
        this.props.getOfficerTitleList(req, (response) => this.initOfficerTitleListData(response));
    }
    // This function is used for update officer title's responsibility's privilege.
    submitPrivilege = (parentIndex, resIndex, childItem, callback) => {
        let temp_officerlist = { ...this.state.officerTitleList[parentIndex] };
        let temp_responsibility = temp_officerlist.ResponsibilityList[resIndex]
        temp_officerlist.ResponsibilityList = [];
        temp_officerlist.ResponsibilityList.push(temp_responsibility);
        temp_officerlist.ResponsibilityList[0].ResponsibilityGroupList = childItem;
        let status = this.state.selectedStatus;
        let req = { 'officerTitleId': null, 'status': this.getStatus(status) }
        this.setState({ selectedStatus: status });
        this.props.editResponsibility(temp_officerlist, () => {
            callback();
            this.props.getOfficerTitleList(req, this.setOfficerTitleListFilterData);
        })
    }
    getNodes(responsebility, index, isDragger) {
        let nodes = null;
        if (responsebility && responsebility.length > 0) {
            let tempResponsebility = Object.create(responsebility)
            nodes = tempResponsebility.map((res, resIndex) => {
                return (
                    <PrivilegeCardTooltip displayNoChangesMadeMessage={this.props.displayNoChangesMadeMessage} userPermission={this.props.userPermission} _ele={<li data-allowDragable={isDragger}>
                        <CheckboxElement IsEditable={false} IsChecked={this.setAccessIndicator(res.ResponsibilityGroupList) == 1 ? true : false} className={this.setAccessIndicator(res.ResponsibilityGroupList) == 2 ? '.ui.checkbox partial' : ''} />
                        <span className="privelegeName">{res.ResponsibilityName}</span></li>} popupList={res.ResponsibilityGroupList} parentIndex={index} resIndex={resIndex} submitPrivilege={this.submitPrivilege} />
                )
            });

        }
        return nodes
    }

    setPopup = (responsibility, index, isDragger) => {
        return this.getNodes(responsibility, index, isDragger);

    }
    setAccessIndicator = (res) => {
        let count = 0, tcount = 0;
        res.map((item) => {
            item.ResponsibilityPrivilegeList.map((subres) => {
                tcount++;
                if (subres.IsChecked) {
                    count++;
                }
            })
        })
        if (count > 0 && tcount > 0) {
            if (count == tcount) {
                return 1;// For Full Access Indicator
            } else if (count > 0 && count < tcount) {
                return 2;// For Limited Access Indicator
            }
        } return 3;// For No Access Indicator

    }

    initOfficerTitleListData = (response) => {
        this.setState({ officerTitleList: [] });
        this.setState({
            officerTitleList: response,
            AutoSuggestList: response,
            officerTitleListInitData: response,
            loading: false
        });

    }

    setOfficerTitleListData = (response) => {
        this.setState({
            officerTitleList: response,
            loading: false
        });

    }

    setOfficerTitleListFilterData = (response) => {

        this.setState({
            officerTitleListInitData: response,
            officerTitleList: this.state.value ? response.filter(p => String(p.OfficerTitleName.toLowerCase().trim()).includes(this.state.value.toLowerCase())) : response,
            loading: false
        });

    }

    viewOfficerTitle = id => {
        let reqData = { id: id };
        this.props.resetState();
        this.props.getOfficerTitleDetail(reqData, (response) => {
            let committeeTypeList = [{ key: 0, value: 0, text: 'Select' }]
            committeeTypeList = committeeTypeList.concat(response.filter(item => AlphaNumericWhiteSpace_Regex.test(item.CommitteeTypeName)).map(item => {
                return { key: item.CommitteeTypeId, value: item.CommitteeTypeId, text: item.CommitteeTypeName }
            }));

            this.setState({ committeeTypeList, committeeInfo: response, showEditForm: true });

        }
        )
    }
    closeModal = _type => {
        let self = this;
        const status = this.getStatus(self.state.selectedStatus);
        let req = { 'officerTitleId': null, 'status': status }
        switch (_type) {
            case 1:
                self.setState({ showEditForm: false, showViewForm: false });
                self.setState({
                    isLoading: false, results: []
                });
                self.props.getOfficerTitleList(req, this.setOfficerTitleListFilterData);
                break;
            case 2:
                self.setState({ showViewForm: false });
                break;
            case 3:
                self.setState({ showAddForm: false });
                self.setState({
                    isLoading: false, results: [], selectedStatus: 0, value: ''
                });
                let obj = { 'officerTitleId': null, 'status': true }
                self.props.getOfficerTitleList(obj, (response) => this.initOfficerTitleListData(response));
                break;
            case 4:
                self.setState({ showAddForm: false });
                self.setState({
                    isLoading: false, results: [], selectedStatus: 0
                });
                break;
            case 5:
                self.setState({ showEditForm: false, showViewForm: false });
                self.setState({
                    isLoading: false, results: []
                });
                break;
            default:
                self.setState({ showEditForm: false })
        }
    }
    addOfficerTitle = () => {
        const reqData = { id: 0, status: 1 }
        this.props.resetState();
        this.props.getOfficerResponsebilityCommitteeTypeLevel(reqData, (response) => {          
            let committeeTypeList = [{ key: 0, value: 0, text: 'Select' }]
            committeeTypeList = committeeTypeList.concat(response.committeeInfo.filter(item => AlphaNumericWhiteSpace_Regex.test(item.CommitteeTypeName)).map(item => {
                return { key: item.CommitteeTypeId, value: item.CommitteeTypeId, text: item.CommitteeTypeName }
            }));
            this.setState({ responsebilityList: response.responsibility, committeeTypeList, committeeInfo: response.committeeInfo, showAddForm: true });
        })

    }

    componentWillMount() {
        this.resetComponent()
    }

    resetComponent = (value) => {
        const self = this;
        this.setState({ isLoading: false, results: [], activeFilter: false });
        if (value || value === '') {
            this.setState({ value: '' });
            const status = self.getStatus(self.state.selectedStatus);
            let req = {
                'officerTitleId': null,
                'status': status
            }
            this.props.getOfficerTitleList(req, this.initOfficerTitleListData);
        }
    }

    getStatus = (value) => {
        let status = true;
        if (value === 0) {
            status = true;
        } else if (value === 1) {
            status = false;
        } else {
            status = null;
        }
        return status;
    }


    handleResultSelect = (e, { result }) => {
        const self = this;
        this.setState({ value: result.OfficerTitleName });
        const status = self.getStatus(self.state.selectedStatus);
        let req = {
            'officerTitleId': result.OfficerTitleId,
            'status': status
        }
        this.props.getOfficerTitleList(req, (response) => this.setOfficerTitleListData(response));
    }

    handleSearchChange = (e, { value }) => {
        this.setState({ value })
        setTimeout(() => {
            if (this.state.value.length < 1) {return this.resetComponent(value)}
            this.setState({ isLoading: true })
            const filteredData = this.state.AutoSuggestList.filter(p => String(p.OfficerTitleName.toLowerCase().trim()).includes(this.state.value.toLowerCase()));
            this.setState({
                isLoading: false,
                results: filteredData.length < 10 ? filteredData :
                    filteredData.slice(0, 10),
                activeFilter: true
            })
        }, 300)
    }

    onChangeStatus = (e, value) => {
        let self = this;
        self.setState({
            selectedStatus: value, isLoading: false, results: []
        });
        const status = self.getStatus(value);
        let req = {
            'officerTitleId': null,
            'status': status
        }
        let Searchvalue = this.state.value;

        if (Searchvalue === "" || Searchvalue === undefined)
            {self.props.getOfficerTitleList(req, self.initOfficerTitleListData);}
        else
            {self.props.getOfficerTitleList(req, self.setOfficerTitleListFilterData);}

        this.scrollToParent();
    }

    scrollToParent = () => {
        setTimeout(() => {
            const refnode = this.myRef.current;
            // console.log('refnode=====================================', refnode)
            refnode.scrollTop = 0;
        }, 100);
    }

    handleOnKeyDown(e) {
        if (e.which === 13) {
            this.setState({ isLoading: true });
            setTimeout(() => {
                this.setState({ isLoading: false, results: null });
            }, 200);
            this.setState({ value: e.target.value });
            this.state.officerTitleList = this.state.officerTitleListInitData;
            const filteredData = this.state.officerTitleList.filter(p => String(p.OfficerTitleName.toLowerCase().trim()).includes(this.state.value.toLowerCase()));
            this.setOfficerTitleListData(filteredData);
            this.scrollToParent();
        }
    }

    handleSearchIcon_Click = () => {
        this.setState({ isLoading: true });
        setTimeout(() => {
            this.setState({ isLoading: false, results: null });
        }, 200);
        this.setState({ value: this.state.value });
        this.state.officerTitleList = this.state.officerTitleListInitData;
        const filteredData = this.state.officerTitleList.filter(p => String(p.OfficerTitleName.toLowerCase().trim()).includes(this.state.value.toLowerCase()));
        this.setOfficerTitleListData(filteredData);
        this.scrollToParent();
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState({
            officerTitleList: arrayMove(this.state.officerTitleList, oldIndex, newIndex)
        }, () => {
            let tempOfficerList = [];
            this.state.officerTitleList.filter(a => a.Status).map((item, index) => {
                tempOfficerList.push({ OfficerTitleId: item.OfficerTitleId, SequenceNo: index + 1 })
            });
            if (tempOfficerList && tempOfficerList.length > 0) {
                let req = { 'officerTitleId': null, 'status': this.getStatus(this.state.selectedStatus) }
                this.props.postResequence(tempOfficerList, () => this.props.getOfficerTitleList(req, (response) => this.initOfficerTitleListData(response)))
            }
        })
    };


    render() {
        const {
            showAddForm, showViewForm, showEditForm, officerTitleList,
            isLoading, value, results, statusList, selectedStatus, committeeTypeList, committeeInfo
        } = this.state;
        const { userPermission } = this.props;
        const resultRenderer = ({ OfficerTitleName }) => <Label content={OfficerTitleName} />;
        return (
            <div data-testid ='officerTitleListCmp'>
                <div className="headingTitle clearfix">
                    <h2>Officer Titles</h2>
                    {userPermission.Add &&
                        <button className="ui secondary button" onClick={() => this.addOfficerTitle()}><i aria-hidden="true" class="plus icon"></i> Add Officer Title</button>}
                    <div className="selectDrop">
                        <RenderSelect placeholder="" onchange={this.onChangeStatus}
                            options={statusList} value={selectedStatus} />
                    </div>
                    <div className="searchBx">
                        <Search
                            onKeyDown={this.handleOnKeyDown}
                            loading={isLoading}
                            onResultSelect={this.handleResultSelect}
                            onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                            results={results}
                            value={value}
                            resultRenderer={resultRenderer}
                            showNoResults={false}
                            noResultsMessage={"No Officer Title found in the system."}
                            placeholder="Search by Officer Title"
                            {...this.props}
                        />
                        <span className="hiddenSearchIcon" onClick={this.handleSearchIcon_Click}></span>
                    </div>
                    <div className="chBoxLegend">
                        <ul>
                            <li>
                                <CheckboxElement IsEditable={false} IsChecked={true} className="ui checkbox" />
                                <span>Full Permissions</span>
                            </li>
                            <li>
                                <CheckboxElement IsEditable={false} IsChecked={false} className="ui checkbox partial" />
                                <span>Partial Permissions</span>
                            </li>
                            <li>
                                <CheckboxElement IsEditable={false} IsChecked={false} className="ui checkbox" />
                                <span>No Permissions</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="contentWrapper type2 clearfix">
                    <div className="manageRoleWrap" ref={this.myRef}>
                        <React.Fragment>
                            <Card.Group itemsPerRow={4} className="waterMark">
                                {officerTitleList && officerTitleList.map((item, index) => {
                                    return < Card card-index={item.SequenceNo} data-allowDragable={!this.state.activeFilter} >
                                        <span className="bookmarkNum">{item.SequenceNo}</span>

                                        <div className={item.SequenceNo > 0 ? 'roleCard' : 'roleCard inactive'} data-cardSequence={item.SequenceNo}>
                                            <div className="roleStatus">
                                                {item.SequenceNo > 0 && <span className="positionMumber mr10">{item.SequenceNo}</span>}
                                                <span class="statusIndicator " className={item.Status ? 'statusIndicator active' : 'statusIndicator inactive'}>
                                                    {item.Status ? 'Active' : 'Inactive'}

                                                </span>

                                                <span className="floatRight" >
                                                    {userPermission.Update &&
                                                        <CustomTooltip content='Edit Officer Title' position='bottom left' icon={<Icon name='pencil' onClick={() => this.viewOfficerTitle(item.OfficerTitleId)} />} />
                                                    }
                                                </span>
                                            </div>
                                            {item.OfficerTitleName.length > 15 &&
                                                <CustomTooltip icon={<h4> {item.OfficerTitleName.length > 15 ? item.OfficerTitleName.substring(0, 15) + '...' : item.OfficerTitleName} </h4>} position='bottom left' content={item.OfficerTitleName} />

                                            }
                                            {item.OfficerTitleName.length <= 15 &&
                                                <h4> {item.OfficerTitleName.length > 15 ? item.OfficerTitleName.substring(0, 15) + '...' : item.OfficerTitleName} </h4>
                                            }
                                            <div className="officerSubInfo">
                                                {item.IsASTMStaff ? <span>ASTM Staff</span> : <span> &nbsp;</span>}
                                                {item.IsApplicableToAllCommittees ? <span> &nbsp;</span> : <span>&nbsp;&bull;&nbsp;Specific to a Committee</span>}
                                            </div>
                                            <div className="privileges">
                                                <span className="privelegeHead">Responsibilities</span>
                                                <ul className="">

                                                    {this.setPopup(item.ResponsibilityList, index)}
                                                </ul>

                                            </div>
                                        </div>
                                    </Card>

                                })
                                }
                            </Card.Group >
                            <SortableOfficer userPermission={userPermission} gridList={officerTitleList} onSortEnd={this.onSortEnd} setPopup={this.setPopup} viewOfficerTitle={this.viewOfficerTitle} activeFilter={this.state.activeFilter} />

                        </React.Fragment>

                        {(!officerTitleList || (officerTitleList && officerTitleList.length === 0)) &&
                            <div className="noRecordMessage">No Officer Titles added.</div>}
                    </div>

                </div>
                {
                    showAddForm && (
                        <AddOfficerTitleModal {...this.props} closeModal={this.closeModal} committeeType={{ committeeTypeList, committeeInfo }} responsebility={this.state.responsebilityList} />
                    )
                }
                {
                    showViewForm && (
                        <ViewOfficerTitleModal openEditForm={this.openEditForm} closeModal={this.closeModal} viewDataProp={this.state.officerTitleView} />
                    )
                }
                {
                    showEditForm && (
                        <EditOfficerTitleForm {...this.props} closeModal={this.closeModal} committeeType={{ committeeTypeList, committeeInfo }} />
                    )
                }
            </div >


        );

    }
}
export default OfficerTitleList;
