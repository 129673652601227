// @flow
import * as type from 'actionTypes';

const init = {
	isEdit: false,
	initialValues: {
		IsUnlimitedMembers: 'Unlimited',
		IsUnlimitedCommittees: 'Unlimited',
		committeeTypesApplication: [],
		CommitteeTypeName: '',
		IsLimitedMembersPermitted: false,
		NoOfMembersPermitted: '',
		IsBalanceRequired: false,
		IsEnableCommitteeOnWeb: false,
		OperatorId: 1,
		CreatedBy: 1,
		IsActive: true,
		CommitteeLevel: [],
		MembershipType: [],
		CommitteeBalanceRule: [],
		ExternalApplication: [],
		SelectedMembershipType: [],
		OperatorList: [],
		ClassificationLeftList: [],
		ClassificationRigthList: []
	}

}


const reducerCommitteeType = (state = init, action) => {
	switch (action.type) {

		case type.COMMITTEETYPE_LIST:

			return {
				...state,
				type: action.type,
				_list: action.payload,
				initialValues: undefined,
				isEdit: false
			};
		case type.COMMITTEETYPE_DETAILS_SUBMIT: // Submit details
			return {
				...state,
				initialValues: Object.assign({}, state.initialValues, action.payload.details),
				_details: action.payload.details,
				type: action.type,
				_app: action.payload.app
			};
		case type.COMMITTEETYPEADD:
			let _initialValues = undefined;
			_initialValues = JSON.parse(JSON.stringify(init.initialValues));
			_initialValues.MembershipType = action.payload;

			return {
				...state,
				initialValues: _initialValues

			}
		case type.COMMITTEETYPEEDITVIEW:

			let initialValues = undefined;
			let ClassificationLeftList = JSON.parse(JSON.stringify(action.payload.classification));
			let ClassificationRigthList = JSON.parse(JSON.stringify(action.payload.classification));
			initialValues = action.payload.committeeInfo;
			initialValues.SelectedMembershipType = [];
			initialValues.SelectedMembershipType = action.payload.committeeInfo.MembershipType;
			initialValues.MembershipType = action.payload.membershipType;
			initialValues.ClassificationLeftList = ClassificationLeftList.map((item) => {
				if (action.payload.committeeInfo.CommitteeBalanceRule.filter((subItem) => subItem.IsLeft === true && subItem.ClassificationTypeId === item.ClassificationTypeId).length > 0) {
					item['checked'] = true;
				} else {
					item['checked'] = false;
				}
				return item
			});
			initialValues.IsUnlimitedMembers = action.payload.committeeInfo.IsLimitedMembersPermitted ? 'Limited' : 'Unlimited'
			initialValues.ClassificationRigthList = ClassificationRigthList.map((item) => {
				if (action.payload.committeeInfo.CommitteeBalanceRule.filter((subItem) => subItem.IsLeft === false && subItem.ClassificationTypeId === item.ClassificationTypeId).length > 0) {
					item['checked'] = true;
				} else {
					item['checked'] = false;
				}
				return item
			});

			if (action.payload.operator) {
				action.payload.operator.unshift({ 'OperatorId': 0, 'OperatorName': 'Select', 'OperatorSymbol': '' });
			}
			initialValues.OperatorList = action.payload.operator;
			return {
				...state,
				initialValues: initialValues
			}
		case type.COMMITTEETYPEMODELRESET:
			return {
				...state,
				initialValues: undefined
			}
		default:
			return state;
	}
};

export default reducerCommitteeType;