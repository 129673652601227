import React, { Component } from "react";
import { Icon, Grid, Form, Button, Accordion, Modal, Popup, Confirm } from "semantic-ui-react";
import { Field, reduxForm, change, untouch } from "redux-form";
import RenderInputField from "components/atoms/Input";
import RenderRadioField from "components/atoms/RadioBox";
import CheckboxElement from "components/atoms/CheckBox";
import ComitteeDnd from "components/atoms/ComitteeDnD";
import CustomTooltip from "components/atoms/Tooltip";
import RenderSelect from "components/atoms/Select";
import {
  shallowCopy,
  AlphaNumericWhiteSpace_Regex,
  getMessageContent
} from "../../../helpers/utilCommon";
import _ from "lodash";

class AddCommitteeType extends Component {
  prevDataInitialValues = _.cloneDeep(this.props.initialValues);
  Eligibilities = [
    { Title: "Classification", Status: false },
    { Title: "Scope", Status: false },
    { Title: "Overview", Status: false },
    { Title: "Bylaws", Status: false }
  ];
  constructor(props) {
    super(props);
    this.myRef = null;
    this.state = {
      IsUnlimitedMembersValue:
        this.props.initialValues.IsLimitedMembersPermitted && this.props.isEdit
          ? "Limited"
          : "Unlimited",
      IsBalanceRequiredValue:
        this.props.initialValues.IsBalanceRequired && this.props.isEdit
          ? "Yes"
          : "No",
      isPermittedMembers:
        this.props.initialValues.IsLimitedMembersPermitted && this.props.isEdit,
      IsBalanceRequired: this.props.initialValues.IsBalanceRequired,
      levelvalue: "",
      levelList: [],
      CommitteeLevel:
        this.props.initialValues.CommitteeLevel && this.props.isEdit
          ? this.props.initialValues.CommitteeLevel
          : [],
      membershipTypeList: this.props.initialValues.MembershipType,
      IsEnableCommitteeOnWeb: !!this.props.initialValues.IsEnableCommitteeOnWeb,
      IsEnableCommitteeOnWebOld: !!this.props.initialValues
        .IsEnableCommitteeOnWeb,
      IsSuppressOnWI: !!this.props.initialValues.IsSuppressOnWI,
      IsSuppressOnWIOld: !!this.props.initialValues.IsSuppressOnWI,
      ExternalApplication: this.props.isEdit
        ? this.props.initialValues.ExternalApplication
        : [],
      classificationListLeft:
        this.props.isEdit && this.props.initialValues.IsBalanceRequired
          ? JSON.parse(
            JSON.stringify(this.props.initialValues.ClassificationLeftList)
          )
          : [],
      operatorList:
        this.props.isEdit && this.props.initialValues.IsBalanceRequired
          ? this.props.initialValues.OperatorList
          : [],
      selectedOperator:
        this.props.isEdit && this.props.initialValues.IsBalanceRequired
          ? this.props.initialValues.OperatorId
          : 0,
      SelectedMembershipType:
        this.props.isEdit &&
          this.props.initialValues.IsBalanceRequired &&
          this.props.initialValues.SelectedMembershipType.length > 0
          ? this.props.initialValues.SelectedMembershipType.map(item => {
            return {
              MembershipTypeId: item.MembershipTypeId,
              CommitteeTypeMembershipTypeId:
                item.CommitteeTypeMembershipTypeId
            };
          })
          : [],
      editModeSelectedMembershipType: this.props.isEdit
        ? this.props.initialValues.SelectedMembershipType
        : [],
      loadprivilege: false,
      isDuplicateCommitteeType: false,
      isLevelAdded: false,
      isNoOfMembersPermitted: true,
      CommitteeBalanceRule:
        this.props.isEdit &&
          this.props.initialValues.IsBalanceRequired &&
          this.props.initialValues.CommitteeBalanceRule.length > 0
          ? this.props.initialValues.CommitteeBalanceRule.map(item => {
            return {
              CommitteeBalanceRuleId: item.CommitteeBalanceRuleId,
              ClassificationTypeId: item.ClassificationTypeId,
              IsLeft: item.IsLeft
            };
          })
          : [],
      classificationListRight:
        this.props.isEdit && this.props.initialValues.IsBalanceRequired
          ? JSON.parse(
            JSON.stringify(this.props.initialValues.ClassificationRigthList)
          )
          : [],
      isOperatorSelected: false,
      isClassificationSelected: false,
      isClassificationSelectedRight: false,
      isSameLevel: false,
      showLevelerrorPopup: false,
      showMemberTypeErrorPopup: false,
      isBalanceRule: false,
      CommitteeLevelNameOnly: [],
      intervalId: 0,
      activePopUp: -1,
      CommitteeTypeName: this.props.initialValues.CommitteeTypeName || "",
      sepecifyLevel: "",
      isConfirm: false,
      editData: null,
      showMembershipTypeErrorConfirmBox: false
    };
    if (this.props.initialValues.IsBalanceRequired) {
      this.setCommitteeTypeEditable();
    }
    this.handleSepecifyLevel = this.handleSepecifyLevel.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.props.change('CommitteeTypeName', this.props.initialValues.CommitteeTypeName || "")
    }

  }
  setCommitteeTypeEditable() {
    for (
      let i = 0;
      i < this.props.initialValues.ClassificationLeftList.length;
      i++
    ) {
      this.state.classificationListRight[i].IsEditable = this.state.classificationListLeft[i].checked;
    }
    for (let i = 0; i < this.props.initialValues.ClassificationRigthList.length; i++) {
      this.state.classificationListLeft[i].IsEditable = this.state.classificationListRight[i].checked;
    }
  }

  selectMembershipType = item => {
    const self = this;
    if (item.columns["column-2"].taskIds.length > 0) {
      let SelectedMembershipType = [];
      item.columns["column-2"].taskIds.map(itemDetail => {
        let committeeTypeMembershipTypeId =
          self.state.editModeSelectedMembershipType.length > 0 &&
            self.state.editModeSelectedMembershipType.filter(
              item11 => item11.MembershipTypeId == itemDetail
            ).length > 0
            ? self.state.editModeSelectedMembershipType.filter(
              item11 => item11.MembershipTypeId == itemDetail
            )[0].CommitteeTypeMembershipTypeId
            : 0;
        SelectedMembershipType.push({
          MembershipTypeId: itemDetail,
          CommitteeTypeMembershipTypeId: committeeTypeMembershipTypeId
        });
      });
      self.setState({ SelectedMembershipType: SelectedMembershipType });
    } else {
      self.setState({ SelectedMembershipType: [] });
    }
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  permittedMemberOnChangeHandler(e) {
    this.setState({ isNoOfMembersPermitted: true });
    const { textContent } = e.currentTarget;
    let _obj = [];
    if (textContent == "Limited") {
      this.resetFields("addCommitteeType", {
        MaxNumberofMember: ""
      });
      _obj.push({ key: "MaxNumberofMember", value: "" });
      this.setState({
        isPermittedMembers: true
      });
      _obj.push({ key: "IsUnlimitedMembers", value: false });
      _obj.push({ key: "isPermittedMembers", value: true });
    } else {
      this.setState({
        isPermittedMembers: false
      });
      _obj.push({ key: "IsUnlimitedMembers", value: true });
      _obj.push({ key: "isPermittedMembers", value: false });
    }
    this.props.committeesettingHoldValue(_obj);
  }

  resetFields = (formName, fieldsObj) => {
    Object.keys(fieldsObj).forEach(fieldKey => {
      // Reset the field's value
      this.props.dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));
      // Reset the field's error
      this.props.dispatch(untouch(formName, fieldKey));
    });
  };

  balanceRequiredOnChangeHandler(e) {
    const { textContent } = e.currentTarget;
    let _obj = [];
    if (textContent == "Yes") {
      this.setState({
        IsBalanceRequired: true
      });
      this.props.getClassificationList(this.setClassificationListData);
      this.props.getOperatorList(this.setOperatorList);
    } else {
      this.setState({
        IsBalanceRequired: false
      });
    }
  }

  setOperatorList = response => {
    if (response) {
      response.unshift({
        OperatorId: 0,
        OperatorName: "Select",
        OperatorSymbol: ""
      });
      this.setState({
        operatorList: response,
        loading: false,
        selectedOperator: 0
      });
    }
  };

  setClassificationListData = response => {
    if (response) {
      for (let i = 0; i < response.length; i++) {
        response[i].checked = false;
      }
      this.setState({
        classificationListLeft: JSON.parse(JSON.stringify(response)),
        classificationListRight: JSON.parse(JSON.stringify(response)),
        loading: false
      });
    }
  };

  addLevel = () => {
    let self = this;
    if (self.state.levelvalue) {
      self.setState({ isLevelAdded: false });
      this.resetFields("addCommitteeType", {
        level: ""
      });
      let prevList = self.state.CommitteeLevel;
      let prevListNameOnly = self.state.CommitteeLevelNameOnly;
      if (
        prevListNameOnly.filter(
          item =>
            item.trim().toLowerCase() ===
            self.state.levelvalue.trim().toLowerCase()
        ).length > 0
      ) {
        this.setState({
          isSameLevel: true
        });
        return;
      }
      this.setState({ isSameLevel: false });
      let levelNum = "";
      for (let i = 0; i <= prevList.length; i++) {
        levelNum = levelNum + "." + 1;
      }

      if (prevList.length === 0) {
        levelNum = "1.0";
      } else {
        levelNum = levelNum.substr(1);
      }
      prevListNameOnly.push(self.state.levelvalue);
      let temp = shallowCopy(this.Eligibilities);
      prevList.push({
        CommitteeLevelName: levelNum + " " + self.state.levelvalue,
        ParentId: prevList.length > 0 ? prevList.length + 1 : 1,
        Eligibilities: temp,
        CommitteeLevelTitle: self.state.levelvalue
      });
      this.setState({
        CommitteeLevel: prevList,
        levelvalue: "",
        CommitteeLevelNameOnly: prevListNameOnly
      });
      this.props.change("level", "");

    }
  };
  changeEnableCommitte = () => {
    let IsEnableCommitteeOnWeb = !this.state.IsEnableCommitteeOnWeb;
    this.setState({ IsEnableCommitteeOnWeb: IsEnableCommitteeOnWeb }, () => {
      if (this.state.IsEnableCommitteeOnWeb && !this.state.loadprivilege) {
        this.props.getApplicationPrivilege(response =>
          this.setState({ ExternalApplication: response, loadprivilege: true })
        );
      }
    });
  };

  changeEnableCommitteOnWorkItemAndBallot = () => {
    let IsSuppressOnWI = !this.state.IsSuppressOnWI;
    this.setState({ IsSuppressOnWI: IsSuppressOnWI });
  };

  changeApplicationPrivilege = (pIndex, cIndex) => {
    let externalApplication = this.state.ExternalApplication;
    externalApplication[pIndex].Privilege[
      cIndex
    ].IsChecked = !externalApplication[pIndex].Privilege[cIndex].IsChecked;

    // Check the Designation and Title based on Checked option
    if (cIndex > 1 && externalApplication[pIndex].Privilege[cIndex].IsChecked) {
      externalApplication[pIndex].Privilege[0].IsChecked = true;
      externalApplication[pIndex].Privilege[1].IsChecked = true;
    }

    this.setState({ ExternalApplication: externalApplication });
  };

  setLevelValue = e => {
    if (e.currentTarget.defaultValue) {
      this.setState({
        levelvalue: e.currentTarget.defaultValue
      });
    }
    this.resetFields("addCommitteeType", {
      level: ""
    });
  };
  closeLevelPopup = () => {
    this.setState({ showLevelerrorPopup: false });
  };

  closeMemberPopup = () => {
    this.setState({ showMemberTypeErrorPopup: false });
  };
  openMemberPopup = () => {
    this.setState({ showMemberTypeErrorPopup: true });
  };
  deleteLevel = index => {
    let self = this;
    let prevList = self.state.CommitteeLevel;
    console.log('self.state.CommitteeLevel===============================', index)
    // If the level exists in DB, then check it from DB
    if (prevList[index].CommitteeLevelId) {
      self.props.checkLevelAssociatedWithOffice(prevList[index], response => {
        // If no officer title found attached with the level, then only remove it
        if (!response) {
          this.removeSelectedLevel(prevList, index)
        }
      });
    } else {
      this.removeSelectedLevel(prevList, index)
    }
  };

  removeSelectedLevel = (prevList, index) => {
    let prevListNameOnly = this.state.CommitteeLevelNameOnly;
    if (
      prevList[index].IsAllowDelete != undefined &&
      !prevList[index].IsAllowDelete
    ) {
      this.setState({ showLevelerrorPopup: true });

      return false;
    }
    prevList.splice(index, 1);
    prevListNameOnly.splice(index, 1);
    for (let j = 1; j < prevList.length; j++) {
      let levelNum = "1";
      for (let i = 0; i < j; i++) {
        levelNum = levelNum + "." + "1";
      }
      prevList[j].CommitteeLevelName =
        levelNum +
        " " +
        prevList[j].CommitteeLevelName.substring(
          prevList[j].CommitteeLevelName.split(" ")[0].length,
          prevList[j].CommitteeLevelName.length
        );
      prevList[j].ParentId = j - 1;
    }
    if (prevList[0]) {
      prevList[0].CommitteeLevelName =
        "1.0" +
        " " +
        prevList[0].CommitteeLevelName.substring(
          prevList[0].CommitteeLevelName.split(" ")[0].length,
          prevList[0].CommitteeLevelName.length
        );
    }
    this.setState({
      CommitteeLevel: prevList,
      CommitteeLevelNameOnly: prevListNameOnly
    });
  };

  setData = list => {
    let response = [];
    if (list && list.length > 0) {
      list.map(res => {
        let result = {};
        result.value = res.OperatorId;
        result.text = res.OperatorName;
        response.push(result);
      });
    }
    return response;
  };

  setBalRuleListLeft = (event, index, item) => {
    this.state.classificationListRight[index].IsEditable = !this.state
      .classificationListLeft[index].checked;
    let prevClassificationList = this.state.classificationListLeft;
    prevClassificationList[index].checked = !prevClassificationList[index]
      .checked;
    let prevList = this.state.CommitteeBalanceRule;
    if (prevClassificationList[index].checked) {
      let reqObj = {
        ClassificationTypeId: item.ClassificationTypeId,
        IsLeft: true
      };
      prevList.push(reqObj);
      this.setState({
        CommitteeBalanceRule: prevList,
        classificationListLeft: prevClassificationList,
        isClassificationSelected: false,
        isOperatorSelected: false
      });
    } else {
      for (let i = 0; i < prevList.length; i++) {
        if (
          prevClassificationList[index].ClassificationTypeId ===
          prevList[i].ClassificationTypeId
        ) {
          prevList.splice(i, 1);
        }
      }

      this.setState({
        classificationListLeft: prevClassificationList,
        CommitteeBalanceRule: prevList
      });
    }
  };
  validateDuplicate = (event, value) => {
    const self = this;
    self.setState({ isDuplicateCommitteeType: false });
    self.props.checkUniqueName(value, response => {
      if (response && response.length > 0) {
        if (
          response[0].CommitteeTypeId !=
          self.props.initialValues.CommitteeTypeId
        ) {
          self.setState({ isDuplicateCommitteeType: true });
          this.refs.pageContainer.offsetParent.scrollIntoView(true);
        }
      }
    });
  };

  setBalRuleListRight = (event, index, item) => {
    this.state.classificationListLeft[index].IsEditable = !this.state
      .classificationListRight[index].checked;
    let prevClassificationList = this.state.classificationListRight;
    prevClassificationList[index].checked = !prevClassificationList[index]
      .checked;
    let prevList = this.state.CommitteeBalanceRule;
    if (prevClassificationList[index].checked) {
      let reqObj = {
        ClassificationTypeId: item.ClassificationTypeId,
        IsLeft: false
      };
      prevList.push(reqObj);
      this.setState({
        CommitteeBalanceRule: prevList,
        classificationListRight: prevClassificationList,
        isClassificationSelectedRight: false,
        isOperatorSelected: false
      });
    } else {
      for (let i = 0; i < prevList.length; i++) {
        if (
          prevClassificationList[index].ClassificationTypeId ===
          prevList[i].ClassificationTypeId
        ) {
          prevList.splice(i, 1);
        }
      }
      this.setState({
        classificationListRight: prevClassificationList,
        CommitteeBalanceRule: prevList
      });
    }
  };

  onChangeOperator = (e, value) => {
    this.setState({
      selectedOperator: value,
      isBalanceRule: false,
      isOperatorSelected: false
    });
  };

  // levelRequired = () => {
  //   const self = this;
  //   self.setState({ isLevelAdded: false });
  //   if (!self.state.CommitteeLevel || self.state.CommitteeLevel.length === 0) {
  //     self.setState({ isLevelAdded: true });
  //     return self.props.requiredLevelError;
  //   }
  //   return undefined;
  // };
  handleForm = values => {
    let elemId = "";
    const self = this;
    let count = 0;
    let isOperatorSelected = false;
    self.setState({ isNoOfMembersPermitted: true });

    if (!self.state.CommitteeLevel || self.state.CommitteeLevel.length === 0) {
      count++;
      this.setState({ isLevelAdded: true });
      elemId = document.getElementById("levelDiv").getClientRects();
      window.scrollTo(elemId[0].x, elemId[0].y);
      this.refs.pageContainer.offsetParent.scrollIntoView(true);
    } else {
      values.CommitteeLevel = self.state.CommitteeLevel;
      this.setState({ isLevelAdded: false });
    }
    values.IsLimitedMembersPermitted = values.IsUnlimitedMembers === "Limited";
    if (
      values.IsUnlimitedMembers === "Limited" &&
      (values.NoOfMembersPermitted == 0 || values.NoOfMembersPermitted == "")
    ) {
      if (count === 0) {
        elemId = document.getElementById("addNumberMember").getClientRects();
        window.scrollTo(elemId[0].x, elemId[0].y);
        this.refs.pageContainer.offsetParent.scrollIntoView(true);
      }
      count++;

      self.setState({ isNoOfMembersPermitted: false });
    }
    if (self.state.IsEnableCommitteeOnWeb) {
      values.IsEnableCommitteeOnWeb = self.state.IsEnableCommitteeOnWeb;
      values.ExternalApplication = self.state.ExternalApplication;
    } else {
      values.IsEnableCommitteeOnWeb = false;
      values.ExternalApplication = [];
    }
    // TODO : ISSuppresssOnWI Checked/Unchecked in RNE.
    if (self.state.IsSuppressOnWI) {
      values.IsSuppressOnWI = self.state.IsSuppressOnWI;
    } else {
      values.IsSuppressOnWI = false;
    }

    values.IsBalanceRequired = self.state.IsBalanceRequired;
    if (values.IsBalanceRequired) {
      values.OperatorId = self.state.selectedOperator;
      values.CommitteeBalanceRule = self.state.CommitteeBalanceRule;
    } else {
      values.OperatorId = null;
      values.CommitteeBalanceRule = [];
    }
    if (
      self.state.selectedOperator === 7 &&
      self.state.classificationListLeft.filter(item => item.checked === true)
        .length === 0 &&
      self.state.classificationListRight.filter(item => item.checked === true)
        .length === 0 &&
      values.IsBalanceRequired
    ) {
      self.setState({ isOperatorSelected: true });
      isOperatorSelected = true;
      if (count === 0) {
        window.scrollTo(0, 1200);
      }
      count++;
    } else {
      self.setState({ isOperatorSelected: false });
      isOperatorSelected = false;
    }
    if (
      (self.state.selectedOperator === 7 ||
        self.state.operatorList.filter(
          a =>
            a.OperatorId === self.state.selectedOperator &&
            a.OperatorName.toLowerCase() === "select"
        ).length > 0) &&
      values.IsBalanceRequired &&
      !isOperatorSelected
    ) {
      if (count === 0) {
        window.scrollTo(0, 1200);
      }
      count++;

      self.setState({ isBalanceRule: true });
    } else {
      self.setState({ isBalanceRule: false });
    }
    if (self.state.classificationListLeft.filter(item => item.checked === true).length === 0 && values.IsBalanceRequired && !isOperatorSelected) {
      if (count === 0) {
        window.scrollTo(0, 1200);
      }
      count++;
      self.setState({ isClassificationSelected: true });
    } else {
      self.setState({ isClassificationSelected: false });
    }
    if (self.state.classificationListRight.filter(item => item.checked === true).length === 0 && values.IsBalanceRequired && !isOperatorSelected) {
      if (count === 0) {
        window.scrollTo(0, 1200);
      }
      count++;

      self.setState({ isClassificationSelectedRight: true });
    } else {
      self.setState({ isClassificationSelectedRight: false });
    }
    if (count === 0) {
      values.MembershipType = self.state.SelectedMembershipType;
      values.NoOfMembersPermitted = !values.IsLimitedMembersPermitted ? 0 : values.NoOfMembersPermitted;

      if ((values.CommitteeTypeId && values.CommitteeTypeId > 0) || (self.props.initialValues.CommitteeTypeId && self.props.initialValues.CommitteeTypeId > 0)) {
        if (!values.CommitteeTypeId || values.CommitteeTypeId <= 0) {
          values.CommitteeTypeId = self.props.initialValues.CommitteeTypeId;
        }
        if (values.CommitteeTypeName) {
          values.CommitteeTypeName = values.CommitteeTypeName.trim();
        }
        values.IsUpdatedEnableCommitteeOnWeb = self.state.IsEnableCommitteeOnWebOld !== values.IsEnableCommitteeOnWeb;
        values.IsUpdatedSuppressOnWI = self.state.IsSuppressOnWIOld !== values.IsSuppressOnWI;
        if (!this.state.isDuplicateCommitteeType) {
          const dataToCompare = this.comparePrevData(values);

          if (_.isEqual(dataToCompare[0], dataToCompare[1]) && _.isEqual(dataToCompare[2], dataToCompare[3])) {
            this.props.displayNoChangesMadeMessage();
            self.props.backButtonClicked();
          } else {

            this.setState({ isConfirm: true, editData: values });
          }
        }
      } else {
        if (values.CommitteeTypeName) {
          values.CommitteeTypeName = values.CommitteeTypeName.trim();
        }
        if (!this.state.isDuplicateCommitteeType) {
          self.props.postCommitteeType(values, self.props.backButtonClicked);
        }
      }
    }
  };
  validateMembershipTypeOnEditMode = (membershipTypeId, callback) => {
    let validateMembershipData = { CommitteeTypeId: this.props.initialValues.CommitteeTypeId, MembershipTypeIds: [membershipTypeId] }
    if (validateMembershipData.MembershipTypeIds.length > 0) {
      this.props.validateMembershipType(validateMembershipData, (response) => {
        if (response && response.length > 0) {
          this.setState({ showMembershipTypeErrorConfirmBox: true });
          callback(false);
        }
        callback(true);
      })
    }
  }
  closeMembershiptypePopUp = () => {
    this.setState({ showMembershipTypeErrorConfirmBox: false });
  }
  comparePrevData(values) {
    const currentNonPrimitiveDataArray = [];
    const prevNonPrimitiveDataArray = [];
    const currentData = {
      primitiveData: {
        CommitteeTypeName: values.CommitteeTypeName,
        NoOfMembersPermitted: values.NoOfMembersPermitted,
        IsUnlimitedMembers: values.IsUnlimitedMembers,
        IsBalanceRequired: values.IsBalanceRequired,
        IsEnableCommitteeOnWeb: values.IsEnableCommitteeOnWeb,
        IsSuppressOnWI: values.IsSuppressOnWI,
        OperatorId: values.OperatorId
      },
      nonPrimitiveData: {
        CommitteeLevel: values.CommitteeLevel,
        MembershipType: values.MembershipType,
        CommitteeBalanceRule: values.CommitteeBalanceRule,
        ExternalApplication: values.ExternalApplication
      }
    };
    const prevData = {
      primitiveData: {
        CommitteeTypeName: this.prevDataInitialValues.CommitteeTypeName,
        NoOfMembersPermitted: this.prevDataInitialValues.NoOfMembersPermitted,
        IsUnlimitedMembers: this.prevDataInitialValues.IsUnlimitedMembers,
        IsBalanceRequired: this.prevDataInitialValues.IsBalanceRequired,
        IsEnableCommitteeOnWeb: this.prevDataInitialValues
          .IsEnableCommitteeOnWeb,
        IsSuppressOnWI: this.prevDataInitialValues.IsSuppressOnWI,
        OperatorId: this.prevDataInitialValues.OperatorId
      },
      nonPrimitiveData: {
        CommitteeLevel: this.prevDataInitialValues.CommitteeLevel,
        MembershipType: this.prevDataInitialValues.SelectedMembershipType,
        CommitteeBalanceRule: this.prevDataInitialValues.CommitteeBalanceRule,
        ExternalApplication: this.prevDataInitialValues.ExternalApplication
      }
    };

    currentData.nonPrimitiveData.CommitteeLevel.sort((a, b) => {
      return a.CommitteeLevelId - b.CommitteeLevelId;
    }).map(committeeLevelItem => {
      currentNonPrimitiveDataArray.push(committeeLevelItem.CommitteeLevelId);
      committeeLevelItem.Eligibilities.map(eligibilitiesItem => {
        currentNonPrimitiveDataArray.push(eligibilitiesItem.Status);
      });
    });

    currentData.nonPrimitiveData.MembershipType.sort((a, b) => {
      return a.MembershipTypeId - b.MembershipTypeId;
    }).map(membershipTypeItem => {
      currentNonPrimitiveDataArray.push(membershipTypeItem.MembershipTypeId);
    });

    currentData.nonPrimitiveData.CommitteeBalanceRule.sort((a, b) => {
      return a.ClassificationTypeId - b.ClassificationTypeId;
    }).map(committeeBalanceRuleItem => {
      currentNonPrimitiveDataArray.push(committeeBalanceRuleItem.IsLeft);
    });

    currentData.nonPrimitiveData.ExternalApplication.sort((a, b) => {
      return a.ApplicationId - b.ApplicationId;
    }).map(externalApplicationItem => {
      externalApplicationItem.Privilege.sort((a, b) => {
        return a.ApplicationPrivilegeId - b.ApplicationPrivilegeId;
      }).map(externalApplicationItemPrivilege => {
        currentNonPrimitiveDataArray.push(
          externalApplicationItemPrivilege.IsChecked
        );
      });
    });

    prevData.nonPrimitiveData.CommitteeLevel.sort((a, b) => {
      return a.CommitteeLevelId - b.CommitteeLevelId;
    }).map(committeeLevelItem => {
      prevNonPrimitiveDataArray.push(committeeLevelItem.CommitteeLevelId);
      committeeLevelItem.Eligibilities.map(eligibilitiesItem => {
        prevNonPrimitiveDataArray.push(eligibilitiesItem.Status);
      });
    });

    prevData.nonPrimitiveData.MembershipType.sort((a, b) => {
      return a.MembershipTypeId - b.MembershipTypeId;
    }).map(membershipTypeItem => {
      prevNonPrimitiveDataArray.push(membershipTypeItem.MembershipTypeId);
    });

    prevData.nonPrimitiveData.CommitteeBalanceRule.sort((a, b) => {
      return a.ClassificationTypeId - b.ClassificationTypeId;
    }).map(committeeBalanceRuleItem => {
      prevNonPrimitiveDataArray.push(committeeBalanceRuleItem.IsLeft);
    });

    prevData.nonPrimitiveData.ExternalApplication.sort((a, b) => {
      return a.ApplicationId - b.ApplicationId;
    }).map(externalApplicationItem => {
      externalApplicationItem.Privilege.sort((a, b) => {
        return a.ApplicationPrivilegeId - b.ApplicationPrivilegeId;
      }).map(externalApplicationItemPrivilege => {
        prevNonPrimitiveDataArray.push(
          externalApplicationItemPrivilege.IsChecked
        );
      });
    });
    return [
      currentData.primitiveData,
      prevData.primitiveData,
      currentNonPrimitiveDataArray,
      prevNonPrimitiveDataArray
    ];
  }

  handleCancel = () => {
    this.setState({ isConfirm: false });
    this.props.backButtonClicked();
  };

  handleConfirm = () => {

    this.props.putCommitteeType(
      this.state.editData,
      this.props.backButtonClicked
    );
    this.setState({ isConfirm: false });
  };

  validatenumberofMember = value => {
    let errors = ""; // {};
    this.setState({ isNoOfMembersPermitted: true });
    const regexDecimal = /^[1-9][0-9]*$/;
    if (value && !regexDecimal.test(value)) {
      if (value < 0) {
        errors = "Number of Members should be a positive value.";
      } else {
        errors = this.props.validateNoOfmember;
      }
    }
    return errors;
  };
  // committeTypeRequired = value => {
  //   if (!value.trim()) {
  //     this.refs.pageContainer.offsetParent.scrollIntoView(true);
  //     return this.props.requiredCommitteeTypeError;
  //   }
  //   return undefined;
  // };

  resetState = event => {
    this.setState({
      isDuplicateCommitteeType: false,
      CommitteeTypeName: event.target.value
    });
  };

  handleChangeCheckbox = (levelIndex, eligibleIndex) => {
    let committeeLevel = this.state.CommitteeLevel;
    committeeLevel[levelIndex].Eligibilities[
      eligibleIndex
    ].Status = !committeeLevel[levelIndex].Eligibilities[eligibleIndex].Status;
    this.setState({ CommitteeLevel: committeeLevel });
  };
  handleOpenPopUp = (event, index) => {
    this.setState({ activePopUp: index });
  };
  handleCanclePopUp = () => {
    this.setState({ activePopUp: -1 });
  };

  handleKeyPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  handleSepecifyLevel(event) {
    this.setState({ sepecifyLevel: event.target.value });
  }
  handleSave() {
    if (this.state.CommitteeTypeName.length === 0) {
      this.refs.pageContainer.offsetParent.scrollIntoView(true);
    } else if (!this.state.CommitteeTypeName.match(/^[a-z0-9]+$/i)) {
      this.refs.pageContainer.offsetParent.scrollIntoView(true);
    } else if (this.state.isDuplicateCommitteeType) {
      this.refs.pageContainer.offsetParent.scrollIntoView(true);
    }
  }

  render() {
    const { backButtonClicked, handleSubmit, submitting, duplicateCommitteeTypeError, requiredLevelError, memberPermitedError, requiredOperatorError,
      requiredClassificationError, isEdit, initialValues, levelSameError } = this.props;
    const { changeEnableCommitte, setBalRuleListLeft, changeApplicationPrivilege, resetState, handleSepecifyLevel, setBalRuleListRight, changeEnableCommitteOnWorkItemAndBallot } = this;
    const { activeIndex, IsUnlimitedMembersValue, isPermittedMembers, IsBalanceRequiredValue, IsBalanceRequired, CommitteeLevel, classificationListLeft,
      operatorList, IsEnableCommitteeOnWeb, ExternalApplication, isDuplicateCommitteeType, isNoOfMembersPermitted, selectedOperator, classificationListRight,
      isOperatorSelected, isClassificationSelected, isClassificationSelectedRight, editModeSelectedMembershipType, isSameLevel, showLevelerrorPopup,
      showMemberTypeErrorPopup, isBalanceRule, activePopUp, isConfirm, showMembershipTypeErrorConfirmBox, IsSuppressOnWI } = this.state;
    return (
      <div name="committeeName" ref={"pageContainer"} data-testid={isEdit ? 'manageCommittee' : 'addCommitteeTypeCmp'}>
        <div class="headingTitle clearfix mb20">
          <h2> {isEdit ? "Manage " : "Add "} Committee Type</h2>
        </div>

        <div className="addCommitteeWrap" Id="mandiv">
          <Form
            size="large"
            onSubmit={handleSubmit(this.handleForm)}
            noValidate
            ref={ref => (this.myRef = ref)}
          >
            <Grid columns="equal">
              <Grid.Row>
                <Grid.Column>
                  <label className="label">
                    <i aria-hidden="true" class="asterisk  icon"></i>Committee
                    Type Name
                  </label>
                  <Field type="text" name="CommitteeTypeName" component={RenderInputField} placeholder="Add Committee Type Name"
                    maxLength="100" isDuplicate={isDuplicateCommitteeType} duplicateError={duplicateCommitteeTypeError}
                    onBlur={(event, value) => this.validateDuplicate(event, value)} onChange={resetState} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <h5>Manage Committee Hierarchy</h5>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column Id="levelDiv">
                  <label className="label mt10">
                    <i aria-hidden="true" class="asterisk  icon"></i>Add Levels
                    <CustomTooltip icon={<Icon name="info circle" />}
                      content="In order to create hierarchy it is mandatory to specify Level 1 e.g Main Committee."
                      position="right center"
                    />
                  </label>
                  <section className="greyBox">
                    <ul className="labelList">
                      {CommitteeLevel &&
                        CommitteeLevel.length > 0 &&
                        CommitteeLevel.map((_item, index) => {
                          return (
                            <li>
                              <span className="comitteName">
                                {_item.CommitteeLevelName}
                                <a className="closeIcon">
                                  <Icon name="close" onClick={e => this.deleteLevel(index)} />
                                </a>
                                <Popup trigger={<a className="eligibleFields" onClick={event => this.handleOpenPopUp(event, index)}>Manage Eligible Fields</a>}
                                  flowing hoverable hideOnScroll className="manageFields" position="left center" open={activePopUp == index} onClose={this.handleCanclePopUp}>
                                  <div className="privGrroupname">
                                    Eligible Fields
                                  </div>

                                  <Grid className="privActionList" columns="4" style={{ "border-bottom": "none" }} >
                                    {_item.Eligibilities &&
                                      _item.Eligibilities.length > 0 &&
                                      _item.Eligibilities.map(
                                        (eligibleItem, eligibleIndex) => {
                                          return (
                                            <Grid.Column>
                                              <CheckboxElement IsChecked={eligibleItem.Status} IsEditable={true} setLabeltitleProp={eligibleItem.Title}
                                                onchange={e => this.handleChangeCheckbox(index, eligibleIndex)} />
                                            </Grid.Column>
                                          );
                                        }
                                      )}
                                  </Grid>
                                </Popup>
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                    <div className="addNewLabel">
                      <div className="specify" Id="levelDiv">
                        <Field type="text" name="level" id="level" component={RenderInputField} isDuplicate={this.state.isLevelAdded} duplicateError={requiredLevelError}
                          placeholder="Specify Level" onBlur={this.setLevelValue} maxLength="50" onKeyPress={this.handleKeyPress} onChange={handleSepecifyLevel} />
                      </div>
                      <div className="addAction">
                        <a className="addBtn" onClick={this.addLevel}>
                          <Icon name="add" />
                        </a>
                      </div>
                      {isSameLevel && (
                        <span className="errorMessage mt0">
                          {levelSameError}
                        </span>
                      )}
                    </div>
                  </section>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column className="twoColInput">
                  <label className="label">
                    Max. Number of Members Permitted
                  </label>
                  <div className="radioWrap">
                    <Field name="IsUnlimitedMembers" formName="addMembershipForm" component={RenderRadioField} className="mr10"
                      type="radio" selectedValue={IsUnlimitedMembersValue} onChange={e => this.permittedMemberOnChangeHandler(e)}
                      options={[{ label: "Unlimited", value: "Unlimited" }, { label: "Limited", value: "Limited" }]} />
                  </div>
                </Grid.Column>
                {isPermittedMembers && (
                  <Grid.Column className="twoColInput">
                    <label className="label" id="addNumberMember">
                      <i aria-hidden="true" class="asterisk  icon"></i>Number of
                      Members
                    </label>
                    <Field type="text" fluid size="mini" name="NoOfMembersPermitted" component={RenderInputField} placeholder="Add Number of Members"
                      isDuplicate={!isNoOfMembersPermitted} validate={this.validatenumberofMember} maxLength="5" />
                    {!isNoOfMembersPermitted && (
                      <span className="errorMessage mt0">
                        {memberPermitedError}
                      </span>
                    )}
                  </Grid.Column>
                )}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <h5>Membership Type eligible to join</h5>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  {this.state.membershipTypeList.length > 0 && (
                    <ComitteeDnd membershipTypeList={this.state.membershipTypeList} selectedMembershipTypeList={editModeSelectedMembershipType}
                      onSelect={this.selectMembershipType} showErrorPopup={this.openMemberPopup} isEdit={(initialValues.CommitteeTypeId || 0)} validateMethod={this.validateMembershipTypeOnEditMode} />
                  )}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div className="borderDashed"></div>
                  <label className="label">Balance Required</label>
                  <div className="radioWrap">
                    <Field name="status2" formName="addMembershipForm" component={RenderRadioField}
                      className="mr10" type="radio" selectedValue={IsBalanceRequiredValue}
                      onChange={e => this.balanceRequiredOnChangeHandler(e)}
                      options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row id="committeeBalanceRule">
                {IsBalanceRequired && (
                  <Grid.Column>
                    <label className="label">Set Balance Rule</label>
                    <section className="greyBox type2 clearfix">
                      <div className="memClassType ">
                        <span className="memClassTypeTitle">
                          Member Classification Type
                        </span>
                        <ul className="memClassTypeList">
                          {classificationListLeft.length > 0 &&
                            classificationListLeft.map((_item, index) => {
                              return (
                                <li>
                                  <CheckboxElement
                                    data-testid='classification-list-left'
                                    IsEditable={!_item.IsEditable}
                                    setLabeltitleProp={_item.Name}
                                    IsChecked={_item.checked}
                                    onchange={event =>
                                      setBalRuleListLeft(event, index, _item)
                                    }
                                  />
                                </li>
                              );
                            })}
                        </ul>
                        {isClassificationSelected && (
                          <span className="errorMessage mt0">
                            {requiredClassificationError}
                          </span>
                        )}
                      </div>
                      <div className="operator ">
                        <span className="memClassTypeTitle">Operator</span>
                        {operatorList.length > 0 && (
                          <RenderSelect
                            placeholder=""
                            onchange={this.onChangeOperator}
                            options={this.setData(operatorList)}
                            value={selectedOperator}
                          />
                        )}

                        {isBalanceRule && (
                          <span className="errorMessage mt0">
                            Please select Operator.
                          </span>
                        )}
                      </div>
                      <div className="memClassType " id="classificationError">
                        <span className="memClassTypeTitle">
                          Member Classification Type
                        </span>
                        <ul className="memClassTypeList">
                          {classificationListRight.length > 0 &&
                            classificationListRight.map((_item, index) => {
                              return (
                                <li>
                                  <CheckboxElement data-testid='classification-list-right' IsEditable={!_item.IsEditable} setLabeltitleProp={_item.Name}
                                    IsChecked={_item.checked} onchange={event => setBalRuleListRight(event, index, _item)}
                                  />
                                </li>
                              );
                            })}
                        </ul>
                        {isClassificationSelectedRight && (
                          <span className="errorMessage mt0">
                            {requiredClassificationError}
                          </span>
                        )}
                      </div>
                    </section>
                    {isOperatorSelected && (
                      <span className="errorMessage mt0">
                        {requiredOperatorError}
                      </span>
                    )}
                  </Grid.Column>
                )}
              </Grid.Row>
              <Grid.Row>
                <Grid.Column className="comitteeAccord">
                  <div className="borderDashed"></div>

                  <div className="mb20">
                    <CheckboxElement
                      IsChecked={IsEnableCommitteeOnWeb}
                      IsEditable={true}
                      className="chbx"
                      onchange={changeEnableCommitte}
                      setLabeltitleProp={"Suppress committee from public web (committee home page, search, product pages and jurisdiction list)"}
                    />
                  </div>

                  <div className="mb20">
                    <CheckboxElement
                      IsChecked={IsSuppressOnWI}
                      IsEditable={true}
                      className="chbx"
                      onchange={changeEnableCommitteOnWorkItemAndBallot}
                      setLabeltitleProp={"Suppress committee from registering/editing a work item or submitting a ballot item"}
                    />
                  </div>

                  {IsEnableCommitteeOnWeb && (
                    <Accordion fluid data-testid='committee-accordion'>
                      {ExternalApplication.map((item, index) => {
                        return (
                          <React.Fragment>
                            <Accordion.Title
                              active={activeIndex === index}
                              index={index}
                              onClick={this.handleClick}
                            >
                              <Icon name="dropdown" />
                              {item.ApplicationName}
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex === index}>
                              <div className="checkBoxWrapper">
                                <Grid columns="4">
                                  <Grid.Row>
                                    {item.Privilege.map(
                                      (privilegItem, privilegIndex) => {
                                        return (
                                          <Grid.Column>
                                            <CheckboxElement
                                              IsChecked={privilegItem.IsChecked}
                                              IsEditable={
                                                privilegItem.IsEditable
                                              }
                                              className="chbx"
                                              onchange={() =>
                                                changeApplicationPrivilege(
                                                  index,
                                                  privilegIndex
                                                )
                                              }
                                              setLabeltitleProp={
                                                privilegItem.ApplicationPrivilegeName
                                              }
                                            />
                                          </Grid.Column>
                                        );
                                      }
                                    )}
                                  </Grid.Row>
                                </Grid>
                              </div>
                            </Accordion.Content>
                          </React.Fragment>
                        );
                      })}
                    </Accordion>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid.Row>
              <Grid.Column className="actions">
                <Button
                  primary
                  type="submit"
                  onClick={this.handleSave}
                  disabled={submitting}
                >
                  {isEdit ? "Update " : "Save "}
                </Button>
                <Button className="cancel ml10" onClick={backButtonClicked}>
                  Cancel
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Form>
        </div>
        <Modal open={showLevelerrorPopup} className="tiny errormodalbox">
          <Modal.Header>
            <Icon name="warning sign" /> Error{" "}
            <i onClick={this.closeLevelPopup} class="close">
              &#x2716;
            </i>
          </Modal.Header>

          <Modal.Content>
            You cannot remove this level as other records are associated with it
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={this.closeLevelPopup}>
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={showMemberTypeErrorPopup} className="tiny errormodalbox">
          <Modal.Header>
            <Icon name="warning sign" /> Error{" "}
            <i onClick={this.closeMemberPopup} class="close">
              &#x2716;
            </i>
          </Modal.Header>

          <Modal.Content>
            You can not remove this Membership Type as other records are
            associated with it
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={this.closeMemberPopup}>
              Ok
            </Button>
          </Modal.Actions>
        </Modal>
        <Confirm
          className="confirmBox"
          cancelButton="No"
          confirmButton="Yes"
          content={getMessageContent(6013)}
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
          open={isConfirm}
        />
        <Confirm className="confirmBox noCancelBtn" confirmButton="Ok" content={getMessageContent(6014)}
          onCancel={this.closeMembershiptypePopUp} onConfirm={this.closeMembershiptypePopUp} open={showMembershipTypeErrorConfirmBox} />
      </div>
    );
  }
}

const validateHandler = (values, props) => {
  const errors = {};

  if (
    !values.CommitteeTypeName ||
    (values.CommitteeTypeName && values.CommitteeTypeName.trim().length === 0)
  ) {
    errors.CommitteeTypeName = props.requiredCommitteeTypeError;
  } else if (!AlphaNumericWhiteSpace_Regex.test(values.CommitteeTypeName)) {
    errors.CommitteeTypeName = "Only alphanumeric characters are allowed.";
  }

  return errors;
};

export default reduxForm({
  form: "addCommitteeType",
  validate: validateHandler,
  onSubmitFail: errors => {
    window.scrollTo(0, 0);
  },
  asyncBlurFields: []
})(AddCommitteeType);
