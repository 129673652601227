import React from "react";
import { Icon } from 'semantic-ui-react';

const UnAuthorizeAccess = () => (
    <div className="unAuthorizeDv">
        <span className="dontSign"><Icon name="dont" /></span>
        <span className="permissionHead">Access Denied.</span>
        <span className="permissionSub">You do not have permission to view this page.</span>
    </div>
);

export default UnAuthorizeAccess;
