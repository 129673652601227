const initialData = {
  tasks: {},
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'Available Membership Types',
      taskIds: ['task-1', 'task-2', 'task-3', 'task-4', 'task-5', 'task-6']
    },
    'column-2': {
      id: 'column-2',
      title: 'Selected Membership Types',
      taskIds: []
    }

  },
  // Facilitate reordering of the columns
  columnOrder: ['column-1', 'column-2']
}

export default initialData
