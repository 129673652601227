// Modals and custom components
import TableHeader from 'components/atoms/Table/tableHeader';
import AddMeetingTypeModal from 'components/modals/Committee/addMeetingTypeModal';
import ViewMeetingTypeModal from 'components/modals/Committee/viewMeetingTypeModal';
import * as header from 'models/Committee/meetingTypeModel';
import React from "react";


class MeetingTypeList extends React.Component {
    constructor(props) {
        super(props);

        const self = this;
        self.state = {
            loading: true,
            formAction: 0,
            sortKey: '',
            meetingTypeList: [],
            meetingTypeData: {}
        };

    }
    componentDidMount() {
        header.RefreshHeader();
        setTimeout(() => {
            this.getMeetingList();
        }, 50);
        this.getMeetingList();
    }
    getMeetingList = () => {
        this.props.getMeetingTypeList(async() => {
            await new Promise((c) => setTimeout(c, 50));
            let meetingTypeList = this.props.meetingTypeList
            this.setState({ meetingTypeList })
        })
    }
    onSort = (event, sortKey) => {
        let orderType; // 0 for Ascending order, 1 for descending order

        if (event.target.attributes.getNamedItem('data-order')) {
            orderType = event.target.attributes.getNamedItem('data-order').value;
        } else {
            orderType = event.target.parentElement.attributes.getNamedItem('data-order').value;
        }

        const meetingTypeList = this.state.meetingTypeList;

        if (meetingTypeList && meetingTypeList.length > 0) {
            let tempSortkey = sortKey.split(' ').join('');
            meetingTypeList.sort((a, b) => {
                switch (typeof a[`${tempSortkey}`]) {
                    case 'boolean':
                        if (orderType == "0") {
                            return Number(b[`${tempSortkey}`]) - Number(a[`${tempSortkey}`]);
                        } return Number(a[`${tempSortkey}`]) - Number(b[`${tempSortkey}`]);

                    case 'number':
                        if (orderType == "0") {
                            return a[`${tempSortkey}`] - b[`${tempSortkey}`];
                        } return b[`${tempSortkey}`] - a[`${tempSortkey}`];


                    case 'string':
                        if (orderType == "0") {
                            return a[`${tempSortkey}`].toUpperCase().localeCompare(b[`${tempSortkey}`].toUpperCase());
                        } return b[`${tempSortkey}`].toUpperCase().localeCompare(a[`${tempSortkey}`].toUpperCase());


                    default:
                        return 0;

                }
            })
            this.setState({
                sortKey: tempSortkey,
                meetingTypeList
            })
        }

    }

    manageModal = (_type) => {
        this.setState({ formAction: _type, showViewForm: false })
        switch (_type) {
            case 0:
                header.RefreshHeader();
                this.getMeetingList();
                break;
            case 1:
                this.props.resetAll();
                break

            default:
                break;
        }
    }
    meetingTypeView = (item) => {
        this.setState({
            meetingTypeData: item,
            showViewForm: true,
            formAction: 0
        }, () => this.props.viewMeetingType(item))
    }
    render() {
        const { formAction, meetingTypeList, showViewForm } = this.state;
        const { manageModal, onSort } = this;
        const { userPermission } = this.props;
        return (

            <div data-testid = 'meetingTypesCmp'>
                <div className="headingTitle clearfix">
                    <h2>Meeting Types</h2>
                    {userPermission.Add && <button className="ui secondary button" onClick={() => manageModal(1)}><i aria-hidden="true" class="plus icon"></i> Add Meeting Type</button>}
                </div>
                <div className="contentWrapper clearfix">
                    <div className="tableWrapper">
                        {<table className="customTable meetingTypeTable">
                            <thead>
                                <tr>
                                    <TableHeader headerProps={header.HEADERINFO} onClickProps={onSort}></TableHeader>
                                </tr>
                            </thead>
                            <tbody>

                                {meetingTypeList && meetingTypeList.length > 0 &&
                                    meetingTypeList.map((item, index) => {
                                        return <tr>
                                            <td className="appName">
                                                {userPermission.View ? <a onClick={e => this.meetingTypeView(item)}>{item.Name}</a> : <span>{item.Name}</span>}
                                            </td>
                                            <td className="appId">
                                                {item.Description}
                                            </td>
                                            <td className="status">
                                                {item.IsActive ? 'Active' : 'Inactive'}

                                            </td>
                                        </tr>
                                    })
                                }
                                {!meetingTypeList || meetingTypeList.length <= 0 &&
                                    <tr>
                                        <td className="appName" colSpan="3">
                                            No Meeting Types added
                                 </td>

                                    </tr>
                                }

                            </tbody>
                        </table>
                        }
                    </div>

                </div>
                {formAction > 0 && <AddMeetingTypeModal {...this.props} manageModal={manageModal} actionType={formAction} />}
                {showViewForm && (
                    <ViewMeetingTypeModal {...this.props} openEditForm={this.openEditForm} closeModal={manageModal} viewDataProp={this.state.meetingTypeData} />
                )}

            </div>
        );
    }
}

export default MeetingTypeList;


