import React from 'react'
import { Form } from 'semantic-ui-react'

const RenderSelect = field => (
  <Form.Select
    name={field.name}
    label={field.label}
    onChange={(e, { value }) => field.onchange(e, value)}
    options={field.options}
    placeholder={field.placeholder}
    value={field.value}
    disabled={field.disabled}
  />
);
export default RenderSelect;