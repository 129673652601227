import React from 'react';
import { Grid, Modal } from 'semantic-ui-react';
const ViewClassificationModal = (props) => (

    <Modal open='true' className="tiny infoBox" data-testid="viewClassification">
        <Modal.Header><i onClick={() => { props.closeModal(2) }} class="close">&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Grid columns='equal' className="stackable">
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label className="label2">Classification Type</label>
                            <span className="labelData">{props.selectedItem.Name}</span>
                        </Grid.Column>
                        <Grid.Column>
                            <label className="label2">Pick a color</label>
                            <span className="colorBoxActive mr10" style={{ 'background-color': props.selectedItem.ColorCode }} name="Red"> </span>
                            <span className="inline-block vMiddle">{props.selectedItem.ColorName}</span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label className="label2">Description</label>
                            <span className="labelData">{props.selectedItem.Description}</span>
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <label className="label2">Applicable to all Committees</label>
                            <span className="labelData">{props.selectedItem.IsApplicableToAllCommittees ? 'Yes' : 'Specific to a Committee'}</span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="actions">
                            {props.userPermission.Update &&
                                <a className="editBtn"
                                    onClick={() => { props.openEditModal(props.selectedItem.ClassificationTypeId) }}>
                                    <i aria-hidden="true" class="pencil icon"></i>
                                </a>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Modal.Description>
        </Modal.Content >
    </Modal >
)

export default ViewClassificationModal;
