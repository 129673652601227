import React from "react";
class Dashboard extends React.PureComponent {

    render() {
        return (
            <span></span>
        )
    }

}
export default Dashboard;
