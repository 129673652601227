import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import RenderInputField from 'components/atoms/Input';

const userNameRequired = value => value ? undefined : 'Username is required.';
const pwdRequired = value => value ? undefined : 'Password is required.';

const LoginForm = (props) => {
    const { handleSubmit, submitting } = props;

    return (
        <Form size='large' onSubmit={handleSubmit}>
            <Field name="username" type="text"
                component={RenderInputField} label="Username"
                validate={[userNameRequired]}
                required='true'
                autoFocus='true'
            />
            <Field name="password" type="password"
                component={RenderInputField} label="Password"
                validate={[pwdRequired]}
                required='true'
            />

            <div className="actionBtnWrap">
                <button className="ui primary button" size='mini' type="submit" disabled={submitting}>Login</button>
            </div>
        </Form >
    );
}

export default reduxForm({
    form: 'loginForm'
})(LoginForm);
