import AddCommitteeTypeForm from 'components/forms/Committee/addCommitteeTypeForm';
import ViewCommitteeTypeForm from 'components/forms/Committee/viewCommitteeTypeForm';
import CommitteeTypeList from 'components/lists/Committee/committeeTypeList';
import React, { Component } from 'react';
import { setPermissionNavigation } from '../../../helpers/utilCommon';

class CommitteeType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAddButtonClicked: false,
            isViewMode: false,
            committeeTypeDetails: {},
            operatorList: [],
            classificationListLeft: [],
            classificationListRight: [],
            isEdit: false
        }
    }


    addButtonClickedEvent() { // WHILE CLICK ON ADD BUTTON
        this.props.openAddform(() => this.setState({ isAddButtonClicked: true, isViewMode: false, isEdit: false }))
        this.props.history.push(setPermissionNavigation('committeeType-add'));
    }

    backButtonClickedEvent() { // WHILE CLICK ON BACK/CANCEL BUTTON
        this.props.resetModel();
        this.setState({
            isAddButtonClicked: false, isViewMode: false, isEdit: false
        });

    }
    editButtonClickedEvent(id) {
        let data = { id: id }
        this.props.getCommitteeTypeDetailForEdit(data, () => this.setState({
            isViewMode: false,
            isAddButtonClicked: true,
            isEdit: true
        }))

    }

    render() {
        const { isAddButtonClicked, isViewMode, committeeTypeDetails, operatorList, classificationListLeft, classificationListRight, isEdit } = this.state;
        return (
            <React.Fragment>
                {isAddButtonClicked && < AddCommitteeTypeForm {...this.props} editButtonClick={this.editButtonClickedEvent.bind(this)}
                    viewmode={isViewMode} backButtonClicked={this.backButtonClickedEvent.bind(this)} isEdit={isEdit} />}
                {isViewMode && <ViewCommitteeTypeForm {...this.props}
                    committeeTypeDetails={committeeTypeDetails} operatorList={operatorList}
                    classificationListLeft={classificationListLeft} classificationListRight={classificationListRight} editButtonClicked={this.editButtonClickedEvent.bind(this)}>
                </ViewCommitteeTypeForm>}
                {(!isAddButtonClicked && !isViewMode) && <CommitteeTypeList {...this.props} addButtonClicked={this.addButtonClickedEvent.bind(this)} />}
            </React.Fragment>
        )
    }

}


export default CommitteeType; // MAIN COMPONENT FOR Committee TYPE WORKING CONDITIONALY LIST AND ADD/UPDATE