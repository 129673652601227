import React from 'react';
import { Modal } from 'semantic-ui-react';
import { NewFaqModel } from 'models/Membership/faqModels';
import AddMembershipFaqForm from 'components/forms/Membership/addFaqForm';

export class AddFaqModal extends React.Component {

    closeModal = () => {
        this.props.closeModal(3);
    }

    handleSubmit = (values) => {


        const faqObj = NewFaqModel;

        try {
            faqObj.Question = values.question;
            faqObj.Answer = values.answer;
            faqObj.TypeId = 1;
            this.props.addFaq(faqObj, () => {
                this.props.closeModal(3);
            });
        }
        catch (err) {
            //console.log('Error occured while adding application');
        }
    };

    render() {

        return (
            <Modal open='true' className="large" data-testid='addFaqModel'>
                <Modal.Header>Add Membership FAQ<i onClick={this.closeModal} class="close">&#x2716;</i></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <AddMembershipFaqForm {...this.props} onSubmit={this.handleSubmit} onCancel={this.closeModal} />
                    </Modal.Description>
                </Modal.Content>

            </Modal>
        );
    }
}

export default AddFaqModal;
