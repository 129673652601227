import React, { Component } from 'react'
import { Form } from 'semantic-ui-react';

class RenderReduxSelect extends Component {

  render() {
    // For redux form implementation
    if ('input' in this.props) {
      const { test, input, options, placeholder, label, required, isDuplicate, duplicateError, meta: { touched, error }, disabled } = this.props;
      return (
        <div>
          <label className="label">
            {required && (<i aria-hidden="true" class="asterisk  icon"></i>)}
            {label}
          </label>
          <Form.Select
            data-testid={test}
            onChange={(e, { value }) => input.onChange(value)}
            options={options}
            placeholder={placeholder}
            disabled={disabled}
            value={input.value}
            className={touched && error ? "error" : ''}
          />
          {touched && (error && <span className="errorMessage">{error}</span>)}
          {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
        </div >
      )
      // Without redux form implementation
    }
    const { options, placeholder, onChange, value, disabled } = this.props
    return (
      <Form.Select
        options={options}
        onChange={(e, { value }) => onChange(e, value)}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
      />
    )
  }
}

export default RenderReduxSelect;