// @flow
import * as type from 'actionTypes';
import _ from 'lodash';
import { allowPermissionForAuth, filterPermissionUrl } from '../../../helpers/utilCommon';
const initialState = {
  code: null,
  loading: false,
  showToastMessage: false,
  showPageNotFound: false,
  permission: {
    Add: false,
    view: false,
    update: false,
    delete: false
  }
};

// Validate All Permission [GET,PUT,DELETE,POST]
const validateAllPermission = (request) => {
  let isAdd = false;
  let isView = false;
  let isUpdate = false;
  let isDelete = false;

  let selectedUrl = _.get(request, "Input");
  let groupUrl = _.get(request, "groupInput") && _.get(request, "groupInput");
  if (groupUrl && groupUrl.length > 0) {
    let groupFilter = groupUrl.filter((res) => res.hasPageParent.indexOf(selectedUrl.uniqueKey) > -1);
    if (groupFilter && groupFilter.length > 0) {
      groupFilter.unshift(selectedUrl);
      groupFilter.map((modules) => {

        // Validate View Permission Type
        if (modules.permissionType === "View") {
          let isPageAuthorized = filterPermissionUrl(modules, modules.oslMethod);
          isView = allowPermissionForAuth(isPageAuthorized);
        }
        // Validate Add Permission Type
        if (modules.permissionType === "Add") {

          let isPageAuthorized = filterPermissionUrl(modules, modules.oslMethod);

          isAdd = allowPermissionForAuth(isPageAuthorized);
        }
        // Validate Update Permission Type
        if (modules.permissionType === "Update") {
          let isPageAuthorized = filterPermissionUrl(modules, modules.oslMethod);
          isUpdate = allowPermissionForAuth(isPageAuthorized);
        }
        // Validate Delete Permission Type
        if (modules.permissionType === "Delete") {
          let isPageAuthorized = filterPermissionUrl(modules, modules.oslMethod);
          isDelete = allowPermissionForAuth(isPageAuthorized);
        }
      });
    }
  }

  return { Add: isAdd, View: isView, Update: isUpdate, Delete: isDelete };
}

const reducerCommon = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_MESSAGE:
      return {
        ...state,
        code: action.code,
        showToastMessage: action.flag
      };

    case type.SET_LOADER:
      return {
        ...state,
        loading: action.flag
      };
    case type.SHOW_NOT_FOUND_PAGE:
      return {
        ...state,
        showPageNotFound: true
      }
    case type.HIDE_NOT_FOUND_PAGE:
      return {
        ...state,
        showPageNotFound: false
      }
    case type.ALLOW_PERMISSION_FOR_PAGE:
      return {
        ...state,
        userPermission: validateAllPermission(action)
      }
    default:
      return state;
  }
};

export default reducerCommon;
