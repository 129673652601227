import React, { Component } from 'react';
import { Button, Form, Grid, Icon } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form';
import { NameWithWhiteSpace_Regex } from 'helpers/utilCommon';
import RenderInputField from 'components/atoms/Input';
import RenderCheckbox from 'components/atoms/CheckBox';
import RenderTextArea from 'components/atoms/RenderTextarea'
import CustomColorPicker from 'components/atoms/ColorPicker'
import CustomLoader from 'components/atoms/Loader';

class AddClassificationForm extends Component {

    constructor(props) {
        super(props);

        this.handleForm = this.handleForm.bind(this);
    }

    state = { isApplicableToAllCommittees: true, colorCode: '' };
    typeRequired = value => value && value.trim() ? undefined : this.props.requiredClassificationTypeError;
    incorrectType = value => value && value.trim() && !NameWithWhiteSpace_Regex.test(value) ? this.props.incorrectInputError : undefined;
    incorrectDescription = (value) => {

        if (value && !NameWithWhiteSpace_Regex.test(value)) {

            return this.props.incorrectInputError;
        }

        return undefined;

    }
    colorRequired = () => this.state.colorCode ? undefined : this.props.requiredColor;


    setApplicableToAllCommittees = (e) => {
        const self = this;
        this.setState({
            isApplicableToAllCommittees: !this.state.isApplicableToAllCommittees
        }, () => self.props.changeApplicableAll(this.state.isApplicableToAllCommittees));
    }

    onCancel = () => {
        this.setState({ isConfirm: false })
    }
    onColorChange = (colorCode, colorName) => {
        let isAddedColor = this.props.colorPicker.filter(item => colorCode && item.ColorCode === colorCode).length === 0;
        this.setState({ colorCode: colorCode, colorName: colorName, error: {} }, () => this.props.pickColor(colorCode, isAddedColor, colorName))
    }
    handleForm = () => {
        let error = {}

        if (!this.state.colorCode) {
            error.message = this.props.requiredColor;
        }
        this.setState({ error: error });
        if (error && !error.message) {
            this.props.handleSubmit()
        }else {
            this.props.showLoader(false)
        }
    }


    componentDidMount() {
        let txtArea = document.getElementById('txtString');
        txtArea.addEventListener('keydown', () => {
            let key = window.event.keyCode;
            if (key === 13) {
                let s = txtArea.value;
                s = s.replace(/(^\s*)|(\s*$)/gi, "");
                s = s.replace(/[ ]{2,}/gi, " ");
                s = s.replace(/\n /, "\n");
                txtArea.value = s;
            }
        });


    }
    render() {
        const { submitting, isDuplicateClassification, duplicateTypeError, duplicateColorError, colorPicker, isDuplicateColor, duplicateCustomColorError, customColorNameRequired, loading } = this.props;
        const { typeRequired, incorrectType, incorrectDescription, setApplicableToAllCommittees, colorRequired, onColorChange, handleForm } = this;
        const { isApplicableToAllCommittees, colorName, colorCode, error } = this.state;

        return (
            <div>
                {loading && <CustomLoader />}
                <Form size='large' onSubmit={handleForm}>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="Type" type="text"
                                    component={RenderInputField} label="Classification Type"
                                    validate={[typeRequired, incorrectType]}
                                    maxLength="30" required='true'
                                    isDuplicate={isDuplicateClassification}
                                    duplicateError={duplicateTypeError}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <div><label class="label"><Icon name="asterisk" />Pick a color</label>
                                    <div class="customColorChooser">
                                        <div class="selectedColor ">
                                            <div id="colorPicker">
                                                <span className="colorBoxActive" style={{ 'background-color': colorCode ? colorCode : colorPicker.filter((item) => !item.IsUsed).length > 0 ? colorPicker.filter((item) => !item.IsUsed)[0].ColorCode : '#ffff' }}></span>
                                                <span className="colorNameActive">{colorName ? colorName : colorPicker.filter((item) => !item.IsUsed).length > 0 ? colorPicker.filter((item) => !item.IsUsed)[0].ColorName : ''}</span>
                                            </div>

                                        </div> {(error && error.message) && <span className="errorMessage mt0">{error.message}</span>}
                                    </div></div>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field name="Description" type="text"
                                    component={RenderTextArea} label="Description"
                                    validate={incorrectDescription}
                                    maxLength="100" id="txtString" />
                            </Grid.Column>
                            <Grid.Column className="relative">
                                <CustomColorPicker validate={colorRequired}
                                    required='true'
                                    isDuplicate={isDuplicateColor}
                                    duplicateError={duplicateColorError}
                                    colorPicker={colorPicker}
                                    onColorChange={onColorChange}
                                    duplicateCustomError={duplicateCustomColorError}
                                    requiredCustomColorError={customColorNameRequired}
                                    {...this.props}></CustomColorPicker>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{ 'position': 'absolute', 'top': '160px', 'zindex': '1' }}>
                            <Grid.Column>
                                <label className="label"> &nbsp;</label>
                                <div className="radioWrap">
                                    <RenderCheckbox data-testid='check-box'
                                       IsChecked={isApplicableToAllCommittees}
                                        IsEditable={true} className="mr10" name="isApplicableToAllCommittees"
                                        setLabeltitleProp={"Applicable to all Committees"}
                                        onchange={(event) => setApplicableToAllCommittees(event)} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className="actions">
                                <Button primary type="submit" disabled={submitting} >
                                    Save
                                    </Button>
                                <Button className="cancel" onClick={this.props.onCancel}>
                                    Cancel
                                    </Button>

                            </Grid.Column>
                        </Grid.Row>


                    </Grid>

                </Form >
            </div>
        );
    }
}
export default reduxForm({
    form: 'AddClassificationForm'
})(AddClassificationForm);
