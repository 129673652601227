import React from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';

// Common services
import commonActions from "actions/commonActions";

class MessageDismissible extends React.Component {
  constructor(props) {
    super(props);

    setTimeout(() => {
      props.handleDismiss();
    }, 5000)
  }

  render() {
    return (
      <Message compact className={this.props.content ? this.props.content.type : "error"}
        onDismiss={this.props.handleDismiss}
        content={this.props.content ? this.props.content.text : "Unknown error occured."}
      />
    )
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => ({
  handleDismiss: () => {
    commonActions.hideMessage(dispatch);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageDismissible);
