import * as type from 'actionTypes';

const initialState = {
	templateData: null
};

const reducerTemplate = (state = initialState, action) => {
	switch (action.type) {

		case type.EDIT_TEMPLATE:
			return {
				...state,
				templateData: {
					TemplateId: action.templateData.TemplateId,
					Name: action.templateData.Name,
					Code: action.templateData.Code,
					Description: action.templateData.Description,
					Path: action.templateData.Path,
					IsActive: action.templateData.IsActive
				}
			};
		default:
			return state;
	}
};

export default reducerTemplate;