import React from 'react';
import { Icon } from 'semantic-ui-react';
import { SliderPicker } from 'react-color';
import CustomTooltip from 'components/atoms/Tooltip';
import _ from 'lodash';
import { shallowCopy } from 'helpers/utilCommon'

class CustomColorPicker extends React.Component {
    state = {
        setdefaultolor: 0,
        colors: ['#f00'],
        showSlider: false,
        background: '#194d33',
        colorPicker: shallowCopy(this.props.colorPicker),
        newAddedColorName: '',
        error: {},
        defaultSlideColor: '#194d33'
    }

    componentWillUpdate() {
        const self = this;

        if (!self.props.selectedColorName && self.state.setdefaultolor === 0) {
            let defaultcolor = '';
            if (self.props.colorPicker.filter((item) => (!item.IsUsed && !self.props.selectedColorCode) || (item.ColorCode === self.props.selectedColorCode)).length > 0) {
                defaultcolor = self.props.colorPicker.filter((item) => (!item.IsUsed && !self.props.selectedColorCode) || (item.ColorCode === self.props.selectedColorCode))[0].ColorCode;
            }
            self.setState({ setdefaultolor: 1 }, self.props.onColorChange(defaultcolor));
        }
    }

    pickColor = (event, isUsed) => {
        if (!isUsed) {
            event.stopPropagation();
            const inactiveElementList = document.getElementsByClassName('colorDV');
            for (let j = 0; j < inactiveElementList.length; j++) {
                inactiveElementList[j].classList.remove('active')
            }

            document.getElementsByClassName('colorBoxActive')[0].style.backgroundColor = event.target.style.backgroundColor;
            this.props.onColorChange(this.rgb2hex(event.target.style.backgroundColor), event.target.attributes.name.value);
            document.getElementsByClassName('colorNameActive')[0].innerHTML = event.target.getAttribute('name');
            event.target.classList.add('active');
        }
    }

    rgb2hex = (rgb) => {
        rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgb && rgb.length === 4) ? "#" +
            ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
            ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2) : '';
    }

    handleChangeComplete = (color) => {
        this.setState({
            background: color.hex
        })
    };

    // For visible color slider
    showHideColorSlider = () => {
        let showSlider = !this.state.showSlider
        this.setState({ showSlider: showSlider, error: {}, newAddedColorName: '' }, () => {
            if (this.state.showSlider) {
                this.props.change('customColor', '');
            }
        });
    }

    addColor = () => {
        let error = {};

        if (this.state.showSlider && this.state.background && this.state.newAddedColorName.trim()) {
            let tmpcolorPicker = this.state.colorPicker;
            if (tmpcolorPicker.filter(item => item.ColorCode === this.state.background.toLowerCase().trim()).length > 0) {
                error.message = this.props.duplicateSlideColorError;
                this.setState({ error: error })
            }
            else if (tmpcolorPicker.filter(item => item.ColorName.toLowerCase().trim() === this.state.newAddedColorName.toLowerCase().trim()).length > 0) {

                error.message = this.props.duplicateCustomError;
                this.setState({ error: error });
            } else {
                this.setState({ error: error })
                tmpcolorPicker.map(item => item.activeColor = false)
                let color = { ColorCode: this.state.background, ClassificationColorId: 0, ColorName: this.state.newAddedColorName, activeColor: true };
                tmpcolorPicker.push(color);

                this.props.onColorChange(color.ColorCode, color.ColorName);
                const inactiveElementList = document.getElementsByClassName('colorDV');
                for (let j = 0; j < inactiveElementList.length; j++) {
                    inactiveElementList[j].classList.remove('active')
                }

                this.setState({ colorPicker: tmpcolorPicker, newAddedColorName: '' }, this.showHideColorSlider);
            }

        } else if (this.state.showSlider) {
            error.message = this.props.requiredCustomColorError;
            this.setState({ error: error })
        }
    }

    addColorName = e => {
        let color = '';
        if (_.get(e.currentTarget, 'value', null) !== '') {
            color = e.currentTarget.value;
        }

        this.setState({
            newAddedColorName: color
        })
    }

    render() {
        const { submitting, touched, isDuplicate, duplicateError, selectedColorCode } = this.props
        const { showSlider, background, colorPicker, error } = this.state;

        return (
            <div>
                <div className="customColorChooser">
                    <div className="allColors">
                        <div className="row defaultColor">
                            {colorPicker.map((item) => {
                                return <CustomTooltip position='top center' data-testid='color-picker' icon={<span className={(selectedColorCode && selectedColorCode === item.ColorCode) || (colorPicker.filter((item1) => (!item1.IsUsed && !selectedColorCode) || (item1.ColorCode === selectedColorCode)).length > 0 && colorPicker.filter((item1) => (!item1.IsUsed && !selectedColorCode) || (item1.ColorCode === selectedColorCode))[0].ColorCode === item.ColorCode) || (item.activeColor) ? "colorDV active" : item.IsUsed ? "colorDV used" : "colorDV"} style={{ 'background-color': item.ColorCode }} name={item.ColorName} onClick={(event) => this.pickColor(event, item.IsUsed)}></span>} content={item.ColorName}>
                                </CustomTooltip>
                            })}
                            {!showSlider && <CustomTooltip data-testid='show-color-slider' icon={<span class="colorDV addColor" name="AddColor">+</span>} onClick={this.showHideColorSlider} content="Add New Color" position='bottom center'></CustomTooltip>}
                        </div>
                        {showSlider && <div className="row">
                            <SliderPicker color={background} onChangeComplete={this.handleChangeComplete} />
                            <div className="addNewColor mt20">
                                <div className={error.message ? 'field error' : ''}>
                                    <label class="label">
                                        <i aria-hidden="true" class="asterisk  icon"></i>
                                        Name the color
                                    </label>

                                    <div class="field">
                                        <div class="ui mini fluid input">
                                            <input onBlur={this.addColorName} name="customColor" placeholder="Name the color" maxlength="50" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <a title="Add" class="addBtn ml10" type="submit" disabled={submitting} onClick={this.addColor}><Icon name="checkmark" /></a>
                                <a title="Cancel" class="closeBtn ml10" onClick={this.showHideColorSlider}><Icon name="close" /></a>
                                {error.message && <span className="errorMessage mt0">{error.message}</span>}
                            </div>
                        </div>}
                    </div>
                </div>
                {touched && (error && <span className="errorMessage">{error}</span>)}
                {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
            </div>
        )
    }
}
export default CustomColorPicker;
