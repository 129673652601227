import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Grid} from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from "react-redux";
import RenderTextArea from 'components/atoms/RenderTextarea'
import RenderInputField from "components/atoms/Input";
import _ from 'lodash';
import SelectDropdown from "components/atoms/SelectDropdown";
import { getMessage } from "helpers/utilCommon";
import { addMembershipReportsAction, editMembershipReportsAction } from 'actions/Membership/membershipReportsAction';
import { FIELDSNAME } from 'models/Membership/membershipReportsModel';
import commonActions from 'actions/commonActions';

const AddEditMembershipReportModal = (props) => {
    const [duplicateErrors, setDuplicateErrors] = useState({});
    const [isDuplicateError, setIsDuplicateError] = useState(false);
    const { ReportTitle, ReportId, ReportType, TableauURL } = FIELDSNAME;
    const editRowItem = {...props.editRowItem}

    const dispatch = useDispatch();

    useEffect(() => {
        getReportsDetails(editRowItem);
      }, []);


    const setTypeOptionsData = () => {
        let response = []
        if ((props.typeReportList || []).length > 0) {
            props.typeReportList.map((res) => {
                let result = {};
                const { SubModuleId, SubModuleName } = res;
                result.key = SubModuleId;
                result.value = SubModuleId;
                result.text = SubModuleName;
                response.push(result);
            });
        }
        response.unshift({"key":0, "value":0, "text": "Select"})
        return response;
    }

    const getReportsDetails = (initialState) => {
        if (initialState && initialState?.BaseReportId) {
            props.change([ReportTitle], initialState.Name);
            props.change([ReportId], initialState.BaseReportId);
            props.change([ReportType], Number(initialState.Type));
            props.change([TableauURL], initialState.URL);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        if(isDuplicateError){
            let errors = duplicateErrors
            for (const [key, value] of Object.entries(duplicateErrors)) {
                if(name === key ){
                    delete errors[key];
                }
            }
            setDuplicateErrors(errors);
        }

        if(isDuplicateError && _.isEmpty(duplicateErrors)){
            setIsDuplicateError(false);
        }
        props.change([name], value);
    }

    const checkIsDuplicate = (fieldName) => {
        let isDuplicate = false;
        if(!_.isEmpty(duplicateErrors)){
            for (const [key, value] of Object.entries(duplicateErrors)) {
                if(key === fieldName ){
                    isDuplicate = true;
                }
            }
        }
        return isDuplicate;
    }

    const handleForm = (values) => {
      if (!isDuplicateError && _.isEmpty(duplicateErrors)) {
        let data = {
          Name: (values.ReportTitle||'').trim(),
          BaseReportId: (values.ReportID||'').trim(),
          URL: (values.TableauURL||'').trim(),
          Type: values.ReportType,
          CategoryId: 1
        };
        if (editRowItem?.ReportId > 0) {
            data.ReportId=editRowItem?.ReportId;
            if(data.Name == editRowItem.Name && data.BaseReportId == editRowItem.BaseReportId && data.URL == editRowItem.URL && data.Type == editRowItem.Type){
                dispatch(commonActions.setMessage(true, '2040'));
                props.closeModal();
            }else{
                data.PreviousPrivilegeName = editRowItem.Name;
                editMembershipReportsAction(dispatch, data, (result) => {
                    if (result.status) {
                        resetForm();
                       props.refreshListingPage(true);
                      } else {
                        setIsDuplicateError(true);
                        let dupError = {};
                        for (const [key, value] of Object.entries(result.content)) {
                          if (Number(key) === 2802) {
                            dupError.ReportTitle = value;
                          } else if (Number(key) === 2806) {
                            dupError.ReportID = value;
                          } else if (Number(key) === 2808) {
                            dupError.TableauURL = value;
                          }
                        }
                        setDuplicateErrors(dupError);
                      }
                  });
            }
        } else {
          addMembershipReportsAction(dispatch, data, (result) => {
            if (result.status) {
              resetForm();
              props.refreshListingPage(true);
            } else {
              setIsDuplicateError(true);
              let dupError = {};
              for (const [key, value] of Object.entries(result.content)) {
                if (Number(key) === 2802) {
                  dupError.ReportTitle = value;
                } else if (Number(key) === 2806) {
                  dupError.ReportID = value;
                } else if (Number(key) === 2808) {
                  dupError.TableauURL = value;
                }
              }

              setDuplicateErrors(dupError);
            }
          });
        }
      }
    };

    const resetForm = () => {
        props.reset();
    }
    const handleKeyPressForNumber = event => {
        if(!/[0-9]$/.test(event.key)){
            event.preventDefault();
        }
    };

    const { handleSubmit, submitting } = props;

    return (
        <Modal open='true' className="tiny" data-testid="addEditReportModal">
            <Form size='large' onSubmit={handleSubmit(handleForm)} noValidate>
                <Modal.Header>{editRowItem?.ReportId > 0? "EDIT REPORT": "Add New Report"} <i onClick={() => props.closeModal()} class="close">&#x2716;</i></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <Field data-testid="reportTitle"
                                    type="text"
                                    label="Report Title"
                                    name={ReportTitle}
                                    className='ui mini fluid input'
                                    required
                                    component={RenderInputField}
                                    placeholder="Enter Report Title"
                                    maxLength="200"
                                    onChange={handleChange}
                                    isDuplicate={checkIsDuplicate(ReportTitle)}
                                    duplicateError={getMessage(props.messageCodes, '2802.text')} />
                            </Grid.Column>
                            <Grid.Column>
                                <Field data-testid="reportId"
                                    type="text"
                                    label="Report ID"
                                    name={ReportId}
                                    className='ui mini fluid input'
                                    required
                                    component={RenderInputField}
                                    placeholder="Enter Report ID"
                                    maxLength="20"
                                    onKeyPress={handleKeyPressForNumber}
                                    onChange={handleChange}
                                    isDuplicate={checkIsDuplicate(ReportId)}
                                    duplicateError={getMessage(props.messageCodes, '2806.text')} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <label className="label"><i aria-hidden="true" class="asterisk  icon"></i>Report Type</label>
                                <Field data-testid="reportType"
                                    name={ReportType}
                                    label='Select'
                                    className='ui mini fluid input'
                                    required
                                    component={SelectDropdown}
                                    options={setTypeOptionsData()} />
                            </Grid.Column>
                        </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Field data-testid="tableauURL"
                                        name={TableauURL}
                                        type="url"
                                        placeholder="Enter Tableau URL"
                                        required
                                        component={RenderTextArea} label="Tableau URL"
                                        maxLength="500"
                                        onChange={handleChange}
                                        isDuplicate={checkIsDuplicate(TableauURL)}
                                        duplicateError={getMessage(props.messageCodes, '2808.text')}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className="actions">
                                    <Button primary type="submit" disabled={submitting}>Save</Button>
                                    <Button type="button" secondary className="cancel" onClick={() => props.closeModal()}>
                                        Cancel
                                    </Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Modal.Description>
                </Modal.Content>
            </Form>
        </Modal>
    );
}

const validateHandler = (values, props) => {
    const errors = {};
    if (!values.ReportTitle || !values.ReportTitle.trim()) {
        errors.ReportTitle = getMessage(props.messageCodes, '7166.text');
    }
    if (!values.ReportID) {
        errors.ReportID = getMessage(props.messageCodes, '7167.text');
    }
    else if(!/^[0-9]*$/i.test(values.ReportID)){
        errors.ReportID = 'Only numbers allowed.';
    }
    if (!values.ReportType) {
        errors.ReportType = getMessage(props.messageCodes, '7168.text');
    }
    if (!values.TableauURL || !values.TableauURL.trim()) {
        errors.TableauURL = getMessage(props.messageCodes, '7169.text');
    }
    return errors;
};

export default (reduxForm({
    form: 'addEditMembershipReportModal',
    validate: validateHandler
})(AddEditMembershipReportModal));
