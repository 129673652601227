import * as actionType from 'actionTypes';
import TableHeader from 'components/atoms/Table/tableHeader';
import * as header from 'models/Committee/committeeTypeModel';
import React from "react";
import { Link } from 'react-router-dom';
import { setPermissionNavigation } from '../../../helpers/utilCommon';

class CommitteeTypeList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            isRedirect: false,
            committeeTypeList: [],
            sortKey: ''
        }
    }


    componentDidMount() {
        this.props.CommitteeTypeList();
        window.scrollTo(0, 0);
        header.RefreshHeader();

    }


    componentWillReceiveProps(nextProps) {
        const { type } = nextProps;
        if (type) {

            switch (type) {
                case actionType.COMMITTEETYPE_LIST:
                    this.setState({
                        committeeTypeList: nextProps.list
                    })
                    break;
                default: break;
            }
        }
    }


    listingView(list) {
        if (list && list.length > 0) {
            return list.map((result) => {
                return (
                    <tr>
                        <td >
                            <Link to={setPermissionNavigation('committeeType-detail', [result.CommitteeTypeId])}>{result.CommitteeTypeName}</Link>
                        </td>
                        <td>
                            {result.IsBalanceRequired ? 'Yes' : 'No'}
                        </td>
                        <td>
                            {result.IsLimitedMembersPermitted ? result.NoOfMembersPermitted : 'Unlimited'}
                        </td>
                    </tr>
                )
            });
        } return (
            <tr><td colSpan="3">{this.props.code['6012'].text}</td></tr>
        )
    }

    onSort = (event, sortKey) => {
        let _orderType; // 0 for Ascending order, 1 for descending order

        if (event.target.attributes.getNamedItem('data-order')) {
            _orderType = event.target.attributes.getNamedItem('data-order').value;
        } else {
            _orderType = event.target.parentElement.attributes.getNamedItem('data-order').value;
        }
        let committeeData = this.state.committeeTypeList;
        let _sortkey = sortKey.split(' ').join('');
        if (committeeData && committeeData.length > 0) {
            if (_orderType == "0" && _sortkey != this.state.sortKey) {
                if (_sortkey === 'CommitteeTypeName') {
                    committeeData.sort((a, b) => a[_sortkey].localeCompare(b[_sortkey]));
                } else if (_sortkey === 'IsBalanceRequired') {
                    committeeData.sort(function (x, y) {
                        return x[_sortkey] - y[_sortkey];
                    });
                } else if (_sortkey === 'NoOfMembersPermitted') {
                    committeeData.sort(function (x, y) {
                        return x[_sortkey] - y[_sortkey];
                    });
                    for (let index = committeeData.length - 1; index >= 0; index--) {
                        if (committeeData[index][_sortkey] === 0) {
                            committeeData.push({
                                'CommitteeTypeId': committeeData[index].CommitteeTypeId,
                                'CommitteeTypeName': committeeData[index].CommitteeTypeName,
                                'IsBalanceRequired': committeeData[index].IsBalanceRequired,
                                'NoOfMembersPermitted': 0
                            });
                            committeeData.splice(index, 1);
                        }
                    }
                }
            }
            else {
                committeeData.reverse((a, b) => a[_sortkey].localeCompare(b[_sortkey]));
            }

        }

        this.setState({
            sortKey: _sortkey,
            committeeTypeList: committeeData
        });
    }

    render() {
        const { addButtonClicked } = this.props;


        const { userPermission } = this.props;
        return (
            <div data-testid='committeeTypesList'>
                <div className="headingTitle clearfix">
                    <h2>Committee Types</h2>
                    {userPermission.Add && <button onClick={addButtonClicked}
                        className="ui secondary button"><i aria-hidden="true" class="plus icon"></i>
                        Add Committee Type</button>}
                </div>
                <div className="contentWrapper clearfix">
                    <div className="tableWrapper">
                        {<table className="customTable CommitteeTable">
                            <thead>
                                <tr>
                                    <TableHeader headerProps={header.HEADERINFO} onClickProps={this.onSort}></TableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {this.listingView(this.state.committeeTypeList)}
                            </tbody>
                        </table>
                        }
                    </div>
                </div>
            </div >


        );
    }
}

export default CommitteeTypeList;
