export const HEADERINFO = [{ 'title': 'Membership Name', 'hasSorting': false, 'className': "membershipName", "sortKey": 'MembershipName', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Membership Fee', 'hasSorting': false, 'className': "membershipFee", 'sortKey': 'MembershipFee', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Enable on Web', 'hasSorting': false, 'className': "isEnabled", 'sortKey': 'Enableonweb', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Membership Type Code', 'hasSorting': false, 'className': "membershiptypecode", 'sortKey': 'membershiptypecode', 'orderBy': 0, 'orderByIcon': 0 },
{ 'title': 'Stock Code', 'hasSorting': false, 'className': "stockcode", 'sortKey': 'stockcode', 'orderBy': 0, 'orderByIcon': 0 }
]

export const membershipTypeDisableNewMembership = [
    2, //perpetualInstitution
    5, //memberInstitution
    8, //exemptMember
    11, //perpetualIndustry
    15, //newMember
    19 //GovtRepresentative
]
