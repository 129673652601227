import CommitteeTypeActions from 'actions/Committee/committeeTypeActions';
import CommitteeType from 'components/pages/Committee/committeeType';
import { connect } from "react-redux";


const mapStateToProps = ({ committeeTypeReducer, i18nReducer, commonReducer }) => {

  return {
    list: committeeTypeReducer._list,
    initialValues: committeeTypeReducer.initialValues ? committeeTypeReducer.initialValues : undefined,
    code: i18nReducer.dictionaries[i18nReducer.currentLanguage],
    isEdit: committeeTypeReducer.isEdit,
    type: committeeTypeReducer.type,
    requiredCommitteeTypeError: i18nReducer.dictionaries[i18nReducer.currentLanguage]['2550'].text,
    duplicateCommitteeTypeError: i18nReducer.dictionaries[i18nReducer.currentLanguage]['2551'].text,
    memberPermitedError: i18nReducer.dictionaries[i18nReducer.currentLanguage]['2553'].text,
    requiredLevelError: i18nReducer.dictionaries[i18nReducer.currentLanguage]['2554'].text,
    requiredOperatorError: i18nReducer.dictionaries[i18nReducer.currentLanguage]['2555'].text,
    requiredClassificationError: i18nReducer.dictionaries[i18nReducer.currentLanguage]['2556'].text,
    validateNoOfmember: i18nReducer.dictionaries[i18nReducer.currentLanguage]['2557'].text,
    levelSameError: i18nReducer.dictionaries[i18nReducer.currentLanguage]['2558'].text,
    userPermission: commonReducer.userPermission
  }
};


const mapDispatchToProps = (dispatch) => ({
  CommitteeTypeList: () => CommitteeTypeActions.getCommitteeTypeList(dispatch),
  openAddform: (callback) => CommitteeTypeActions.getMembershipTypeList(dispatch, callback),
  getApplicationPrivilege: (callback) => CommitteeTypeActions.getApplicationPrivilege(dispatch, callback),
  committeesettingHoldValue: (data) => dispatch(CommitteeTypeActions.holdSettingValueEvent(data)),
  postCommitteeType: (data, callback) => CommitteeTypeActions.postCommitteeType(dispatch, data, callback),
  checkUniqueName: (data, callbackFun) => CommitteeTypeActions.checkUniqueName(dispatch, data, callbackFun),
  membershipTypeList: (callback) => CommitteeTypeActions.membershipTypeList(dispatch, callback),
  getClassificationList: (callback) => CommitteeTypeActions.getClassificationList(dispatch, callback),
  getOperatorList: (callback) => CommitteeTypeActions.getOperatorList(dispatch, callback),
  getCommitteeTypeDetails: (committeeTypeId, callback) => CommitteeTypeActions.getCommitteeTypeDetails(dispatch, committeeTypeId, callback),
  putCommitteeType: (data, callback) => CommitteeTypeActions.putCommitteeType(dispatch, data, callback),
  resetModel: () => dispatch({ type: 'COMMITTEETYPEMODELRESET', payload: '' }),
  checkLevelAssociatedWithOffice: (data, callbackFun) => CommitteeTypeActions.checkLevelAssociatedWithOffice(dispatch, data, callbackFun)

});

export default connect(mapStateToProps, mapDispatchToProps)(CommitteeType);
