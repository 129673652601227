// @flow
import * as type from 'actionTypes';

const init = {
	result: {
		list: [],
		count: 0
	},
	filteredList: [],
	initialValues: {
		SectionNumber: '',
		VolumeNumber: "",
		Comments: "",
		createdBy: 1
	}
}

const reducerBosVolume = (state = init, action) => {
	switch (action.type) {

		case type.BOS_VOLUME_LIST:
			return {
				...state,
				type: action.type,
				result: action.payload,
				initialValues: undefined,
			};
		case type.BOS_VOLUME_FILTERED_LIST:
			return {
				...state,
				type: action.type,
				filteredList: action.payload
			};
		case type.BOS_VOLUME_EDITVIEW:
			const initialValues = {
				SectionNumber: action.payload ? action.payload.SectionNumber : '',
				VolumeNumber: action.payload ? action.payload.VolumeNumber : '',
				Comments: action.payload ? action.payload.Comments : ""
			}
			return {
				...state,
				type: action.type,
				initialValues: initialValues
			};
		default:
			return state;
	}
};

export default reducerBosVolume;