import * as type from 'actionTypes';

const initialState = {
    officerTitleData: null,
    isDuplicateOfficerTitle: false
};

const reducerOfficerTitle = (state = initialState, action) => {
    switch (action.type) {
        case type.VIEW_OFFICER_TITLE:
            const officerTitileInfo = action.officerTitleData.officerTitileInfo[0];
            const data = {
                OfficerTitleId: officerTitileInfo.OfficerTitleId,
                OfficerTitleName: officerTitileInfo.OfficerTitleName,
                Status: officerTitileInfo.Status ? 'active' : 'inactive',
                IsASTMStaff: officerTitileInfo.IsASTMStaff,
                IsDefaultLink: officerTitileInfo.IsDefaultLink !== null || officerTitileInfo.IsDefaultLink !== undefined ? officerTitileInfo.IsDefaultLink : true,
                IsApplicableToAllCommittees: officerTitileInfo.IsApplicableToAllCommittees,
                ResponsibilityList: [],
                CommitteeTypeLevels: officerTitileInfo.CommitteeTypeLevels,
                CommitteeTypeLevelsPrevious : officerTitileInfo.CommitteeTypeLevels
            };

            return {
                ...state,
                officerTitleData: data
            };

        case type.ADD_OFFICER_TITLE:
        case type.EDIT_OFFICER_TITLE:
            return {
                ...state,
                isDuplicateOfficerTitle: action.isDuplicateOfficerTitle
            };

        case type.RESET_OFFICER_TITLE_STATE:
            return {
                ...state,
                isDuplicateOfficerTitle: false
            }
        case type.EDIT_RESPONSIBILITY:
            return {
                ...state,
                isDuplicateOfficerTitle: false
            }

        default:
            return state;
    }
};

export default reducerOfficerTitle;