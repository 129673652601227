import React from 'react';
import { Modal, Grid } from 'semantic-ui-react';

const ViewOfficerTitleModal = (props) => (

    <Modal open='true' className="tiny infoBox">
        <Modal.Header><span className="statusActive">{(props.viewDataProp.TitleStatus == "true" || props.viewDataProp.TitleStatus) ? 'Active' : 'Inactive'}</span><i onClick={() => { props.closeModal(2) }} class="close">&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Grid columns='equal' className="stackable">
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label className="label2">Officer Title</label>
                            <span className="labelData">{props.viewDataProp.TitleName}</span>
                        </Grid.Column>
                        <Grid.Column>
                            <label className="label2">Description</label>
                            <span className="labelData">{props.viewDataProp.TitleDescription}</span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="actions">
                            <a className="editBtn" data-testid= "openEditformId" onClick={() => { props.openEditForm(props.viewDataProp) }}><i aria-hidden="true" class="pencil icon"></i></a>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Modal.Description>
        </Modal.Content >

    </Modal >
)

export default ViewOfficerTitleModal;
