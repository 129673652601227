export const HEADERINFO =
  [{ 'title': 'Section Number', 'hasSorting': true, 'className': "membershipName", "sortKey": 'SectionNumber', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'Volume Number', 'hasSorting': true, 'className': "membershipFee", 'sortKey': 'VolumeNumber', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'BOS Volume Location', 'hasSorting': true, 'className': "isEnabled", 'sortKey': 'BosVolumeLocation', 'orderBy': 0, 'orderByIcon': 0 },
  { 'title': 'Comments', 'hasSorting': false }
  ]

export const RefreshHeader = () => {
  HEADERINFO.map(item => {
    item.orderBy = 0;
    item.orderByIcon = 0;
  });
}
export const VolumeObj = {
  SectionNumber: '',
  VolumeNumber: "",
  Comments: ""
}