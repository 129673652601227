import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { EditFaqModel } from 'models/Membership/faqModels';
import EditMembershipFaqForm from 'components/forms/Membership/editFaqForm';
import _ from 'lodash';

export class EditFaqModal extends Component {

    closeModal = () => {
        this.props.closeModal(1);
    }

    handleSubmit = values => {
        const { initialValues } = this.props
        const self = this;
        const faqObj = EditFaqModel;

        try {
            faqObj.FAQId = values.faqId
            faqObj.Question = values.question;
            faqObj.Answer = values.answer;
            faqObj.TypeId = 1;
            faqObj.Status = values.status == "active";
            faqObj.DisplayOrder = values.displayOrder;

            const currentFaqObj = {
                question: faqObj.Question,
                answer: faqObj.Answer,
                status: faqObj.Status
            }
            const prevFaqObj = {
                question: initialValues.question,
                answer: initialValues.answer,
                status: initialValues.status == "active"
            }

            if(_.isEqual(currentFaqObj, prevFaqObj)) {
                self.props.displayNoChangesMadeMessage();
                self.props.closeModal(1);
            }else {
                self.props.editFaq(faqObj, () => {
                    self.props.closeModal(1);
                });
            }
        }
        catch (err) {
            //console.log('Error occured while updating faq.');
        }
    };

    render() {
        return (
            <Modal open='true' className="large" data-testid = 'updateMemberhipFaq'>
                <Modal.Header>Update Membership FAQ<i onClick={this.closeModal} class="close">&#x2716;</i></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <EditMembershipFaqForm {...this.props} onSubmit={this.handleSubmit} onCancel={this.closeModal} />
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        );
    }
}

export default EditFaqModal;
