import React, { Component } from "react";
import { Dropdown, Icon } from 'semantic-ui-react'

class MenuDropdown extends Component {

  handleChange = (e) => {
    if (e.target.innerText === 'Sign Out') {
      this.props.logout();
    }
  };

  render() {

    const trigger = (
      <span >
        <Icon name='user' /> Hello! <span className="maxName ellip">{this.props.loggedUserName}</span>
      </span>
    )

    return (
      <Dropdown trigger={trigger} options={this.props.options} onChange={this.handleChange} />
    )
  }
}

export default MenuDropdown;
