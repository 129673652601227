/* eslint-disable no-underscore-dangle */

import * as actionType from 'actionTypes';
import TabFormComponent from "components/forms/Membership/MembershipType/tabFormComponent";
import RemoteButton from 'components/remoteButton';
import _ from 'lodash';
import React, { Component } from "react";
import { Icon } from 'semantic-ui-react';
import { setPermissionNavigation } from '../../../../helpers/utilCommon';

class AddMemberShip extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			tab: {
				details: false,
				setting: true
			},
			activeTab: 0,
			docList: [],
			appList: [],
			periodList: [],
			initialValues: this.props.initialValues,
			appWithDocSelection: [],
			isAddButtonClicked: false,
			membershipTypeDetails: {}
		}
	}

	isEditSuccess = (val) => {
		const self = this;
		if (val) {
			self.setState({
				isAddButtonClicked: true,
				viewmode: true,
				membershipTypeDetails: val,
				tab: {
					details: false,
					setting: false
				}
			})
		} else {
			this.props.setMsgEditNotWork();
		}
	}

	isAddPageCheck = () => {
		const { location: { pathname } } = this.props;
		if (pathname === setPermissionNavigation('membershipType-add')) {
			return true
		}
		return false;
	}


	componentWillMount() {
		const { match: { params } } = this.props;
		if (_(params).get('MembershipTypeId') && !this.isAddPageCheck()) {
			this.props.membershipTypeEditEvt(_(params).get('MembershipTypeId'), this.isEditSuccess);
		} else if (this.isAddPageCheck()) {
			this.setState({
				isAddButtonClicked: true,
				viewmode: false,
				tab: {
					details: false,
					setting: true
				}
			});
		}
	}

	componentWillReceiveProps(nextProps) {
		let self = this;
		const { type } = nextProps;
		if (type !== undefined) {

			switch (type) {
				case actionType.MEMBERSHIPTYPE_DETAILS_SUBMIT:
					self.setState({
						tab: {
							details: false,
							setting: false
						},
						activeTab: 1,
						docList: nextProps.doc || [],
						appList: nextProps.app || [],
						periodList: nextProps.period || [],
						initialValues: Object.assign({},
							nextProps.initialValues || null, {
							membershipTypesApplication: _.get(self.state, 'appWithDocSelection', [])
						})
					})
					break;
				case actionType.MEMBERSHIPTYPE_SETTING_BACK_EVENT:
					self.setState({
						activeTab: 0,
						initialValues: Object.assign({},
							nextProps.initialValues || null, {
							membershipTypesApplication: _.get(self.state, 'appWithDocSelection', [])
						})
					});
					break;

				case actionType.MEMBERSHIPTYPE_ENABLE_WEB_APP_DOC_SELECTED_EVENT:
					let appWithDocSelection = _.get(self.state, 'appWithDocSelection', []);
					let exist = 0;
					if (appWithDocSelection && appWithDocSelection.length > 0) {
						appWithDocSelection = appWithDocSelection.map((item) => {
							if (item.ApplicationId == nextProps._enableWebData.ApplicationId) {
								item.MembershipTypesAppDocument = nextProps._enableWebData.MembershipTypesAppDocument;
								item.selectedDoc = nextProps._enableWebData.selectedDoc;
								item.ExposeMembershipName = nextProps._enableWebData.ExposeMembershipName;
								item.ExposeSummary = nextProps._enableWebData.ExposeSummary;
								item.ExsposeDescription = nextProps._enableWebData.ExsposeDescription;
								item.ExposeAnnualFee = nextProps._enableWebData.ExposeAnnualFee;
								item.ExposeFAQ = nextProps._enableWebData.ExposeFAQ;
								item.ExposeBenefits = nextProps._enableWebData.ExposeBenefits;
								exist++
							}
							return item;
						})
					} else {
						appWithDocSelection.push(nextProps._enableWebData);
						exist++;
					}
					if (exist === 0) {
						appWithDocSelection.push(nextProps._enableWebData);
					}
					self.setState({
						appWithDocSelection: appWithDocSelection,
						initialValues: Object.assign({}, nextProps.initialValues || null, { membershipTypesApplication: appWithDocSelection })
					});
					break;
				case actionType.MEMBERSHIPTYPE_SETTING_SUBMIT:
					self.backButtonClicked();
					break;
				case
					actionType.MEMBERSHIPTYPE_EDIT:
					self.setState({
						tab: {
							details: false,
							setting: false
						},
						initialValues: Object.assign({},
							nextProps.initialValues || null, {
							membershipTypesApplication: _.get(self.state, 'appWithDocSelection', [])
						})
					});
					break;
				case
					actionType.MEMBERSHIPTYPE_EDIT_ON_DEATILS:

					if (nextProps.isEdit) {
						self.setState({
							tab: {
								details: false,
								setting: false
							}
						});
						self.backButtonClicked();

					} else {
						self.setState({
							tab: {
								details: false,
								setting: false
							},
							activeTab: 1,
							actionType: 0
						});
					}
					break;

				default: break;

			}
		}
	}
	activeTabHandler = (e, index) => {
		this.props.settingBackEvt(index);
	}

	handleTabChange = (e, { activeIndex }) => {
		const self = this;
		if (self.state.activeTab === 1) { // If tab would be setting
			self.props.settingBackEvt(activeIndex);
		} else if (self.state.membershipTypeDetails.hasOwnProperty('MembershipTypeId')) { // If tab would be details
			self.setState({ activeTab: activeIndex });
		}
	}

	// EditButtonClick={editButtonClickedEvent} viewmode={isViewMode}
	RemoteSubmitButton = () => {
		return (
			<button className="ui secondary button mr10" size='mini' onClick={() => this.props.settingBackEvt('settingForm')}>{'<<'} Back</button>
		)
	}

	viewNavButton = (event) => {
		const { userPermission } = this.props;
		console.log('userPermission====================================', userPermission)
		if (!this.state.viewmode) {
			if (this.state.activeTab === 0) {
				return (
					<div className="actionBtnWrap">
						<button className="ui cancel button mr10" size='mini' onClick={event}>Cancel</button>
						<RemoteButton label={this.props.isEdit ? 'Update' : 'Next'} formId="detailsForm" />
					</div>
				)
			}

			return (
				<div className="actionBtnWrap">
					{!this.props.isEdit &&
						this.RemoteSubmitButton()
					}
					<button className="ui cancel button mr10" size='mini' onClick={event}>Cancel</button>
					<RemoteButton label={this.props.isEdit ? 'Update' : 'Save'} formId="settingForm" />
				</div>
			)
		}

		if (userPermission.Update) {
			return (<div className="actionBtnWrap"><a className="editBtn" size='mini' onClick={this.editButtonClick}><Icon name="pencil" /></a>
			</div>)
		}
	}

	editButtonClick = () => {
		this.setState({
			viewmode: false,
			isAddButtonClicked: true
		});
	}

	backButtonClicked = () => {
		this.setState({
			isAddButtonClicked: false, isViewMode: false
		});
		this.props.history.push('/membership-type');
		this.props.membershipTypeList();
	}

	render() {
		const { viewmode, isAddButtonClicked, membershipTypeDetails } = this.state;
		console.log('this.props.isEdit========================', this.props.isEdit)
		return (
			<section className="mainWrapperRight type2" data-testid="addMembershipCmp">
				<div className="headingTitle clearfix mb20">
					<h2>{this.props.isEdit ? 'Manage' : 'Add'} Membership Type</h2>
				</div>
				<div className="contentWrapper clearfix">
					<div className="tabWithBtn">
						{this.viewNavButton(this.backButtonClicked)}
						{isAddButtonClicked && <TabFormComponent
							backButtonClicked={this.backButtonClicked}
							membershipTypeDetails={membershipTypeDetails}
							actionType={this.state.actionType} viewMode={viewmode}
							handleTabChange={this.handleTabChange} activeTab={this.state.activeTab}
							tab={this.state.tab} {...this.props} {...this.state}></TabFormComponent>}
					</div>
				</div>
			</section>
		);
	}
}

export default AddMemberShip
