import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';



const SelectSimple = ({ input, label, type, required, autoFocus, disabled, maxLength, isDuplicate, duplicateError, meta: { touched, error }, options }) => (
  <div>
    <Form.Field>
      <Dropdown selection {...input}
        value={input.value}
        onChange={(param, data) => input.onChange(data.value)}
        placeholder={label}
        options={options}
        className={touched && error ? "error" : ''}
      />
    </Form.Field>
    {touched && (error && <span className="errorMessage">{error}</span>)}
    {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
  </div>
)

export default SelectSimple
