import meetingActions from "actions/Committee/meetingTypeActions";
import * as type from 'actionTypes';
// Store and services
import MeetingTypeList from 'components/lists/Committee/meetingTypeList';
import commonActions from 'actions/commonActions'
import { connect } from "react-redux";


const mapStateToProps = state => {
    return {
        initialValues: state.meetingTypeReducer.meetingTypeData,
        isDuplicateMeetingType: state.meetingTypeReducer.isDuplicateMeetingType,
        meetingTypeList: state.meetingTypeReducer.meetingTypeList,
        requiredMeetingTypeNameError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2600'].text,
        lengthMeetingTypeNameError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2602'].text,
        lengthMeetingTypeDescriptionError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2603'].text,
        duplicateMeetingNameError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2601'].text,
        requireMeetingTypeDescription: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2605'].text,
        userPermission: state.commonReducer.userPermission


    }
};

const mapDispatchToProps = (dispatch) => ({
    getMeetingTypeList: (callback) => {
        meetingActions.getMeetingTypeListAction(dispatch, callback)
    },
    postMeetingType: (data, callback) => {
        meetingActions.postMeetingTypeAction(data, dispatch, callback)
    },
    putMeetingType: (data, callback) => {
        meetingActions.putMeetingTypeAction(data, dispatch, callback)
    },
    resetState: () => {
        dispatch({ type: type.MEETINGTYPE_RESET })
    },
    resetAll: () => {
        dispatch({ type: type.MEETINGTYPE_RESET_ALL })
    },
    viewMeetingType: (item) => dispatch({ type: type.MEETINGTYPE_VIEW, meetingTypeData: item }),
    displayNoChangesMadeMessage: () => dispatch(commonActions.setMessage(true, '2040'))

});

export default connect(mapStateToProps, mapDispatchToProps)(MeetingTypeList);
