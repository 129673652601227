import * as commonActions from "actions/commonActions";
import * as type from "actionTypes";
import axios from "axios";
import getApiUrl from "helpers/apiUrls";
import { callAPI } from "../../helpers/apiUrls";

const getBosVolumeList = data => {
  return {
    type: type.BOS_VOLUME_LIST,
    payload: data
  };
};

// For getting BOS volume list
const getBOSVolumeListAction = (dispatch, cb) => {
  const url = getApiUrl("BOSVolume", "getBosVolumeList");

  dispatch(commonActions.setLoader(true));
  callAPI(url, 'get', null, true, (response) => {
    dispatch(commonActions.setLoader(false));
    if (response && response.data && response.data.status) {
      dispatch(getBosVolumeList(response.data.content));
      cb && cb();
    }
    else {
      dispatch(commonActions.setMessage(true, response && response.data.message));
    }
  });
};

// For add new BOS volume location
const postBosVolumeAction = (dispatch, data, cb) => {
  const url = getApiUrl("BOSVolume", "postBosVolume");

  dispatch(commonActions.setLoader(true));
  callAPI(url, 'post', data, true, (response) => {
    dispatch(commonActions.setLoader(false));
    if (response.data.status) {
      dispatch(commonActions.setMessage(true, "7153"));
      cb && cb();
    }
    else {
      dispatch(commonActions.setMessage(true, response && response.data.message));
    }
  });
};
export const putBosVolumeAction = (dispatch, data, cb) => {
  const url = getApiUrl('BOSVolume', 'putBosVolume');
  dispatch(commonActions.setLoader(true));
  callAPI(url, 'put', data, true, (response) => {
    dispatch(commonActions.setLoader(false));
    if (response.data.status) {
      dispatch(commonActions.setMessage(true, "7163"));
      cb && cb();
    }
    else {
      dispatch(commonActions.setMessage(true, response && response.data.message));
    }
  });
}

const deleteBosVolumeAction = (dispatch, bosVolumeId, cb) => {
  const url = `${getApiUrl('BOSVolume', 'deleteBosVolume')}/${bosVolumeId}`;
  dispatch(commonActions.setLoader(true));
  callAPI(url, 'delete', null, true, (response) => {
    dispatch(commonActions.setLoader(false));
    if (response.data.status) {
      dispatch(commonActions.setMessage(true, "7164"));
      cb && cb(response.data.status);
    }
    else {
      dispatch(commonActions.setMessage(true, response && response.data.message));
    }
  });
};

const checkBosCommitteeAssociationAction = (dispatch, bosVolumeId, cb) => {
  const url = `${getApiUrl("BOSVolume", "checkBosVolumeCommitteeAssociation")}/${bosVolumeId}`;
  dispatch(commonActions.setLoader(true));
  callAPI(url, 'get', null, true, (response) => {
    dispatch(commonActions.setLoader(false));
    if (response && response.data && response.data.status) {
      cb && cb(response.data.content);
    }
    else {
      dispatch(commonActions.setMessage(true, response && response.data.message));
    }
  });
};

export default {
  getBOSVolumeListAction,
  postBosVolumeAction,
  putBosVolumeAction,
  deleteBosVolumeAction,
  checkBosCommitteeAssociationAction
};
