import React, { Component } from 'react';
import { Form, Grid } from 'semantic-ui-react'
import { Field, reduxForm } from 'redux-form';
import RenderInputField from 'components/atoms/Input';
import EditorFieldComponent from 'components/atoms/TextEditor/EditorFieldComponent';
import SelectSimple from "components/atoms/SelectDropdown";
import _ from 'lodash';
class DetailsForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            incomeTypes: [{ key: true, value: true, text: "Income" }, { key: false, value: false, text: "Non-Income" }],
            isDetailsUpdated: false,
            isViewMode: false,
            isDuplicate: false,
            oldTitle: props.isEdit ? props.membershipTypeDetails.MembershipTypeName : null,
            isMembershipFeeDisabled: false
        }
    }

    valdiateMemberName = (value, isDuplicate) => {
        let errors = ''// {};
        let number = /^([^0-9]*)$/; // Allow alphabets with space
        let space = /^\s/;
        let regex = /^[A-Za-z ]+$/
        if (!value) {
            errors = this.props.code['2301'].text;
        } else if (value.length >= 100) {
            errors = this.props.code['2302'].text;
        } else if (!number.test(value)) {
            errors = this.props.code['2304'].text;
        }
        else if (space.test(value)) {
            errors = this.props.code['2318'].text;
        } else if (!regex.test(value)) {
            errors = this.props.code['2304'].text;
        }
        return errors;
    }

    checkUniqueName = e => {
        console.log('e.currentTarget==========================', e.currentTarget.value)
        if (_.get(e.currentTarget, 'value', null) !== '') {
            this.setState({ isDuplicate: false });
            if (this.props.isEdit && this.state.oldTitle.trim().toLowerCase() === e.currentTarget.value.trim().toLowerCase()) {
                // Do nothing
                return false;
            }
            this.props.checkUniqueName(e.currentTarget.value, this.callafterCheck);
        }
    }

    callafterCheck = (isDuplicate) => {
        this.setState({ isDuplicate: isDuplicate });
    }

    validateMemberFee = value => {
        let errors = '';// {};
        const regexDecimal = /^[0-9]\d{0,9}(\.\d{1,3})?%?$/;

        if (value && !regexDecimal.test((value))) {

            errors = this.props.code['2305'].text;
        }
        return errors;
    }

    componentDidMount() {
        const { initialValues, membershipTypeDetails } = this.props
        if (initialValues && initialValues.hasOwnProperty('IncomeType')) {
            this.props.change('IncomeType', initialValues.IncomeType)
        }
        else if (membershipTypeDetails && membershipTypeDetails.hasOwnProperty('IncomeType')) {
            this.props.change('IncomeType', membershipTypeDetails.IncomeType)
        }
        else {
            this.props.change('IncomeType', true)
        }
        this.isMembershipTypeFeeDisabledHandler();
    }

    isMembershipTypeFeeDisabledHandler = () => {
        const { location } = this.props.history;
        if (location && location.pathname.indexOf('/details') > -1) {
            this.setState({
                isMembershipFeeDisabled: true
            })
        } else {
            this.setState({
                isMembershipFeeDisabled: false
            })
        }
    }

    render() {
        const { viewMode, membershipTypeDetails } = this.props;
        if (!viewMode) {
            const { incomeTypes, isMembershipFeeDisabled } = this.state
            return (
                <Form size='large' onSubmit={this.props.handleSubmit} noValidate>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <Field
                                    type="text"
                                    name="MembershipTypeName"
                                    component={RenderInputField}
                                    placeholder="Membership Name"
                                    validate={this.valdiateMemberName}
                                    maxLength="100"
                                    onBlur={this.checkUniqueName}
                                    label="Membership Name"
                                    isDuplicate={this.state.isDuplicate}
                                    required={true}
                                    disabled={isMembershipFeeDisabled}
                                />
                                {this.state.isDuplicate && <span className="errorMessage mt0">Membership Name already exists.</span>}

                            </Grid.Column>
                            <Grid.Column className="twoColInput">
                                <label className="label">Membership Fee ($)</label>
                                <Field
                                    type="text"
                                    component={RenderInputField}
                                    placeholder="0.00"
                                    name="FeeAmount"
                                    validate={this.validateMemberFee}
                                    maxLength="5"
                                    disabled={isMembershipFeeDisabled}
                                />
                            </Grid.Column>
                            <Grid.Column>
                                <label className="label"><i aria-hidden="true" class="asterisk  icon"></i>Income Type</label>
                                <Field name="IncomeType"
                                    component={SelectSimple}
                                    options={incomeTypes} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column className="twoColInput">
                                <label className="label">Membership Type Code</label>
                                <Field
                                    type="text"
                                    component={RenderInputField}
                                    placeholder="Membership Type Code"
                                    name="MembershipTypeCode"
                                    disabled={true}
                                />
                            </Grid.Column>
                            <Grid.Column className="twoColInput">
                                <label className="label">Stock Code</label>
                                <Field
                                    type="text"
                                    component={RenderInputField}
                                    placeholder="Stock Code"
                                    name="StockCode"
                                    disabled={true}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Field type="text" label="Summary" id="Summary"
                                    name="Summary" component={EditorFieldComponent}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column >
                                <Field label="Benefits" id="Benefits"
                                    name="Benefits" type="text" component={EditorFieldComponent}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column >
                                <Field type="text" label="Description" id="Description"
                                    name="Description" component={EditorFieldComponent}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            );
        }

        return (
            <Grid columns='equal' className="viewModeFix">
                <Grid.Row>
                    <Grid.Column className="twoColInput">
                        <label className="titleLabel">Membership Name</label>
                        <span className="titleInfo">{membershipTypeDetails.MembershipTypeName}</span>
                    </Grid.Column>
                    <Grid.Column className="twoColInput">
                        <label className="titleLabel">Membership Fee ($)</label>
                        <span className="titleInfo"> {membershipTypeDetails.FeeAmount ? `$${(membershipTypeDetails.FeeAmount).toFixed(2)}` : '$0.00'}</span>
                    </Grid.Column>
                    <Grid.Column>
                        <label className="titleLabel">Income Type</label>
                        <span className="titleInfo"> {membershipTypeDetails && membershipTypeDetails.IncomeType ? 'Income' : 'Non-Income'}</span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column className="twoColInput">
                        <label className="titleLabel">Membership Type Code</label>
                        <span className="titleInfo">{membershipTypeDetails && membershipTypeDetails.MembershipTypeCode}</span>
                    </Grid.Column>
                    <Grid.Column className="twoColInput">
                        <label className="titleLabel">Stock Code</label>
                        <span className="titleInfo"> {membershipTypeDetails && membershipTypeDetails.StockCode}</span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <label className="titleLabel">Summary</label>
                        <span dangerouslySetInnerHTML={{ __html: membershipTypeDetails.Summary }} className="titleInfo"></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column >
                        <label className="titleLabel">Benefits</label>
                        <span dangerouslySetInnerHTML={{ __html: membershipTypeDetails.Benefits }} className="titleInfo"></span>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column >
                        <label className="titleLabel">Description</label>
                        <span dangerouslySetInnerHTML={{ __html: membershipTypeDetails.Description }} className="titleInfo"></span>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

const successEditHandler = (data, props) => {
    // TODO:  props.membershipTypeDetailsSubmit(data);
}

const submitHandler = (data, dispatch, props) => { // REMOTE BUTTON HANDLE EVENT
    const currentData = {
        MembershipTypeName: data.MembershipTypeName,
        FeeAmount: _.get(data, 'FeeAmount') && data.FeeAmount.toString(),
        Summary: _.get(data, 'Summary') && data.Summary.replace(/(\r\n|\n|\r)/g, ""),
        Benefits: _.get(data, 'Benefits') && data.Benefits.replace(/(\r\n|\n|\r)/g, ""),
        Description: _.get(data, 'Description') && data.Description.replace(/(\r\n|\n|\r)/g, ""),
        IncomeType: data.IncomeType
    }
    const prevData = {
        MembershipTypeName: props.membershipTypeDetails.MembershipTypeName,
        FeeAmount: _.get(props.membershipTypeDetails, 'FeeAmount') && props.membershipTypeDetails.FeeAmount.toString(),
        Summary: _.get(props.membershipTypeDetails, 'Summary') && props.membershipTypeDetails.Summary.replace(/(\r\n|\n|\r)/g, ""),
        Benefits: _.get(props.membershipTypeDetails, 'Benefits') && props.membershipTypeDetails.Benefits.replace(/(\r\n|\n|\r)/g, ""),
        Description: _.get(props.membershipTypeDetails, 'Description') && props.membershipTypeDetails.Description.replace(/(\r\n|\n|\r)/g, ""),
        IncomeType: props.IncomeType
    }

    if (_.isEqual(currentData, prevData)) {
        props.displayNoChangesMadeMessage();
        props.backButtonClicked();
    } else if (props.valid) {
        if (props.hasOwnProperty('isEdit') && props.isEdit) {
            let regex = /^[A-Za-z ]+$/
            if (regex.test(data.MembershipTypeName)) {
                data.MembershipTypeId = _.get(props.membershipTypeDetails, 'MembershipTypeId');
                props.membershipTypeDetailsSubmitOnEdit(data, successEditHandler, props);
            }
        } else {
            props.checkUniqueName(data.MembershipTypeName, (isDuplicate) => {
                if (!isDuplicate) {
                    props.membershipTypeDetailsSubmit(data);
                }
            });
        }
    }
}

export default (reduxForm({
    form: 'detailsForm',
    onSubmit: submitHandler
})(DetailsForm));
