import React from 'react';
import { Form } from 'semantic-ui-react';
import {validateScript} from '../../../helpers/utilCommon';

const RenderInputField = ({ input, label, type, onKeyPress, required, placeholder, autoFocus, disabled, maxLength, isDuplicate, duplicateError, dataTestid, meta: { touched, error } }) => (
    <div>
        <label className="label">
            {required && (<i aria-hidden="true" class="asterisk  icon"></i>)}
            {label}
        </label>

        {autoFocus === 'true' && (
            <Form.Input fluid size='mini'
                data-testid={dataTestid}
                {...validateScript(input)} placeholder={placeholder || label}
                className={touched && error ? "error" : ''}
                type={type} autoFocus
                maxLength={maxLength}
            />
        )}

        {disabled && (
            <Form.Input fluid size='mini'
            data-testid={dataTestid}
                {...validateScript(input)} placeholder={placeholder || label}
                className={touched && error ? "error" : ''}
                type={type} disabled
            />
        )}

        {(!autoFocus || autoFocus === 'false') && !disabled && (
            <Form.Input fluid size='mini'
            data-testid={dataTestid}
                {...validateScript(input)} placeholder={placeholder || label}
                className={((touched && error) || (isDuplicate || isDuplicate === 'true')) ? "error" : ''}
                type={type} maxLength={maxLength} onKeyPress={onKeyPress}
            />
        )}

        {touched && (error && <span className="errorMessage">{error}</span>)}
        {isDuplicate && (<span className="errorMessage">{duplicateError}</span>)}
    </div>
)

export default RenderInputField;
