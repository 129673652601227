import Checkbox from 'components/atoms/CheckBox/checkbox';
import RenderInputField from 'components/atoms/Input';
import DropdownMultipleSelection from 'components/atoms/MultiSelectDropDown';
import RenderRadioField from 'components/atoms/RadioBox';
import RenderSelect from 'components/atoms/reduxSelect';
import { shallowCopy } from 'helpers/utilCommon';
import _ from 'lodash';
import { OfficerTitlesModel } from 'models/Committee/officerTitleModels';
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Button, Confirm, Form, Grid, Modal } from 'semantic-ui-react';

class EditOfficerTitleForm extends Component {
    prevDataInitialValues = _.cloneDeep(this.props.initialValues)

    
    constructor(props) {
        super(props);
        this.state = {
            isConfirm: false,
            IsApplicableToAllCommittees: props.initialValues.IsApplicableToAllCommittees ? 'Yes' : 'No',
            CommitteeTypeLevels: props.initialValues && props.initialValues.CommitteeTypeLevels && props.initialValues.CommitteeTypeLevels.length > 0 ? props.initialValues.CommitteeTypeLevels.map((item, index) => {
                item.isPrevious = true;
                item.CommitteeList = this.findDifferenceCommitteeType(props.initialValues.CommitteeTypeLevels, props.committeeType.committeeTypeList, index);
                item.levelsIds = item.CommitteeLevel.map(item2 => item2.CommitteeLevelId) || [];
                item.hierarchyList = props.committeeType.committeeInfo.filter(item2 => item2.CommitteeTypeId.toString() === item.CommitteeTypeId.toString())[0].CommitteeLevel.map(item2 => {
                    return { key: item2.CommitteeLevelId, value: item2.CommitteeLevelId, text: item2.CommitteeLevelName }
                });

                return item
            }) : [{ CommitteeTypeId: 0, CommitteeList: this.props.committeeType.committeeTypeList, hierarchyList: [], levelsIds: [], CommitteeLevel: [{ CommitteeLevelId: 0, LevelId: 0 }] }],
            committeeTypeRequired: false,
            committeeTypeList: this.props.committeeType.committeeTypeList,
            isDelinkConfirm: false,
            delinkIndex: -1,
            enableSubmitButton: true
        }
    }
    componentDidMount() {
        if (this.state.CommitteeTypeLevels.length > 0) {
            this.state.CommitteeTypeLevels.map((item, index) => {
                this.props.change(`committeeType_${index + 1}`, item.CommitteeTypeId)
            })
        }
    }

    isNonEditableOfficer() {
        const officers = ['staff manager', 'administrative assistant', 'sub committee chair', 'chair']
        const { initialValues: { OfficerTitleName } } = this.props;
        if (officers.includes(OfficerTitleName.toLowerCase())) {
            return true
        }
        return false;
    }

    // Validation functions
    titleNameRequired = value => value && value.trim() ? undefined : this.props.requiredTitleNameError;

    closeModal = (type) => {
        this.props.closeModal(type);
        this.props.resetState();
    }

    handleForm = (values) => {
        const { initialValues: { OfficerTitleName } } = this.props

        if (values.OfficerTitleName !== OfficerTitleName) {
            this.checkUniqueName((response) => {
                if (!response) {
                    this.callAPI(values);
                }
                this.setState({ enableSubmitButton: true });
            });
        } else {
            this.callAPI(values);
            this.setState({ enableSubmitButton: true });
        }
    };

    callAPI = (values) => {
        const { initialValues: { OfficerTitleName, Status, IsASTMStaff, IsApplicableToAllCommittees, IsDefaultLink } } = this.props
        const appObj = OfficerTitlesModel;
        const self = this;
        // For Committee Type Comparison
        const appObjCommitteeTypeIds = []
        const prevDataCommitteeTypeIds = []
        // For Committee Hierarchy Comparison
        let appObjCommitteeLevel = []
        const prevDataCommitteeLevel = []
        let committeeTypeRequired = self.committeeTypeRequired
        try {
            appObj.OfficerTitleId = values.OfficerTitleId;
            appObj.IsASTMStaff = values.IsASTMStaff;
            appObj.IsDefaultLink = values.IsDefaultLink;
            appObj.IsApplicableToAllCommittees = self.state.IsApplicableToAllCommittees === 'Yes';
            appObj.OfficerTitleName = values.OfficerTitleName.trim();
            appObj.Status = values.Status === 'active';
            appObj.CommitteeTypeLevelsPrevious = self.props.initialValues.CommitteeTypeLevels || []
            appObj.CommitteeTypeLevels = !appObj.IsApplicableToAllCommittees ? self.state.CommitteeTypeLevels.filter(item => item.CommitteeTypeId > 0).map(item => { return { CommitteeTypeId: item.CommitteeTypeId, CommitteeLevel: item.CommitteeLevel } }) : [];
            if (appObj.CommitteeTypeLevels.length > 0 && appObj.CommitteeTypeLevels.filter(item => item.CommitteeTypeId > 0).length > 0 && !appObj.IsApplicableToAllCommittees) {
                appObj.CommitteeTypeLevels.map(item => {
                    if (item.CommitteeLevel.filter(item2 => item2.CommitteeLevelId > 0) <= 0) {
                        if (self.props.committeeType.committeeInfo.filter(item3 => item3.CommitteeTypeId === item.CommitteeTypeId && item.CommitteeLevel.length > 0).length > 0) {
                            item.CommitteeLevel = self.props.committeeType.committeeInfo.filter(item3 => item3.CommitteeTypeId === item.CommitteeTypeId && item.CommitteeLevel.length > 0)[0].CommitteeLevel.map(item3 => { return { CommitteeLevelId: item3.CommitteeLevelId, LevelId: item3.LevelId } })
                        }
                    }
                    return item;
                })

            } else if (!appObj.IsApplicableToAllCommittees) {
                committeeTypeRequired = true;
                self.setState({ committeeTypeRequired, isRecordAdded: false })
            }

            if (!committeeTypeRequired) {
                const currentData = {
                    OfficerTitleName: appObj.OfficerTitleName,
                    Status: appObj.Status,
                    IsASTMStaff: appObj.IsASTMStaff,
                    IsDefaultLink: appObj.IsDefaultLink,
                    IsApplicableToAllCommittees: appObj.IsApplicableToAllCommittees
                }
                const prevData = {
                    OfficerTitleName: OfficerTitleName,
                    Status: Status === 'active',
                    IsASTMStaff: IsASTMStaff,
                    IsDefaultLink: IsDefaultLink,
                    IsApplicableToAllCommittees: IsApplicableToAllCommittees
                }

                if (!currentData.IsApplicableToAllCommittees && !_.isEqual(currentData, prevData)) {
                    this.props.editOfficerTitle(appObj, () => {
                        this.onCancelHandler();
                    });
                } else if (!currentData.IsApplicableToAllCommittees) {
                    appObj.CommitteeTypeLevels.map((appObjItem) => {
                        appObjCommitteeTypeIds.push(appObjItem.CommitteeTypeId);
                        appObjItem.CommitteeLevel.map((committeeLevelItem) => {
                            appObjCommitteeLevel.push(committeeLevelItem.CommitteeLevelId)
                        })
                    })
                    this.prevDataInitialValues.CommitteeTypeLevels.map((prevDataItem) => {
                        prevDataCommitteeTypeIds.push(prevDataItem.CommitteeTypeId);
                        prevDataItem.CommitteeLevel.map((committeeLevelItem) => {
                            prevDataCommitteeLevel.push(committeeLevelItem.CommitteeLevelId)
                        })
                    })

                    if (_.isEqual(appObjCommitteeTypeIds, prevDataCommitteeTypeIds) && _.isEqual(appObjCommitteeLevel, prevDataCommitteeLevel)) {
                        this.props.displayNoChangesMadeMessage();
                        this.onCancelHandler();
                    } else {
                        this.props.editOfficerTitle(appObj, () => {
                            this.onCancelHandler();
                        });
                    }
                } else if (_.isEqual(currentData, prevData) && currentData.IsApplicableToAllCommittees) {
                    this.props.displayNoChangesMadeMessage();
                    this.onCancelHandler();
                } else {
                    this.props.editOfficerTitle(appObj, () => {
                        this.onCancelHandler();
                    });
                    this.onCancelHandler();
                }
            }
        }
        catch (err) {
            this.setState({ isRecordAdded: false })
        }
    }

    onCancelHandler = () => {
        this.props.closeModal(1);
    }

    handleConfirm = () => {
        this.setState({
            isConfirm: false
        });
    }

    setApplicableToAllCommittees = (e) => {
        const { textContent } = e.currentTarget;
        let IsApplicableToAllCommittees = this.state.IsApplicableToAllCommittees
        if (textContent === 'Applicable to all Committee Types') {
            IsApplicableToAllCommittees = 'Yes';
        } else {
            IsApplicableToAllCommittees = 'No';
        }
        // ITs removed ref no. 4312
        // if (!(this.state.IsApplicableToAllCommittees === 'Yes') !== this.props.initialValues.IsApplicableToAllCommittees) {
        //     this.setState({
        //         isConfirm: true
        //     });
        // }
        this.setState({ IsApplicableToAllCommittees });
    }
    delinkCommittee = (index) => {
        let CommitteeTypeLevels = this.state.CommitteeTypeLevels;
        if (CommitteeTypeLevels.length > 1) {
            CommitteeTypeLevels = shallowCopy(CommitteeTypeLevels.filter(item => item.CommitteeTypeId !== CommitteeTypeLevels[index].CommitteeTypeId));//CommitteeTypeLevels.splice(index, 1);
            CommitteeTypeLevels.map((item, itemIndex) => {
                item.CommitteeList = this.findDifferenceCommitteeType(CommitteeTypeLevels, this.props.committeeType.committeeTypeList, itemIndex)
                return item;
            })
            this.setState({ CommitteeTypeLevels, committeeTypeRequired: false, isRecordAdded: false, delinkIndex: -1, isDelinkConfirm: false }, () => {
                this.state.CommitteeTypeLevels.map((item, itemIndex) => {
                    this.props.change(`committeeType_${itemIndex + 1}`, item.CommitteeTypeId)
                })
            });
        }
    }
    delinkConfirm = () => {
        this.delinkCommittee(this.state.delinkIndex)
    }
    delinkCancel = () => {
        this.setState({ isDelinkConfirm: false, delinkIndex: -1 })
    }
    removeComitteeTypeItemHandler = (event, index) => {
        event.preventDefault();
        let CommitteeTypeLevels = shallowCopy(this.state.CommitteeTypeLevels);
        if (CommitteeTypeLevels[index].isPrevious) {
            let postdata = { OfficerTitleId: this.props.initialValues.OfficerTitleId, CommitteeTypeId: CommitteeTypeLevels[index].CommitteeTypeId, LevelId: CommitteeTypeLevels[index].CommitteeLevel.map(item => item.LevelId) }
            this.props.postOfficerTitleCommitteeImpact(postdata, (response) => {
                if (response) {
                    this.setState({ isDelinkConfirm: true, delinkIndex: index })
                } else {
                    this.delinkCommittee(index);
                }
            })
        } else {
            this.delinkCommittee(index);
        }

    }

    addComitteeTypeItemHandler = () => {
        let CommitteeTypeLevels = this.state.CommitteeTypeLevels;
        let CommitteeList = this.findDifferenceCommitteeType(CommitteeTypeLevels, this.props.committeeType.committeeTypeList, -1);
        CommitteeTypeLevels.push({ CommitteeTypeId: 0, CommitteeList, CommitteeLevel: [{ CommitteeLevelId: 0, LevelId: 0 }] })

        this.setState({ CommitteeTypeLevels, committeeTypeRequired: false, isRecordAdded: false }, () =>
            this.props.change(`committeeType_${CommitteeTypeLevels.length}`, 0));
    }
    findDifferenceCommitteeType = (CommitteeTypeLevels, committeeTypeList, itemIndex) => {
        let committeetype = [];
        CommitteeTypeLevels.map((item, index) => {
            if (index !== itemIndex && item.CommitteeTypeId > 0) {
                committeetype.push(item.CommitteeTypeId)
            }
            return item;
        })
        return committeeTypeList.filter(item => committeetype.length === 0 || !committeetype.map(cType => cType.toString()).includes(item.value.toString()))
    }
    onChangeCommitteeTypeHandler = (value, index) => {
        let CommitteeTypeLevels = this.state.CommitteeTypeLevels;
        let committeeInfo = this.props.committeeType.committeeInfo
        CommitteeTypeLevels[index].CommitteeTypeId = value;
        CommitteeTypeLevels[index].levelsIds = [];

        if (value && value > 0) {
            CommitteeTypeLevels[index].hierarchyList = committeeInfo.filter(item => item.CommitteeTypeId.toString() === value.toString())[0].CommitteeLevel.map(item => {
                return { key: item.CommitteeLevelId, value: item.CommitteeLevelId, text: item.CommitteeLevelName }
            })
        } else {
            CommitteeTypeLevels[index].hierarchyList = [];
        }

        CommitteeTypeLevels.map((item, itemIndex) => {
            item.CommitteeList = this.findDifferenceCommitteeType(CommitteeTypeLevels, this.props.committeeType.committeeTypeList, itemIndex)
            return item;
        });
        this.setState({ CommitteeTypeLevels, committeeTypeRequired: false, isRecordAdded: false });
    }
    onChangeCommitteeLevelHandler = (value, index) => {
        let CommitteeTypeLevels = this.state.CommitteeTypeLevels;
        let committeeInfo = this.props.committeeType.committeeInfo
        CommitteeTypeLevels[index].levelsIds = value || [];
        CommitteeTypeLevels[index].CommitteeLevel = [];

        if (CommitteeTypeLevels[index].levelsIds.length > 0) {

            CommitteeTypeLevels[index].levelsIds.map(item => {
                committeeInfo.filter(info => info.CommitteeTypeId.toString() === CommitteeTypeLevels[index].CommitteeTypeId.toString())[0].CommitteeLevel.filter(item2 => item2.CommitteeLevelId.toString() == item.toString()).map(item3 => {
                    CommitteeTypeLevels[index].CommitteeLevel.push({ CommitteeLevelId: item3.CommitteeLevelId, LevelId: item3.LevelId })
                })
            })
        } else {
            CommitteeTypeLevels[index].CommitteeLevel = committeeInfo.filter(item => item.CommitteeTypeId.toString() === CommitteeTypeLevels[index].CommitteeTypeId.toString())[0].CommitteeLevel || [];
        }
        this.setState({ CommitteeTypeLevels });

    }
    checkUniqueName = (callback) => {
        this.setState({ enableSubmitButton: false })
        if (_.get(this.refs.OfficerTitleName, 'value', null) !== '') {
            let reqParam = { title: this.refs.OfficerTitleName.value, id: this.props.initialValues.OfficerTitleId };
            this.props.getOfficerTitleAlreadyExists(reqParam, (response) => {
                callback(response);
            })
        } else {
            this.props.resetState();
        }
    }
    render() {
        console.log('props value on editoffiertitleform', this.props);
        const { handleSubmit, change, initialValues, isDuplicateOfficerTitle, duplicateOfficerTitleError, resetState, delinkConfirmMessag } = this.props;
        const { titleNameRequired, addComitteeTypeItemHandler, onChangeCommitteeLevelHandler, onChangeCommitteeTypeHandler, removeComitteeTypeItemHandler } = this;
        const { isConfirm, IsApplicableToAllCommittees, committeeTypeRequired, CommitteeTypeLevels, isDelinkConfirm, enableSubmitButton } = this.state;
        return (
            <React.Fragment>
                <Modal data-testid='editOfficerFormCmp' open='true' className="large">
                    <Form size='large' onSubmit={handleSubmit(this.handleForm)} noValidate>
                        <Modal.Header>Edit Officer Title <i onClick={this.closeModal} class="close">&#x2716;</i></Modal.Header>
                        <Modal.Content>
                            <Modal.Description>
                                <Grid columns='equal'>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Field ref={'OfficerTitleName'} name="OfficerTitleName" type="text"
                                                component={RenderInputField} label="Officer Title"
                                                validate={[titleNameRequired]}
                                                autoFocus='false' maxLength="100" required='true'
                                                isDuplicate={isDuplicateOfficerTitle} onChange={resetState}
                                                duplicateError={duplicateOfficerTitleError}
                                                disabled={this.isNonEditableOfficer()}
                                            />

                                        </Grid.Column>
                                        <Grid.Column>
                                            <label className="label"> <i aria-hidden="true" class="asterisk  icon"></i> Status</label>
                                            <div className="radioWrap">

                                                <Field name="Status"
                                                    component={RenderRadioField}
                                                    className="mr10"
                                                    type="radio" change={change}
                                                    options={[{ label: 'Active', value: 'active' }, { label: 'Inactive', value: 'inactive' }]}
                                                    selectedValue={initialValues.Status} />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <label className="label"> <i aria-hidden="true" className="  icon"></i> </label>
                                            <div className="radioWrap radioWrapCheckBoxOfficerTitle"  style={{ display: 'flex' }}>
                                                <Field name="IsASTMStaff" type="CheckBox" className="mr10"
                                                    component={Checkbox} label="ASTM Staff" />
                                                <Field name="IsDefaultLink" type="CheckBox" className="mr10"
                                                    component={Checkbox} label="Link by Default" />
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <label className="label"> &nbsp;</label>
                                            <div className="radioWrap">
                                                <Field name="applicable_comm"
                                                    component={RenderRadioField}
                                                    className="mr10"
                                                    type="radio"
                                                    selectedValue={IsApplicableToAllCommittees}
                                                    onChange={(event) => this.setApplicableToAllCommittees(event)}
                                                    options={[{ label: 'Applicable to all Committee Types', value: 'Yes' }, { label: 'Specific to a Committee Type', value: 'No' }]} />

                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {IsApplicableToAllCommittees === 'No' && <Grid.Row >
                                        <Grid.Column>
                                            <div className="comApplyRemove">
                                                <div className="tableWrapper">
                                                    <table className="customTable">
                                                        <tbody>
                                                            {CommitteeTypeLevels.length > 0 && CommitteeTypeLevels.map((item, index) => {
                                                                console.log('item==============================', item)
                                                                return <tr>

                                                                    <td width="30%">
                                                                        <div className={committeeTypeRequired ? 'validationError' : ''}>
                                                                            <Field name={`committeeType_${index + 1}`}
                                                                                label="Committee Type"
                                                                                placeholder="Select"
                                                                                component={RenderSelect}
                                                                                value={item.CommitteeTypeId}
                                                                                onChange={(e, value) => onChangeCommitteeTypeHandler(value, index)}
                                                                                options={item.CommitteeList}
                                                                                disabled={item.isPrevious} />
                                                                            {committeeTypeRequired && <span className="errorMessage mt0">{this.props.requiredCommitteeType}</span>}
                                                                        </div>

                                                                    </td>
                                                                    <td width="60%">

                                                                        <DropdownMultipleSelection name="Hierarchy"
                                                                            placeholder="All"
                                                                            isMultiple={true}
                                                                            label="Committee Hierarchy"
                                                                            value={item.levelsIds}
                                                                            onChange={(value) => onChangeCommitteeLevelHandler(value, index)}
                                                                            options={item.hierarchyList}
                                                                        />
                                                                    </td>
                                                                    <td width="7%" className="action txtCenter">
                                                                        {CommitteeTypeLevels.length > 1 && <button><i aria-hidden="true" class="trash icon squareIcon" onClick={(event) => removeComitteeTypeItemHandler(event, index)}></i></button>}
                                                                    </td>
                                                                </tr>

                                                            })}

                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="deletAction">
                                                    <button title="Add" type="button" class="addBtn noBorder" disabled={!(CommitteeTypeLevels.length > 0 && CommitteeTypeLevels.filter(item2 => item2.CommitteeTypeId === 0).length === 0)} onClick={addComitteeTypeItemHandler}><i aria-hidden="true" class="add icon"></i></button>
                                                </div>
                                            </div>

                                        </Grid.Column>
                                    </Grid.Row>}
                                    <Grid.Row>
                                        <Grid.Column className="actions">
                                            <Button primary type="submit" disabled={!enableSubmitButton}>Update</Button>
                                            <Button className="cancel" onClick={this.closeModal}>Cancel</Button>

                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>


                            </Modal.Description>
                        </Modal.Content>
                    </Form>
                    <Confirm className="confirmBox noCancelBtn" confirmButton="Ok" content='Update will be applicable to only those records which are not associated with this Officer Title yet.'
                        onConfirm={this.handleConfirm} open={isConfirm} />
                    <Confirm className="confirmBox " confirmButton="Yes" cancelButton="No" content={delinkConfirmMessag}
                        onConfirm={this.delinkConfirm} onCancel={this.delinkCancel} open={isDelinkConfirm} />

                </Modal>
            </React.Fragment>

        );
    }
}

export default (reduxForm({
    form: 'editOfficerTitleForm'
})(EditOfficerTitleForm));
