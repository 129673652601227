import TableHeader from 'components/atoms/Table/tableHeader';
import AddMembershipDocument from 'components/forms/Membership/addDocumentForm';
import UpdateMembershipDocument from 'components/forms/Membership/editDocumentForm';
import DateFormat from 'helpers/utilCommon';
import * as header from 'models/Membership/membershipDocumentModel';
import React, { Component } from "react";
import { Confirm, Icon } from 'semantic-ui-react';

class MembershipDocumentList extends Component {
	constructor(props) {
		super(props);

		const self = this;
		self.state = {
			loading: true,
			membershipDocumentView: {},
			membershipDocumentList: [],
			isConfirm: false,
			isDeleted: false
		};
	}

	componentDidMount() {
		this.props.getMemberShipDocumentsList(this.setMembershipDocumentList);
	}

	setMembershipDocumentList = (response) => {
		let self = this;
		self.setState({
			membershipDocumentList: response,
			loading: false
		});

	}

	membershipDocumentView = (_item) => {
		this.props.setEditFormData(_item, () => {
			this.setState({ openViewForm: true });
		});
	}

	closeModal = _type => {
		let self = this;
		switch (_type) {
			case 1: self.setState({ openViewForm: false });
				self.props.getMemberShipDocumentsList(self.setMembershipDocumentList);
				break;
			case 2:
				self.setState({ openViewForm: false });
				break;
			case 3:
				self.setState({ openAddForm: false });
				self.props.getMemberShipDocumentsList(self.setMembershipDocumentList);
				break;
			default:
				self.setState({ openUpdateForm: false })
		}
	}

	deleteDocument = (items) => {
		this.setState({
			isConfirm: true,
			deletePayloads: items
		})

	}

	handleCancel = () => this.setState({ isConfirm: false })

	handleConfirm = () => {
		if (!this.state.isDeleted) {
			this.setState({ isDeleted: true });

			let self = this;
			this.props.deleteDocument(this.state.deletePayloads, () => {
				self.props.getMemberShipDocumentsList(self.setMembershipDocumentList);
				self.setState({
					isConfirm: false,
					isDeleted: false

				});
			});
		}
	}

	handleDownloadFile(Items) {
		this.props.downloadFile(Items, response => {
			if (response.status) {
				let { url } = response.content;
				const link = window.document.createElement('a');
				link.href = url;
				link.download = response.fileName;
				link.click();
			}
		})
	}

	onSort = (event, sortKey) => {
		// Definition not required
	}
	render() {
		const { membershipDocumentList, loading, openViewForm, openAddForm, isConfirm } = this.state;
		const { userPermission } = this.props;

		return (


			<div className="homePage" data-testid = 'membershipDocumentCmp'>
				<div className="ui">
					<div className="headingTitle clearfix">
						<h2>Membership Documents</h2>
						{userPermission.Add &&
							<button onClick={() => { this.setState({ openAddForm: true }) }}
								className="ui secondary button"><i aria-hidden="true" class="plus icon"></i>
								Add Document</button>}
					</div>
					<div className="contentWrapper clearfix">
						<div className="tableWrapper">
							{<table className="customTable membershipDocTable">
								<thead>
									<tr>
										<TableHeader headerProps={header.HEADERINFO} onClickProps={this.onSort}></TableHeader>

									</tr>
								</thead>
								<tbody>
									{membershipDocumentList && membershipDocumentList.length > 0 &&
										(
											membershipDocumentList.map((_item) => {
												return <tr>

													{/* <td className="Key">
														{_item.Key}
													</td> */}
													<td className="Name">
														{_item.Name}
													</td>
													<td className="Type">
														{(_item.Type === 'pdf') ? 'PDF' : 'Word Doc'}
													</td>
													<td className="Status">
														{(_item.Status == "true" || _item.Status) ? 'Active' : 'Inactive'}
													</td>
													<td className="UpdatedBy">
														{_item.ModifiedUserName}
													</td>
													<td className="UpdatedDate">
														{_item.ModifiedDate ? DateFormat.utcDateTimeToLocalDateTime(_item.ModifiedDate) : DateFormat.utcDateTimeToLocalDateTime(_item.CreatedDate)}
													</td>
													<td className="action">
														{userPermission.Update &&
															<button data-testid = 'edit-button' onClick={e => this.membershipDocumentView(_item)}>
																<Icon className="squareIcon" name='pencil' /></button>}
														<button data-testid = 'download-button' onClick={e => this.handleDownloadFile(_item)}><Icon className="squareIcon" name='download' /></button>
														{userPermission.Delete &&
															<button data-testid = 'delete-button' onClick={e => this.deleteDocument(_item)}><Icon name='trash' className="squareIcon" /></button>}
													</td>
												</tr>
											})
										)}

									{(!loading && (!membershipDocumentList || membershipDocumentList.length <= 0)) && (
										<tr>
											<td colSpan="7">{this.props.noRecordMessage}</td>
										</tr>
									)}
								</tbody>
							</table>
							}
						</div>

					</div>
				</div>

				{openAddForm && (
					<AddMembershipDocument closeModal={this.closeModal} {...this.props} />
				)}
				{openViewForm && (
					<UpdateMembershipDocument closeModal={this.closeModal} {...this.props} />
				)}
				<Confirm className="confirmBox" cancelButton='No' confirmButton="Yes" content='Are you sure you want to delete this document?'
					onCancel={this.handleCancel} onConfirm={this.handleConfirm} open={isConfirm} data-testid='confirm-cmp' />

			</div>

		);
	}
}

export default MembershipDocumentList;

