import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import styled from 'styled-components'

import initialData from './initial-data'
import Column from './column'

const Container = styled.div`
  display:flex;
`

class ComitteeDnd extends React.Component {
    constructor(props) {
        super(props);

        initialData.tasks = props.membershipTypeList;
        initialData.tasks = {}
        props.membershipTypeList.map((item, index) => {

            if (index === 0) {
                initialData.tasks['-1'] = { id: -1, content: 'All' }
            }
            initialData.tasks[item.MembershipTypeId] = { id: item.MembershipTypeId, content: item.MembershipTypeName }
        }
        )

        initialData.columns['column-1'].taskIds = [];
        props.membershipTypeList.map((item, index) => {
            if (index === 0 && props.selectedMembershipTypeList.length < props.membershipTypeList.length) {
                initialData.columns['column-1'].taskIds.push(-1)
            }
            if ((props.selectedMembershipTypeList.length === 0) || (props.selectedMembershipTypeList.length > 0 && props.selectedMembershipTypeList.filter((item1) => item1.MembershipTypeId === item.MembershipTypeId).length === 0)) {
                initialData.columns['column-1'].taskIds.push(item.MembershipTypeId)
            }

        })
        initialData.columns['column-2'].taskIds = [];
        if (props.selectedMembershipTypeList.length > 0) {
            props.selectedMembershipTypeList.map((item, index) => {
                initialData.columns['column-2'].taskIds.push(item.MembershipTypeId)

            })
        } else {
            initialData.columns['column-2'].taskIds = [];
        }


    }
    state = initialData
    validateDragEnd = result => {
        return new Promise((resolve) => {
            const { isEdit, validateMethod } = this.props;
            const { destination, source, draggableId } = result
            if (source.droppableId !== destination.droppableId && destination.droppableId === 'column-1') {
                if (isEdit > 0) {

                    validateMethod(draggableId, (response) => {
                        if (response) {
                            if (this.props.selectedMembershipTypeList.length > 0 && this.props.selectedMembershipTypeList.filter((item1) => item1.MembershipTypeId === draggableId && !item1.IsDraggable).length > 0) {
                                this.props.showErrorPopup();
                                resolve(false);
                            }
                            resolve(true);
                        }
                        resolve(false);

                    })
                }
                else if (this.props.selectedMembershipTypeList.length > 0 && this.props.selectedMembershipTypeList.filter((item1) => item1.MembershipTypeId === draggableId && !item1.IsDraggable).length > 0) {
                    this.props.showErrorPopup();
                    resolve(false)
                } else {
                    resolve(true);
                }
            } else {
                resolve(true);
            }

        })

    }
    onDragEnd = result => {
        const { destination, source, draggableId } = result

        if (!destination) {
            return false
        }

        if (source.droppableId === destination.droppableId) {
            return false;
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }
        this.validateDragEnd(result).then(response => {

            if (response) {
                const start = this.state.columns[source.droppableId]
                const finish = this.state.columns[destination.droppableId]

                if (start === finish) {
                    const newTaskIds = Array.from(start.taskIds)
                    newTaskIds.splice(source.index, 1)
                    if (destination.droppableId === "column-1" && destination.index === 0) {
                        newTaskIds.splice(destination.index + 1, 0, draggableId)
                    } else {
                        newTaskIds.splice(destination.index, 0, draggableId)
                    }


                    const newColumn = {
                        ...start,
                        taskIds: newTaskIds
                    }

                    const newState = {
                        ...this.state,
                        columns: {
                            ...this.state.columns,
                            [newColumn.id]: newColumn
                        }
                    }

                    this.setState(newState)
                    return
                }
                if (draggableId === -1) {

                    const newStart = {
                        ...start,
                        taskIds: []
                    }
                    const finishTaskIds1 = Array.from(finish.taskIds)

                    start.taskIds.map((item, index) => {
                        if (item > 0) {
                            finishTaskIds1.splice(index, 0, item)
                        }
                    })

                    const newFinish = {
                        ...finish,
                        taskIds: finishTaskIds1
                    }
                    const newState = {
                        ...this.state,
                        columns: {
                            ...this.state.columns,
                            [newStart.id]: newStart,
                            [newFinish.id]: newFinish
                        }
                    }
                    this.props.onSelect(newState)
                    this.setState(newState)

                } else {
                    // Moving from one list to another

                    const startTaskIds = Array.from(start.taskIds)
                    startTaskIds.splice(source.index, 1)
                    if (startTaskIds.length === 1 && start.id !== "column-2") {
                        startTaskIds.splice(0, 1)
                    }
                    const newStart = {
                        ...start,
                        taskIds: startTaskIds
                    }

                    const finishTaskIds = Array.from(finish.taskIds)
                    if (start.id === "column-2" && finishTaskIds.length === 0) {
                        finishTaskIds.splice(0, 1, -1);
                        finishTaskIds.splice(1, 0, draggableId)
                    } else if (destination.droppableId === "column-1" && destination.index === 0) {
                        finishTaskIds.splice(destination.index + 1, 0, draggableId)
                    } else {
                        finishTaskIds.splice(destination.index, 0, draggableId)
                    }

                    const newFinish = {
                        ...finish,
                        taskIds: finishTaskIds
                    }

                    const newState = {
                        ...this.state,
                        columns: {
                            ...this.state.columns,
                            [newStart.id]: newStart,
                            [newFinish.id]: newFinish
                        }
                    }
                    this.props.onSelect(newState)
                    this.setState(newState)
                }
            }
        })

    }

    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Container>
                    {this.state.columnOrder.map(columnId => {
                        const column = this.state.columns[columnId]
                        const tasks = column.taskIds.map(
                            taskId => this.state.tasks[taskId]
                        )

                        return (
                            <Column key={column.id} column={column} tasks={tasks} />
                        )
                    })}
                </Container>
            </DragDropContext>
        )
    }
}


export default ComitteeDnd;
