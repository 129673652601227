import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';

const RemoteSubmitButton = ({ dispatch, label, formId }) => ( // CUSTOM COMPONENT FOR REMOTE BUTTON

    <button onClick={() => dispatch(submit(formId))} className="ui primary button" size='mini'>
        {label}
    </button>

);

export default connect()(RemoteSubmitButton);