// @flow
import * as type from 'actionTypes';
const initialState = {
	loginCredential: null
};

const reducerAuth = (state = initialState, action) => {
	switch (action.type) {
		case type.LOGIN:
			return {
				...state,
				login: action.loginCredential
			};
		case type.AZURE_LOGIN:
			return {
				...state,
				azureCredentials: action.azureCredentials
			};

		case type.AZURE_REFRESH_TOKEN:
			return {
				...state,
				azureRefereshTokenCredentials: action.azureRefereshTokenCredentials
			};

		default:
			return state;
	}
};

export default reducerAuth;
