import commonActions from 'actions/commonActions';
import userActions from "actions/userActions";
import AddUserForm from 'components/forms/User/addUser';
import * as lodash from 'lodash';
import { connect } from "react-redux";


const mapStateToProps = (state) => {
    return {
        roledata: state.userReducer.roledata,
        privilege: state.userReducer.privilegedata,
        defaultRole: 1,
        userInfo: state.userReducer.userInfo,
        isEdit: state.userReducer.isEdit,
        type: state.userReducer.type,
        userNameRequired: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2501'].text,
        userNameExist: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2500'].text,
        userNameSpaceNotAllow: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2502'].text,
        privilegeAssignedRequired: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2503'].text,
        userNameInvalid: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2504'].text,
        isDuplicate: state.userReducer.isDuplicate,
        viewType: state.userReducer.viewType,
        passwordRequired: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2509'].text,
        confirmPasswordRequired: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2510'].text,
        passwordInvalid: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2511'].text,
        confirmPasswordNotMatched: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2512'].text,
        userPermission: state.commonReducer.userPermission,
        messageCodes: lodash.get(state.i18nReducer.dictionaries, state.i18nReducer.currentLanguage)
    }
};

const mapDispatchToProps = (dispatch) => ({
    roleList: (data, callbackFun) => userActions.getRoleList(dispatch, data, callbackFun),
    defaultPrivilege: (data, callbackFun) => userActions.getdefaultPrivilege(dispatch, data, callbackFun),
    privilegeByRole: (data, callbackFun) => userActions.getPrivilegeByRole(dispatch, data, callbackFun),
    privilegeByUser: (data, callbackFun) => userActions.getPrivilegeByUser(dispatch, data, callbackFun),
    userDetail: (data) => userActions.getUserdetail(dispatch, data),
    usePrivilegeSubmit: (data) => userActions.UserPrivilageSubmit(dispatch, data),
    getUserList: (callback) => userActions.getUserList(dispatch, callback),
    userValidateInAD: (data) => dispatch(userActions.userValidateInAd(data)),
    changePrivilege: (data) => dispatch(userActions.userChangePrivilege(data)),
    userPrivilegeSubmit: (data, callbackFun) => userActions.UserPrivilageSubmit(dispatch, data, callbackFun),
    checkDuplicate: (data, callbackFun) => userActions.getUserdetail(dispatch, data, callbackFun),
    getUserdetailwithPrivilage: (data, callbackFun) => userActions.getUserdetailwithPrivilage(dispatch, data, callbackFun),
    putUserPrivilage: (data, callback) => userActions.putUserPrivilage(dispatch, data, callback),
    getUserListOnSearch: (searchKey, callback) => userActions.getUserListOnSearch(dispatch, searchKey, callback),
    getvalidateUser: (data, callback) => userActions.getValidateUser(dispatch, data, callback),
    setUserId: (userId) => dispatch({ action: 'SET_USER_ID', userId }),
    displayNoChangesMadeMessage: () => dispatch(commonActions.setMessage(true, '2040'))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUserForm);