import React, { Component } from 'react';
import AddBOSVolumeForm from 'components/forms/Committee/addBosVolumeForm';
import BosVolumeList from 'components/lists/Committee/bosVolumeList';
import { PAGE_SIZE_FOR_PAGINATION, ItemsPerPage } from 'helpers/utilCommon';
import { withRouter } from "react-router";
import * as _func from './function';
import PaginationShorthand from "components/atoms/Pager";
import RenderSelect from 'components/atoms/Select';
import * as actionType from 'actionTypes';

class BOSVolumeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAddEnable: false,
            pageType: 'list',
            pageSize: PAGE_SIZE_FOR_PAGINATION,
            pageNumber: 1,
            totalItems: 0,
            currentlyShowingItems: '',
            bosVolumeList: [],
            sortKey: ''
        }
    }
    componentWillMount() {
        _func.setClassInstance(this);
    }

    componentWillReceiveProps(nextProps) {
        const { type } = nextProps;
        if (type) {
            switch (type) {
                case actionType.BOS_VOLUME_LIST:
                    this.setState({ bosVolumeList: nextProps.result.list })
                    break;
                default:
                    break;
            }
        }
    }
    shouldComponentUpdate(nextProps) {
        if (this.props.match.params !== nextProps.match.params) {
            _func.changePageType(nextProps.match.params.pageType)
        }
        return true;
    }
    render() {
        const { userPermission, result } = this.props;
        const { pageSize, pageNumber, currentlyShowingItems, bosVolumeList, totalItems, pageType } = this.state
        return (
            <React.Fragment>
                {(pageType === "add" || pageType === "edit") &&
                    <AddBOSVolumeForm
                        {...this.props}
                        backToListingPage={_func.backToListingPage.bind(this)}
                        refreshPagination={_func.changeListDataOnPagination}
                        pageType={pageType}
                    />
                }
                {pageType === "list" && <>
                    <div className="headingTitle clearfix" data-testid='bosVolumeListCmp'>
                        <h2>BOS Volume Location</h2>
                        {
                            userPermission.Add &&
                            <button onClick={_func.addButtonClickedEvent.bind(this)}
                                className="ui secondary button">
                                <i aria-hidden="true" class="plus icon"></i>
                                ADD BOS VOLUME</button>
                        }
                    </div>
                    <BosVolumeList
                        {...this.props}
                        bosVolumeList={bosVolumeList}
                        refreshPagination={_func.changeListDataOnPagination}
                        onSort={_func.onSort}
                    />
                    {result.list && result.list.length > 0 &&
                        <section className="pagerWrap">
                            <div className="pager">
                                <PaginationShorthand defaultActivePage={pageNumber}
                                    totalPages={Math.ceil(totalItems / pageSize)}
                                    onPageChange={_func.onPageChangeHandler} />
                            </div>

                            <div className="itemPerPage">
                                <RenderSelect onchange={_func.onChangeNumberOfItemsPerPage}
                                    value={pageSize} options={ItemsPerPage} />
                                <span className="itemsPerPage">items per page</span>
                            </div>
                            <div className="totalPage">{currentlyShowingItems}</div>
                        </section>
                    }
                </>
                }
            </React.Fragment>
        )
    }

}

export default withRouter(BOSVolumeComponent);
