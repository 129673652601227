import React from 'react';
import { Modal } from 'semantic-ui-react';
import {compareSimpleFormData} from 'helpers/utilCommon'

// Forms
import AddMeetingTypeForm from './../../forms/Committee/addMeetingTypeForm';


class AddMeetingTypeModal extends React.Component {

    closeModal = () => {
        this.props.closeModal(3);
        this.props.resetState();
    }

    handleSubmit = (values) => {
        const meetingObj = {}

        meetingObj.Name =values.TitleName;
        meetingObj.Description = values.Description;
        meetingObj.MeetingTypeId = values.MeetingTypeId;

        if (values.MeetingTypeId && values.MeetingTypeId > 0) {
            meetingObj.IsActive = values.status === "active";

            if (compareSimpleFormData(this.props.initialValues, {TitleName:values.TitleName, Description: values.Description, status: values.status}, ['TitleName', 'Description', 'status']) > 0) {
                this.props.putMeetingType(meetingObj, () => this.props.manageModal(0));
            }else {
                this.props.displayNoChangesMadeMessage();
                this.props.manageModal(0);
            }
        }else {
            meetingObj.IsActive = true;
            this.props.postMeetingType(meetingObj, () => this.props.manageModal(0));
        }
    };

    render() {
        const { manageModal, actionType } = this.props
        return (
            <Modal open='true' className="tiny">
                <Modal.Header>{actionType == 2 ? 'Edit Meeting Type' : 'Add Meeting Type'}  <i class="close" onClick={() => manageModal(0)}>&#x2716;</i></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <AddMeetingTypeForm {...this.props} onSubmit={this.handleSubmit} onCancel={manageModal} actionType={actionType} />
                    </Modal.Description>
                </Modal.Content>

            </Modal>
        );
    }
}


export default AddMeetingTypeModal;
