import App from 'App';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import loginActions from '../../actions/authActions';
import { initiateAzureRequestAction } from '../../actions/commonActions';

const mapStateToProps = state => {
	return {
		showToastMessage: state.commonReducer.showToastMessage,
		loading: state.commonReducer.loading,
		content: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage][state.commonReducer.code],
		...state
	}
};

const mapDispatchToProps = (dispatch) => ({
	authenticateByAzureAD: (callback) => {
		loginActions.authenticateByAzureADAction(dispatch, callback);
	},
	authByCookiesToken: (inputDTO, callback) => {
		loginActions.authByCookiesTokenAction(dispatch, inputDTO, callback);
	},
	initiateAzureRequest: (code, sessionState, authState, cb) => initiateAzureRequestAction(code, sessionState, authState, cb, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

