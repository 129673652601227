import React from 'react';
import { Grid, Modal } from 'semantic-ui-react';

const ViewMeetingTypeModal = (props) => (
    <Modal open='true' className="tiny infoBox" data-testid='viewMeetingType'>
        <Modal.Header><span className={props.viewDataProp.IsActive ? 'statusActive' : 'statusInactive'}>{props.viewDataProp.IsActive ? 'Active' : 'Inactive'}</span><i class="close" onClick={() => props.closeModal(0)}>&#x2716;</i></Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <Grid columns='equal' className="stackable">
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label className="label2">Meeting Type</label>
                            <span className="labelData">{props.viewDataProp.Name}</span>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="mb10">
                        <Grid.Column>
                            <label className="label2">Description</label>
                            <span className="labelData">{props.viewDataProp.Description}</span>
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column className="actions">
                            {props.userPermission.Update && <a data-testid= "closeEditMeetingModal" className="editBtn" onClick={() => props.closeModal(2)}><i aria-hidden="true" class="pencil icon"></i></a>}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </Modal.Description>
        </Modal.Content >

    </Modal >
)

export default ViewMeetingTypeModal;
