import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { EditClassificationModel } from 'models/Membership/classificationModels';
import EditClassificationForm from 'components/forms/Membership/editClassificationForm';
import _ from 'lodash';

export class EditClassificationModal extends Component {
    prevData = _.cloneDeep(this.props.initialValues)

    closeModal = () => {
        this.props.closeModal(1);
    }

    handleSubmit = values => {
        const self = this;
        const classificationObj = EditClassificationModel;

        try {
            classificationObj.MemberClassificationId = values.classificationTypeId
            classificationObj.Description = values.description;
            classificationObj.Name = values.type.trim();
            classificationObj.IsApplicableToAllCommittees = self.props.initialValues.isApplicableToAllCommittees;
            classificationObj.colorCode = self.props.initialValues.colorCode;
            classificationObj.isAddedColor = self.props.initialValues.isAddedColor;
            classificationObj.colorName = self.props.initialValues.colorName;

            const currentClassificationObj = {
                Name: classificationObj.Name,
                Description: classificationObj.Description,
                IsApplicableToAllCommittees: classificationObj.IsApplicableToAllCommittees,
                colorCode: classificationObj.colorCode,
                isAddedColor: classificationObj.isAddedColor
            }
            const prevClassificationObj = {
                Name: self.prevData.type,
                Description: self.prevData.description,
                IsApplicableToAllCommittees: self.prevData.isApplicableToAllCommittees,
                colorCode: self.prevData.ColorCode,
                isAddedColor: self.prevData.isAddedColor
            }

            if(_.isEqual(currentClassificationObj, prevClassificationObj)) {
                self.props.displayNoChangesMadeMessage();
                self.props.closeModal(1);
            }else {
                self.props.editClassification(classificationObj, () => {
                    this.props.showLoader(true)
                    self.props.closeModal(1);
                });
            }
        }
        catch (err) {
            this.props.showLoader(false)
            //console.log('Error occured while updating Classification.');
        }
    };

    render() {

        return (
            <Modal open='true' style={{ 'width': '780px' }} data-testid="updateClassification">
                <Modal.Header>Update Classification Type <i onClick={this.closeModal} class="close">&#x2716;</i></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <EditClassificationForm {...this.props} onSubmit={this.handleSubmit} onCancel={this.closeModal} />
                    </Modal.Description>
                </Modal.Content>

            </Modal>
        );
    }
}

export default EditClassificationModal;
