import MembershipTypeList from 'components/lists/Membership/membershipTypeList';
import React, { Component } from 'react';
import { setPermissionNavigation } from '../../../helpers/utilCommon';
class MembershipType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAddButtonClicked: false,
            isViewMode: true
        }
    }

    addButtonClickedEvent() { // WHILE CLICK ON ADD BUTTON

        this.setState({
            isAddButtonClicked: true, isViewMode: false
        });
        this.props.history.push(setPermissionNavigation('membershipType-add'));
    }

    backButtonClickedEvent() { // WHILE CLICK ON BACK/CANCEL BUTTON

        this.setState({
            isAddButtonClicked: false, isViewMode: false
        });

    }
    viewButtonClickEvent() {
        this.setState({
            isAddButtonClicked: true, isViewMode: true
        });
    }
    editButtonClickedEvent() {
        this.setState({
            isViewMode: false,
            isAddButtonClicked: true
        });
    }

    render() {
        return (
            <MembershipTypeList {...this.props} addButtonClicked={this.addButtonClickedEvent.bind(this)}
                viewButtonClicked={this.viewButtonClickEvent.bind(this)} />

        )
    }

}


export default MembershipType; // MAIN COMPONENT FOR MEMBERSHIP TYPE WORKING CONDITIONALY LIST AND ADD/UPDATE