
export const MODULES = {
    0: "Home",
    1: "Membership_Management",
    2: "Committee_Management",
    3: "Ballot_Rule_Management",
    4: "User_Management"
}

/* **********************************  Keys Defination *********************************

ModuleName                  : Display Module Name
ModuleKeys                  : Module Key for Identification for Module
iconClass                   : Display Module Icon
hasNavChild                 : Check Sub Page View Display
routeUrl                    : Navigation Url/Route
permissionType              : Identification of Permission
hasPageParent               : Validate Its Child or Not : - Note : Visibility of Permission on Page Ex. Add, Update, Delete
isVisibleOnNavigation       : Display In Navigation Component Parent/Child
title                       : Display SubChild Tile Name
breadcrumbsTitle            : Display in BreadCrumb Name
breadcrumbsParentPath       : Display BreadCrumb Path
breadcrumbsParentTitle      : Display BreadCrumb Parent Title
oslUrl                      : For Validate OSL path if allow permission
oslMethod                   : For Validate OSL Method if allow permission
uniqueKey                   : Unique Identification : Note :- Pattern should be Ex. [Name of Module]-[type of view]

***************************************************************************************/

export const NAVIGATION = [
    // Dashboard Module JSON
    {
        'ModuleName': "Home",
        'ModuleKeys': MODULES[0],
        'iconClass': '',
        'isShow': false, // For Toggle Navgation if Required
        'hasNavChild': true, // For Check Has Page Exist
        'routeUrl': "/",
        "isVisibleOnNavigation": false,
        "searchParams": {},
        [MODULES[0]]: [
            { // Membership Type List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'home',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "Dashboard",
                "breadcrumbsParentPath": "/home",
                "breadcrumbsParentTitle": "",
                "oslUrl": "",
                "oslMethod": "",
                "uniqueKey": "home-list"
            }
        ]
    },
    // Membership Management Module JSON
    {
        'ModuleName': "Membership Management",
        'ModuleKeys': MODULES[1],
        'iconClass': 'address card  icon',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "",
        "isVisibleOnNavigation": true,
        "searchParams": {},
        [MODULES[1]]: [
            { // Membership Type List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'membership-type',
                "isVisibleOnNavigation": true,
                'title': "Manage Membership Types",
                "breadcrumbsTitle": "Membership Types",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/membershiptype/get/*",
                "uniqueKey": "membershipType-list",
                "oslMethod": "GET"
            },
            {// Membership Type Add Permission
                "permissionType": "Add",
                "hasPageParent": ["membershipType-list"],
                'routeUrl': 'membership-type/add',
                "isVisibleOnNavigation": false,
                'title': "Add Membership Type",
                "breadcrumbsTitle": "Add Membership Type",
                "breadcrumbsParentPath": "membership-type",
                "breadcrumbsParentTitle": "Membership Types",
                "oslUrl": "/membershiptype/post",
                "uniqueKey": "membershipType-add",
                "oslMethod": "POST"
            },
            {// Membership Type Details Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'membership-type/details/:MembershipTypeId?',
                "isVisibleOnNavigation": false,
                'title': "Membership Type Details",
                "breadcrumbsTitle": "Membership Type Details",
                "breadcrumbsParentPath": "membership-type",
                "breadcrumbsParentTitle": "Membership Types",
                "oslUrl": "/membershiptype/get/*",
                "uniqueKey": "membershipType-detail",
                "oslMethod": "GET"
            },
            {// Membership Type Update Permission
                "permissionType": "Update",
                "hasPageParent": ["membershipType-detail"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/membershiptype/*",
                "uniqueKey": "membershipType-update",
                "oslMethod": "PUT"
            },
            {// FAQ List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'faq-list',
                "isVisibleOnNavigation": true,
                'title': "Manage Membership FAQs",
                "breadcrumbsTitle": "Membership FAQs",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/faq/get/*",
                "uniqueKey": "faq-list",
                "oslMethod": "GET"
            },
            {// FAQ Add Permission
                "permissionType": "Add",
                "hasPageParent": ["faq-list"],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/faq/*",
                "uniqueKey": "faq-add",
                "oslMethod": "POST"
            },
            {// FAQ Update Permission
                "permissionType": "Update",
                "hasPageParent": ["faq-list"],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/faq/*",
                "uniqueKey": "faq-update",
                "oslMethod": "PUT"
            },
            {// FAQ Delete Permission
                "permissionType": "Delete",
                "hasPageParent": ["faq-list"],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/faq/*",
                "uniqueKey": "faq-delete",
                "oslMethod": "DELETE"
            },
            { // Documnets View Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'document-list',
                "isVisibleOnNavigation": true,
                'title': "Manage Membership Documents",
                "breadcrumbsTitle": "Membership Documents",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/*",
                "uniqueKey": "document-list",
                "oslMethod": "GET"
            },
            { // Documnets Add Permission
                "permissionType": "Add",
                "hasPageParent": ["document-list"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/*",
                "uniqueKey": "document-add",
                "oslMethod": "POST"
            },
            { // Documnets Update Permission
                "permissionType": "Update",
                "hasPageParent": ["document-list"],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/*",
                "uniqueKey": "document-update",
                "oslMethod": "PUT"
            },
            { // Documnets Delete Permission
                "permissionType": "Delete",
                "hasPageParent": ["document-list"],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/document/*",
                "uniqueKey": "document-delete",
                "oslMethod": "DELETE"
            },
            {// Classification List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'classification-list',
                "isVisibleOnNavigation": true,
                'title': "Manage Member Classifications",
                "breadcrumbsTitle": "Member Classifications",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberclassification/*",
                "uniqueKey": "classification-list",
                "oslMethod": "GET"
            },
            {// Classification Add Permission
                "permissionType": "Add",
                "hasPageParent": ["classification-list"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberclassification/*",
                "uniqueKey": "classification-add",
                "oslMethod": "POST"
            },
            {// Classification Update Permission
                "permissionType": "Update",
                "hasPageParent": ["classification-list"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/memberclassification/*",
                "uniqueKey": "classification-update",
                "oslMethod": "PUT"
            },
            {// Classification Delete Permission
                "permissionType": "Delete",
                "hasPageParent": ["classification-list"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "",
                "uniqueKey": "classification-delete",
                "oslMethod": "DELETE"
            },
            {// Membership Templates List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'membershiptemplate',
                "isVisibleOnNavigation": true,
                'title': "Manage Templates",
                "breadcrumbsTitle": "Membership Templates",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/membershiptemplate/*",
                "uniqueKey": "membershiptemplate",
                "oslMethod": "GET"
            },
            {// Membership Templates Add Permission
                "permissionType": "Add",
                "hasPageParent": ["membershiptemplate"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/membershiptemplate/*",
                "uniqueKey": "membershiptemplate-add",
                "oslMethod": "POST"
            },
            {// Membership Templates Update Permission
                "permissionType": "Update",
                "hasPageParent": ["membershiptemplate"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/membershiptemplate/*",
                "uniqueKey": "membershiptemplate-update",
                "oslMethod": "PUT"
            },
            {// Manage Reports List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'membershipreports',
                "isVisibleOnNavigation": true,
                'title': "Manage Reports",
                "breadcrumbsTitle": "Manage Reports",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageReport/*",
                "uniqueKey": "membershipreports",
                "oslMethod": "GET"
            },
            {// Membership Reports Add Permission
                "permissionType": "Add",
                "hasPageParent": ["membershipreports"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageReport/*",
                "uniqueKey": "Report-add",
                "oslMethod": "POST"
            },
            {// Membership Reports Update Permission
                "permissionType": "Update",
                "hasPageParent": ["membershipreports"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageReport/*",
                "uniqueKey": "Report-update",
                "oslMethod": "PUT"
            },
            {// Membership Reports Delete Permission
                "permissionType": "Delete",
                "hasPageParent": ["membershipreports"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': "",
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageReport/*",
                "uniqueKey": "Report-add",
                "oslMethod": "DELETE"
            }
        ]
    },
    // Committee Management Module JSON
    {
        'ModuleName': "Committee Management",
        'ModuleKeys': MODULES[2],
        'iconClass': 'users  icon',
        'isShow': false,
        'hasNavChild': true,
        'routeUrl': "",
        "breadcrumbsRootTitle": "",
        "oslUrl": "",
        "isVisibleOnNavigation": true,
        "searchParams": {},
        [MODULES[2]]: [
            { // Committee List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'committee-list',
                "isVisibleOnNavigation": true,
                'title': 'Manage Committee Types',
                "breadcrumbsTitle": "Committee Types",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeetype/*",
                "uniqueKey": "committeeType-list",
                "oslMethod": "GET"
            },
            { // Committee Details Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'committee-type/details/:CommitteeTypeId?',
                "isVisibleOnNavigation": false,
                'title': 'Committee Type Details',
                "breadcrumbsTitle": "Committee Type Details",
                "breadcrumbsParentPath": "committee-list",
                "breadcrumbsParentTitle": "Committee Types",
                "oslUrl": "/committeetype/*",
                "uniqueKey": "committeeType-detail",
                "oslMethod": "GET"
            },
            {// Committee Type Add Permission
                "permissionType": "Add",
                "hasPageParent": ["committeeType-list"],
                'routeUrl': 'committee-type/add',
                "isVisibleOnNavigation": false,
                'title': 'Add Committee Types',
                "breadcrumbsTitle": "Add Committee Types",
                "breadcrumbsParentPath": "committee-list",
                "breadcrumbsParentTitle": "Committee Types",
                "oslUrl": "/committeetype/*",
                "uniqueKey": "committeeType-add",
                "oslMethod": "POST"
            },
            { // Committee Type Update Permission
                "permissionType": "Update",
                "hasPageParent": ["committeeType-detail"],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': 'Manage Committee Types',
                "breadcrumbsTitle": "Committee Types",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeetype/*",
                "uniqueKey": "committeeType-update",
                "oslMethod": "PUT"
            },
            { // Committee Type Delete Permission
                "permissionType": "Delete",
                "hasPageParent": [],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/committeetype/*",
                "uniqueKey": "committeeType-delete"
            },
            { // Officer Titles List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'officer-title-list',
                "isVisibleOnNavigation": true,
                'title': 'Manage Officer Titles',
                "breadcrumbsTitle": "OfficerTitles",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/officertitle/*",
                "uniqueKey": "officerTitle-list",
                "oslMethod": "GET"
            },
            { // Officer Titles Add Permission
                "permissionType": "Add",
                "hasPageParent": ["officerTitle-list"],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/officertitle/*",
                "uniqueKey": "officerTitle-add",
                "oslMethod": "POST"
            },
            { // Officer Titles Update Permission
                "permissionType": "Update",
                "hasPageParent": ["officerTitle-list"],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/officertitle/*",
                "uniqueKey": "officerTitle-update",
                "oslMethod": "PUT"
            },
            { // Officer Titles Delete Permission
                "permissionType": "Delete",
                "hasPageParent": ["officerTitle-list"],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "",
                "uniqueKey": "officerTitle-delete",
                "oslMethod": "DELETE"
            },
            {// Meeting Types List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'meeting-list',
                "isVisibleOnNavigation": true,
                'title': 'Manage Meeting Types',
                "breadcrumbsTitle": "Manage Meeting Types",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/meetingtype",
                "uniqueKey": "meeting-list",
                "oslMethod": "GET"
            },
            {// Meeting Types Add Permission
                "permissionType": "Add",
                "hasPageParent": ["meeting-list"],
                'routeUrl': 'meeting-list',
                "isVisibleOnNavigation": false,
                'title': 'Manage Meeting Types',
                "breadcrumbsTitle": "Manage Meeting Types",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/meetingtype",
                "uniqueKey": "meeting-list",
                "oslMethod": "POST"
            },
            {// Meeting Types Update Permission
                "permissionType": "Update",
                "hasPageParent": ["meeting-list"],
                'routeUrl': 'meeting-list',
                "isVisibleOnNavigation": false,
                'title': 'Manage Meeting Types',
                "breadcrumbsTitle": "Manage Meeting Types",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/meetingtype",
                "uniqueKey": "meeting-list",
                "oslMethod": "PUT"
            },
            //BOS VOLUME PERMISSION
            {
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'bos-volume/list',
                "isVisibleOnNavigation": true,
                'title': 'Manage BOS Volume Location',
                "breadcrumbsTitle": "BOS Volume Location",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/bosVolume/*",
                "uniqueKey": "bos-volume",
                "oslMethod": "GET"
            },
            {// BOS Volume Add Permission
                "permissionType": "Add",
                "hasPageParent": ["bos-volume"],
                'routeUrl': 'bos-volume/add',
                "isVisibleOnNavigation": false,
                'title': 'Add BOS Volume Location',
                "breadcrumbsTitle": "Add BOS Volume Location",
                "breadcrumbsParentPath": "bos-volume",
                "breadcrumbsParentTitle": "BOS Volume Location",
                "oslUrl": "/bosVolume/*",
                "uniqueKey": "bosVolume-add",
                "oslMethod": "POST"
            },
            { // BOS Volume Update Permission
                "permissionType": "Update",
                "hasPageParent": ["bos-volume"],
                'routeUrl': 'bos-volume/edit/:bosId?',
                "isVisibleOnNavigation": false,
                'title': 'Update BOS Volume Location',
                "breadcrumbsTitle": "Update BOS Volume Location",
                "breadcrumbsParentPath": "bos-volume",
                "breadcrumbsParentTitle": "BOS Volume Location",
                "oslUrl": "/bosVolume/*",
                "uniqueKey": "bosVolume-edit",
                "oslMethod": "PUT"
            },
            { // BOS Volume Delete Permission
                "permissionType": "Delete",
                "hasPageParent": ['bos-volume'],
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/bosVolume/*",
                "uniqueKey": "bosVolume-delete",
                "oslMethod": "DELETE"
            },
        ]
    },
    // Ballot Rule Management  Module JSON
    {
        'ModuleName': "Ballot Rule Management",
        'ModuleKeys': MODULES[3],
        'iconClass': 'cog  icon',
        "openNewApp": true,
        'isShow': false,
        'hasNavChild': false,
        'routeUrl': "re",
        "isVisibleOnNavigation": true,
        "searchParams": {},
        [MODULES[3]]: [
            {// Ballot Rule Management View Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 're',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "Ballot Rule Management",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/getProcessList/*",
                "uniqueKey": "ballot-rule-management-view",
                "oslMethod": "GET"
            }, {// Ballot Rule Management Add Permission
                "permissionType": "Add",
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageProcess/*",
                "uniqueKey": "ballot-rule-management-add",
                "oslMethod": "POST"
            },
            {// Ballot Rule Management Update Permission
                "permissionType": "Update",
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageProcess/*",
                "uniqueKey": "ballot-rule-management-update",
                "oslMethod": "PUT"
            },
            { // Ballot Rule Management Delete Permission
                "permissionType": "Delete",
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/manageProcess/*",
                "uniqueKey": "ballot-rule-management-delete",
                "oslMethod": "DELETE"
            },
            { // Ballot Rule Management Publish Permission
                "permissionType": "Publish",
                'routeUrl': '',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/publishProcess/*",
                "uniqueKey": "ballot-rule-management-Publish",
                "oslMethod": "PUT"
            },
        ]
    },
    // User Management Module JSON
    {
        'ModuleName': "User Management",
        'ModuleKeys': MODULES[4],
        'iconClass': 'user  icon',
        'isShow': false,
        'hasNavChild': false,
        'routeUrl': "user",
        "isVisibleOnNavigation": true,
        "searchParams": {},
        [MODULES[4]]: [
            {// User List Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': 'user',
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "User",
                "breadcrumbsParentPath": "",
                "breadcrumbsParentTitle": "",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": "user-list",
                "oslMethod": "GET"
            },
            {// User Details Permission
                "permissionType": "View",
                "hasPageParent": [],
                'routeUrl': "user/details/:UserId?",
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "User Details",
                "breadcrumbsParentPath": "user",
                "breadcrumbsParentTitle": "User",
                "oslUrl": "/usermanagement/*", // "/userPrivilege/1",
                "uniqueKey": "user-detail",
                "oslMethod": "GET"
            },
            {// User Add Permission
                "permissionType": "Add",
                "hasPageParent": ["user-detail", "user-list"],
                'routeUrl': "user/add",
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "Add User",
                "breadcrumbsParentPath": "user",
                "breadcrumbsParentTitle": "User",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": "user-add",
                "oslMethod": "POST"
            },
            {// User Update Permission
                "permissionType": "Update",
                "hasPageParent": ["user-detail", "user-list"],
                'routeUrl': "",
                "isVisibleOnNavigation": false,
                'title': '',
                "breadcrumbsTitle": "Add User",
                "breadcrumbsParentPath": "user",
                "breadcrumbsParentTitle": "User",
                "oslUrl": "/usermanagement/*",
                "uniqueKey": "user-update",
                "oslMethod": "PUT"
            }
        ]
    }
];

// User Privilege Type
export const USERPRIVILEGE = {
    PUBLISH: "Publish",
    VIEW: "View",
    ADD: "Add",
    DELETE: "Delete",
    UPDATE: "Update",
    All: "All"
}

export const USERROLES = {
    ADMIN: "1",
    ASTM_STAFF: "2"
}


// TODO : get domain prefix to set cookies doamin wise.
export const getDoaminPrefix = () => {
    const hostname = window.location.hostname;
    let prefixName = '';
    if (hostname) {
        let envPrefix = hostname.indexOf('-') > -1 ? hostname.split('-') : '';
        if (envPrefix && envPrefix.length > 0) {
            prefixName = `${envPrefix[0].toUpperCase()}`;
            if (prefixName == 'QA') {
                prefixName = 'TEST'
            }
            if (['DEV', 'TEST', 'STAGE'].includes(prefixName)) {
                prefixName = prefixName + '-';
            }
        }
    }
    return prefixName;
}

export const cookieKeysEnum = {
    'APP_SESSIONID': `${getDoaminPrefix()}MCS-AZURE-APP-SESSION-ID`,
    'accessToken': `${getDoaminPrefix()}MCS-AZURE-ACESS-TOKEN`
}
