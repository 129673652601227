import React from 'react';
import { Modal } from 'semantic-ui-react';
import { NewClassificationModel } from 'models/Membership/classificationModels';
import AddClassificationForm from 'components/forms/Membership/addClassificationForm';

export class AddClassificationModal extends React.Component {
    state = { isAdded: false };
    closeModal = () => {
        this.props.closeModal(3);
    }

    handleSubmit = (values) => {
        if (!this.state.isAdded) {
            this.setState({ isAdded: true });
            const self = this;
            const classificationObj = NewClassificationModel;
            try {
                classificationObj.Name = values.Type.trim();
                classificationObj.Description = values.Description;
                classificationObj.ClassificationTypeId = 1;
                classificationObj.IsApplicableToAllCommittees = self.props.initialValues.isApplicableToAllCommittees;
                classificationObj.colorCode = self.props.initialValues.colorCode;
                classificationObj.isAddedColor = self.props.initialValues.isAddedColor;
                classificationObj.colorName = self.props.initialValues.colorName;

                self.props.addClassification(classificationObj, (response) => {
                    if(response){
                        this.props.showLoader(true)
                        self.props.closeModal(3);
                        this.setState({ isAdded: false });
                    }else{
                        this.setState({ isAdded: false }); 
                    }
                   
                });
            }
            catch (err) {
                this.props.showLoader(false)
                this.setState({ isAdded: false });
            }
        }
    };

    render() {

        return (
            <Modal open='true' style={{ 'width': '780px' }} data-testid="addMembershipClassification">
                <Modal.Header>Add Classification Type <i onClick={this.closeModal} class="close">&#x2716;</i></Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <AddClassificationForm {...this.props} onSubmit={this.handleSubmit} onCancel={this.closeModal} />
                    </Modal.Description>
                </Modal.Content>

            </Modal>
        );
    }
}

export default AddClassificationModal;
