import axios from 'axios';
import getApiUrl from 'helpers/apiUrls';
import * as commonActions from 'actions/commonActions';
import { GetSubmoduelOnModuleKey } from 'models/Membership/membershipReportsModel';

export const getMembershipReportListAction = (dispatch, callback) => {
	let url = getApiUrl('membershipReports', 'listMembershipReport') + `/${GetSubmoduelOnModuleKey}`;
	dispatch(commonActions.setLoader(true));
	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, response.data.message));
				callback();
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7170'));
			callback();
		});
};

export const addMembershipReportsAction = (dispatch, appObj, callback) => {
	const url = getApiUrl('membershipReports', 'addMembershipReport');
	dispatch(commonActions.setLoader(true));
	axios.post(url, appObj)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data);
				dispatch(commonActions.setMessage(true, '7171'));
			}
			callback(response.data);
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '404'));
		});
}

export const getReportTypeListAction = (dispatch, callback) => {
	let url = getApiUrl('membershipReports', 'listMembershipTypeList') + `/${GetSubmoduelOnModuleKey}`;
	dispatch(commonActions.setLoader(true));
	axios.get(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true,'7177'));
				callback();
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '404'));
			callback();
		});
};

export const deleteReportAction = (dispatch, data, callback)=>{
	let url = getApiUrl('membershipReports', 'deleteMembershipReport');
	dispatch(commonActions.setLoader(true));
	axios.delete(url, {data})
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				if (response.data.status) {
					callback(response.data);
					dispatch(commonActions.setMessage(true, '7177'));
				}
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, '7173'));
				callback();
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '404'));
			callback();
		});
}

export const editMembershipReportsAction = (dispatch, appObj, callback) => {
	const url = getApiUrl('membershipReports', 'editMembershipReport');
	dispatch(commonActions.setLoader(true));
	axios.put(url, appObj)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data);
				dispatch(commonActions.setMessage(true, '7178'));
			}
			callback(response.data);
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '404'));
		});
}
