import axios from 'axios';
import getApiUrl from 'helpers/apiUrls'
import * as commonActions from 'actions/commonActions';
import * as type from 'actionTypes';

export const getMemberShipDocumentsList = (dispatch, callback) => {

	const url = getApiUrl('membershipDocument', 'getMemberShipDocumentsList');
	dispatch(commonActions.setLoader(true));
	axios.get(url)
		.then((response) => {

			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setLoader(false));
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '7017'));
		});
};

export const addMembershipDocument = (dispatch, document, callback) => {
	const url = getApiUrl('membershipDocument', 'addMembershipDocument');
	dispatch(commonActions.setLoader(true));

	axios.post(url, document)
		.then((response) => {
			dispatch(commonActions.setLoader(false));

			if (response.data.status) {
				callback(response.data);
				dispatch(commonActions.setMessage(true, '5007'));
			}
			// Handle error case
			else if (response.data.message === "2210") {
				dispatch({ type: type.ADD_DOCUMENT, isDuplicateDocName: true })
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}

		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '2016'));
		});
};

export const editMembershipDocument = (dispatch, appObj, callback) => {
	const url = getApiUrl('membershipDocument', 'editMembershipDocument');
	dispatch(commonActions.setLoader(true));
	axios.put(url, appObj)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback(response.data.content);
				dispatch(commonActions.setMessage(true, '5006'));
			}
			// Handle error case
			else if (response.data.message === "2210") {
				dispatch({ type: type.EDIT_DOCUMENT, isDuplicateDocName: true })
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '2017'));
		});
}

export const deleteDocument = (dispatch, payload, callback) => {
	const fileName = window.btoa(`${payload.BlobFileName}.${payload.Type}`);
	const documentId = payload.Id;
	const url = getApiUrl('membershipDocument', 'deleteMembershipDocument') + '/' + documentId + '/' + fileName;
	dispatch(commonActions.setLoader(true));
	axios.delete(url)
		.then((response) => {
			dispatch(commonActions.setLoader(false));
			if (response.data.status) {
				callback();
				dispatch(commonActions.setMessage(true, '5019'));
			}
			// Handle error case
			else if (response.data.message.toString() === "2212") {
				callback();
				dispatch(commonActions.setMessage(true, '2212'));
			} else {
				dispatch(commonActions.setMessage(true, response.data.message));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setLoader(false));
			dispatch(commonActions.setMessage(true, '2017'));
		});
}


export const getAwsSignedAction = (dispatch, fileName, callback) => {
	const url = getApiUrl('membershipDocument', 'getSignedUrl') + '/' + fileName;
	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				callback(response.data.content);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, 7103));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, 7103));
		});
};

export const downloadFileAction = (dispatch, payload, callback) => {
	const fileName = `${payload.BlobFileName}`;
	const url = getApiUrl('membershipDocument', 'downloadFile') + '/' + window.btoa(fileName);
	axios.get(url)
		.then((response) => {
			if (response.data.status) {
				callback(response.data);
			} else {
				// Handle error case
				dispatch(commonActions.setMessage(true, 7103));
			}
		})
		.catch((err) => {
			dispatch(commonActions.setMessage(true, 7103));
		});

};

export default {
	getMemberShipDocumentsList,
	addMembershipDocument,
	editMembershipDocument,
	deleteDocument,
	getAwsSignedAction,
	downloadFileAction
}
