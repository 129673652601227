import axios from 'axios';
import * as lodash from 'lodash';
import { logout, setMessage } from './actions/commonActions';
import getApiUrl from './helpers/apiUrls';
import * as utilCommon from './helpers/utilCommon';
import { cookieKeysEnum } from './models/Common/commonModels';

const excludeRequests = [
    getApiUrl('login', 'getAzureADRefreshToken'),
    getApiUrl('login', 'login'),
    getApiUrl('login', 'authenticateByAzureAD')
];
let isRefreshing = false;
let isLogoutRequest = false;

let tokenSubscribers = [];
const setupInterceptors = (store) => {

    // All Interceptor Request
    axios.interceptors.request.use((config) => {
        // Set app request token.
        setAuthorizationOnApp(config);
        // Set Unique UDID for each request.
        config.headers.uniquerequestid = utilCommon.generateUUID();
        // TODO : Check Hostname is localhost and set azure request header.
        if (utilCommon.isLocalhost()) {
            config.headers.AZURE_INIT_REQUEST = utilCommon.cookiesManager.getCookie(cookieKeysEnum.APP_SESSIONID, false)
        }
        return config;
    });

    // All Interceptor Response
    axios.interceptors.response.use((response) => {
        if (response && response.data) {
            // Validate Data is in String Format
            if (lodash.isString(response.data)) {
                // Parse JSON for security
                let updateData = response.data.replace(')]}', "");
                response.data = JSON.parse(updateData);
            }
            let msgCode = lodash.get(response.data, 'message');
            // Pass code in auto logout function.
            autoLogoutOnInvalidCodes(msgCode, store);
        }
        return response;
    }, (error) => {
        const { config, response: { status, data: { message } } } = error;
        const originalRequest = config;
        if (status === 401 && process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
            let email = utilCommon.getCurrentUserEmail();
            // TODO : Force Logout while no valid email id found.
            if (!isRefreshing) {
                if (!email) {
                    autoLogoutOnInvalidCodes(status, store);
                    return false;
                }
                // If code will reach here,then new token will be updated.
                isRefreshing = true;
                getAzureADRefreshTokenAction({ "email": email }, (tokenResult) => {
                    if (tokenResult.status) {
                        isRefreshing = false;
                        onRrefreshedHandler(tokenResult.accessToken);
                        tokenSubscribers = [];
                    }
                });
            }
            // TODO : Auto-Logout while refresh token call failer [401:Status, 1005:Message] => No Redis Found in Redis Cache.
            if (config.url === getApiUrl('login', 'getAzureADRefreshToken') && Number(message) === 1005) {
                autoLogoutOnInvalidCodes(message, store);
                return false;
            }
            // TODO : Re-Request  for All failer calls.
            return new Promise((resolve, reject) => {
                subscribeToken(token => {
                    // Replace the expired token and retry
                    originalRequest.headers.Authorization = 'Bearer ' + token;
                    resolve(axios(originalRequest));
                });
            });
        }
        return Promise.reject(error);
    });

};

// Refresh token call While got 401 (Un-Authorized) Status.
export const getAzureADRefreshTokenAction = (inputDTO, callback) => {
    const url = getApiUrl('login', 'getAzureADRefreshToken');
    axios.post(url, inputDTO).then((response) => {
        if (lodash.get(response.data, 'success')) {
            callback({ status: true, accessToken: response.data.accessToken })
            utilCommon.setLoginDataToCacheManager(response, 'REFRESH'); // Add Refresh Token Details in LS.
        } else {
            callback({ status: false, accessToken: null })
            utilCommon.setLoginDataToCacheManager(response, 'DELETE'); // For delete Detials in LS.
        }

    })
};

// Cached all Http calls which one failed
export const subscribeToken = (cb) => {
    tokenSubscribers.push(cb);
}

// Refresh handler while all http calls failed
export const onRrefreshedHandler = (token) => {
    tokenSubscribers.map(cb => cb(token));
}

// Auto Logout while found unauthorized request.
export const autoLogoutOnInvalidCodes = (code, store) => {
    // In case of Auto logout while getting code (1005) from server.

    if (code && Number(code) === 1005 && !isLogoutRequest) {
        isLogoutRequest = true;
        if (window.location.pathname != '/') {
            store.dispatch(setMessage(true, '401'));
            logout(store.dispatch);
            // Logout If token Unauthorized
            utilCommon.cacheManager.clearSession();
            utilCommon.cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
            setTimeout(() => { window.location.assign(utilCommon.clearAzureAdAuthCodeAction()) }, 100)
        }
    }
}

// Custom Logout While Get 401 on Error.
export const customLoginClearCache = (status, store) => {
    if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "false" && status === 401) {
        setTimeout(() => store.dispatch(setMessage(true, status)), 0);
        // Logout If token Unauthorized
        utilCommon.cacheManager.clearSession();
        if (window.location.pathname != '/') {
            store.dispatch(logout(store.dispatch));
        }
        return false;
    }
}

// Set Authorization token in all request.
export const setAuthorizationOnApp = (config) => {
    let token = null;
    if (utilCommon.isReactAppAuthorized() && excludeRequests.indexOf(config.url) === -1 && config.url.indexOf(process.env.REACT_APP_AWS_EXCLUDE_INBOUND_BEARER_URL) === -1 && config.url.indexOf(process.env.REACT_APP_AWS_EXCLUDE_OUTBOUND_BEARER_URL) === -1) {
        token = utilCommon.cacheManager.getItem(cookieKeysEnum.accessToken, false);
        config.headers.Authorization = 'Bearer ' + token;
    }
}

export default setupInterceptors;


