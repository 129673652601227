import * as commonActions from 'actions/commonActions';
import * as type from 'actionTypes';
import axios from 'axios';
import getApiUrl from 'helpers/apiUrls';

const membershipTypeList = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_LIST,
    payload: data
  };
}

const membershipTypeEditAction = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_EDIT,
    payload: data
  };
}

const membershipTypeDetailsSubmit = (reqData, appData = [], docData = [], period = []) => {
  return {
    type: type.MEMBERSHIPTYPE_DETAILS_SUBMIT,
    payload: { details: reqData, app: appData, doc: docData, period: period }
  }
}

const membershipTypeSettingSubmit = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_SETTING_SUBMIT,
    payload: data
  }
}

const membershipTypeSettingBackEvent = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_SETTING_BACK_EVENT,
    payload: data
  }
}
const holdSettingValueEvent = (data) => {

  return {
    type: type.MEMBERSHIPTYPE_SETTING_HOLD_VALUE,
    payload: data
  }
}

const membershipTypeEnableWebDocSelectedEvent = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_ENABLE_WEB_DOC_SELECTED_EVENT,
    payload: data
  }
}

const membershipTypeEnableWebAppDocSelectedEvent = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_ENABLE_WEB_APP_DOC_SELECTED_EVENT,
    payload: data
  }
}


const membershipTypeEnableWebDataEvent = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_ENABLE_WEB_APP_DATA_EVENT,
    payload: data
  }
}
export const membershiptTypeSettingDocDelete = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_DOC_DELETE,
    payload: data
  }
}

export const getDocumentAndAppList = (dispatch, req) => {

  const appUrl = getApiUrl('membershipType', 'getApplicationStatus');
  const DocUrl = getApiUrl('membershipType', 'getDocumentStatus');
  const periodUrl = getApiUrl('membershipType', 'membershipTypeGetPeriod');
  dispatch(commonActions.setLoader(true));
  Promise.all([
    axios.get(appUrl + '/1'),
    axios.get(DocUrl + '/1'),
    axios.get(periodUrl)
  ])
    .then(([appRes, docRes, period]) => {

      if (appRes.data.status && docRes.data.status) {
        dispatch(commonActions.setLoader(false));
        dispatch(membershipTypeDetailsSubmit(req, appRes.data.content, docRes.data.content, period.data.content))
      } else {
        dispatch(commonActions.setLoader(false));
        dispatch(commonActions.setMessage(true, '6000'));
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, '7025'));

    });

};

export const getMembershipTypeList = (dispatch) => {

  const url = getApiUrl('membershipType', 'getMembershipTypeList');
  dispatch(commonActions.setLoader(true));

  axios.get(url)
    .then((response) => {

      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(membershipTypeList(response.data.content));
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, response.data.message));
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, '7025'));

    });
};

export const memberTypeSubmit = (dispatch, data, callbackFun) => {

  const url = getApiUrl('membershipType', 'membershipTypePost');
  dispatch(commonActions.setLoader(true));

  axios.post(url, data)
    .then((response) => {

      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        let content = response.data.content ? response.data.content : [];
        dispatch(membershipTypeSettingSubmit(content));
        dispatch(commonActions.setMessage(true, '5010'));
        callbackFun();
      }
      // Handle error case
      else if (response.data.message !== 2303) {
        dispatch(commonActions.setMessage(true, response.data.message));
      }

    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      return false;
    });
};

export const getMembershipTypeEdit = (dispatch, id, callbackFun) => {

  const url = getApiUrl('membershipType', 'membershipTypeEdit');
  const validateMemberTypeUrl = getApiUrl('membershipType', 'CheckMembershipType');
  dispatch(commonActions.setLoader(true));
  Promise.all([
    axios.get(url + id),
    axios.get(validateMemberTypeUrl + '/' + id)
  ]).then(([response, validateResponse]) => {
    dispatch(commonActions.setLoader(false));
    if (response.data.status && response.data.content && response.data.content.MembershipTypeId) {
      let fee = response.data.content.FeeAmount;
      response.data.content.FeeAmount = (fee === 0 || fee === '0') ? '' : fee;
      dispatch(membershipTypeEditAction({ memberShipTypeData: response.data.content, suppressData: validateResponse.data }));
      callbackFun(response.data.content);
    } else {
      // Handle error case
      dispatch({ type: type.SHOW_NOT_FOUND_PAGE }) // Code: - 6005
    }
  })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, '7025'));
    });
};

const membershipTypeSubmitOnEditDetailsAction = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_EDIT_ON_DEATILS,
    payload: data
  }
}

export const membershipTypeDetailsSubmitOnEditAction = (dispatch, data, callbackFun, props) => {
  const url = getApiUrl('membershipType', 'membershipTypeSubmitEditDetails');
  dispatch(commonActions.setLoader(true));
  axios.put(url, data)
    .then((response) => {

      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(membershipTypeSubmitOnEditDetailsAction(response.data.content));
        callbackFun(response.data.content, props);
        dispatch(commonActions.setMessage(true, '5011'));
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, response.data.message));
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, '7025'));

    });
};

const membershipTypeSubmitOnEditSettingAction = (data) => {
  return {
    type: type.MEMBERSHIPTYPE_EDIT_ON_SETTING,
    payload: data
  }
}

export const membershipTypeSettingSubmitOnEditAction = (dispatch, data, callbackFun) => {

  const url = getApiUrl('membershipType', 'membershipTypeSubmitEditSetting');
  dispatch(commonActions.setLoader(true));

  axios.put(url, data)
    .then((response) => {

      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(membershipTypeSubmitOnEditSettingAction(response.data.content));
        dispatch(commonActions.setMessage(true, '5012'));
        callbackFun(true);
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, '7028'));
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, '7025'));

    });
};

export const membershiptypeDeleteAppDocOnView = (dispatch, data, callbackFun) => {

  const url = getApiUrl('membershipType', 'membershiptypeDeleteAppDoc');
  dispatch(commonActions.setLoader(true));
  axios.post(url, data)
    .then((response) => {

      dispatch(commonActions.setLoader(false));
      if (response.data.status) {
        dispatch(membershiptTypeSettingDocDelete(response.data.content));
        callbackFun();
        dispatch(commonActions.setMessage(true, '5019'));
      } else {
        // Handle error case
        dispatch(commonActions.setMessage(true, '7058'));
      }
    })
    .catch((err) => {
      dispatch(commonActions.setLoader(false));
      dispatch(commonActions.setMessage(true, '7025'));

    });
};

export const checkUniqueName = (dispatch, data, callbackFun) => {
  const url = getApiUrl('membershipType', 'checkUniqueName');

  axios.get(url + '/' + data)
    .then((response) => {
      if (response.data.status) {
        if (response.data.content) {
          callbackFun(true, data);
        } else {
          callbackFun(false, data);
        }
      }
    })
    .catch((err) => {

console.log('err', err)
    });
}

export default {
  getMembershipTypeList,
  membershipTypeSettingBackEvent,
  getDocumentAndAppList,
  membershipTypeEnableWebDocSelectedEvent,
  membershipTypeEnableWebAppDocSelectedEvent,
  membershipTypeEnableWebDataEvent,
  memberTypeSubmit,
  getMembershipTypeEdit,
  membershipTypeDetailsSubmitOnEditAction,
  membershipTypeSettingSubmitOnEditAction,
  membershiptypeDeleteAppDocOnView,
  holdSettingValueEvent,
  checkUniqueName,
  membershiptTypeSettingDocDelete
}
