// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.public-DraftEditor-content span, .public-DraftEditor-content p, .public-DraftEditor-content li {
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
}

* {
  font-size: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/TextEditor/text-editor.css"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,oBAAA;AADJ;;AAKA;EACI,kBAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,oBAAA;AAFJ","sourcesContent":["\n\n.public-DraftEditor-content span, .public-DraftEditor-content p, .public-DraftEditor-content li {\n    font-size: inherit;\n    font-weight: inherit;\n    font-style: inherit;\n    font-family: inherit;\n\n}\n\n* {\n    font-size: inherit;\n    font-size: inherit;\n    font-weight: inherit;\n    font-style: inherit;\n    font-family: inherit;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
