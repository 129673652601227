import * as commonActions from 'actions/commonActions';
import membershipTypeActions from 'actions/Membership/membershipTypeActions';
import AddMemberShip from 'components/forms/Membership/MembershipType/addMembershipTypeForm';
import { connect } from "react-redux";

const mapStateToProps = ({ membershipType, i18nReducer, commonReducer }) => ({
  list: membershipType._list,
  initialValues: membershipType.initialValues ? membershipType.initialValues : undefined,
  details: membershipType._details,
  code: i18nReducer.dictionaries[i18nReducer.currentLanguage],
  isEdit: membershipType.isEdit,
  activeTab: membershipType.activeTab,
  type: membershipType.type,
  app: membershipType._app,
  doc: membershipType._doc,
  period: membershipType._period,
  _enableWebData: membershipType._enableWebData,
  validateSuppress: membershipType.validateSupress,
  userPermission: commonReducer.userPermission
});

const mapDispatchToProps = (dispatch) => ({
  membershipTypeList: () => membershipTypeActions.getMembershipTypeList(dispatch), // Get membership type list
  membershipTypeDetailsSubmit: (data) => membershipTypeActions.getDocumentAndAppList(dispatch, data), // Submit on details page
  settingBackEvt: (data) => dispatch(membershipTypeActions.membershipTypeSettingBackEvent(data)), // Back from setting page to details page
  memberTypeSubmit: (data, callbackFun) => membershipTypeActions.memberTypeSubmit(dispatch, data, callbackFun), // Submit on setting pgae
  selectedAppDocEvt: (data) => dispatch(membershipTypeActions.membershipTypeEnableWebAppDocSelectedEvent(data)), // Select application - checked and documment event
  membershipTypeEditEvt: (id, callbackFun) => membershipTypeActions.getMembershipTypeEdit(dispatch, id, callbackFun), // Get data by id for display detials
  membershipTypeDetailsSubmitOnEdit: (data, callbackFun, props) => membershipTypeActions.membershipTypeDetailsSubmitOnEditAction(dispatch, data, callbackFun, props),
  membershipTypeSettingSubmitOnEdit: (data, callbackFun) => membershipTypeActions.membershipTypeSettingSubmitOnEditAction(dispatch, data, callbackFun),
  selectedDocEvt: (data) => dispatch(membershipTypeActions.membershipTypeEnableWebDocSelectedEvent(data)),
  selectedDataEvt: (data) => dispatch(membershipTypeActions.membershipTypeEnableWebDataEvent(data)),
  setInitValueEvt: (data) => dispatch(membershipTypeActions.setInitialValuesAction(data)),
  setMsgEditNotWork: () => dispatch(commonActions.setMessage(true, '7027')),
  deleteAttacheDoc: (data, callbackFun) => membershipTypeActions.membershiptypeDeleteAppDocOnView(dispatch, data, callbackFun),
  membersettingHoldValue: (data) => dispatch(membershipTypeActions.holdSettingValueEvent(data)),
  checkUniqueName: (data, callbackFun) => membershipTypeActions.checkUniqueName(dispatch, data, callbackFun),
  displayNoChangesMadeMessage: () => dispatch(commonActions.setMessage(true, '2040'))

});

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberShip);
