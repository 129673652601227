import * as commonActions from 'actions/commonActions';
import ClassificationList from 'components/lists/Membership/classificationList';
import { connect } from "react-redux";
import { addClassification, editClassification, getCheckClassificationAssign, getClassificationDetails, getClassificationList, getColorPicker, resequenceClassifications } from '../../actions/Membership/classificationActions';

const mapStateToProps = state => {
    return {
        initialValues: state.classificationReducer.classificationData, // Needed to initialize values for redux form
        isDuplicateClassification: state.classificationReducer.isDuplicateClassification,
        requiredClassificationTypeError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2021'].text,
        maxlengthTypeError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2022'].text,
        requiredDescriptionError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2023'].text,
        maxlengthDescriptionError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2024'].text,
        duplicateTypeError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2401'].text,
        noRecordMessage: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['6006'].text,
        duplicateColorError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2403'].text,
        requiredColor: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2026'].text,
        isDuplicateColor: state.classificationReducer.isDuplicateColor,
        customColorNameRequired: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2405'].text,
        duplicateCustomColorError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2404'].text,
        duplicateSlideColorError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2406'].text,
        loading: state.commonReducer.loading,
        userPermission: state.commonReducer.userPermission
    }
};

const mapDispatchToProps = (dispatch) => ({
    getClassificationList: (callback) => {
        getClassificationList(dispatch, callback);
    },
    getClassificationDetails: (classificationTypeId, callback) => {
        getClassificationDetails(classificationTypeId, dispatch, callback);
    },
    addClassification: (classification, callback) => {
        addClassification(dispatch, classification, callback);
    },
    editClassification: (classification, callback) => {
        editClassification(dispatch, classification, callback);
    },
    getCheckClassificationAssign: (classificationId, callback) => {
        getCheckClassificationAssign(dispatch, classificationId, callback);
    },
    changeApplicableAll: (isApplicableToAllCommittees) => dispatch({ type: 'APPLICABLE_ALL_COMMITTEES', isApplicableToAllCommittees: isApplicableToAllCommittees }),
    getcolorPicker: (classificationColorId, callback) => {
        getColorPicker(dispatch, classificationColorId, callback)
    },
    showLoader: (isLoading) => {
        dispatch(commonActions.setLoader(isLoading));
    },
    resequenceClassifications: (classificationsList, callback) => {
        resequenceClassifications(classificationsList, callback, dispatch);
    },
    pickColor: (colorCode, isAddedColor, colorName) => dispatch({ type: 'PICK_COLOR', colorInfo: { colorCode, isAddedColor, colorName } }),
    displayNoChangesMadeMessage: () => dispatch(commonActions.setMessage(true, '2040'))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationList);
