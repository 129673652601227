import React, { createContext, useEffect, useState } from "react";
import { flagFeature } from "../actions/commonActions";

const InitialValue = {
  isInitializing: false,
  FlagFeaturesData: {},
};

export const FeatureFlag = createContext(InitialValue);
export const FeatureFlagProvider = ({ children }) => {
  const [featureFlag, setFeatureFlag] = useState(InitialValue);
  const [loadChild, setLoadChild] = useState(false);

  const updateFlag = (data) => {
    setFeatureFlag(data);
  };
  useEffect(() => {
    flagFeature((response) => {
      setLoadChild(true);
      updateFlag({ isInitializing: response.data.Status, FlagFeaturesData: response.data.Result });
    });
  }, [true]);
  return <FeatureFlag.Provider value={featureFlag}>{loadChild && children} </FeatureFlag.Provider>;
};
