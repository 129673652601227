import { addFaq, deleteFAQ, editFaq, getFaqDetails, getFaqList, resequenceFaqs } from 'actions/Membership/faqActions';
import MembershipFaqList from 'components/lists/Membership/faqList';
import { connect } from "react-redux";
import commonActions from 'actions/commonActions';


const mapStateToProps = state => {
    return {
        initialValues: state.faqReducer.faqData,
        isDuplicateQuestion: state.faqReducer.isDuplicateQuestion,
        requiredFaqQuestionError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2016'].text,
        requiredFaqAnswerError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2017'].text,
        maxlengthQuestionError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2014'].text,
        maxlengthAnswerError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2018'].text,
        duplicateQuestionError: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['2020'].text,
        noRecordMessage: state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage]['6001'].text,
        userPermission: state.commonReducer.userPermission
    }
};

const mapDispatchToProps = (dispatch) => ({
    getFaqList: (callback) => {
        getFaqList(dispatch, callback);
    },
    getFaqDetails: (faqId, callback) => {
        getFaqDetails(faqId, dispatch, callback);
    },
    addFaq: (faq, callback) => {
        addFaq(dispatch, faq, callback);
    },
    editFaq: (faq, callback) => {
        editFaq(dispatch, faq, callback);
    },
    resequenceFaqs: (faqsList) => {
        resequenceFaqs(dispatch, faqsList);
    },
    deleteFAQ: (faqId, callback) => {
        deleteFAQ(dispatch, faqId, callback);
    },
    displayNoChangesMadeMessage: () => dispatch(commonActions.setMessage(true, '2040'))
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipFaqList);