import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './text-editor.css';
import htmlToDraft from 'html-to-draftjs';

import { unemojify } from "node-emoji";

import draftToHtml from 'draftjs-to-html';

class ControlledEditor extends Component {

  constructor(props) {
    super(props);

    // Initialize the state depending on the input value
    if (props.value && props.value.length > 0) {
      this.state = {
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(props.value)
          )
        )
      };
    } else {
      this.state = {
        editorState: EditorState.createEmpty()
      };
    }

    this.props.onChange(
      draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
    );


  }
  finalValue = (editorState) => {
    if (editorState.getCurrentContent().getPlainText() && editorState.getCurrentContent().getPlainText()
      .trim() !== '') {
      return unemojify(
        draftToHtml(convertToRaw(editorState.getCurrentContent()))
      )
    }
    return "<p></p>";
  }
  onEditorStateChange = (editorState) => {
    const { onChange, value } = this.props;
    const newValue = this.finalValue(editorState);
   

    // Hanldle code for length validation // default 5000 if required then override to MAX_LENGTH to props
    /* Like as : - this.setState({
      MAX_LENGTH : this.props.MAX_LENGTH
    })  use it to componentDidMount */
    const contentState = editorState.getCurrentContent();
    const oldContent = this.state.editorState.getCurrentContent();
    
    if (contentState === oldContent || (contentState.getPlainText().length >= 0 && (contentState.getPlainText().trim()!=='' || oldContent.getPlainText().trim()!==''))) {
      if (value !== newValue) {
        onChange(newValue);

      }
      this.setState({ editorState });
    } else {
      const editorState = EditorState.moveFocusToEnd(
        EditorState.push(
          this.state.editorState,
          ContentState.createFromText(oldContent.getPlainText())
        )
      );
      if (value !== newValue) {
        onChange(newValue);

      }
      this.setState({ editorState });
    }
  };

  render() {
    const { editorState, dafaultEditorState } = this.state;
    const { label, required } = this.props
    return (
      <div>
        <label className="label">
          {(required) && (<i aria-hidden="true" class="asterisk  icon"></i>)}
          {label}
        </label>
        <Editor
          data-testid = 'editor'
          editorState={editorState}
          defaultEditorState={dafaultEditorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'link', 'history']
          }}
        />
      </div>
    )
  }
}

export default ControlledEditor;

