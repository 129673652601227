
import logoImg from "assets/images/astm-internal.png";
import React from "react";
import { Icon, Image } from 'semantic-ui-react';

const PrivilegeNotFound = () => (
    <div className="loginPage privligeWrap">

        <figure className="logo">
            <Image src={logoImg} />
        </figure>

        <div className="loginWrap">
            <div className="noPrivilege">
                <span className="dontSign"><Icon name="exclamation triangle" /></span>
                <span className="permissionSub">You do not have permission to access this page. </span>
            </div>
        </div>


    </div>
)
export default PrivilegeNotFound;
