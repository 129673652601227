import React from 'react';
import { Popup } from 'semantic-ui-react';

const CustomTooltip = (props) => (

  <div className="commonToolTip" {...props}>
    <Popup
      data-testid='tool-tip'
      trigger={props.icon}
      content={props.content}
      inverted
      hideOnScroll
      position={props.position}
      className={props.className}
    /></div>
)

export default CustomTooltip
